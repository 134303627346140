import styles from './WifiSignalIcon.module.css';

interface Props {
    quality: number
}

const baseCurve = <div className={`${styles.curve} ${styles.base}`} />

/**
 * Page for displaying the current status of the Network, and what the client can / cannot talk to.
 * Useful for troubleshooting purposes.
 */
export default function WifiSignalIcon({ quality }: Props) {

    const highCurve = quality > 80 ? <div className={`${styles.curve} ${styles.high}`} /> : null;

    const mediumCurve = quality > 60 ? <div className={`${styles.curve} ${styles.medium}`} /> : null;

    const lowCurve = quality > 40 ? <div className={`${styles.curve} ${styles.low}`} /> : null;
    return (
        <div className={styles.wifiSignalIcon}>
            {highCurve}
            {mediumCurve}
            {lowCurve}
            {baseCurve}
        </div>
    );
}