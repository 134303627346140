import { useCallback, useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { PrinterContext } from '../../../../HigherOrder/PrintController/PrintController';
import type { PrintRequest } from '../../../../HigherOrder/PrintController/PrintController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import type { FormValues, User } from '../Reports';
import type { TransactionTotals } from './ReportTable';
import { useTimeZone } from 'components/hooks/TimeZone';
import { format } from 'date-fns';

export function useSummaryPrinter(getUser: (clerkId: number) => User | undefined) {

    const { localFormat } = useTimeZone();
    const { auth } = useContext(AuthContext);
    const { print } = useContext(PrinterContext);
    const { values: { startDate, endDate, user, billPayReport, mobileTopUpReport, moneyOrderReport, giftCardReport, type, cashLoadReport, netSpendReport } } = useFormikContext<FormValues>();

    const abbreviations = useMemo(() => [
        billPayReport ? 'BP' : ''
        , mobileTopUpReport ? 'MTU' : ''
        , moneyOrderReport ? 'MO' : ''
        , giftCardReport ? 'GC' : ''
        , cashLoadReport ? 'AC' : ''
        , netSpendReport ? 'NS' : '']
        .filter((abbreviation) => !!abbreviation)
        , [billPayReport, cashLoadReport, giftCardReport, mobileTopUpReport, moneyOrderReport, netSpendReport]);

    const printSummary = useCallback((totals: TransactionTotals, containsCheckTransaction: boolean) => {
        return print(([
            { text: type === 'daily' ? 'Daily Report' : 'Date Range Report', style: { emphasized: true } },
            { text: Number(user) === -1 ? 'All User Report' : 'Single User Report', style: { emphasized: true } },
            { text: '' },
            {
                text: abbreviations.length === 5 ? `Combo ${abbreviations[0]}, ${abbreviations[1]}, ${abbreviations[2]}, ${abbreviations[3]}, & ${abbreviations[4]} Report`
                    : abbreviations.length === 4 ? `Combo ${abbreviations[0]}, ${abbreviations[1]}, ${abbreviations[2]}, & ${abbreviations[3]} Report`
                        : abbreviations.length === 3 ? `Combo ${abbreviations[0]}, ${abbreviations[1]}, & ${abbreviations[2]} Report`
                            : abbreviations.length === 2 ? `Combo ${abbreviations[0]} & ${abbreviations[1]} Report`
                                : abbreviations.length === 1 ? `${abbreviations[0]} Only Report`
                                    : `No Products Selected`
                , style: { emphasized: true }
            },
            { text: '' },
            { text: `Agent : ${auth?.agentId}` },
            { text: `Clerk : ${getUser(Number(user))?.username}` },
            { text: 'Report Run Time' },
            { text: localFormat(new Date(), 'yyyy-MM-dd HH:mm:ss zzz') },
            { text: type === 'daily' ? `Report Date: ${format(Number(startDate), 'yyyy-MM-dd')}` : `Start Date: ${format(Number(startDate), 'yyyy-MM-dd')}` },
            { text: type === 'daily' ? '' : `Stop Date: ${format(Number(endDate), 'yyyy-MM-dd') }` },
            { text: '' },
            { text: `Cash Payments: ${totals.cashTotal}` }
        ] as PrintRequest).concat(
            containsCheckTransaction ? [
                { text: `Check Payments: ${totals.checkTotal}` }
            ] : []
        ).concat([
            { text: `Processing Fees: ${totals.processFeeTotal}` },
            { text: `Agent Fees Colleted: ${totals.agentFeeTotal}` },
            { text: `Total Fees: ${totals.allFeeTotal}` },
            { text: `Grand Total: ${totals.grandTotal}` },
            { text: `Register Amt: ${totals.registerAmount}` },
            { text: `Deposit Amt: ${totals.depositAmount}` },
        ]), { noDuplicate: true });
    }, [abbreviations, auth?.agentId, endDate, localFormat, getUser, print, startDate, type, user]);

    return { printSummary }
}