import type { ReactNode } from 'react';
import type { FormikHelpers, FormikErrors, FormikProps } from 'formik';
import { Formik, Form } from 'formik';
import Keyboard from '../Keyboard/Keyboard';
import AnalyticsNotifier from './AnalyticsNotifier/AnalyticsNotifier';

interface Props {
    children: ReactNode | ((formikProps: FormikProps<any>) => ReactNode),
    numericOnly?: boolean,
    noKeyboard?: boolean,
    dynamicKeyboard?: boolean,
    className?: string,
    initialValues: any,
    onSubmit: (values: any, formikActions: FormikHelpers<any>) => void,
    onReset?: (values: any, formikActions: FormikHelpers<any>) => void,
    validate?: (values: any) => void | object | Promise<FormikErrors<any>>,
    enableReinitialize?: boolean
}

export default function AdvancedForm({ children, numericOnly, noKeyboard, dynamicKeyboard, className,
    initialValues, onSubmit, onReset, validate, enableReinitialize }: Props) {

    return (
        <Keyboard noKeyboard={noKeyboard} dynamicKeyboard={dynamicKeyboard} numericOnly={numericOnly} >
            <Formik initialValues={initialValues} onSubmit={onSubmit} onReset={onReset} validate={validate} enableReinitialize={enableReinitialize}>
                {(formikProps) =>
                    <Form autoComplete="off" className={`AdvancedForm ${className ?? ''} ${(noKeyboard || dynamicKeyboard) ? 'NoKeyboard' : ''}`}>
                        {typeof children === 'function' &&
                            children(formikProps)
                        }
                        {!(typeof children === 'function') &&
                            children
                        }
                        <AnalyticsNotifier />
                    </Form>
                }
            </Formik>
        </Keyboard >
    );
}