import { useFormikContext } from "formik";
import { useContext } from "react";
import CashField from "../../../../Common/CashField/CashField";
import { TrainingContext } from "../../../../HigherOrder/TrainingOverlay/TrainingOverlay";
import CartForm from "../CartForm/CartForm";
import './Amount.css'
import type { initialValues } from "../CashLoad";
import { tutorialAmount } from "../CashLoad";
import MessageAggregator from "../../../../Common/MessageAggregator/MessageAggregator";
import { tooltipBoldClass } from "utilities/tooltip";


export default function Amount() {

    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { values } = useFormikContext<typeof initialValues>();

    const isAmountTutorial = isCurrentPageTraining
        && values.cash !== tutorialAmount;

    return (
        <div className='Amount'>
            <div>
                <h1>Please Enter an Amount</h1>
                <CashField
                    autoFocus={true}
                    name='cash'
                    trainingFocused={isAmountTutorial} trainingTooltip={
                        <>
                            <div>ENTER THE AMOUNT</div>
                            <b className={tooltipBoldClass}>{'$' + (Number(tutorialAmount) / 100).toFixed(2)}</b>
                        </>
                    }
                    placement={'top'} />
                <div className='LegalMessage'>Between $25.00 and $500.00</div>
                <MessageAggregator />
            </div>
            <div>
                <CartForm />
            </div>
        </div>
    );
}