import { useContext, useState, useCallback, useMemo } from 'react';
import { differenceInYears, format } from 'date-fns';
import ReportForm from './ReportForm/ReportForm';
import ReportTable from './ReportTable/ReportTable';
import Message from '../../../Common/Message/Message';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import type { FormikHelpers } from 'formik';
import styles from './ReportForm/ReportForm.module.css';
import { useEffectOnce } from 'usehooks-ts';
import { serverParse, useTimeZone } from 'components/hooks/TimeZone';

export interface User {
    agentId?: number,
    badPassword?: boolean,
    clerkId: number,
    hasChallenge?: boolean,
    memoId?: number,
    type?: string,
    username: string
}

interface RawTransaction {
    accountNum: string,
    amount: string,
    authCode: string,
    billerId: string,
    billerName: string,
    clerkName: string,
    companyFee: string,
    customerFee: string,
    dercd: string,
    paymentType: string,
    processFee: string,
    processTimeFlag: string,
    product: string,
    storeFee: string,
    trace: string,
    transDate: string,
    transTime: string,
    voidTrace: string
}

interface RawAgeVerification {
    agentId: string,
    clerkName: string,
    dlNum: string,
    dob: string,
    timever: string
}

export interface ParsedAgeVerification extends RawAgeVerification {
    key: string,
    age: number,
    reportDate: string,
    reportTime: string
}

export interface ParsedTransaction extends RawTransaction {
    key: string,
    checkAmt: string,
    cashAmt: string
}

export interface FormValues {
    type: string,
    user: string,
    billPayReport: boolean,
    moneyOrderReport: boolean,
    mobileTopUpReport: boolean,
    giftCardReport: boolean,
    cashLoadReport: boolean,
    netSpendReport: boolean,
    startDate: string,
    endDate: string
}

export const isMoneyOrderTransaction = ({ paymentType, product }: RawTransaction | ParsedTransaction) =>
    paymentType === 'MO' || product === 'MO'

export default function Reports() {

    const [searched, setSearched] = useState(false);
    const [data, setData] = useState<ParsedTransaction[] | ParsedAgeVerification[]>([]);
    const [row, setRow] = useState<null | ParsedTransaction | ParsedAgeVerification>(null);
    const [users, setUsers] = useState([] as User[]);

    const { auth } = useContext(AuthContext);
    const { parms } = useContext(ParmContext);
    const hasParms = !!parms;
    const { failoverFetch } = useContext(APIContext);
    const { localFormat } = useTimeZone();

    const userMap = useMemo(() =>
        new Map(users.map((user) => [user.clerkId, user]))
        , [users]);

    const getUser = useCallback((clerkId: number) =>
        userMap.get(clerkId)
        , [userMap]);

    const getUsersFromServer = useCallback(async () => {
        if (auth && hasParms) {
            try {
                const response = await failoverFetch('/Clerks?' + new URLSearchParams({
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as User[];
                const filteredUsers = [{ clerkId: -1, username: '*All' }].concat(data)
                    .filter(({ clerkId }) =>
                        clerkId === parms.clerkInfo.clerkId || parms.clerkInfo.type === 'S'
                    );
                setUsers(filteredUsers);
            } catch (error) {
                console.error(error);
            }
        }
    }, [auth, failoverFetch, hasParms, parms?.clerkInfo.clerkId, parms?.clerkInfo.type]);

    const clearData = () => {
        setData([]);
        setRow(null);
        setSearched(false);
    }

    const getTransDateTime = (transaction: RawTransaction) => {
        const paddedTransTime = (transaction.transTime + (isMoneyOrderTransaction(transaction) ? '00' : '')).padStart(6, '0');
        const parsedTransDate = serverParse(transaction.transDate + paddedTransTime, 'yyyyMMddHHmmss');
        return {
            transTime: localFormat(parsedTransDate, 'HH:mm'),
            transDate: localFormat(parsedTransDate, 'MM/dd/yyyy')
        }
    };

    const parseTransactions = (transactions: RawTransaction[], { type, cashLoadReport, netSpendReport, billPayReport }: FormValues) => {
        const voidableReport = type === 'voidable';

        return transactions.map((transaction) => {
            const { transTime, transDate } = getTransDateTime(transaction);
            const isFeeBalanced = (parseFloat(transaction.processFee) + parseFloat(transaction.companyFee) + parseFloat(transaction.storeFee)).toFixed(2) === transaction.customerFee;
            const isVoid = transaction.dercd === 'V';
            const isCheck = transaction.paymentType.trimEnd() === 'CHK';

            return {
                ...transaction,
                transDate, transTime,
                key: transaction.trace + transaction.paymentType,
                checkAmt: !isVoid && isCheck ? transaction.amount : '0.00',
                cashAmt: !isVoid && !isCheck ? transaction.amount : '0.00',
                amount: isVoid ? '0.00' : transaction.amount,
                processFee: isVoid || !isFeeBalanced ? '0.00' : (((Number(transaction.processFee) * 100) + (Number(transaction.companyFee) * 100)) / 100).toFixed(2),
                storeFee: isVoid || !isFeeBalanced ? '0.00' : Number(transaction.storeFee).toFixed(2),
                companyFee: isVoid || !isFeeBalanced ? '0.00' : transaction.companyFee,
                customerFee: isVoid || !isFeeBalanced ? '0.00' : transaction.customerFee
            } as ParsedTransaction;
        }).sort((a, b) => a.trace.localeCompare(b.trace))
            .filter(({ billerId, product, paymentType }) => {
                const isAmazonCash = Number(billerId) === 3306;
                const isNetSpend = [3327, 3328].includes(Number(billerId));
                const isCash = paymentType.trimEnd() === 'CASH';
                const isBillPay = product === 'BP' || (product === '' && isCash);
                const isMoneyOrder = product !== 'BP'
                    && ((voidableReport && !isCash) || !voidableReport);

                return (isAmazonCash && cashLoadReport)
                    || (isNetSpend && netSpendReport)
                    || (!isAmazonCash && !isNetSpend && billPayReport && isBillPay)
                    || isMoneyOrder
            })
            .filter(({ billerId, product, paymentType }) =>
                (Number(billerId) === 3306 && cashLoadReport)
                || ([3327, 3328].includes(Number(billerId)) && netSpendReport)
                || (Number(billerId) !== 3306 && ![3327, 3328].includes(Number(billerId)) && billPayReport && (product === 'BP' || (product === '' && paymentType.trim() === 'CASH')))
                || (voidableReport && product !== 'BP' && paymentType.trim() !== 'CASH')
                || (!voidableReport && product !== 'BP')
            );
    }

    const getTransactions = async (values: FormValues) => {
        if (auth) {
            const toDate = values.type === 'daily' ? values.startDate : values.endDate;
            const response = await failoverFetch('/getTransactions?' + new URLSearchParams({
                clerkId: values.user.toString(),
                authToken: auth.authToken,
                billPay: (values.billPayReport || values.cashLoadReport || values.netSpendReport).toString(),
                moneyOrder: values.moneyOrderReport.toString(),
                prePaidMobile: values.mobileTopUpReport.toString(),
                giftCards: values.giftCardReport.toString(),
                fromDate: format(Number(values.startDate), 'yyyyMMdd'),
                toDate: format(Number(toDate), 'yyyyMMdd'),
                fromTime: '0',
                toTime: '235959'
            }));
            const data = JSON.parse(response) as { transactions: RawTransaction[] };
            return parseTransactions(data.transactions, values);
        }
        return [];
    }

    const getVoidables = async (values: FormValues) => {
        if (auth) {
            const response = await failoverFetch('/getVoidables?' + new URLSearchParams({
                clerkId: values.user.toString(),
                authToken: auth.authToken,
                billPay: (values.billPayReport || values.cashLoadReport || values.netSpendReport).toString(),
                moneyOrder: values.moneyOrderReport.toString()
            }));
            const data = JSON.parse(response) as { transactions: RawTransaction[] };
            return parseTransactions(data.transactions, values);
        }
        return [];
    }

    const getAgeVerifications = async (values: FormValues) => {
        if (auth) {
            const response = await failoverFetch('/GetAgeVerificationServlet?' + new URLSearchParams({
                authToken: auth.authToken,
                fromDate: format(Number(values.startDate), 'yyyyMMdd'),
                toDate: format(Number(values.endDate), 'yyyyMMdd'),
            }));
            const data = JSON.parse(response) as { AgeVerifications: RawAgeVerification[] };
            return data.AgeVerifications.map((element) => {
                const birthDate = serverParse(element.dob, 'yyyyMMdd');
                const checkDate = serverParse(element.timever, 'yyyy-MM-dd HH:mm:ss.SSSSSS');

                return {
                    ...element,
                    key: element.timever,
                    age: differenceInYears(checkDate, birthDate),
                    reportDate: localFormat(checkDate, 'MM/dd/yyyy'),
                    reportTime: localFormat(checkDate, 'HH:mm')
                } as ParsedAgeVerification;
            });
        };
        return [];
    }

    const handleSubmit = async (values: FormValues, { setStatus }: FormikHelpers<FormValues>) => {
        const promise = values.type === 'voidable' ? getVoidables(values)
            : values.type === 'ageVerif' ? getAgeVerifications(values)
                : getTransactions(values);

        try {
            const response = await promise;
            setData(response);
            setSearched(true);
            setRow(null);
        } catch (error) {
            setStatus({ message: 'An error occurred loading transactions.', messageType: 'error' });
        }
    }

    useEffectOnce(() => {
        getUsersFromServer();
    });

    const now = Date.now();

    const initialValues = {
        type: "daily",
        user: parms?.clerkInfo.type === 'S' ? '-1' : parms?.clerkInfo.clerkId.toString() ?? '-1',
        billPayReport: !!parms?.bpActive && parms.parameters.billPayActive,
        moneyOrderReport: (parms?.moActive ?? false),
        mobileTopUpReport: !!parms?.parameters.prePayActive,
        giftCardReport: !!parms?.parameters.giftCardActive,
        cashLoadReport: !!parms?.parameters.amazonCashActive,
        netSpendReport: !!parms?.parameters.netspendActive,
        startDate: now.toString(),
        endDate: now.toString(),
    }

    return (
        <AdvancedForm className={styles.reports} initialValues={initialValues} onSubmit={handleSubmit} noKeyboard={true} >
            <Message />
            <ReportForm
                clearData={clearData}
                users={users} />
            <ReportTable
                data={data}
                setRow={setRow}
                row={row}
                getUser={getUser}
                searched={searched} />
        </AdvancedForm>
    );
}