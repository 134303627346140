
import type { Dispatch, SetStateAction } from 'react';
import { useContext, useEffect } from 'react';
import BasicPopup from '../../../../Common/Popups/BasicPopup/BasicPopup';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { MOContext } from '../../../../HigherOrder/MoneyOrderController/MoneyOrderController';
import { useFormikContext } from 'formik';
import Message from '../../../../Common/Message/Message';
import type { initialValues, MOResponseBody } from '../MoneyOrder';

interface Props {
    numberToEject: number,
    nextValidMO: string | null,
    setNextValidMO: Dispatch<SetStateAction<string | null>>,
    setSerial: ({ serialNumber, serialCheck }: { serialNumber: string, serialCheck: string }) => void,
    index: number,
    paymentResponse: MOResponseBody | null,
    open: boolean,
    logAction: (action: string) => void
}

export default function Eject({ numberToEject, nextValidMO, setNextValidMO, setSerial, index, paymentResponse, open, logAction }: Props) {

    const { ejectMoneyOrder, resetStatus, getMOSerialNumber, getMOCheckDigit, incrementMOSerialNumber } = useContext(MOContext);
    const { setStatus, setSubmitting } = useFormikContext<typeof initialValues>();

    const eject = async () => {
        if (paymentResponse) {
            for (let i = 0; i < numberToEject; i++) {
                await ejectMoneyOrder(paymentResponse['tracer' + index as keyof MOResponseBody], Number(paymentResponse['checkno' + index as keyof MOResponseBody]));
                await resetStatus();
            }
            await new Promise(r => setTimeout(r, 5000));
        }
    }

    /**
     * At this point, nothing is voided, cart is still out of sequence, and PACKSTART is at the MO # in the printer (what the Agent just enetered).
     */
    const handleEject = async () => {
        logAction(`Eject pressed for ${numberToEject} MO's, next valid MO is ${nextValidMO}`);
        setStatus(null);
        setSubmitting(true);
        try {
            await resetStatus();
            console.debug(`Physically ejecting ${numberToEject} money orders!`);
            await eject();
            console.debug(`Setting parameter and input to new MO# ${getMOSerialNumber(numberToEject)}`);
            setSerial({ serialNumber: getMOSerialNumber(numberToEject).toString(), serialCheck: getMOCheckDigit(numberToEject).toString() });
            incrementMOSerialNumber(numberToEject); // Resequence will reset this number to what the Agent entered.
        } catch (error) {
            console.error(error);
            setNextValidMO(null);
            setStatus({ message: 'An unexpected error occurred while ejecting Money Orders. Please verify the printer is ready and try again.', messageType: 'error' })
        } finally {
            setSubmitting(false);
        }
    }

    const handleBack = () => {
        logAction(`Back button pressed on Eject popup.`);
        setNextValidMO(null);
    }

    useEffect(() => {
        if (open) {
            logAction('Eject Popup Shown');
        }
    }, [logAction, open]);

    return (
        <BasicPopup
            className='ErrorPopup'
            open={open}>
            <h3>{numberToEject + " MO's will be ejected, as the next valid MO # is " + nextValidMO}</h3>
            <AdvancedButton type='button'
                onClick={handleEject}>Eject</AdvancedButton>
            <AdvancedButton type='button'
                onClick={handleBack}>Go Back</AdvancedButton>
            <Message />
        </BasicPopup>
    );
}