import { useCallback, useContext } from 'react';
import { PrinterContext, instanceOfTextLine } from '../../HigherOrder/PrintController/PrintController';
import type { LineStyle, RequestLine, TextLine } from '../../HigherOrder/PrintController/PrintController';

const updateStyle = (element: HTMLElement, style: LineStyle = {}) => {
    if (element.tagName === 'B' || element.tagName === 'TH') {
        return { ...style, emphasized: true }
    } else if (element.tagName === 'SMALL') {
        return { ...style, smallFont: true }
    } else if (element.tagName === 'U') {
        return { ...style, underline: true }
    } else if (element.tagName === 'SPAN' && element.style.fontSize === 'x-large') {
        return { ...style, doubleSize: true }
    }
    return style;
}

const instanceOfHTMLElement = (node: ChildNode): node is HTMLElement =>
    node.nodeType === Node.ELEMENT_NODE;

const instanceOfImageElement = (element: HTMLElement): element is HTMLImageElement =>
    element.tagName === 'IMG';

const parseChildNodes = (element: HTMLElement, style: LineStyle = {}) => {
    const requestLines = [] as RequestLine[];
    for (const childNode of element.childNodes) {
        if (childNode.nodeType === Node.TEXT_NODE) {
            requestLines.push({ text: childNode.textContent ?? '', style });
        } else if (instanceOfHTMLElement(childNode)) {
            if (childNode.tagName === 'BR') {
                requestLines.push({ text: '', style });
            } else if (instanceOfImageElement(childNode)) {
                requestLines.push({ url: childNode.src });
            } else if (element.tagName === 'TR') {
                const textLines = parseChildNodes(childNode).filter((line) => instanceOfTextLine(line)) as TextLine[];
                if (textLines.length > 0) {
                    requestLines.push({
                        columns: textLines.map(({ text }) => text),
                        style: textLines[0].style
                    });
                }
            } else {
                const updatedStyle = updateStyle(childNode as HTMLElement, style);
                requestLines.push(...parseChildNodes(childNode as HTMLElement, updatedStyle));
            }
        }
    }
    return requestLines;
}

export function useGenericPrinter() {

    const { print } = useContext(PrinterContext);

    const genericPrint = useCallback((html: string) => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(html, 'text/html');
        const body = dom.querySelector('body');
        if (body) {
            return print(parseChildNodes(body), { noDuplicate: true });
        }
        throw new Error('Unable to parse receipt HTML!');
    }, [print]);

    return { genericPrint };
}