import { useContext, useEffect, useMemo, useState } from 'react';
import type { MouseEvent } from 'react';
import SupportHeader from '../SupportHeader/SupportHeader';
import styles from './SupportFAQ.module.css';
import supportStyles from '../Support.module.css';
import { Link } from 'react-router-dom';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { TrainingContext } from '../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { initialTrainings } from '../Support';
import { getClass } from 'utilities/classnames';
import { getTooltipClass, getTooltipTriggerClass, tooltipButtonClass } from 'utilities/tooltip';
import { SettingsContext } from 'components/HigherOrder/SettingsController/SettingsController';

export interface EntryPreview {
    id: number,
    readCount: number,
    title: string
}

export default function SupportFAQ() {

    const { auth } = useContext(AuthContext);
    const { parms } = useContext(ParmContext);
    const { failoverFetch } = useContext(APIContext);
    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { setTrainingSetting } = useContext(SettingsContext);

    const [entries, setEntries] = useState<EntryPreview[]>([]);
    const [completedTrainings, setCompletedTrainings] = useState(initialTrainings);

    const setTrainingState = (newTraining: Partial<typeof completedTrainings>) => {
        setCompletedTrainings((currentTrainings) => {
            return { ...currentTrainings, ...newTraining };
        })
    }

    const isSearchTraining = isCurrentPageTraining
        && !completedTrainings?.search;

    const completeSearchTraining = (event: MouseEvent<HTMLElement>) => {
        if (isSearchTraining) {
            event.preventDefault();
            setTrainingState({ search: true });
        }
    }

    const isCategoryTraining = isCurrentPageTraining
        && completedTrainings?.search
        && !completedTrainings.category;

    const completeCategoryTraining = (event: MouseEvent<HTMLElement>) => {
        if (isCategoryTraining) {
            event.preventDefault();
            setTrainingState({ category: true });
        }
    }

    const isFAQTraining = isCurrentPageTraining
        && completedTrainings.search
        && completedTrainings.category
        && !completedTrainings.FAQ;

    const completeFAQTraining = (event: MouseEvent<HTMLElement>) => {
        if (isFAQTraining) {
            event.preventDefault();
            setTrainingSetting({ support: true });
            setTrainingState({
                search: false,
                category: false,
                FAQ: false
            });
        }
    }

    const companyPhone = ((parms?.clerkInfo.memoId ?? 0) > 0) ? "800-922-8079" : "800-621-8030";

    /** 
     * Get FAQ Entries from the server.
     */
    const loadFAQ = async () => {
        if (auth) {
            try {
                const response = await failoverFetch('/Support?' + new URLSearchParams({
                    type: 'popularEntries',
                    agentId: auth.agentId,
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as EntryPreview[];
                setEntries(data);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const FAQEntriesList = useMemo(() => {
        const start = 0;
        const end = 5;

        return entries.map((entry, index) => {
            if (index >= start && index <= end) {
                return <Link key={entry.id} to={'/Support/SupportEntries?entryId=' + entry.id}>{entry.title}</Link>;
            }
            return null;
        });
    }, [entries])

    useEffect(() => {
        loadFAQ();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <SupportHeader fullSize={true}
                isSearchTraining={isSearchTraining}
                isCategoryTraining={isCategoryTraining}
                completeSearchTraining={completeSearchTraining}
                completeCategoryTraining={completeCategoryTraining} />
            <div className={styles.FAQContainer}>
                <h4>Frequently asked questions</h4>
                <div className={getClass([
                    styles.FAQEntries,
                    getTooltipTriggerClass(isFAQTraining)
                ])}
                    onClick={completeFAQTraining} >
                    <div className={isFAQTraining ? 'Focused' : ''}>
                        {FAQEntriesList}
                    </div>
                    <div className={getTooltipClass({ placement: 'top' })}>
                        <div>Here are some of our most frequently asked questions.</div>
                        <div>Feel free to check them out.</div>
                        <span className={tooltipButtonClass} onClick={completeFAQTraining}>Continue</span>
                    </div>
                </div>
            </div>
            <div className={supportStyles.supportPhone}>
                <a href={`tel:${companyPhone}`}>{companyPhone}</a>
            </div>
        </div>
    );
}