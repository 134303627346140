import { useContext, useState } from 'react';
import type { MouseEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import packageJson from '../../../../../package.json'
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import styles from './Settings.module.css';
import { isElectron } from 'utilities/Environment';
import { getClass } from 'utilities/classnames';
import { getTooltipClass, getTooltipTriggerClass, tooltipButtonClass, tooltipTitleClass } from 'utilities/tooltip';

/**
 * A page with links to various settings and administrative pages.
 */
export default function Settings() {

    const { parms, loggedIn } = useContext(ParmContext);
    const { push } = useHistory();
    const { isCurrentPageTraining, resetTrainingIntro } = useContext(TrainingContext);
    const { auth } = useContext(AuthContext);
    const { setTrainingSetting } = useContext(SettingsContext);

    const [completedTrainings, setCompletedTrainings] = useState({
        preferences: false,
        system: false,
        printer: false,
        reports: false
    });

    const setTrainingState = (newTraining: Partial<typeof completedTrainings>) => {
        setCompletedTrainings((currentTrainings) => {
            return { ...currentTrainings, ...newTraining };
        })
    }

    const agentNumber = parms?.clerkInfo.agentId ?? 'N/A';

    const username = auth?.username ?? 'N/A';

    const isPreferencesTraining = isCurrentPageTraining
        && !completedTrainings.preferences;

    const completePreferencesTraining = (event: MouseEvent<HTMLElement>) => {
        if (isPreferencesTraining) {
            event.preventDefault();
            setTrainingState({ preferences: true });
        }
    }

    const isSystemTraining = isCurrentPageTraining
        && !isPreferencesTraining
        && !completedTrainings.system;

    const completeSystemTraining = (event: MouseEvent<HTMLElement>) => {
        if (isSystemTraining) {
            event.preventDefault();
            setTrainingState({ system: true });
        }
    }

    const isPrinterTraining = isCurrentPageTraining
        && !isPreferencesTraining
        && !isSystemTraining
        && !completedTrainings.printer;

    const completePrinterTraining = (event: MouseEvent<HTMLElement>) => {
        if (isPrinterTraining) {
            event.preventDefault();
            setTrainingState({ printer: true });
        }
    }

    const isReportsTraining = isCurrentPageTraining
        && !isPreferencesTraining
        && !isSystemTraining
        && !isPrinterTraining
        && !completedTrainings.reports;

    const completeReportsTraining = (event: MouseEvent<HTMLElement>) => {
        if (isReportsTraining) {
            event.preventDefault();
            setTrainingSetting({ settings: true });
            push('/Support');
            resetTrainingIntro();
        }
    }

    const handleVersionClick = () => {
        if (isElectron) {
            push('UpdateStatus');
        }
    }

    return (
        <div className={styles.settings}>
            <fieldset className={styles.column}>
                <div>
                    <label>User</label>
                </div>
                <div>
                    <input className={styles.info} value={username} disabled />
                </div>
                <div>
                    <label>Agent Number</label>
                </div>
                <div>
                    <input className={styles.info} value={agentNumber} disabled />
                </div>
                <div>
                    <label>SW Version</label>
                </div>
                <div>
                    <input className={styles.info} value={packageJson.version} onClick={handleVersionClick} readOnly />
                </div>
            </fieldset>
            <fieldset className={styles.column}>
                <Link to="/Preferences"
                    className={getClass([
                        getTooltipTriggerClass(isPreferencesTraining),
                        styles.link
                    ])}
                    onClick={completePreferencesTraining}>
                    <span>Preferences</span>
                    <div className={getTooltipClass({ placement: 'bottom' })}>
                        <div className={tooltipTitleClass}>Preferences</div>
                        <div>Here you can update your various preferences, such as</div>
                        <div>timezone, user management, and hours of operation.</div>
                        <span className={tooltipButtonClass} onClick={completePreferencesTraining}>Continue</span>
                    </div>
                </Link>

                <Link to="/System"
                    className={getClass([
                        getTooltipTriggerClass(isSystemTraining),
                        styles.link
                    ])}
                    onClick={completeSystemTraining} >
                    <span>System Settings</span>
                    <div className={getTooltipClass({ placement: 'bottom' })}>
                        <div className={tooltipTitleClass}>System</div>
                        <div>Here you can perform various system operations, such as</div>
                        <div>restarting the application, restarting your computer, resetting your configuration.</div>
                        <div>Additionally, you can see information about your system and network.</div>
                        <span className={tooltipButtonClass} onClick={completeSystemTraining}>Continue</span>
                    </div>
                </Link>

                <Link to="/PrinterSettings"
                    className={getClass([
                        getTooltipTriggerClass(isPrinterTraining),
                        styles.link
                    ])}
                    onClick={completePrinterTraining}>
                    <span>Printer Settings</span>
                    <div className={getTooltipClass({ placement: 'top' })}>
                        <div className={tooltipTitleClass}>Printers</div>
                        <div>Here you can work with various printer settings, including</div>
                        <div>your report printer, receipt printer, and money order printer (if available).</div>
                        <span className={tooltipButtonClass} onClick={completePrinterTraining}>Continue</span>
                    </div>
                </Link>

                <Link to="/Reports"
                    className={getClass([
                        !loggedIn && 'disabled',
                        getTooltipTriggerClass(isReportsTraining),
                        styles.link
                    ])}
                    onClick={completeReportsTraining} >
                    <span>Transactions / Reports</span>
                    <div className={getTooltipClass({ placement: 'top' })}>
                        <div className={tooltipTitleClass}>Reports</div>
                        <div>Here you can view your past transactions, as well as</div>
                        <div>printing past receipts, and voiding certain transactions.</div>
                        <span className={tooltipButtonClass} onClick={completeReportsTraining}>Continue</span>
                    </div>
                </Link>
            </fieldset>
        </div>
    );
}