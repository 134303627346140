import { tooltipBoldClass } from 'utilities/tooltip';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import { tutorialFirstName, tutorialLastName } from '../NamePhoneForm/NamePhoneForm';

interface Props {
    isFirstNameTutorial: boolean,
    isLastNameTutorial: boolean
}

export default function NameForm({ isFirstNameTutorial, isLastNameTutorial }: Props) {
    return (
        <fieldset>
            <div>
                <AdvancedField
                    name='firstName'
                    placeholder="First Name"
                    className='DriversLicense'
                    trainingFocused={isFirstNameTutorial}
                    trainingTooltip={
                        <>
                            <div>ENTER THE FIRST NAME</div>
                            <b className={tooltipBoldClass}>{tutorialFirstName}</b>
                        </>
                    }
                    upperCase autoFocus required showLabel />
            </div>
            <div>
                <AdvancedField
                    name='lastName'
                    placeholder="Last Name"
                    className='DriversLicense'
                    trainingFocused={isLastNameTutorial}
                    trainingTooltip={
                        <>
                            <div>ENTER THE LAST NAME</div>
                            <b className={tooltipBoldClass}>{tutorialLastName}</b>
                        </>
                    }
                    upperCase required showLabel />
            </div>
        </fieldset>
    );
}