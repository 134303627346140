interface XMLChild {
    [key: string]: (XMLChild | string)[] | XMLChild | string
}

export interface XMLRoot {
    [key: string]: XMLChild | string
}

const serializer = new XMLSerializer();

const parseJSChildren = (xmlDoc: XMLDocument, element: Element, js: string | XMLChild) => {
    if (typeof js === 'string') {
        element.append(js);
    } else {
        for (const [key, value] of Object.entries(js)) {
            const childElement = element.appendChild(xmlDoc.createElementNS('', key));
            if (Array.isArray(value)) {
                for (const js of value) {
                    parseJSChildren(xmlDoc, childElement, js);
                }
            } else {
                parseJSChildren(xmlDoc, childElement, value);
            }
        }
    }
}

export const js2xml = (js: XMLRoot) => {
    const [key, value] = Object.entries(js)[0];
    const xmlDoc = document.implementation.createDocument('', key);
    parseJSChildren(xmlDoc, xmlDoc.documentElement, value);
    return serializer.serializeToString(xmlDoc);
}

const instanceOfElement = (node: ChildNode): node is Element =>
    node.nodeType === Node.ELEMENT_NODE;

const parser = new DOMParser();

const parseXMLChildren = (element: Element) => {
    const childNodeMap = new Map<string, Element[]>();
    for (const childNode of element.childNodes) {
        if (instanceOfElement(childNode)) {
            if (childNodeMap.has(childNode.tagName)) {
                childNodeMap.get(childNode.tagName)!.push(childNode);
            } else {
                childNodeMap.set(childNode.tagName, [childNode]);
            }
        }
    }

    if (childNodeMap.size > 0) {
        const child = {} as XMLChild;
        for (const [tagName, childNodes] of childNodeMap.entries()) {
            if (childNodes.length === 1) {
                child[tagName] = parseXMLChildren(childNodes[0]);
            } else {
                child[tagName] = childNodes.map((childNode) => parseXMLChildren(childNode));
            }
        }
        return child;
    } else {
        for (const childNode of element.childNodes) {
            if (childNode.nodeType === Node.TEXT_NODE) {
                return childNode.textContent ?? '';
            }
        }
    }
    return '';
}

export const xml2js = <T>(xml: string) => {
    const { documentElement } = parser.parseFromString(xml, 'text/xml');
    return { [documentElement.tagName]: parseXMLChildren(documentElement) } as T;
}