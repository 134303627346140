import type { ComponentType, ReactNode } from 'react';
import { createContext, Component, useRef, useContext, useEffect, useCallback } from 'react';
import { AuthContext } from '../AuthController/AuthController';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export const AnalyticsContext = createContext({
    startTransaction: () => { },
    endTransaction: () => { return undefined as number | undefined },
    sendError: (error: string) => { }
});

interface Props {
    children: ReactNode
}

/**
 * Controller for hooking into Google Analytics.
 * Currently keeps track of a user (Links User Id W/ Clerk Id).
 * It will keep track of that User's page views.
 * It will also keep track of that User's Transaction Times.
 * Additional Logging can be added in the future.
 */
export default function AnalyticsController({ children }: Props) {

    const { auth } = useContext(AuthContext);
    const transactionStartTime = useRef<number>(0);
    const { pathname } = useLocation();

    const startTransaction = useCallback(() => {
        transactionStartTime.current = Date.now();
    }, [])

    const endTransaction = useCallback(() => {
        if (transactionStartTime.current) {
            const diff = Date.now() - transactionStartTime.current;
            ReactGA.timing({
                category: 'Transaction Times',
                variable: pathname,
                value: diff
            });
            startTransaction();
            return diff;
        }
    }, [pathname, startTransaction])

    const sendError = useCallback((error: string) => {
        ReactGA.exception({
            description: error,
            fatal: true
        });
    }, [])

    useEffect(() => {
        ReactGA.initialize('UA-86789706-3', {
            gaOptions: {
                siteSpeedSampleRate: 100,
                userId: auth?.clerkId
            }
        });
    }, [auth?.clerkId]);

    useEffect(() => {
        ReactGA.pageview(pathname);
    }, [pathname]);

    return (
        <AnalyticsContext.Provider value={{
            startTransaction,
            endTransaction,
            sendError
        }} >
            {children}
        </AnalyticsContext.Provider>
    );
}

export function withAnalytics<T extends object>(WrappedComponent: ComponentType<T>) {
    return class extends Component {
        render() {
            return (
                <AnalyticsContext.Consumer>
                    {value =>
                        <WrappedComponent {...value} {...this.props as T} />
                    }
                </AnalyticsContext.Consumer>
            );
        }
    }
}