import { useContext, useState } from 'react';
import { ParmContext } from '../../HigherOrder/ParmController/ParmController';
import styles from './Banner.module.css';
import BannerComponent from './Banner';
import { useLocation } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { getClass } from 'utilities/classnames';

export default function Banners() {

    const { parms, loggedIn } = useContext(ParmContext);
    const { pathname } = useLocation();

    const banners = parms?.banners?.filter(({ screen }) =>
        screen === 'ALL'
        || (loggedIn && screen === 'LOGGEDIN')
        || (!loggedIn && screen === 'LOGGEDOUT')
        || pathname.includes(screen)
    ) ?? [];

    const [currentIndex, setCurrentIndex] = useState(0);
    const currentBanner = banners[currentIndex] ?? banners[0];

    const nextIndex = (currentIndex + 1) % banners.length;

    const interval = banners.length > 0 ? 1000 * 15 : null;
    useInterval(() => setCurrentIndex(nextIndex), interval);

    return (
        <div className={getClass([
            styles.bannerWrapper,
            !banners.length && styles.closed
        ])}
            style={{ backgroundColor: `#${currentBanner?.color}` }}>
            {banners.map(banner =>
                <BannerComponent
                    key={banner.id}
                    banner={banner}
                    current={currentBanner.id === banner.id} />)}
        </div>
    );
}