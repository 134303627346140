import { useContext, useCallback } from 'react';
import { ParmContext } from '../../HigherOrder/ParmController/ParmController';
import { PrinterContext } from '../../HigherOrder/PrintController/PrintController';
import type { PrintRequest } from '../../HigherOrder/PrintController/PrintController';
import type { VoidData } from '../../App/Mainview/Reports/ReportTable/TransactionVoider';
import { getFooterRows, getHeaderRows } from './BillPayPrinter';
import { serverParse, useTimeZone } from 'components/hooks/TimeZone';

const emptyArr = [] as never[];

export function useBillPayVoidPrinter() {

    const { localFormat } = useTimeZone();
    const { parms, companyId } = useContext(ParmContext);
    const { print } = useContext(PrinterContext);

    const memoId = parms?.clerkInfo.memoId ?? 0;
    const headers = parms?.parameters.headers ?? emptyArr;
    const trailers = parms?.parameters.trailers ?? emptyArr;

    const printBillPayVoid = useCallback((data: VoidData) => {
        const parsedDate = serverParse(data.date + data.time, 'yyyyMMddHHmmss');
        const parsedData = {
            ...data,
            date: localFormat(parsedDate, 'MM/dd/yyyy'),
            time: localFormat(parsedDate, "HH:mm:ss zzz"),
            cashAmt: parseFloat(data.cashAmt),
            totalFee: parseFloat(data.totalFee)
        };

        return print(([
            { text: 'TRANSACTION VOID', style: { emphasized: true } },
            { text: '' },
        ] as PrintRequest).concat(
            getHeaderRows(headers)
        ).concat([
            { text: '' },
            { text: `Date: ${parsedData.date} Time: ${parsedData.time}` },
            { text: '' },
            { text: `Agent Number: ${companyId}` },
            { text: '' },
            { text: `Receipt Num: ${parsedData.trace}`, style: { emphasized: true, doubleSize: true } },
            { text: '' },
            { text: `Posting Date: ${parsedData.date}` },
            { text: '' },
            { text: `Cash Refund: $${parseFloat(parsedData.cashAmt.toString()).toFixed(2)}` },
            { text: `Fee Return: $${(parseFloat(parsedData.totalFee.toString())).toFixed(2)}` },
            { text: '' },
            { text: `RETURN: $${(((parsedData.cashAmt * 100 + parsedData.totalFee * 100)) / 100).toFixed(2)}`, style: { emphasized: true } },
            { text: '' },
            { text: 'TRANSACTION VOIDED', style: { emphasized: true } },
            { text: '' }]
        ).concat(
            getFooterRows(trailers, memoId)
        ), { trace: data.trace })
    }, [companyId, headers, localFormat, memoId, print, trailers]);

    return { printBillPayVoid };
}