import { useCallback } from 'react';
import { useInterval } from 'usehooks-ts';

/**
 * Status servlet for when the server is officially 'down' 
 * Will check the server every minute to see if it's back up.
 */
export function useUptimeStatus(failoverFetch: (input: string) => Promise<string>, serverDown: boolean) {

    const uptimeStatusRequest = useCallback(() => {
        return failoverFetch('/Uptime');
    }, [failoverFetch]);

    const interval = serverDown ? 1000 * 60 : null;
    useInterval(uptimeStatusRequest, interval);
}