const specialCharacterSet = new Set(['%', "'", '"']);
const commonValidation = (value: string) => {
    for (const char of value) {
        if (specialCharacterSet.has(char)) {
            return 'Special characters are not allowed.';
        }
    }
}

const validNameLengths = (name: string) =>
    /.{1,}\s.{2,}/.test(name);


const validFirstLastName = (name: string) => {
    const names = name.split(/\s+/);
    for (let i = 0; i < names.length - 1; i++) {
        for (let j = i + 1; j < names.length; j++) {
            if (names[i].length <= 3 && names[i].toLowerCase() === names[j].toLowerCase()) {
                return false;
            }
        }
    }
    return true;
}

const noNameRepeats = (name: string) => {
    const names = name.split(/\s+/);
    for (const name of names) {
        if (/.*([a-zA-Z0-9])\1{3}.*/.test(name)) {
            return false;
        }
    }
    return true;
}

export const validateName = (name = '') => {
    const value = name.trim();
    if (!value) {
        return 'Name Required';
    } else if (!validNameLengths(value)
        || !validFirstLastName(value)
        || !noNameRepeats(value)
    ) {
        return 'Invalid Name';
    }
    return commonValidation(value);
}

export const validatePhone = (phone = '') => {
    const value = phone.trim();
    if (!value) {
        return 'Phone Required';
    }
    else if (!/[2-9]{1}\d{2}[2-9]{1}\d{6}/.test(value) || /\d{3}(\d{1})\1{6}/.test(value)) {
        return 'Invalid Phone';
    }
    return commonValidation(value);
}

export const validateEmail = (email = '') => {
    const value = email.trim();
    if (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        return 'Invalid Email';
    }
    return commonValidation(value);
}

export const validateAddress = (address = '') => {
    const value = address.trim();
    if (!/.{5,}/.test(value)
        || (/.*[0-9]+.*/.test(value)
            && !/.*[a-zA-Z]+./.test(value))) {
        return 'Invalid Address';
    }
    return commonValidation(value);
}

export const validateCity = (city = '') => {
    const value = city.trim();
    if (!/.{2,}/.test(value)
        || /.*[0-9]+.*/.test(value)) {
        return 'Invalid City';
    }
    return commonValidation(value);
}

export const validateState = (state = '') => {
    const value = state.trim();
    return commonValidation(value);
}

export const validateZip = (zip = '') => {
    const value = zip.trim();
    if (!/\d{5,}/.test(value)) {
        return 'Invalid Zip';
    }
    return commonValidation(value);
}