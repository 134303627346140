import { getIn } from "formik";
import type { CustomColumnProps } from "./VirtualizedTable";
import styles from './VirtualizedTable.module.css';

interface Props {
    rowData: any,
    column: CustomColumnProps,
    headerRow?: boolean
}

export default function CellRenderer({ rowData, column, headerRow }: Props) {

    return (
        <div
            style={{
                width: column.width ?? 60,
                flexGrow: column.flexGrow ?? 1
            }}
            className={styles.cell} >
            {!!column.cellRenderer && !headerRow &&
                column.cellRenderer({ rowData })
            }
            {(!column.cellRenderer || !!headerRow) &&
                getIn(rowData, column.dataKey)
            }
        </div>
    );
}