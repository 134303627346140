import type { ReactNode } from 'react';
import { PureComponent } from 'react';
import { withAnalytics } from '../../HigherOrder/AnalyticsController/AnalyticsController';

interface Props {
    sendError: (error: string) => void,
    children: ReactNode
}

/**
 * An errorboundary, like a catch for React components.
 * So we can tell the user to refresh the page if something goes wrong.
 */
class ErrorBoundary extends PureComponent<Props> {

    state = { hasError: false };

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any) {
        // You can also log the error to an error reporting service
        this.props.sendError(error.toString());
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>An unexpected Error occurred. Please refresh this screen by pressing CTRL + R.</h1>
        }

        return this.props.children;
    }
}

export default withAnalytics(ErrorBoundary);