import { useContext, useMemo } from 'react';
import { Route, Switch } from "react-router-dom";
import packageJson from '../../../../../package.json';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import ChallengeForm from './ChallengeForm/ChallengeForm';
import LoginForm from './LoginForm/LoginForm';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import type { Auth } from '../../../HigherOrder/AuthController/AuthController';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { SystemContext } from '../../../HigherOrder/SystemConroller/SystemController';
import type { FormikHelpers } from 'formik';
import { SurveyContext } from 'components/HigherOrder/SurveyController/SurveyController';
import { isOnline } from 'utilities/network';
import { js2xml } from 'utilities/xml';

interface LoginResponse {
    agentId: string,
    authToken: string,
    clerkId: string
}

export interface FormValues {
    agent: string | number,
    username: string,
    password: string,
    challenge: string,
    question: string
}

export default function LoginController() {

    const { setAuth } = useContext(AuthContext);
    const { parms } = useContext(ParmContext);
    const { failoverFetch, getParameters } = useContext(APIContext);
    const { network: { mac } } = useContext(SystemContext);
    const { getNextSurvey } = useContext(SurveyContext);

    const loginRequest = async ({ agent, username, password, question, challenge }: FormValues) => {
        const xml = js2xml({
            login: {
                custId: agent.toString(),
                mac: mac,
                username: username,
                password: password,
                challenge: challenge
            }
        });

        const response = await failoverFetch('/login', {
            method: 'POST',
            body: xml,
            headers: { 'Content-Type': 'text/xml' }
        });
        const data = JSON.parse(response) as LoginResponse;

        return {
            ...data,
            agentId: Number(data.agentId).toString(),
            username,
            challengeQuestion: question,
            challengeResponse: challenge,
            badPassword: password.includes(agent.toString()) || password.includes(username) || !password || password.length < 8
        }
    }

    const hasValidParameters = (auth: Auth) => {
        const savedSWVersion = localStorage.getItem("swVersion");
        return parms?.clerkInfo.agentId === Number(auth.agentId)
            && parms?.clerkInfo.clerkId === Number(auth.clerkId)
            && parms?.clerkInfo.username === auth.username
            && savedSWVersion === packageJson.version;
    };

    /** 
     * Logs the user in. Works for both Challenge & normal Login.
     * If the user doesn't have parameters, it will request them before completing.
     * Will notify if the user doesn't have internet.
     */
    const onSubmit = async (values: FormValues, { setStatus, setSubmitting, setFieldValue }: FormikHelpers<FormValues>) => {
        setStatus({ message: 'Logging you in...' });

        try {
            const auth = await loginRequest(values);
            if (!hasValidParameters(auth)) {
                try {
                    const rawParms = await getParameters(auth);
                    if (rawParms) {
                        await getNextSurvey(auth, rawParms);
                    }
                } catch (err) {
                    console.error(err);
                    setStatus({ message: 'An unexpected error has occurred while grabbing your settings.', messageType: 'error' });
                    setSubmitting(false);
                }
            } else {
                await getNextSurvey(auth, parms!);
            }
            setAuth(auth);
        } catch (err: any) {
            console.error(err);
            setFieldValue('password', '');
            setFieldValue('challenge', '');
            if (err?.response?.data?.attemptsLeft === 1) {
                setStatus({ message: 'You have 1 login attempt remaining. If you forgot your password, try using "Forgot Password"!', messageType: 'error', processing: false });
            } else if (err?.response?.data?.login) {
                setStatus({ message: err.response.data.login, messageType: 'error', processing: false });
            } else {
                setStatus({ message: 'Checking for Internet Connection...' });
                if (!(await isOnline())) {
                    setStatus({ message: 'No Internet Connection.', messageType: 'error', processing: false });
                } else {
                    setStatus({ message: 'An unexpected error has occurred while logging you in.', messageType: 'error', processing: false });
                }
            }
            setSubmitting(false);
        }
    }

    const initialValues = useMemo(() => {
        return {
            agent: parms?.clerkInfo.agentId ?? '',
            username: parms?.clerkInfo.username ?? '',
            password: '',
            challenge: '',
            question: ''
        };
    }, [parms?.clerkInfo.agentId, parms?.clerkInfo.username])

    return (
        <AdvancedForm initialValues={initialValues} onSubmit={onSubmit} >
            <Switch>
                <Route path='/Login/Challenge' component={ChallengeForm} />
                <Route component={LoginForm} />
            </Switch>
        </AdvancedForm>
    );
}