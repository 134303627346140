
import ConfirmationPopup from '../../../../Common/Popups/ConfirmationPopup/ConfirmationPopup';
import { FormikContextType, useFormikContext } from 'formik';
import Message from '../../../../Common/Message/Message';
import { initialValues, MOResponseBody } from '../MoneyOrder';
import { useEffect } from 'react';

interface Props {
    paymentResponse: MOResponseBody | null,
    index: number,
    message: any,
    onContinue: (actions: FormikContextType<typeof initialValues>) => Promise<void>,
    onRetry: (actions: FormikContextType<typeof initialValues>) => Promise<void>,
    open: boolean,
    logAction: (action: string) => void
}

export default function Retry({ paymentResponse, index, message, onContinue, onRetry, open, logAction }: Props) {

    const formik = useFormikContext<typeof initialValues>();

    const handleContinue = async () => {
        logAction('Agent pressed "Yes", confirming MO did print.');
        await onContinue(formik);
    }

    const handleRetry = async () => {
        logAction('Agent pressed "No", indicating MO did not print.')
        await onRetry(formik);
    }

    useEffect(() => {
        if (open) {
            logAction('Retry Popup Shown');
        }
    }, [logAction, open]);

    if (paymentResponse && paymentResponse['checkno' + index as keyof MOResponseBody]) {
        return (
            <div className='ErrorPopup'>
                <ConfirmationPopup
                    onConfirm={handleContinue}
                    onClose={handleRetry}
                    open={open}>
                    <h3>Did Money Order # {paymentResponse['checkno' + index as keyof MOResponseBody]} print?</h3>
                    <h5>We detected the following error: {message}</h5>
                    <Message />
                </ConfirmationPopup>
            </div>
        )
    } else {
        return null;
    }
}