import { useContext, Fragment, useMemo } from 'react';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { useFormikContext } from 'formik';
import Cart from '../Cart/Cart';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { TrainingContext } from '../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import Processing from '../../../../Common/Processing/Processing';
import './CartForm.css';
import type { initialValues } from '../CashLoad';
import { tutorialAmount } from '../CashLoad';
import { getTooltipClass, getTooltipTriggerClass, tooltipBoldClass } from 'utilities/tooltip';

/**
 * Common cart form for MTU. This is used on both the second page, as a cart for ProviderProduct selection, aswell as the final checkout cart.
 */
export default function CartForm() {

    const { isCurrentPageTraining } = useContext(TrainingContext);

    const { values, isSubmitting } = useFormikContext<typeof initialValues>();

    const { pathname } = useLocation();
    const { goBack } = useHistory();

    const isCartTutorial = isCurrentPageTraining
        && values.cash === tutorialAmount;

    const cartClass = isCartTutorial ? 'Focused' : '';

    const controls = useMemo(() => {
        if (pathname.includes('/Checkout')) {
            return (
                <Fragment>
                    <div>
                        Please validate that the information is correct with the customer
                    </div>
                    <div className='CheckoutButtons'>
                        <AdvancedButton
                            type='button'
                            onClick={goBack} >BACK</AdvancedButton>
                        <div className={getTooltipTriggerClass(isCartTutorial)}>
                            <AdvancedButton autoFocus={true} trainingFocused={isCartTutorial}>CONFIRM</AdvancedButton>
                            <div className={getTooltipClass()}>
                                <div>REVIEW CHECKOUT</div>
                                <b className={tooltipBoldClass}>CLICK CONFIRM</b>
                            </div>
                        </div>
                    </div>
                    <Processing open={isSubmitting} />
                </Fragment>
            );
        } else if (pathname.includes('/Amount')) {
            return (
                <div className={getTooltipTriggerClass(isCartTutorial)}>
                    <AdvancedButton trainingFocused={isCartTutorial}>CHECKOUT</AdvancedButton>
                    <div className={getTooltipClass()}>
                        <div>REVIEW CART</div>
                        <b className={tooltipBoldClass}>CLICK CHECKOUT</b>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }, [goBack, isCartTutorial, isSubmitting, pathname])

    const getFirstSegment = () => {
        return pathname.split('/')[1];
    };

    if (pathname.includes('/Checkout') && Number(values.cash) === 0) {
        return (
            <Redirect to={`/${getFirstSegment()}`} />
        );
    } else {
        return (
            <fieldset className={'CartForm ' + cartClass}>
                <h1>Cart</h1>
                <Cart />
                {controls}
            </fieldset>
        );
    }
}