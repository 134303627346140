import { useContext } from 'react';
import { UpdateContext } from '../../Updater';
import styles from './UpdateStatus.module.css';
import type { UpdateDownloadedEvent, UpdateInfo } from 'electron-updater';
import { isElectron } from 'utilities/Environment';

const instanceOfUpdateDownloadedEvent = (info: UpdateInfo | UpdateDownloadedEvent): info is UpdateDownloadedEvent => {
    return (info as UpdateDownloadedEvent).downloadedFile !== undefined;
}

const checkForUpdate = () => {
    if (isElectron) {
        require('electron').ipcRenderer.send('checkForUpdate');
    }
}

export default function UpdateStatus() {

    const { checkingForUpdate, downloadingUpdate, updateDownloaded, updateInfo, progressInfo, quitAndInstall, updateErrors } = useContext(UpdateContext);

    const checkable = !checkingForUpdate && !downloadingUpdate && !updateDownloaded;
    const installable = updateDownloaded && updateInfo && instanceOfUpdateDownloadedEvent(updateInfo);

    return (
        <div className={styles.updateStatus}>
            <h2>
                {checkingForUpdate ? 'Checking for updates.'
                    : downloadingUpdate ? 'Update download in progress.'
                        : updateDownloaded ? 'Update downloaded, awaiting install.'
                            : 'No updates currently available.'}
            </h2>
            {!!updateInfo &&
                <h3>Latest version is {updateInfo.version}, released on {updateInfo.releaseDate}</h3>
            }
            {!!progressInfo &&
                <div>
                    <progress className={styles.updateProgress} value={progressInfo.percent / 100} >%{progressInfo.percent}</progress>
                    <div>{progressInfo.transferred / 1000000} megabytes downloaded out of {progressInfo.total / 1000000}, at {progressInfo.bytesPerSecond / 1000000} megabytes per second</div>
                </div>
            }
            <div>
                {checkable &&
                    <button onClick={checkForUpdate}>Check For Updates</button>
                }
                {installable &&
                    <button onClick={() => quitAndInstall(updateInfo)}>Update and Restart</button>
                }
            </div>
            {updateErrors.length > 0 &&
                <>
                    <h3>Errors</h3>
                    <code className={styles.errorBlock}>
                        {updateErrors.map((error, index) =>
                            <div key={index}>{error.toString()}</div>
                        )}
                    </code>
                </>
            }
        </div>
    );
}