import { useState, useContext, useCallback } from 'react';
import { useLocation, useHistory, Route, Switch } from "react-router-dom";
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import NamePhoneForm from './NamePhoneForm/NamePhoneForm';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import type { FormikHelpers } from 'formik';
import { useBillPay } from 'components/hooks/BillPay';
import type { Customer } from 'components/hooks/BillPay';
import { useMTU, MTUError } from 'components/hooks/MTU';
import type { FullOperator, OperatorPreview, OperatorProps } from 'components/hooks/MTU';

import ProviderProductForm from '../MobileTopUp/ProviderProductForm/ProviderProductForm';
import CartForm from '../MobileTopUp/CartForm/CartForm';
import { FetchError } from 'utilities/network';
import { useEffectOnce } from 'usehooks-ts';

const tutorialOperator = {
    operator: 'eBay USA',
    operatorId: '3355'
};

const tutorialProduct = '5000';

const validate = (values: typeof initialValues) =>
    values.phone.length < 7 ? { phone: 'Please enter a valid phone number.' }
        : {};

export const getFirstLast = (customer?: Customer) => {
    if (customer) {
        return {
            firstName: customer.name.split(' ')[0],
            lastName: customer.name.split(' ').slice(1).join(' ')
        }
    } else {
        return { firstName: 'N/A', lastName: 'N/A' };
    }
}

export const getBestCustomer = (customers: Customer[]) => {
    for (const customer of customers) {
        if (customer.active === 'A') {
            return customer;
        }
    }
    return customers[0];
}

export const initialValues = {
    phone: "",
    phoneConfirm: "",
    firstName: "",
    lastName: "",
    countryCode: '1'
};

export default function DigitalGiftCards() {

    const { pathname } = useLocation();
    const { push, goBack } = useHistory();

    const { setTrainingSetting } = useContext(SettingsContext);
    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { getOperatorsRequest, getOperatorRequest, topupRequest, printReceipt } = useMTU('GIFTCARD');
    const { customerSearchRequest } = useBillPay();

    const [searched, setSearched] = useState(false);
    const [customerId, setCustomerId] = useState(0);
    const [operators, setOperators] = useState([] as OperatorPreview[]);
    const [selectedOperators, setSelectedOperators] = useState<FullOperator[]>([]);
    const [selectedOperatorIndex, setSelectedOperatorIndex] = useState(0);
    const [selectedProductIndex, setSelectedProductIndex] = useState(0);

    const selectedOperator = selectedOperators[selectedOperatorIndex] as FullOperator | undefined;
    const selectedProduct = selectedOperator?.products[selectedProductIndex];

    const getOperatorsFromServer = useCallback(async () => {
        try {
            const operators = await getOperatorsRequest();
            setOperators(operators);
        } catch (err) {
            console.error(err);
        }
    }, [getOperatorsRequest]);

    const getOperatorFromServer = async (values: OperatorProps, { setSubmitting, setStatus }: FormikHelpers<any>) => {
        setSubmitting(true);
        setSelectedOperatorIndex(0);
        try {
            return await getOperatorRequest(values);
        } catch (err) {
            console.error(err);
            if (err instanceof FetchError) {
                const text = await err.response.text();
                setStatus({ message: text, messageType: 'error' });
            } else {
                setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
            }
            return [];
        } finally {
            setSubmitting(false);
        }
    }

    const getCustomers = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        actions.setFieldValue('firstName', '');
        actions.setFieldValue('lastName', '');
        setCustomerId(0);
        try {
            const customerResponse = await customerSearchRequest(values.phone);
            if (customerResponse.customerInfo.length > 0) {
                const customer = getBestCustomer(customerResponse.customerInfo);
                const { firstName, lastName } = getFirstLast(customer);
                actions.setFieldValue('firstName', firstName);
                actions.setFieldValue('lastName', lastName);
                setCustomerId(Number(customer.id));
            }
            push('/DigitalGiftCards/Name');
        } catch (error) {
            console.error(error);
        }
        setSearched(true);
    }

    const makePayment = async (values: typeof initialValues, formik: FormikHelpers<typeof initialValues>, operatorIndex = selectedOperatorIndex, productIndex = selectedProductIndex): Promise<void> => {
        const operator = selectedOperators[operatorIndex];
        const product = operator.products[productIndex];
        formik.setStatus({ message: 'Processing Payment...', messageType: '' });
        try {
            const customerName = values.firstName.trim() + ' ' + values.lastName;
            const paymentResponse = await topupRequest({
                ...values,
                countryCode: '1'
            }, operator, product, {
                id: customerId.toString(),
                name: customerName,
                phone: values.phone
            });
            await printReceipt(paymentResponse, operator, product, customerName);
            push('/DigitalGiftCards');
            formik.resetForm();
            setCustomerId(0);
            setSearched(false);
            formik.setStatus({ message: 'Payment Successful', messageType: '' });
        } catch (error) {
            if (operatorIndex < selectedOperators.length - 1) {
                const nextProductIndex = selectedOperators[operatorIndex + 1].products.findIndex(({ localInfoAmount, retailPrice }) =>
                    localInfoAmount === product.localInfoAmount && retailPrice === product.retailPrice);
                if (nextProductIndex >= 0) {
                    formik.setStatus({ message: 'We need to try this another way. One moment.', messageType: '' });
                    return makePayment(values, formik, operatorIndex + 1, nextProductIndex);
                } else {
                    goBack();
                    formik.setStatus({ message: 'We need to try this another way. Please select a new price point.', messageType: '' });
                    setSelectedOperatorIndex(operatorIndex + 1);
                }
            } else {
                setSelectedOperatorIndex(0);
                console.error(error);
                goBack();
                if (error instanceof MTUError) {
                    formik.setStatus({ message: error.message, messageType: 'error' });
                } else {
                    formik.setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
                }
            }
        }
    }

    const handleSubmit = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        if (pathname.includes('/ProviderProduct') && searched) {
            push('/DigitalGiftCards/Checkout');
        } else if (pathname.includes('/Checkout') && searched && !!selectedProduct && !!selectedOperator) {
            if (!isCurrentPageTraining) {
                await makePayment(values, actions);
            } else {
                setTrainingSetting({ giftCards: true });
            }
        } else {
            if (pathname.includes('/Name')) {
                push('/DigitalGiftCards/ProviderProduct');
            } else {
                await getCustomers(values, actions);
            }
        }
    }

    const clearCustomers = useCallback(() => {
        goBack();
        setCustomerId(0);
        setSearched(false);
    }, [goBack]);

    useEffectOnce(() => {
        getOperatorsFromServer();
    });

    return (
        <AdvancedForm className='DigitalGiftCards'
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate} >
            <Switch>
                {searched &&
                    <Route path='/DigitalGiftCards/ProviderProduct' render={() =>
                        <ProviderProductForm
                            operators={operators}
                            getOperatorFromServer={getOperatorFromServer}
                            tutorialOperator={tutorialOperator}
                            tutorialProduct={tutorialProduct}
                            selectedOperator={selectedOperator}
                            selectedOperators={selectedOperators}
                            selectedProduct={selectedProduct}
                            setSelectedProductIndex={setSelectedProductIndex}
                            setSelectedOperators={setSelectedOperators}
                        />} />
                }
                {searched && !!selectedProduct && !!selectedOperator &&
                    <Route path='/DigitalGiftCards/Checkout' render={() =>
                        <CartForm
                            tutorialProduct={tutorialProduct}
                            selectedProduct={selectedProduct}
                            selectedOperator={selectedOperator} />} />
                }
                <Route render={() =>
                    <NamePhoneForm
                        title='WELCOME TO DIGITAL GIFT CARDS'
                        searched={searched}
                        found={customerId > 0}
                        clearCustomers={clearCustomers} />} />
            </Switch>
        </AdvancedForm>
    );
}