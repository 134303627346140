import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import styles from './Overlay.module.css'
import { getClass } from "utilities/classnames";

interface Props {
    fadeIn?: boolean,
    open: boolean,
    className?: string,
    children?: ReactNode
}

export default function Overlay({ fadeIn, open, className, children }: Props) {

    const [opening, setOpening] = useState(false);
    const [closing, setClosing] = useState(false);

    useEffect(() => {
        if (fadeIn) {
            if (open) {
                const timeout = setTimeout(() => setOpening(true));
                return () => clearTimeout(timeout);
            } else {
                setOpening(false);
                setClosing(true);
                const timeout = setTimeout(() => setClosing(false), 200);
                return () => clearTimeout(timeout);
            }
        }
    }, [fadeIn, open]);

    if (open || closing || opening) {
        return (
            <div className={getClass([
                styles.overlay,
                !!fadeIn && styles.fade,
                closing && styles.closing,
                opening && styles.opening,
                className ?? ''
            ])} >
                {children}
            </div>
        )
    }
    return null;
}