import { useState, useContext, useMemo } from 'react';
import type { FormikContextType } from 'formik';
import { useFormikContext } from 'formik';
import type { CustomColumnProps, TableCellDataGetterParams } from '../../../../../Common/VirtualizedTable/VirtualizedTable';
import VirtualizedTable from '../../../../../Common/VirtualizedTable/VirtualizedTable';
import ConfirmationPopup from '../../../../../Common/Popups/ConfirmationPopup/ConfirmationPopup';
import { ParmContext } from '../../../../../HigherOrder/ParmController/ParmController';
import styles from './CustomerBillers.module.css';
import type { CustomerBillerDetailProps, initialValues } from '../../BillPay';
import { tutorialBiller, tutorialCustomer } from '../../BillPay';
import type { BillerDetail } from 'components/hooks/BillPay';
import { useBillPay } from 'components/hooks/BillPay';
import { tooltipBoldClass } from 'utilities/tooltip';

interface Props {
    getBillerDetail: (props: CustomerBillerDetailProps, formik?: FormikContextType<typeof initialValues>) => Promise<BillerDetail | undefined>
    isPreviousBillersTutorial: boolean
}

const emptyColumn = [] as never[];

const staticColumns = [{
    dataKey: 'N/A (acct)',
    cellRenderer: ({ rowData: { acct, ocr } }: TableCellDataGetterParams) => acct || ocr,
    width: 100,
    flexGrow: 1
}, {
    dataKey: 'billerName',
    width: 200,
    flexGrow: 1
}];

export default function CustomerBillers({ getBillerDetail, isPreviousBillersTutorial }: Props) {

    const [loadingBillerDetail, setLoadingBillerDetail] = useState(false);
    const [confirmationPending, setConfirmationPending] = useState(false);
    const [customerBillerToRemove, setCustomerBillerToRemove] = useState(null as null | typeof flattenedData[0]);

    const formik = useFormikContext<typeof initialValues>();
    const { parms } = useContext(ParmContext);
    const { removeCustomerBillerRequest } = useBillPay();

    const handleRowClick = async (rowData: typeof flattenedData[0]) => {
        if (!loadingBillerDetail) {
            setLoadingBillerDetail(true);
            try {
                await getBillerDetail(rowData, formik);
            } catch (error) {
                console.error(error);
                setLoadingBillerDetail(false);
            }
        }
    }

    const removeCustomerBiller = async () => {
        formik.setStatus({ message: "Deleting Customer Biller..." });
        if (customerBillerToRemove) {
            try {
                await removeCustomerBillerRequest(formik.values.customer, customerBillerToRemove);
                formik.setStatus({ message: "" });
                let customerCopy = { ...formik.values.customer };
                customerCopy.billers.forEach((biller) => {
                    biller.billerAccounts = biller.billerAccounts.filter((acct) => {
                        return acct.acct !== customerBillerToRemove.acct ||
                            biller.billerId !== customerBillerToRemove.billerId;
                    });
                });
                formik.setFieldValue('customer', customerCopy);
            } catch (error) {
                console.error(error);
                formik.setStatus({ message: "There was an error deleting this Customer Biller.", messageType: 'error' });
            } finally {
                setConfirmationPending(false);
                setCustomerBillerToRemove(null);
            }
        }
    }

    const columns = (parms?.clerkInfo.type !== 'L' ? [{
        width: 60,
        dataKey: 'N/A (X)',
        cellRenderer: ({ rowData }: TableCellDataGetterParams) =>
            <button className={styles.button} type='button' tabIndex={-1} onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setCustomerBillerToRemove(rowData);
                setConfirmationPending(true);
            }}>X</button>,
        flexGrow: 1
    } as CustomColumnProps] : emptyColumn).concat(staticColumns);

    const flattenedData = useMemo(() =>
        formik.values.customer.billers.map((biller) =>
            biller.billerAccounts.map((account) =>
                ({ ...biller, ...account, billerAccounts: undefined })
            )
        ).flat()
        , [formik.values.customer.billers])

    const traininRow = useMemo(() =>
        flattenedData.find(({ billerId }) =>
            billerId === tutorialCustomer.billers[0].billerId
        )
        , [flattenedData]);

    const handleConfirmationClose = () => {
        setConfirmationPending(false);
        setCustomerBillerToRemove(null);
    }

    const shouldFocusCustomerBillers = formik.values.billerSearch.length === 0;

    return (
        <div className={styles.customerBillers}>
            <div className={isPreviousBillersTutorial ? 'Focused' : ''}>Previous Billers</div>
            <VirtualizedTable
                direction='rtl'
                autoFocus={shouldFocusCustomerBillers}
                data={flattenedData}
                loading={loadingBillerDetail}
                columns={columns}
                onRowClick={handleRowClick}
                height={245}
                trainingFocused={isPreviousBillersTutorial}
                trainingTooltip={
                    <>
                        <div>SELECT THE SAVED BILLER</div>
                        <b className={tooltipBoldClass}>{tutorialBiller.billerName}</b>
                    </>
                }
                trainingRow={traininRow}
                hideHeader />
            <ConfirmationPopup
                onConfirm={removeCustomerBiller}
                onClose={handleConfirmationClose}
                open={confirmationPending}
                fadeIn>
                <h3>Are you sure you want to remove this previous biller?</h3>
            </ConfirmationPopup>
        </div>
    );
}