import type { ReactNode, MouseEvent } from 'react';
import { createContext, useState, useRef, useContext, useEffect, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { SettingsContext } from '../SettingsController/SettingsController';
import { ParmContext } from '../ParmController/ParmController';
import styles from './TrainingOverlay.module.css';
import { defaultTrainingState } from '../SettingsController/SettingsController';
import Overlay from 'components/Common/Overlay/Overlay';

export const TrainingContext = createContext({
    globalTrainingIntroComplete: true as boolean,
    trainingToggleTrainingComplete: true as boolean,
    trainingKey: 0,
    toggleTraining: (forceClose = false) => { },
    completeToggleTraining: (event: MouseEvent<HTMLElement>) => { },
    isTrainingToggleTraining: false as boolean,
    resetTrainingIntro: () => { },
    trainingAvailable: false as boolean,
    isInBetweenTrainings: false as boolean,
    isTraining: false as boolean,
    isGlobalTraining: false as boolean,
    isGlobalTrainingEnd: false as boolean,
    isCurrentPageTraining: false as boolean,
    isNavigationTraining: false as boolean,
    completeGlobalTrainingIntro: () => { },
    toggleTrainingForceClose: () => { },
    headerTraining: false as boolean,
    sidebarTraining: false as boolean
});

/** 
 * Take the initial state object, loop through it like an array, and flip every boolean.
*/
const completedTrainingSettings = Object.fromEntries(
    Object.entries(defaultTrainingState)
        .map(([key, value]) =>
            [key, !value]
        )) as typeof defaultTrainingState;

const partialGlobalTrainingSettings = {
    ...defaultTrainingState,
    header: true,
    sidebarTop: true,
    sidebarBottom: true
}

const completedGlobalTrainingSettings = {
    global: true,
    header: true,
    sidebarTop: true,
    sidebarBottom: true
}

interface Props {
    children: ReactNode
}

/**
 * An Overlay for training modes.
 * Components are expected to increase their z-index to display above the overlay.
 * Prevents Tab key while active.
 */
export default function TrainingOverlay({ children }: Props) {

    const { clerkSettings: { completedTrainings }, setTrainingSetting, settings: { trainingModeDisabled } } = useContext(SettingsContext);
    const { loggedIn, updateRequired, parms } = useContext(ParmContext);
    const { pathname } = useLocation();
    const firstSegment = pathname.split('/')[1];

    const [globalTrainingIntroCompletedClerk, setGlobalTrainingIntroCompletedClerk] = useState(-1);
    const globalTrainingIntroComplete = (parms?.clerkInfo?.clerkId ?? -1) === globalTrainingIntroCompletedClerk;
    const [trainingToggleTrainingCompletedClerk, setTrainingToggleTrainingCompletedClerk] = useState(-1);
    const trainingToggleTrainingComplete = (parms?.clerkInfo?.clerkId ?? -1) === trainingToggleTrainingCompletedClerk;

    const fullyLoggedIn = loggedIn && !updateRequired;

    const onCashLoad = firstSegment === 'CashLoad';
    const onDigitalGiftCards = firstSegment === 'DigitalGiftCards';
    const onMobileTopUp = firstSegment === 'MobileTopUp';
    const onMoneyOrder = firstSegment === 'MoneyOrder';
    const onBillPay = firstSegment === 'BillPay';
    const onAgeVerification = firstSegment === 'AgeVerification';
    const onSupport = firstSegment === 'Support';
    const onSettings = firstSegment === 'Settings';
    const onNetSpend = firstSegment === 'NetSpend';

    /** 
     * If we're training the agent on the sidebar / header.
     */
    const isNavigationTraining = (!completedTrainings.header
        || !completedTrainings.sidebarBottom)
        && fullyLoggedIn;

    /** 
     * If this is the first training, which tries to connect all trainings together.
     */
    const isGlobalTraining = !completedTrainings.global
        && fullyLoggedIn;

    const isCurrentPageTraining = ((onCashLoad && !completedTrainings.amazonCash)
        || (onDigitalGiftCards && !completedTrainings.giftCards)
        || (onMobileTopUp && !completedTrainings.mtu)
        || (onMoneyOrder && !completedTrainings.mo)
        || (onBillPay && !completedTrainings.bp)
        || (onAgeVerification && !completedTrainings.ageVerif)
        || (onSupport && !completedTrainings.support)
        || (onSettings && !completedTrainings.settings)
        || (onNetSpend && !completedTrainings.netspend))
        && !isNavigationTraining
        && fullyLoggedIn
        && ((globalTrainingIntroComplete
            && trainingToggleTrainingComplete)
            || !isGlobalTraining);

    const prevPathnameRef = useRef(pathname);
    const prevIsCurrentPageTrainingRef = useRef(isCurrentPageTraining);

    const completeGlobalTrainingIntro = useCallback(() => {
        setGlobalTrainingIntroCompletedClerk(parms?.clerkInfo?.clerkId ?? -1);
    }, [parms?.clerkInfo?.clerkId])

    const resetTrainingIntro = useCallback(() => {
        setGlobalTrainingIntroCompletedClerk(-1);
        setTrainingToggleTrainingCompletedClerk(-1);
    }, [])

    const isTraining = isGlobalTraining
        || isNavigationTraining
        || isCurrentPageTraining;

    const getToggledSettings = useCallback((forceClose: boolean) => {
        if (isTraining) {
            if (forceClose) {
                return completedTrainingSettings
            } else if (onSupport) {
                return { ...completedGlobalTrainingSettings, support: true };
            } else if (onMobileTopUp) {
                return { ...completedGlobalTrainingSettings, mtu: true };
            } else if (onMoneyOrder) {
                return { ...completedGlobalTrainingSettings, mo: true };
            } else if (onBillPay) {
                return { ...completedGlobalTrainingSettings, bp: true };
            } else if (onAgeVerification) {
                return { ...completedGlobalTrainingSettings, ageVerif: true };
            } else if (onSettings) {
                return { ...completedGlobalTrainingSettings, settings: true };
            } else if (onDigitalGiftCards) {
                return { ...completedGlobalTrainingSettings, giftCards: true };
            } else if (onCashLoad) {
                return { ...completedGlobalTrainingSettings, amazonCash: true };
            } else if (onNetSpend) {
                return { ...completedGlobalTrainingSettings, netspend: true };
            }
            return completedGlobalTrainingSettings;
        }
        if (onSupport) {
            return defaultTrainingState;
        }
        return partialGlobalTrainingSettings;
    }, [isTraining, onAgeVerification, onBillPay, onCashLoad, onDigitalGiftCards, onMobileTopUp, onMoneyOrder, onNetSpend, onSettings, onSupport]);

    const isGlobalTrainingEnd = fullyLoggedIn
        && (completedTrainings.mtu || !parms?.parameters.prePayActive)
        && (completedTrainings.mo || !(parms?.moActive && ("serial" in navigator)))
        && (completedTrainings.bp || !parms?.bpActive || !parms.parameters.billPayActive)
        && (completedTrainings.ageVerif || !parms?.parameters.ageVerifActive)
        && (completedTrainings.giftCards || !parms?.parameters.giftCardActive)
        && (completedTrainings.amazonCash || !parms?.parameters.amazonCashActive)
        && (completedTrainings.netspend || !parms?.parameters.netspendActive)

    const isInBetweenTrainings = isGlobalTraining
        && !isCurrentPageTraining
        && !isNavigationTraining
        && !isGlobalTrainingEnd;

    const trainingAvailable = (onNetSpend
        || onCashLoad
        || onDigitalGiftCards
        || onMobileTopUp
        || onMoneyOrder
        || onBillPay
        || onAgeVerification
        || onSupport
        || onSettings)
        && fullyLoggedIn;

    const isTrainingToggleTraining = isInBetweenTrainings
        && !trainingToggleTrainingComplete
        && globalTrainingIntroComplete;

    const toggleTraining = useCallback((forceClose = false) => {
        if (!isTrainingToggleTraining) {
            const completedClerkId = isTraining ? (parms?.clerkInfo?.clerkId ?? -1) : -1;
            setGlobalTrainingIntroCompletedClerk(completedClerkId);
            setTrainingToggleTrainingCompletedClerk(completedClerkId);
            const newSettings = getToggledSettings(forceClose);
            setTrainingSetting(newSettings);
        }
    }, [isTrainingToggleTraining, isTraining, parms?.clerkInfo?.clerkId, getToggledSettings, setTrainingSetting]);

    const toggleTrainingForceClose = useCallback(() => {
        toggleTraining(true);
    }, [toggleTraining]);

    const completeToggleTraining = useCallback((event: MouseEvent<HTMLElement>) => {
        if (isTrainingToggleTraining) {
            event.preventDefault();
            setTrainingToggleTrainingCompletedClerk(parms?.clerkInfo?.clerkId ?? -1);
        }

    }, [isTrainingToggleTraining, parms?.clerkInfo?.clerkId])

    /** 
     * If we're now training, when we weren't before, we make sure the intro is reset, and we re-start global training.
    */
    useEffect(() => {
        if ((prevPathnameRef.current !== pathname)
            && (isCurrentPageTraining && !prevIsCurrentPageTrainingRef.current)) {
            resetTrainingIntro();
            setTrainingSetting({ global: false });
        }
        prevPathnameRef.current = pathname;
        prevIsCurrentPageTrainingRef.current = isCurrentPageTraining;
    }, [isCurrentPageTraining, pathname, resetTrainingIntro, setTrainingSetting]);

    const headerTraining = !completedTrainings.header || (isInBetweenTrainings && trainingToggleTrainingComplete);
    const sidebarTraining = !headerTraining && !completedTrainings.sidebarTop;

    const labelClass = headerTraining ? styles.headerTraining
        : sidebarTraining ? styles.sidebarTraining
            : '';

    if (!trainingModeDisabled) {
        return (
            <TrainingContext.Provider value={{
                globalTrainingIntroComplete,
                trainingToggleTrainingComplete,
                trainingKey: isCurrentPageTraining ? 1 : 0,
                toggleTraining,
                isTrainingToggleTraining,
                completeToggleTraining,
                resetTrainingIntro,
                trainingAvailable,
                isInBetweenTrainings,
                isTraining,
                isGlobalTraining,
                isGlobalTrainingEnd,
                isCurrentPageTraining,
                isNavigationTraining,
                completeGlobalTrainingIntro,
                toggleTrainingForceClose,
                headerTraining,
                sidebarTraining
            }} >
                <>
                    {isTraining &&
                        <Overlay open className={styles.trainingOverlay}>
                            <div className={`${styles.trainingModeLabel} Focused ${labelClass}`} >
                                <div className={styles.trainingModeLabelBody}>
                                    <h2>TRAINING MODE</h2>
                                    <div>
                                        <span>EXIT TRAINING MODE</span>
                                        <button type='button' tabIndex={-1} onClick={toggleTrainingForceClose}>X</button>
                                    </div>
                                </div>
                            </div>
                        </Overlay>
                    }
                    {children}
                </>
            </TrainingContext.Provider>
        );
    }
    return <>{children}</>;
}