import { useContext } from 'react';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import ConfirmField from '../../../../Common/ConfirmField/ConfirmField';
import { useHistory } from 'react-router-dom'
import type { FormikContextType } from 'formik';
import { Field, useFormikContext } from 'formik';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { stateOptions } from '../../../../../utilities/Tools';
import './SearchForm.css';
import type { CustomerBillerDetailProps, initialValues } from '../BillPay';
import type { BillerDetail } from 'components/hooks/BillPay';
import { softwareName } from 'utilities/Environment';
import { js2xml, xml2js } from 'utilities/xml';

interface Props {
    getBillerDetail: ({ billerId, billerName, payType, acct, ocr }: CustomerBillerDetailProps, formik?: FormikContextType<typeof initialValues>) => Promise<undefined | BillerDetail>
}

export default function SearchForm({ getBillerDetail }: Props) {

    const formik = useFormikContext<typeof initialValues>();
    const { failoverFetch } = useContext(APIContext);
    const { auth } = useContext(AuthContext);
    const { goBack } = useHistory();

    const handleRequest = async () => {
        if (auth) {
            const xml = js2xml({
                billpay: {
                    bp_lookup: {
                        deagnt: String(auth.agentId).padStart(7, '0'),
                        desv: softwareName,
                        debilr: '',
                        billerName: formik.values.billerSearch,
                        blrAddr: formik.values.requestBillerAddress,
                        blrCity: formik.values.requestBillerCity,
                        blrState: formik.values.requestBillerState,
                        blrZip: formik.values.requestBillerZip,
                        deocr: formik.values.accountNum,
                        payeeid: '',
                    },
                    consumerInfo: {
                        customerId: formik.values.customer.id,
                        authToken: auth.authToken,
                        name: formik.values.customer.name,
                        phone: formik.values.customer.phone,
                        email: '',
                        addr1: '',
                        addr2: '',
                        city: '',
                        state: '',
                        zip: ''
                    }
                }
            });

            formik.setSubmitting(true);
            try {
                const response = await failoverFetch('/gateway', {
                    method: 'POST',
                    body: xml,
                    headers: { 'Content-Type': 'text/xml' }
                });
                const requestResponse = xml2js<any>(response).billpay.bp_lookup;
                if (requestResponse.response_code !== '00') {
                    return Promise.reject({ response: { data: requestResponse.response_message } });
                } else {
                    await getBillerDetail({ billerId: requestResponse.debilr, acct: formik.values.accountNum }, formik);
                }
                goBack();
            } catch (error: any) {
                console.error(error);
                formik.setStatus({ message: error.response.data, messageType: 'error' });
            } finally {
                formik.setSubmitting(false);
            }
        }
    }

    return (
        <div className="SearchForm">
            <h4>Biller Request</h4>
            <fieldset>
                <AdvancedField
                    placeholder='Biller Name'
                    name='billerSearch'
                    upperCase={true}
                    maxLength={120} />
                <AdvancedField
                    placeholder='Biller Address'
                    name='requestBillerAddress'
                    upperCase={true}
                    maxLength={90} />
                <AdvancedField
                    placeholder='Biller City'
                    name='requestBillerCity'
                    upperCase={true}
                    maxLength={20} />
                <div className='SearchStateZip'>
                    <Field
                        component='select'
                        name='requestBillerState'
                    >
                        <option value=''>BILLER STATE</option>
                        {stateOptions}
                    </Field>
                    <AdvancedField
                        placeholder='Biller Zip'
                        name='requestBillerZip'
                        upperCase={true}
                        maxLength={12} />
                </div>
            </fieldset>
            <fieldset>
                <AdvancedField
                    placeholder='Customer Name'
                    name='customer.name'
                    upperCase={true}
                    maxLength={35} />
                <AdvancedField
                    placeholder='Customer Phone'
                    name='customer.phone'
                    upperCase={true}
                    maxLength={15}
                    numeric={true} />
                <AdvancedField
                    name='accountNum'
                    placeholder='Account Number'
                    maxLength={60}
                    required upperCase />
                <ConfirmField
                    name='accountNum'
                    placeholder='Account Number'
                    maxLength={60}
                    required upperCase />
            </fieldset>
            <div>
                <button type='button' onClick={handleRequest}>Request</button>
            </div>
        </div >
    );
}