import { downloadURL } from 'utilities/Environment';
import styles from './PrinterDownload.module.css';

/**
 * Page for promting user to download the desktop application for printing settings.
 */
export default function PrinterDownload() {
    return (
        <div className={styles.printerDownload}>
            <div>You currently are not running the desktop PayHereNetwork application.</div>
            <div>Please click below to download the desktop PayHereNetwork application to ensure you have the best printing experience.</div>
            <a href={downloadURL}>Download Desktop PayHereNetwork Client</a>
        </div>
    );
}