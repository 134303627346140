import { useState, useContext, Fragment } from 'react';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import Message from '../../../../Common/Message/Message';
import ConfirmationPopup from '../../../../Common/Popups/ConfirmationPopup/ConfirmationPopup';
import { MOContext } from '../../../../HigherOrder/MoneyOrderController/MoneyOrderController';
import { useFormikContext } from 'formik';
import type { FormValues } from '../MoneyOrderSettings';

interface Props {
    onClose: () => void,
    open: boolean
}

/**
 * Page to display various Money Order Settigns, both from the Server and from the MO Printer itself.
 * Also, allows users to perfrom various functions with the MO Printer.
 */
export default function EjectPopup({ onClose, open }: Props) {

    const { values: { numberToEject }, setStatus, setSubmitting } = useFormikContext<FormValues>();
    const { ejectMoneyOrder, resetStatus } = useContext(MOContext);

    const [beforeEjectConfirm, setBeforeEjectConfirm] = useState(true);
    const [ejectPopup, setEjectPopup] = useState(false);
    const [afterEjectConfirm, setAfterEjectConfirm] = useState(false);

    const handleBeforeEjectConfirm = () => {
        setBeforeEjectConfirm(false);
        setEjectPopup(true);
    }

    const handleEjectSubmit = () => {
        if (Number(numberToEject) <= 10 && Number(numberToEject) > 0) {
            setEjectPopup(false);
            setAfterEjectConfirm(true);
        } else {
            setStatus({ message: 'Please enter a valid number between 1 and 10', messageType: 'error' });
        }
    }

    const handleAfterEjectConfirm = async () => {
        setSubmitting(true);
        try {
            for (let i = 0; i < Number(numberToEject); i++) {
                await ejectMoneyOrder();
                await resetStatus();
            }
        } catch (err) {
            setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
            console.error(err);
        } finally {
            setSubmitting(false);
            onClose();
        }
    }

    const handleClose = () => {
        setBeforeEjectConfirm(true);
        setEjectPopup(false);
        setAfterEjectConfirm(false);
        onClose();
    }

    return (
        <Fragment>
            <ConfirmationPopup
                onConfirm={handleBeforeEjectConfirm}
                onClose={handleClose}
                open={beforeEjectConfirm && open}
                fadeIn>
                <h3>Are you sure you want to eject money orders?</h3>
            </ConfirmationPopup>
            <ConfirmationPopup
                onConfirm={handleEjectSubmit}
                onClose={handleClose}
                open={ejectPopup && open}
                confirmOnly fadeIn>
                <h4>Please Enter the Number of Documents to Eject</h4>
                <AdvancedField name='numberToEject' placeholder='# To Eject' maxLength={2} autoFocus numeric required />
                <Message />
            </ConfirmationPopup>
            <ConfirmationPopup
                onConfirm={handleAfterEjectConfirm}
                onClose={handleClose}
                open={afterEjectConfirm && open}
                fadeIn>
                <h3>Are you sure you want to eject {numberToEject} money order{Number(numberToEject) > 1 ? 's' : ''}?</h3>
            </ConfirmationPopup>
        </Fragment>
    );
}