import { useContext, useCallback, useRef } from 'react';
import packageJson from '../../../package.json';
import { AuthContext } from '../HigherOrder/AuthController/AuthController';
import { ParmContext } from '../HigherOrder/ParmController/ParmController';
import { APIContext } from '../HigherOrder/APIController/APIController';
import { SystemContext } from '../HigherOrder/SystemConroller/SystemController';
import type { BillScanResponseData, CheckScanResponseData, RDMSuccessData } from './RDM';
import { getImageMarker } from './RDM';
import { rewordForMEMO } from '../../utilities/Tools';
import { softwareName } from 'utilities/Environment';
import { formatInTimeZone } from 'date-fns-tz';
import { js2xml, xml2js } from 'utilities/xml';

export interface PaymentResponseBody {
    response_code: string,
    response_message: string,
    response_message2: string,
    debilr: string,
    detnsrt: string,
    deagnt: string,
    rcptdt: string,
    rcpttm: string,
    vcenter_dttm: string,
    debsdt: string,
    detrac: string,
    decust: string,
    deauth: string,
    desurc: string,
    deamtf: string,
    receipt_text: string
}

export interface PaymentResponse {
    billpay: {
        bp_payment?: PaymentResponseBody,
        billerName: string,
        customerId: string,
        customerinfo: string,
        tranPathId: string
    }
}

export interface CountyOption {
    label: string,
    value: string
}

interface BillerBase {
    billerAddr: string,
    billerCity: string,
    billerState: string,
    billerZip: string,
    billerId: number,
    billerName: string,
}

export interface BillerDetail extends BillerBase {
    agentFee: string,
    agentId: number,
    category: string,
    checks: string,
    comment: string,
    custFee: string,
    notes: string,
    ocrH: number,
    ocrType: string,
    ocrW: number,
    ocrX: number,
    ocrY: number,
    processDays: string,
    processFee: string,
    processType: string,
    requiredChars: string,
    specialChars: string,
    suspendedMessage: string,
    countyOptions?: CountyOption[],
    deocrLbl: string
}

export interface PaymentHistory {
    billerId: number,
    accountNum: string,
    authCode: string,
    cashAmt: number,
    checkAmt: number,
    date: string,
    finalBillerName: string,
    originalBillerName: string,
    receiptText?: string,
    postingTime: string,
    time: string,
    totalAmt: number,
    totalFee: string,
    trace: string,
    transTime?: number,
    customerName: string,
    customerPhone: string,
    customerZip: string,
    customerEmail: string
}

export interface BillerAccount {
    ocr: string,
    acct: string
}

export interface CustomerBiller extends BillerPreview {
    billerAccounts: BillerAccount[]
}

interface CustomerAccount extends Omit<CustomerBiller, 'billerAccounts'>, BillerAccount { }

export interface Customer {
    active: string,
    address1: string,
    address2: string,
    city: string,
    email: string,
    id: string,
    name: string,
    phone: string,
    state: string,
    zip: string,
    billers?: CustomerBiller[]
}

export interface CustomerResponse {
    customerInfo: Customer[]
}

export interface BillerDetailProps {
    billerId: number,
    billerName?: string,
    payType?: string
}

export interface SaveImageProps {
    billStubImage?: BillScanResponseData | null,
    checks?: CheckScanResponseData[]
}

export interface BaseBillPayProps {
    customer?: Customer,
    biller: BillerDetail | null,
    accountNum: string,
    billStubImage?: null | BillScanResponseData,
    imageName: string,
    cash: string,
    checks?: CheckScanResponseData[],
    commit?: boolean
}

export interface BaseCivitekProps {
    street: string,
    city: string,
    state: string,
    zip: string,
    email: string,
    firstName: string,
    lastName: string
}

export interface CivitekChildSupportProps extends BaseCivitekProps {
    county: string
}

export interface CivitekTrafficCitationProps extends BaseCivitekProps {
    dlNumber: string,
    dlState: string,
    birthdate: {
        month: string,
        day: string,
        year: string
    }
}

export interface BillerPreview extends BillerBase {
    payType: string,
}

type BaseCivitekBillPayProps = BaseBillPayProps & BaseCivitekProps;
const isCivitekProps = (props: BaseBillPayProps): props is BaseCivitekBillPayProps =>
    !!props.biller && [3289, 3290].includes(props.biller.billerId);

type CivitekChildSupportBillPayProps = BaseBillPayProps & CivitekChildSupportProps;
const isChildSupportProps = (props: BaseBillPayProps): props is CivitekChildSupportBillPayProps =>
    3290 === props.biller?.billerId;

type CivitekTrafficCitationBillPayProps = BaseBillPayProps & CivitekTrafficCitationProps;
const isTrafficCitationProps = (props: BaseBillPayProps): props is CivitekTrafficCitationBillPayProps =>
    3289 === props.biller?.billerId;

export const getTotalCheckAmt = (checks?: CheckScanResponseData[]) =>
    checks?.reduce((accumulator, { Item }) =>
        accumulator + parseInt(Item.UserAmount)
        , 0) ?? 0;

export const getTotal = ({ cash, checks }: BaseBillPayProps) =>
    Number(cash) + getTotalCheckAmt(checks);

const getScore = ({ billerName, billerId }: BillerPreview, search: string) => {
    let score = 0;
    if (billerName.startsWith(search)) {
        score += 1000000;
    }
    if (billerName.includes(search)) {
        score += 100000;
    }
    if (billerId < 100000 && billerId > 0) {
        score += 10000;
    }
    if (billerId < 0) {
        score += 1000;
    }
    return score;
}

const sortBillers = (billers: BillerPreview[], search: string) =>
    billers.sort((biller1, biller2) => {
        const biller1Score = getScore(biller1, search);
        const biller2Score = getScore(biller2, search);
        if (biller1Score === biller2Score) {
            return biller1.billerName.localeCompare(biller2.billerName);
        }
        return biller2Score - biller1Score;
    });

const excludedBillerSet = new Set([3306, 3327, 3328]);

const excludeBillers = <T extends BillerBase>(billers: T[]) =>
    billers.filter(({ billerId }) => !excludedBillerSet.has(billerId));

const getAmountXMLJSON = ({ cash, checks }: BaseBillPayProps) => {
    let xmlJSON = {} as { [key: string]: string };
    let counter = 1;

    if (Number(cash) > 0) {
        xmlJSON['deamtp' + counter] = (Number(cash) / 100).toFixed(2);
        xmlJSON['deptyp' + counter] = '1';
        xmlJSON['deityp' + counter] = '0';
        xmlJSON['deckmi' + counter++] = '';
    }

    if (checks) {
        checks.forEach((check) => {
            xmlJSON['deamtp' + counter] = (Number(check.Item.UserAmount) / 100).toFixed(2);
            xmlJSON['deptyp' + counter] = '2';
            xmlJSON['deityp' + counter] = '4';
            xmlJSON['deckmi' + counter++] = check.Micr.MicrLine;
        });
    }

    return xmlJSON;
}

const getAdditionalXML = (values: BaseBillPayProps) => {
    if (isCivitekProps(values)) {
        const commonFields = {
            adStreet: values.street,
            adCity: values.city,
            adState: values.state,
            adZip: values.zip,
            adEmail: values.email,
            adFName: values.firstName,
            adLName: values.lastName
        }

        if (isTrafficCitationProps(values)) {
            return {
                ...commonFields,
                adDLNumber: values.dlNumber,
                adDLState: values.dlState,
                adDOB: `${values.birthdate.month.padStart(2, '0')}/${values.birthdate.day.padStart(2, '0')}/${values.birthdate.year}`,
            }
        }
        if (isChildSupportProps(values)) {
            return {
                ...commonFields,
                adCounty: (values as CivitekChildSupportBillPayProps).county,
            }
        }
    }
    return {} as { [key: string]: string };
}

const getPaddedBiller = (billerId: number) =>
    billerId < 0 ? billerId.toString() : billerId.toString().padStart(7, '0');

export function useBillPay() {

    const { auth } = useContext(AuthContext);
    const { parms } = useContext(ParmContext);
    const { failoverFetch } = useContext(APIContext);
    const { system: { serial } } = useContext(SystemContext);

    const customerSearchAbortRef = useRef(new AbortController());
    const billerSearchAbortRef = useRef(new AbortController());

    const saveImage = useCallback(async ({ Item, ImageFront }: RDMSuccessData) => {
        if (auth) {
            const [imageMarker, sequence] = Item.Irn.split(',');
            const imageData = ImageFront.Base64Data;
            const xml = js2xml({
                billpay: {
                    bp_image: {
                        detnsrt: 'FDX001',
                        deagnt: auth.agentId.toString().padStart(7, '0'),
                        device_sn: serial,
                        desv: softwareName,
                        depv: packageJson.version,
                        demseq: '0',
                        declrk: auth.clerkId,
                        deimag: imageMarker,
                        image_file_name: imageMarker + '-' + sequence,
                        image_file_size: atob(imageData).length.toString(),
                        image_file_data: imageData,
                        authToken: auth.authToken
                    }
                }
            });

            await failoverFetch('/gateway', {
                method: 'POST',
                body: xml,
                headers: { 'Content-Type': 'text/xml' }
            });
        }
    }, [auth, failoverFetch, serial]);

    const saveImages = useCallback(async ({ billStubImage, checks }: SaveImageProps) => {
        try {
            if (billStubImage) {
                await saveImage(billStubImage);
            }

            if (checks) {
                await Promise.all(checks.map(check =>
                    saveImage(check)
                ));
            }
        } catch (error) {
            console.error(error);
        }
    }, [saveImage]);

    const parsePaymentErrors = useCallback((paymentResponse?: PaymentResponseBody) => {
        if (!paymentResponse?.response_code) {
            return 'An unexpected error has occurred';
        }

        if (paymentResponse.response_code !== '00') {
            const fullResponse = paymentResponse.response_message + " " + (paymentResponse.response_message2 ?? '');
            if ((parms?.clerkInfo.memoId ?? 0) > 0) {
                return rewordForMEMO(fullResponse);
            }
            return fullResponse;
        }
    }, [parms?.clerkInfo.memoId]);

    const billPayRequest = useCallback(async (values: BaseBillPayProps) => {
        if (auth && values.biller) {
            const xml = js2xml({
                billpay: {
                    bp_payment: {
                        detnsrt: 'FDX001',
                        deagnt: String(auth.agentId).padStart(7, '0'),
                        device_sn: serial,
                        desv: softwareName,
                        depv: packageJson.version,
                        demseq: '0',
                        declrk: auth.clerkId,
                        debilr: getPaddedBiller(values.biller.billerId),
                        blrzip: values.biller.billerZip,
                        deamtt: (getTotal(values) / 100).toFixed(2),
                        deocr: values.billStubImage ? values.billStubImage.Ocr.OcrZone0.OcrLine : values.accountNum,
                        input_mode: values.billStubImage ? '2' : '1',
                        payeeid: '0',
                        commit: values.commit ? 'YES' : 'NO',
                        deimag: getImageMarker(values.imageName),
                        ...getAmountXMLJSON(values),
                        ...getAdditionalXML(values)
                    },
                    consumerInfo: values.customer ? {
                        customerId: values.customer.id,
                        authToken: auth.authToken,
                        name: values.customer.name,
                        phone: values.customer.phone,
                        email: values.customer.email,
                        addr1: values.customer.address1,
                        addr2: '',
                        city: values.customer.city,
                        state: values.customer.state,
                        zip: values.customer.zip
                    } : {
                        authToken: auth.authToken,
                        customerId: '0'
                    }
                }
            });

            const response = await failoverFetch('/gateway', {
                method: 'POST',
                body: xml,
                headers: { 'Content-Type': 'text/xml' }
            })

            const paymentResponse = xml2js<PaymentResponse>(response).billpay;
            const errors = parsePaymentErrors(paymentResponse?.bp_payment);
            if (!errors) {
                saveImages(values);
            }
            return paymentResponse;
        }
    }, [auth, failoverFetch, parsePaymentErrors, saveImages, serial])

    const getBillerDetailRequest = useCallback(async ({ billerId, billerName, payType }: BillerDetailProps) => {
        if (auth) {
            const response = await failoverFetch('/Billers?' + new URLSearchParams({
                type: 'detail',
                agentId: auth.agentId,
                billerId: billerId.toString(),
                billerName: billerName ?? '',
                payType: payType ?? 'CASH',
                payeeId: '0',
                authToken: auth.authToken
            }));
            const data = JSON.parse(response) as BillerDetail;
            return data;
        }
    }, [auth, failoverFetch]);

    const customerSearchRequest = useCallback(async (search: string) => {
        customerSearchAbortRef.current.abort();
        customerSearchAbortRef.current = new AbortController();
        if (auth) {
            const response = await failoverFetch('/getCustomers?' + new URLSearchParams({
                value: search,
                authToken: auth.authToken
            }), { signal: customerSearchAbortRef.current.signal });
            const data = JSON.parse(response) as CustomerResponse;
            return data;
        }
        return { customerInfo: [] };
    }, [auth, failoverFetch]);

    const getCustomerBillersRequest = useCallback(async (customer: Customer) => {
        if (auth) {
            const response = await failoverFetch('/Billers?' + new URLSearchParams({
                type: 'cust',
                customerId: customer.id,
                agentId: auth.agentId,
                authToken: auth.authToken
            }));
            const data = JSON.parse(response) as CustomerBiller[];
            return excludeBillers(data);
        }
    }, [auth, failoverFetch]);

    const billerSearchRequest = useCallback(async (search: string) => {
        billerSearchAbortRef.current.abort();
        billerSearchAbortRef.current = new AbortController();
        if (auth) {
            const response = await failoverFetch('/Billers?' + new URLSearchParams({
                type: 'search',
                search,
                agentId: auth.agentId,
                advancedSearch: 'PRIMARY_ONLY',
                authToken: auth.authToken
            }), { signal: billerSearchAbortRef.current.signal });
            const data = JSON.parse(response) as BillerPreview[];
            return sortBillers(excludeBillers(data), search);
        }
    }, [auth, failoverFetch]);

    const removeCustomerBillerRequest = useCallback(async (customer: Customer, customerAccount: CustomerAccount) => {
        if (auth) {
            await failoverFetch('/Billers?' + new URLSearchParams({
                authToken: auth.authToken,
                custId: customer.id,
                acct: customerAccount.acct,
                billerId: customerAccount.billerId.toString()
            }), {
                method: 'DELETE'
            });
        }
    }, [auth, failoverFetch]);

    const getImageName = useCallback(() => {
        return formatInTimeZone(new Date(), 'Etc/GMT-6', 'yyyyMMddHHmmss') + auth?.agentId.padStart(7, '0') + '.tif';
    }, [auth]);

    return {
        billPayRequest,
        getBillerDetailRequest,
        parsePaymentErrors,
        customerSearchRequest,
        getCustomerBillersRequest,
        billerSearchRequest,
        removeCustomerBillerRequest,
        getImageName
    };
}