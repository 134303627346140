import { useFormikContext } from 'formik';
import type { FullOperator, Product, TopupProps } from 'components/hooks/MTU';
import './Cart.css';
import { getClass } from 'utilities/classnames';

interface Props {
    operator?: FullOperator,
    product?: Product
}

const getProductInfo = (product?: Product) => {
    if (product) {
        if (product.localInfoCurrency === 'USD') {
            return {
                productName: '$' + product.localInfoAmount,
                productFee: '$' + ((product.retailPrice - (Number(product.localInfoAmount) * 100)) / 100).toFixed(2),
                productTotal: '$' + (product.retailPrice / 100).toFixed(2)
            }
        } else {
            return {
                productName: product.localInfoAmount + ' ' + product.localInfoCurrency,
                productFee: '',
                productTotal: '$' + (product.retailPrice / 100).toFixed(2)
            }
        }
    }
    return {
        productName: '',
        productFee: '',
        productTotal: ''
    }
}

export default function Cart({ operator, product }: Props) {

    const { values } = useFormikContext<TopupProps>();

    const operatorName = operator?.operator ?? '';
    const { productName, productFee, productTotal } = getProductInfo(product);

    return (
        <div className='Cart'>
            <div className='Phone Disabled'>
                {values.phone}
            </div>
            <div className={getClass([
                'Operator',
                !operatorName && 'Disabled'
            ])}>
                <label>PROVIDER: </label><span>{operatorName}</span>
            </div>
            <div className={getClass([
                'Product',
                !productName && 'Disabled'
            ])}>
                <label>PRODUCT: </label><span>{productName}</span>
            </div>
            <div className={getClass([
                'Fee',
                !productName && 'Disabled',
                !!productName && !productFee && 'Hidden'
            ])}>
                <label>FEE: </label><span>{productFee}</span>
            </div>
            <div className={getClass([
                'Total',
                !productTotal && 'Disabled'
            ])}>
                <label>TOTAL: </label><span>{productTotal}</span>
            </div>
        </div>
    );
}