import { useContext } from 'react';
import BasicPopup from '../BasicPopup/BasicPopup';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import styles from '../../../HigherOrder/TrainingOverlay/TrainingOverlay.module.css';

export default function GlobalTrainingEnd() {
    const { isGlobalTraining, isGlobalTrainingEnd, globalTrainingIntroComplete, toggleTrainingForceClose } = useContext(TrainingContext);

    return (
        <BasicPopup
            className={styles.globalTrainingPopup}
            darkMode
            noOverlay
            fadeIn
            open={isGlobalTraining && isGlobalTrainingEnd && globalTrainingIntroComplete}>
            <h2>Congratulations!</h2>
            <div>You're now prepared to use the PayHereNetwork client!</div>
            <div className={styles.globalTrainingPopupButtons}>
                <button type='button' onClick={toggleTrainingForceClose}>Exit Training</button>
            </div>
        </BasicPopup>
    );
}