import type { ReactNode } from 'react';
import { useState, useRef, createContext, useEffect, useCallback } from 'react';
import { isElectron, isLocal } from "utilities/Environment";
import { useHistory } from 'react-router-dom';
import ConfirmationPopup from 'components/Common/Popups/ConfirmationPopup/ConfirmationPopup';
import type { Systeminformation } from 'systeminformation';
import type { IpcRendererEvent } from 'electron';
import { useBoolean } from 'usehooks-ts';

export const initialSystem = {
    manufacturer: "N/A",
    model: "N/A",
    serial: "agt_owns_device",
    isPOS: false,
    isGM: false,
    isMini: false
};

export const initialNetwork = {
    iface: "N/A",
    ifaceName: "N/A",
    mac: "N/A"
}

export const SystemContext = createContext({
    system: initialSystem,
    network: initialNetwork,
    onNetworkChange: () => Promise.resolve()
});

const isGM = ({ manufacturer, model }: typeof initialSystem | Systeminformation.SystemData) =>
    manufacturer === 'Partner Tech' && model === 'Bay Trail';

const isMini = ({ manufacturer, model }: typeof initialSystem | Systeminformation.SystemData) =>
    manufacturer === 'GOOGLE' && model === 'Reks';

const isPOS = (testSystem: typeof initialSystem | Systeminformation.SystemData) =>
    isGM(testSystem) || isMini(testSystem);

const getSerialNumber = async (systemData: typeof initialSystem | Systeminformation.SystemData) => {
    if (isMini(systemData)) {
        const fsPromises = require('fs').promises;
        try {
            const serial = await fsPromises.readFile('/opt/billPayHere/device_serial', 'utf8');
            return serial;
        } catch (err) {
            console.error(err);
        }
    }
    return systemData.serial;
}

const getElectronSystem = async () => {
    if (isElectron) {
        return await require('electron').ipcRenderer.invoke('getSystemData') as Systeminformation.SystemData
    }
    return initialSystem;
};

const generateUUID = () => {
    const UUID = crypto.randomUUID().replaceAll('-', '');
    localStorage.setItem('UUID', UUID);
    return UUID;
}

const getUUID = () => {
    const UUID = localStorage.getItem('UUID');
    return UUID || generateUUID();
}

interface Props {
    children: ReactNode
}

/**
 * Top-level component responsible for the terminal System info.
 * This is done in a component, rather than inline, as grabbing system information is expensive, and this allows for caching.
 */
export default function SystemController({ children }: Props) {

    const devToolsPassword = useRef<HTMLInputElement>(null);

    const { push } = useHistory();

    const [system, setSystem] = useState(initialSystem);
    const [network, setNetwork] = useState(initialNetwork);
    const { value: devToolsPromptOpen, setTrue: openDevToolsPromt, setFalse: closeDevToolsPrompt } = useBoolean(false);

    const getNetworkInfo = useCallback(async () => {
        if (isElectron) {
            const [networks, name] = await require('electron').ipcRenderer.invoke('getNetworkData') as [Systeminformation.NetworkInterfacesData[], string];
            const network = networks.find((element) => {
                return element.iface === name;
            });
            if (network) {
                network.mac = network.mac.replace(/:/g, '-').toUpperCase();
                setNetwork(network);
            }
        } else {
            const UUID = getUUID();
            setNetwork((currentNetwork) => {
                return {
                    ...currentNetwork,
                    mac: UUID
                };
            });
        }
    }, []);

    const getSystemInfo = async () => {
        const systemData = await getElectronSystem();
        const newSystem = {
            manufacturer: systemData.manufacturer,
            model: systemData.model,
            serial: await getSerialNumber(systemData) || 'agt_owns_device',
            isPOS: isPOS(systemData),
            isGM: isGM(systemData),
            isMini: isMini(systemData)
        };

        if (isElectron) {
            require('electron').ipcRenderer.send('fullScreen', isPOS(systemData) && !isLocal);
        }
        setSystem(newSystem);
    };

    /** 
     * Linux tries to open a new window in certain scenarios. We need to handle it and push it like it should be doing already.
     */
    const handleWindowBlocked = useCallback((event: IpcRendererEvent, url: string) => {
        push(url.split('#/')[1] ?? '/');
    }, [push]);

    useEffect(() => {
        getSystemInfo();
    }, []);

    useEffect(() => {
        getNetworkInfo();
    }, [getNetworkInfo]);

    useEffect(() => {
        if (isElectron) {
            require('electron').ipcRenderer.on('blocked-new-window', handleWindowBlocked);
        }
        return () => {
            if (isElectron) {
                require('electron').ipcRenderer.removeListener('blocked-new-window', handleWindowBlocked);
            }
        }
    }, [handleWindowBlocked]);

    useEffect(() => {
        if (isElectron) {
            require('electron').ipcRenderer.on('devtools-opened', openDevToolsPromt);
        }
        return () => {
            if (isElectron) {
                require('electron').ipcRenderer.removeListener('devtools-opened', openDevToolsPromt);
            }
        }
    }, [openDevToolsPromt]);

    const handleDevToolsPromptSubmit = useCallback(() => {
        if (devToolsPassword.current) {
            require('electron').ipcRenderer.send('devtools-password', devToolsPassword.current.value);
            closeDevToolsPrompt();
            devToolsPassword.current.value = '';
        }
    }, [closeDevToolsPrompt])

    return (
        <SystemContext.Provider value={{ system, network, onNetworkChange: getNetworkInfo }} >
            {children}
            <ConfirmationPopup
                onConfirm={handleDevToolsPromptSubmit}
                onClose={closeDevToolsPrompt}
                confirmOnly={true}
                open={devToolsPromptOpen}
                fadeIn>
                <h3>Enter Helpdesk Password</h3>
                <input type='password' ref={devToolsPassword} />
            </ConfirmationPopup>
        </SystemContext.Provider>
    );
}