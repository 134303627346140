import type { KeyboardEvent } from 'react';
import { useContext, useState, useRef, useEffect, useMemo } from 'react';
import RowRenderer from './RowRenderer';
import { TrainingContext } from '../../HigherOrder/TrainingOverlay/TrainingOverlay';
import styles from './VirtualizedTable.module.css';
import type { CSSDirection } from 'react-window';
import { FixedSizeList } from 'react-window';
import { useBoolean, useEventListener } from 'usehooks-ts';
import { getClass } from 'utilities/classnames';

export interface TableCellDataGetterParams {
    rowData: any
};

export interface CustomColumnProps {
    width?: number,
    label?: string,
    dataKey: string,
    flexGrow?: number,
    cellRenderer?: (params: TableCellDataGetterParams) => any
}

interface Props {
    autoFocus?: boolean,
    height?: number,
    headerHeight?: number,
    rowHeight?: number,
    data: any[],
    selectedData?: any,
    displayOnly?: boolean,
    trainingRow?: any,
    trainingFocused?: boolean,
    trainingTooltip?: JSX.Element,
    onRowClick?: (data: any) => void,
    columns: CustomColumnProps[],
    alwaysFocused?: boolean,
    className?: string,
    loading?: boolean,
    hideHeader?: boolean,
    direction?: CSSDirection
}

export default function VirtualizedTable({ autoFocus, height, headerHeight, rowHeight, data, selectedData,
    displayOnly, trainingRow, trainingFocused, trainingTooltip, onRowClick, columns,
    alwaysFocused, className, loading, hideHeader, direction }: Props) {

    const { isCurrentPageTraining } = useContext(TrainingContext);

    const [scrollToIndex, setScrollToIndex] = useState(0);
    const { value: focused, setTrue: setFocused, setFalse: setBlurred } = useBoolean(!!autoFocus);
    const [errored, setErrored] = useState(false);

    const currentDIVRef = useRef<HTMLDivElement | null>(null);
    const currentTABLERef = useRef<FixedSizeList | null>(null);

    const headerData = useMemo(() =>
        [Object.fromEntries(columns.map(({ dataKey, label }) =>
            [dataKey, label ?? '']
        ))]
        , [columns]);

    const handleRowClick = ({ index, rowData }: { index: number, rowData: any }) => {
        if (index >= 0) {
            setScrollToIndex(index);
            setFocused();
            if (!trainingFocused || !isCurrentPageTraining || trainingRow === rowData) {
                onRowClick?.(rowData);
            } else {
                setErrored(true);
            }
        }
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement> | globalThis.KeyboardEvent) => {
        if ((event.code === 'Enter' || event.code === 'NumpadEnter') && scrollToIndex >= 0 && data.length > 0) { //Enter key
            event.stopPropagation();
            event.preventDefault();
            handleRowClick({ index: scrollToIndex, rowData: data[scrollToIndex] });
        }
        setScrollToIndex((currentIndex) => {
            if (event.code === 'ArrowUp' && currentIndex > 0) {
                return currentIndex - 1;
            } else if (event.code === 'ArrowDown' && currentIndex < data.length - 1) {
                return currentIndex + 1;
            }
            return currentIndex;
        });
    }

    const handleGlobalKeyDown = (event: globalThis.KeyboardEvent) => {
        if (alwaysFocused && currentDIVRef.current !== document.activeElement) {
            handleKeyDown(event);
        }
    }

    useEventListener('keydown', handleGlobalKeyDown);

    useEffect(() => {
        if (currentTABLERef.current && scrollToIndex >= 0) {
            currentTABLERef.current.scrollToItem(scrollToIndex, 'auto');
        }
    }, [scrollToIndex]);

    useEffect(() => {
        if (autoFocus) {
            currentDIVRef.current?.focus();
        }
    }, [autoFocus]);

    return (
        <div
            className={getClass([
                styles.virtualizedTable,
                className ?? '',
                !!trainingFocused && 'Focused'
            ])}
            tabIndex={alwaysFocused ? -1 : 0}
            onKeyDown={handleKeyDown}
            onFocus={setFocused}
            onBlur={setBlurred}
            ref={currentDIVRef}>
            {!hideHeader &&
                <FixedSizeList
                    height={(headerHeight ?? 25) + 1}
                    itemSize={headerHeight ?? 25}
                    itemCount={1}
                    width='100%'
                    style={{
                        lineHeight: (headerHeight ?? 25) + 'px'
                    }}
                    itemData={{
                        data: headerData,
                        columns,
                        headerRow: true
                    }}
                >
                    {RowRenderer}
                </FixedSizeList>
            }
            <FixedSizeList
                direction={direction}
                height={(height ?? 280) - (hideHeader ? 0 : headerHeight ?? 25)}
                itemSize={rowHeight ?? 45}
                itemCount={data.length}
                width='100%'
                style={{
                    lineHeight: (rowHeight ?? 45) + 'px'
                }}
                ref={currentTABLERef}
                itemData={{
                    data,
                    onRowClick: handleRowClick,
                    selectedData,
                    scrollToIndex: (focused || alwaysFocused) ? scrollToIndex : undefined,
                    displayOnly,
                    trainingRow,
                    trainingFocused,
                    trainingTooltip,
                    errored,
                    columns
                }}
            >
                {RowRenderer}
            </FixedSizeList>
            {loading &&
                <div className={styles.loadingOverlay} >
                    <h3>Loading...</h3>
                </div>
            }
        </div>
    );
}