import type { ReactNode } from 'react';
import { useContext, useRef, useState, useEffect } from 'react';
import styles from './BasicPopup.module.css';
import Overlay from 'components/Common/Overlay/Overlay';
import { useOnClickOutside } from 'usehooks-ts';
import { getClass } from 'utilities/classnames';
import { MainviewContext } from 'components/App/Mainview/Mainview';

interface Props {
    onClose?: () => void,
    className?: string,
    darkMode?: boolean,
    children?: ReactNode,
    noOverlay?: boolean,
    fadeIn?: boolean,
    open: boolean,
    lightMode?: boolean,
    noCenter?: boolean
}

/**
 * Displays a Green, closeable box, which overlays the screen.
 * Accepts children like a normal HTML element.
 * Used for basic popups, as well as a base for advanced popups.
 */
export default function BasicPopup({ onClose, className, darkMode, children, noOverlay, fadeIn, open, lightMode, noCenter }: Props) {

    const fullyOpenRef = useRef(!fadeIn);

    const { shouldShowKeyboard } = useContext(MainviewContext);

    const popupRef = useRef<HTMLDivElement | null>(null);

    const [opening, setOpening] = useState(false);
    const [closing, setClosing] = useState(false);

    const handleClose = () => {
        if (fullyOpenRef.current) {
            onClose?.();
        }
    }

    useEffect(() => {
        if (fadeIn) {
            if (open) {
                const openingTimeout = setTimeout(() => setOpening(true));
                const fullyOpenTimeout = setTimeout(() => fullyOpenRef.current = true, 200);
                return () => {
                    clearTimeout(openingTimeout);
                    clearTimeout(fullyOpenTimeout);
                };
            } else {
                fullyOpenRef.current = false;
                setOpening(false);
                setClosing(true);
                const timeout = setTimeout(() => setClosing(false), 200);
                return () => clearTimeout(timeout);
            }
        }
    }, [fadeIn, open]);

    useOnClickOutside(popupRef, handleClose);

    if (open || closing || opening) {
        return (
            <>
                <Overlay
                    fadeIn={fadeIn}
                    open={!noOverlay && open} />
                <div className={getClass([
                    styles.basicPopup,
                    !!darkMode && styles.dark,
                    !!lightMode && styles.light,
                    !!fadeIn && styles.fade,
                    closing && styles.closing,
                    opening && styles.opening,
                    shouldShowKeyboard && styles.keyboard,
                    !noOverlay && styles.overlay,
                    !noCenter && styles.center,
                    className ?? ''
                ])} ref={popupRef}>
                    {!!onClose &&
                        <button type='button' className={styles.closeButton} onClick={handleClose}>X</button>
                    }
                    {children}
                </div >
            </>
        );
    }
    return null;
}