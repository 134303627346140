import { useContext } from 'react';
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { useFormikContext } from 'formik';
import Cart from '../Cart/Cart';
import MessageAggregator from '../../../../Common/MessageAggregator/MessageAggregator';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { TrainingContext } from '../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import Processing from '../../../../Common/Processing/Processing';
import './CartForm.css';
import type { FullOperator, Product, TopupProps } from 'components/hooks/MTU';
import { getTooltipClass, getTooltipTriggerClass, tooltipBoldClass } from 'utilities/tooltip';

interface Props {
    tutorialProduct: string,
    selectedProduct?: Product,
    selectedOperator?: FullOperator
}

/**
 * Common cart form for MTU. This is used on both the second page, as a cart for ProviderProduct selection, aswell as the final checkout cart.
 */
export default function CartForm({ tutorialProduct, selectedProduct, selectedOperator }: Props) {

    const { isCurrentPageTraining } = useContext(TrainingContext);

    const { isSubmitting } = useFormikContext<TopupProps>();

    const { pathname } = useLocation();
    const { goBack } = useHistory();

    const [, firstSegment, secondSegment] = pathname.split('/');

    const isCartTutorial = isCurrentPageTraining
        && selectedProduct?.retailPrice.toString() === tutorialProduct;

    const cartClass = isCartTutorial ? 'Focused' : '';

    if (secondSegment === 'Checkout' && !selectedProduct) {
        return (
            <Redirect to={`/${firstSegment}`} />
        );
    } else {
        return (
            <fieldset className={'CartForm ' + cartClass}>
                <h1>Cart</h1>
                <Cart product={selectedProduct} operator={selectedOperator} />
                {secondSegment === 'Checkout' &&
                    <>
                        <div>
                            Please validate that the information is correct with the customer
                        </div>
                        <div>
                            ALL SALES ARE FINAL
                        </div>
                        <div className='CheckoutButtons'>
                            <AdvancedButton
                                type='button'
                                onClick={goBack} >BACK</AdvancedButton>
                            <div className={getTooltipTriggerClass(isCartTutorial)}>
                                <AdvancedButton autoFocus={true} trainingFocused={isCartTutorial}>CONFIRM</AdvancedButton>
                                <div className={getTooltipClass()}>
                                    <div>REVIEW CHECKOUT</div>
                                    <b className={tooltipBoldClass}>CLICK CONFIRM</b>
                                </div>
                            </div>
                        </div>
                        <Processing open={isSubmitting} />
                    </>
                }
                {secondSegment === 'ProviderProduct' &&
                    <>
                        <div className={getTooltipTriggerClass(isCartTutorial)}>
                            <AdvancedButton trainingFocused={isCartTutorial}>CHECKOUT</AdvancedButton>
                            <div className={getTooltipClass()}>
                                <div>REVIEW CART</div>
                                <b className={tooltipBoldClass}>CLICK CHECKOUT</b>
                            </div>
                        </div>
                        <MessageAggregator />
                    </>
                }
            </fieldset>
        );
    }
}