import { BillerDetail } from "components/hooks/BillPay";
import { useFormikContext } from "formik";
import { initialValues } from "../NetSpend";
import styles from '../NetSpend.module.css';

interface Props {
    billerDetail: BillerDetail
}

export default function Cart({ billerDetail }: Props) {

    const { values } = useFormikContext<typeof initialValues>();
    const productName = billerDetail.billerId === 3327 ? 'ACCOUNT #' : 'CARD #';
    const accountNum = billerDetail.billerId === 3327 ? values.card : `************` + values.card.substring(12);

    return (
        <div className={styles.cart}>
            <div className={styles.cartDiv}>
                <label className={styles.cartLabel}>PHONE: </label><span className={styles.cartSpan}>{values.phone}</span>
            </div>
            <div className={styles.cartDiv}>
                <label className={styles.cartLabel}>{productName}: </label><span className={styles.cartSpan}>{accountNum}</span>
            </div>
            <div className={styles.cartDiv}>
                <label className={styles.cartLabel}>CASH: </label><span className={styles.cartSpan}>{'$' + (Number(values.amount) / 100).toFixed(2)}</span>
            </div>
            <div className={styles.cartDiv}>
                <label className={styles.cartLabel}>FEE: </label><span className={styles.cartSpan}>{'$' + (Number(billerDetail.custFee)).toFixed(2)}</span>
            </div>
            <div className={styles.cartDiv}>
                <label className={styles.cartLabel}>TOTAL: </label><span className={styles.cartSpan}>{'$' + ((Number(values.amount) / 100) + Number(billerDetail.custFee)).toFixed(2)}</span>
            </div>
        </div>
    );
}