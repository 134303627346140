import type { SurveyQuestion } from '../../../HigherOrder/SurveyController/SurveyController';
import { Field } from 'formik';

interface Props {
    currentQuestion: SurveyQuestion
}

export default function Survey({ currentQuestion }: Props) {
    return (
        <div>
            <h4>{currentQuestion.text}</h4>
            {currentQuestion.type === 'OPTION' &&
                <Field
                    component='select'
                    name={currentQuestion.id.toString()}
                    required={currentQuestion.required === 'Y'} >
                    <option value='' disabled>Please choose an option.</option>
                    {currentQuestion.options.map(({ id, text }) =>
                        <option key={id} value={id}>{text}</option>
                    )}
                </Field>
            }
            {currentQuestion.type === 'WRITE' &&
                <Field
                    component='textarea'
                    name={currentQuestion.id.toString()}
                    maxLength={1024}
                    required={currentQuestion.required === 'Y'}
                    placeholder='Please answer.' />
            }
        </div>
    );
}