import { useFormikContext } from 'formik';
import { initialValues } from '../CashLoad';
import './Cart.css';

export default function Cart() {

  const { values } = useFormikContext<typeof initialValues>();

  return (
    <div className='Cart'>
      <div>
        <label>PHONE: </label><span>{values.phone}</span>
      </div>
      <div>
        <label>NAME: </label><span>{values.firstName.trim() + ' ' + values.lastName}</span>
      </div>
      <div>
        <label>AMOUNT: </label><span>{'$' + (Number(values.cash) / 100).toFixed(2)}</span>
      </div>
    </div>
  );
}