import { useContext, useCallback, useMemo } from 'react';
import { PrinterContext } from '../../../../HigherOrder/PrintController/PrintController';
import type { PrintRequest } from '../../../../HigherOrder/PrintController/PrintController';
import { useFormikContext } from 'formik';
import type { FormValues, ParsedTransaction } from '../Reports';
import type { TransactionTotals } from './ReportTable';
import { format } from 'date-fns';

const header = {
    trace: 'Receipt#',
    cashAmt: 'Cash',
    checkAmt: 'Check',
    processFee: 'Proc',
    storeFee: 'Agent',
    transDate: 'Date',
    transTime: 'Time'
};

const getFooters = (data: ParsedTransaction[], totals: TransactionTotals) => [{
    trace: 'Totals',
    cashAmt: totals.cashTotal,
    checkAmt: totals.checkTotal,
    processFee: totals.processFeeTotal,
    storeFee: totals.agentFeeTotal,
    transDate: 'Count',
    transTime: data.length.toString()
}, {
    trace: 'Deposit',
    cashAmt: totals.depositAmount,
    checkAmt: '',
    processFee: '',
    storeFee: '',
    transDate: 'Register',
    transTime: totals.registerAmount
}]

export function useReportPrinter() {

    const { print } = useContext(PrinterContext);
    const { values: { startDate, endDate, user, billPayReport, mobileTopUpReport, moneyOrderReport, giftCardReport, cashLoadReport, netSpendReport } } = useFormikContext<FormValues>();

    const abbreviations = useMemo(() => [
        billPayReport ? 'BP' : ''
        , mobileTopUpReport ? 'MTU' : ''
        , moneyOrderReport ? 'MO' : ''
        , giftCardReport ? 'GC' : ''
        , cashLoadReport ? 'AC' : ''
        , netSpendReport ? 'NS' : '']
        .filter((abbreviation) => !!abbreviation)
        , [billPayReport, cashLoadReport, giftCardReport, mobileTopUpReport, moneyOrderReport, netSpendReport]);

    const printReport = useCallback((data: ParsedTransaction[], totals: TransactionTotals, containsCheckTransaction: boolean) => {
        const reportData = [header].concat(data).concat(getFooters(data, totals));
        return print(([
            { text: startDate === endDate ? `Daily ${format(Number(endDate), 'yyyy-MM-dd')}` : `${format(Number(startDate), 'yyyy-MM-dd')} - ${format(Number(endDate), 'yyyy-MM-dd')}`, style: { smallFont: true, emphasized: true } },
            { text: Number(user) === -1 ? 'All User Report' : 'Single User Report', style: { smallFont: true, emphasized: true } },
            {
                text: abbreviations.length === 5 ? `Combo ${abbreviations[0]}, ${abbreviations[1]}, ${abbreviations[2]}, ${abbreviations[3]}, & ${abbreviations[4]} Report`
                    : abbreviations.length === 4 ? `Combo ${abbreviations[0]}, ${abbreviations[1]}, ${abbreviations[2]}, & ${abbreviations[3]} Report`
                        : abbreviations.length === 3 ? `Combo ${abbreviations[0]}, ${abbreviations[1]}, & ${abbreviations[2]} Report`
                            : abbreviations.length === 2 ? `Combo ${abbreviations[0]} & ${abbreviations[1]} Report`
                                : abbreviations.length === 1 ? `${abbreviations[0]} Only Report`
                                    : `No Products Selected`
                , style: { smallFont: true, emphasized: true }
            },
        ] as PrintRequest).concat(
            reportData.map(({ trace, cashAmt, checkAmt, processFee, storeFee, transDate, transTime }) => ({
                columns: [trace, cashAmt]
                    .concat(containsCheckTransaction ? [checkAmt] : [])
                    .concat([processFee, storeFee, transDate, transTime])
                , style: { smallFont: true }
            }))
        ), { printer: 'Report' });
    }, [abbreviations, endDate, print, startDate, user]);

    return { printReport };
}