import { useState, useContext, useEffect, useMemo } from 'react';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { SystemContext } from '../../../../HigherOrder/SystemConroller/SystemController';
import styles from './NetworkStatus.module.css';
import { getPublicIP } from 'utilities/network';

/**
 * Page for displaying the current status of the Network, and what the client can / cannot talk to.
 * Useful for troubleshooting purposes.
 */
export default function NetworkStatus() {

    const { network: { ifaceName } } = useContext(SystemContext);
    const { serverURL, statusRequest } = useContext(APIContext);

    const [ip, setIp] = useState({
        status: 'Unknown',
        value: 'N/A'
    });
    const [internet, setInternet] = useState({ status: 'Unknown' });
    const [server, setServer] = useState({ status: 'Unknown' });
    const [testing, setTesting] = useState(false);
    const [lastChecked, setLastChecked] = useState('N/A');

    const getIP = async () => {
        const ip = await getPublicIP();
        if (ip) {
            return {
                status: 'Good',
                value: ip
            }
        }
        return {
            status: 'Unknown',
            value: 'N/A'
        }
    }

    const nicStatus = useMemo(() => {
        if (ifaceName !== 'N/A') {
            return {
                name: ifaceName,
                status: 'Good'
            };
        }
        return {
            name: 'N/A',
            status: 'Unknown'
        }
    }, [ifaceName])

    const networkStatus = {
        status: navigator.onLine ? 'Good' : 'Bad'
    }

    const getServerStatus = async () => {
        try {
            await statusRequest({ agentId: "-1", authToken: '!htrae!' });
            return { status: 'Good' };
        } catch (error) {
            console.error(error);
            return { status: 'Bad' };
        }
    }

    const testNetwork = async () => {
        setTesting(true);
        try {
            const [ip, server] = await Promise.all([getIP(), getServerStatus()]);
            setIp(ip);
            setInternet({ status: ip.status });
            setServer(server);
            setTesting(false);
            setLastChecked(new Date().toISOString())
        } catch (err) {
            console.error(err);
            setTesting(false);
        }
    }

    useEffect(() => {
        testNetwork();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <fieldset className={styles.networkStatus}>
            <div>
                <span className={'Indicator ' + nicStatus.status} />
                <span>NIC Status: {nicStatus.name}</span>
            </div>
            <div>
                <span className={'Indicator ' + networkStatus.status} />
                <span>Network State / Loopback Ping</span>
            </div>
            <div>
                <span className={'Indicator ' + ip.status} />
                <span>IP Address: ({ip.value})</span>
            </div>
            <div>
                <span className={'Indicator ' + internet.status} />
                <span>Internet State: (https://ipv4.icanhazip.com/)</span>
            </div>
            <div>
                <span className={'Indicator ' + server.status} />
                <span>Server State: ({serverURL})</span>
            </div>
            <div>
                {lastChecked}
            </div>
            <button type='button' disabled={testing} onClick={testNetwork}>Test Network</button>
        </fieldset>
    );
}