import { useCallback, useContext } from 'react';
import { ParmContext } from '../../HigherOrder/ParmController/ParmController';
import { PrinterContext } from '../../HigherOrder/PrintController/PrintController';
import type { PrintOptions, PrintRequest } from '../../HigherOrder/PrintController/PrintController';
import type { ParsedTransaction } from '../../App/Mainview/Reports/Reports';
import type { PaymentHistory } from 'components/hooks/BillPay';
import { serverParse, useTimeZone } from 'components/hooks/TimeZone';
import type { Customer } from 'components/hooks/QR';
import { useQR } from 'components/hooks/QR';

interface PaymentData {
    date: string,
    time: string,
    trace: string,
    cashAmt: string,
    checkAmt: string,
    totalAmt: string,
    totalFee: string,
    authCode: string,
    finalBillerName: string,
    accountNum: string,
    postingTime: string,
    receiptText: string,
    billerId: number,
    customer?: Customer
}

const getProcessTime = (processTimeFlag: string) => {
    switch (processTimeFlag) {
        case 'A':
            return 'Same day acknowledgement';
        case 'B':
            return 'Next business day in most cases*';
        case 'C':
            return '2-3 business days';
        default:
            return '2-3 business days';
    }
}

export const getHeaderRows = (headers: string[]) =>
    headers.map((header) => ({
        text: header
    })) as PrintRequest ?? [] as PrintRequest;

export const getFooterRows = (trailers: String[], memoId: number) =>
    (memoId > 0 ? [
        { text: 'SHUT-OFF NOTICES WILL NOT BE' },
        { text: 'ACCEPTED WITH LESS THAN' },
        { text: '5 DAYS TO DUE DATE' },
        { text: '' },
        { text: 'Most payments will post in 1-3 business days. all account information is correct before leaving the payment location.' },
        { text: '' },
        { text: 'Proof of payment must be presented for any inquiry or change to the payment.' },
        { text: '' },
        { text: 'A refund may be obtained in accordance with the terms and conditions set forth on the MEMO website (www.memoco.com) or by contacting MEMO at 1029 Mumma Road, P.O.Box 8863 Camp Hill, PA 17001(800) 922-8079.' },
        { text: '' },
        { text: 'MEMO Financial Services New York, Inc., assumes liability for the non- delivery or delayed delivery of any bill payments made by consumers through MEMO, at an authorized payment location in the state of New York only.' },
        { text: '' },
        { text: 'MEMO BILL PAYMENTS is a service of' }
    ] as PrintRequest : [] as PrintRequest
    ).concat(
        trailers.map((trailer) => ({
            text: trailer
        })) as PrintRequest ?? [] as PrintRequest
    ).concat(
        memoId > 0 && trailers.length === 0 ? [
            { text: 'MEMO Financial Services, Inc. and subsidiaries' },
            { text: 'MEMO Financial Services America, Inc.' },
            { text: 'MEMO Financial Services USA, Inc.' },
            { text: 'MEMO Financial Services New York, Inc.' },
        ] as PrintRequest : [] as PrintRequest
    );

const emptyArr = [] as never[];

export function useBillPayPrinter(showQR?: boolean) {

    const { localFormat } = useTimeZone();
    const { parms, companyId } = useContext(ParmContext);
    const memoId = parms?.clerkInfo.memoId ?? 0;
    const headers = parms?.parameters.headers ?? emptyArr;
    const deliveryDisclaimers = parms?.parameters.deliveryDisclaimers ?? emptyArr;
    const trailers = parms?.parameters.trailers ?? emptyArr;
    const receiptSpace = parms?.receiptSpace ?? emptyArr;

    const { print } = useContext(PrinterContext);
    const { getOptInURL } = useQR();

    const printPaymentData = useCallback(async (paymentData: PaymentData, printOptions: Partial<PrintOptions>) => {
        const qrUrl = await getOptInURL(paymentData.customer);

        const title = paymentData.billerId === 3306 ? 'Amazon Cash'
            : [3327, 3328].includes(paymentData.billerId) ? 'NetSpend'
                : memoId > 0 ? 'MEMO Bill Pay'
                    : 'Bill Payment';

        const deliveryTime = paymentData.postingTime.substring(0, paymentData.postingTime.length - 1);
        const deliveryDisclaimer = deliveryDisclaimers.join(" ").replace('[Delivery Time]', deliveryTime);
        const showDeliveryDisclaimer = paymentData.postingTime.endsWith("*") && !!deliveryDisclaimer.trim();

        return print(([
            { text: title, style: { emphasized: true } },
            { text: '' },
        ] as PrintRequest).concat(
            getHeaderRows(headers)
        ).concat([
            { text: '' },
            { text: `Date: ${paymentData.date} Time: ${paymentData.time}` },
            { text: '' },
            { text: `Agent Number: ${companyId}` },
            { text: '' },
            { text: `Receipt Num: ${paymentData.trace}`, style: { emphasized: true, doubleSize: true } },
            { text: '' },
            { text: `Business Date: ${paymentData.date}` },
            { text: '' },
            { text: `Cash Amount: $${paymentData.cashAmt}` }
        ]).concat(
            Number(paymentData.checkAmt) > 0 ? [{
                text: `Check Amount: $${paymentData?.checkAmt}`
            }] : []
        ).concat([
            { text: `Payment: $${paymentData.totalAmt}` },
            { text: `Customer Fee: $${paymentData.totalFee}` },
            { text: '' },
            { text: `Collect Cash: $${(((Number(paymentData.cashAmt) * 100 + Number(paymentData.totalFee) * 100)) / 100).toFixed(2)}`, style: { emphasized: true } },
            { text: '' },
            { text: 'Proof Of Payment', style: { emphasized: true } },
            { text: paymentData.authCode },
            { text: '' },
            { text: `Biller: ${paymentData.finalBillerName}`, style: { emphasized: true } },
            { text: `Account Num: ${paymentData.accountNum}` },
            { text: '' },
            { text: `Delivery Time:`, style: { emphasized: true, doubleSize: true } },
            { text: paymentData.postingTime.trim(), style: { emphasized: true } },
            { text: '' },
        ]).concat(paymentData.receiptText.trim() ? [
            { text: paymentData.receiptText.trim() },
            { text: '' }
        ] : []).concat([
            { text: 'TRANSACTION APPROVED', style: { emphasized: true } },
            { text: '' }
        ]).concat(
            showDeliveryDisclaimer ? [
                { text: deliveryDisclaimer },
                { text: '' }
            ] : []
        ).concat(
            getFooterRows(trailers, memoId)
        ).concat([
            { text: '' },
        ]).concat(
            receiptSpace.map(({ type, value }) =>
                type === 'TEXT' ? { text: value } : { url: value }
            )
        ).concat([
            { text: '' },
        ]).concat(
            showQR && !!qrUrl ? [
                { url: qrUrl }
            ] : []
        ), printOptions);
    }, [companyId, deliveryDisclaimers, getOptInURL, headers, memoId, print, receiptSpace, showQR, trailers])

    const printLastPayment = useCallback((lastPayment: PaymentHistory) => {
        const parsedDate = serverParse(lastPayment.date + lastPayment.time, 'yyyyMMddHHmmss');
        return printPaymentData({
            date: localFormat(parsedDate, 'MM/dd/yyyy'),
            time: localFormat(parsedDate, "HH:mm:ss zzz"),
            trace: lastPayment.trace,
            cashAmt: (lastPayment.cashAmt / 100).toFixed(2),
            checkAmt: (lastPayment.checkAmt / 100).toFixed(2),
            totalAmt: (lastPayment.totalAmt / 100).toFixed(2),
            totalFee: lastPayment.totalFee,
            authCode: lastPayment.authCode,
            finalBillerName: lastPayment.finalBillerName,
            accountNum: lastPayment.accountNum,
            postingTime: lastPayment.postingTime,
            receiptText: lastPayment.receiptText ?? '',
            billerId: lastPayment.billerId,
            customer: {
                name: lastPayment.customerName,
                phone: lastPayment.customerPhone,
                zip: lastPayment.customerZip,
                email: lastPayment.customerEmail
            }
        }, { reprint: false, trace: lastPayment.trace, transTime: lastPayment.transTime });
    }, [localFormat, printPaymentData]);

    const printTransaction = useCallback((transaction: ParsedTransaction, transactions: ParsedTransaction[]) => {
        const { cashAmt, checkAmt } = transactions.filter(({ trace }) =>
            trace === transaction.trace
        ).reduce((accumulator, currentValue) => ({
            cashAmt: accumulator.cashAmt + parseFloat(currentValue.cashAmt ?? 0),
            checkAmt: accumulator.checkAmt + parseFloat(currentValue.checkAmt ?? 0),
        }), { cashAmt: 0, checkAmt: 0 });

        return printPaymentData({
            date: transaction.transDate,
            time: transaction.transTime,
            trace: transaction.trace,
            cashAmt: cashAmt.toFixed(2),
            checkAmt: checkAmt.toFixed(2),
            totalAmt: (cashAmt + checkAmt).toFixed(2),
            totalFee: transaction.customerFee,
            authCode: transaction.authCode,
            finalBillerName: transaction.billerName,
            accountNum: transaction.accountNum,
            postingTime: getProcessTime(transaction.processTimeFlag),
            billerId: Number(transaction.billerId),
            receiptText: ''
        }, { reprint: true });
    }, [printPaymentData]);

    return { printLastPayment, printTransaction };
}