import AdvancedButton from "components/Common/AdvancedButton/AdvancedButton";
import Processing from "components/Common/Processing/Processing";
import type { BillerDetail } from "components/hooks/BillPay";
import { TrainingContext } from "components/HigherOrder/TrainingOverlay/TrainingOverlay";
import { useFormikContext } from "formik";
import { Fragment, useCallback, useContext, useMemo } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { tutorialAmount } from "../AmountForm/AmountForm";
import Cart from "../Cart/Cart";
import type { initialValues } from "../NetSpend";
import styles from '../NetSpend.module.css';
import { getTooltipClass, getTooltipTriggerClass, tooltipBoldClass } from "utilities/tooltip";

interface Props {
    billerDetail: BillerDetail
}

export default function CartForm({ billerDetail }: Props) {

    const { isCurrentPageTraining } = useContext(TrainingContext);

    const { values, isSubmitting, resetForm } = useFormikContext<typeof initialValues>();

    const { pathname } = useLocation();
    const { goBack, push } = useHistory();

    const isCartTutorial = isCurrentPageTraining
        && values.amount === tutorialAmount;

    const cartClass = isCartTutorial ? 'Focused' : '';

    const cancel = useCallback(() => {
        push('/NetSpend');
        resetForm();
    }, [push, resetForm]);

    const controls = useMemo(() => {
        if (pathname.includes('/CartForm')) {
            return (
                <Fragment>
                    <div>
                        Please validate that the information is correct with the customer
                    </div>
                    <div>
                        ALL SALES ARE FINAL
                    </div>
                    <div className={styles.cartFormCheckoutButtons}>
                        <AdvancedButton
                            type='button'
                            className={styles.cartFormButton}
                            onClick={goBack} >BACK</AdvancedButton>
                        <div className={getTooltipTriggerClass(isCartTutorial)}>
                            <AdvancedButton autoFocus={true} trainingFocused={isCartTutorial} className={styles.cartFormButton}>CONFIRM</AdvancedButton>
                            <div className={getTooltipClass()}>
                                <div>REVIEW CHECKOUT</div>
                                <b className={tooltipBoldClass}>CLICK CONFIRM</b>
                            </div>
                        </div>
                    </div>
                    <Processing open={isSubmitting} />
                </Fragment>
            );
        } else if (pathname.includes('/AmountForm')) {
            return (
                <div className={styles.cartFormCheckoutButtons}>
                    <AdvancedButton
                        type='button'
                        className={styles.cartFormButton}
                        onClick={cancel} >CANCEL</AdvancedButton>
                    <div className={getTooltipTriggerClass(isCartTutorial)}>
                        <AdvancedButton trainingFocused={isCartTutorial} className={styles.cartFormButton}>CHECKOUT</AdvancedButton>
                        <div className={getTooltipClass({ placement: 'top' })}>
                            <div>REVIEW CART</div>
                            <b className={tooltipBoldClass}>CLICK CHECKOUT</b>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }, [cancel, goBack, isCartTutorial, isSubmitting, pathname])

    const getFirstSegment = () => {
        return pathname.split('/')[1];
    };

    if (pathname.includes('/CartForm') && Number(values.amount) === 0) {
        return (
            <Redirect to={`/${getFirstSegment()}`} />
        );
    } else {
        return (
            <fieldset className={styles.cartForm + cartClass}>
                <h1>Cart</h1>
                <Cart billerDetail={billerDetail} />
                {controls}
            </fieldset>
        );
    }
}