import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import type { TableCellDataGetterParams } from '../../../../../Common/VirtualizedTable/VirtualizedTable';
import VirtualizedTable from '../../../../../Common/VirtualizedTable/VirtualizedTable';
import Processing from '../../../../../Common/Processing/Processing';
import { useFormikContext } from 'formik';
import type { CheckScanResponseData } from 'components/hooks/RDM';
import { useRDM } from 'components/hooks/RDM';
import styles from './CheckCart.module.css';
import type { initialValues } from '../../BillPay';

const columns = [{
    label: 'Amount',
    dataKey: 'Item.UserAmount',
    minWidth: 100,
    cellRenderer: ({ rowData }: TableCellDataGetterParams) => {
        return ('$' + (rowData.Item.UserAmount / 100).toFixed(2));
    }
}, {
    label: 'MICR Number',
    dataKey: 'Micr.MicrLine',
    minWidth: 200
}];

const maxChecks = 5;

interface Props {
    pendingChecks: CheckScanResponseData[],
    selectedCheck: CheckScanResponseData | null,
    setSelectedCheck: Dispatch<SetStateAction<CheckScanResponseData | null>>,
    addCheck: (checkInfo: CheckScanResponseData) => void,
    removeSelectedCheck: () => void
}

export default function CheckCart({ pendingChecks, selectedCheck, setSelectedCheck, addCheck, removeSelectedCheck }: Props) {

    const { setStatus, setFieldValue, values } = useFormikContext<typeof initialValues>();
    const { checkScan } = useRDM();

    const [scanning, setScanning] = useState(false);

    const handleAddCheck = async () => {
        if (pendingChecks.length >= maxChecks) {
            setStatus({ message: 'Too many payments.  A maximum of ' + maxChecks + ' are allowed per transaction.' });
        } else {
            setScanning(true);
            setStatus({ message: '' });

            try {
                const response = await checkScan(values.imageName, pendingChecks.length + 1, parseInt(values.checkInput));
                if (response) {
                    setFieldValue('checkInput', 0);
                    if (pendingChecks.find((element) => element.Micr.MicrLine === response.Micr.MicrLine)) {
                        setStatus({ message: 'You cannot scan the same check twice.', messageType: 'error' });
                    } else {
                        addCheck(response);
                    }
                }
            } catch (error) {
                setStatus({ message: error, messageType: 'error' });
            } finally {
                setScanning(false);
            }
        }
    }

    const handleRowClick = (rowData: CheckScanResponseData) => {
        if (selectedCheck && rowData.Micr.MicrLine === selectedCheck.Micr.MicrLine) {
            setFieldValue('checkInput', '0');
            setSelectedCheck(null);
        } else {
            setFieldValue('checkInput', rowData.Item.UserAmount);
            setSelectedCheck(rowData);
        }
    }

    return (
        <div className={styles.checkCart}>
            <fieldset>
                <button className={styles.button} type="button" onClick={handleAddCheck} disabled={!!selectedCheck || scanning}>+</button>
                <button className={styles.button} type="button" onClick={removeSelectedCheck} disabled={!selectedCheck || scanning}>-</button>
            </fieldset>
            <fieldset className={styles.tableFieldset}>
                <VirtualizedTable
                    data={pendingChecks}
                    selectedData={selectedCheck}
                    columns={columns}
                    onRowClick={handleRowClick}
                    height={250} />
            </fieldset>
            <Processing open={scanning}>
                <h3>Place check in scanner.</h3>
            </Processing>
        </div>
    );
}