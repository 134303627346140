import { Fragment, useContext, useMemo } from 'react';
import SupportHeader from '../SupportHeader/SupportHeader';
import Message from '../../../../Common/Message/Message';
import AdvancedForm from '../../../../Common/AdvancedForm/AdvancedForm';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import PhoneField from '../../../../Common/PhoneField/PhoneField';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import styles from './Help.module.css';
import supportStyles from '../Support.module.css';
import { Field, type FormikHelpers } from 'formik';

/**
 * Form to let Agents send help requests to Fidelity Express. 
 * Help requests are stored in FELIB2.EAGLEHELP, which has a trigger to make SalesForce cases.
 */
export default function Help() {

    const { parms } = useContext(ParmContext);
    const { auth } = useContext(AuthContext);
    const { failoverFetch } = useContext(APIContext);

    const isMEMO = ((parms?.clerkInfo.memoId ?? 0) > 0);

    const companyName = isMEMO ? "MEMO" : "Fidelity Express";

    const companyPhone = isMEMO ? "800-922-8079" : "800-621-8030";

    /** 
     * Submits help request to the server.
     */
    const handleSubmit = async (values: typeof initialValues, { setStatus, setSubmitting, resetForm }: FormikHelpers<typeof initialValues>) => {
        if (values.phone.length < 12) {
            setStatus({ message: 'Please enter a valid phone number.', messageType: 'error' });
            setSubmitting(false);
            return;
        }

        setStatus({ message: 'Sending help request...', messageType: '' });
        if (auth) {
            try {
                await failoverFetch('/help', {
                    method: 'POST',
                    body: new URLSearchParams({
                        agentId: values.agent ?? '-1',
                        clerkName: values.username,
                        phone: values.phone,
                        email: values.email,
                        message: values.request,
                        authToken: auth.authToken
                    })
                });
                resetForm();
                setStatus({ message: 'Help request submitted. ' + companyName + ' will be contacting you by phone or email soon.', messageType: '' });
            } catch (error) {
                console.error(error);
                setStatus({ message: 'Help request failed. Please contact ' + companyName + ' by phone or email.', messageType: 'error' });
            } finally {
                setSubmitting(false);
            }
        }
    }

    const initialValues = useMemo(() => {
        return {
            agent: auth?.agentId,
            username: auth?.username ?? '',
            phone: '',
            email: '',
            request: ''
        };
    }, [auth?.agentId, auth?.username])

    return (
        <Fragment>
            <SupportHeader />
            <AdvancedForm className={styles.help} initialValues={initialValues} onSubmit={handleSubmit} >
                <Fragment>
                    <div className={styles.helpFields}>
                        <fieldset>
                            <Field type='text' name='agent' disabled />
                            <Field type='text' name='username' disabled />
                            <PhoneField name='phone' placeholder='Phone Number' maxLength={15} required />
                            <Field name='email' placeholder='Email' maxLength={35} />
                        </fieldset>
                        <fieldset>
                            <Field component='textarea' name='request' maxLength={500} rows='7' cols='30'
                                placeholder='How can we help?' required />
                        </fieldset>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Message />
                        <AdvancedButton>Submit</AdvancedButton>
                    </div>
                </Fragment>
            </AdvancedForm>
            <div className={supportStyles.supportPhone}>
                <a href={`tel:${companyPhone}`}>{companyPhone}</a>
            </div>
        </Fragment>
    );
}