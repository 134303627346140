import { useContext, useMemo, useState } from 'react';
import BasicPopup from '../BasicPopup/BasicPopup';
import { SurveyContext } from '../../../HigherOrder/SurveyController/SurveyController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import styles from './Survey.module.css';
import { ParmContext } from 'components/HigherOrder/ParmController/ParmController';
import AdvancedForm from 'components/Common/AdvancedForm/AdvancedForm';
import AdvancedButton from 'components/Common/AdvancedButton/AdvancedButton';
import SurveyQuestion from './SurveyQuestion';

interface Props {
    closed: boolean
}

export default function Survey({ closed }: Props) {

    const { loggedIn, updateRequired } = useContext(ParmContext);
    const { answerSurvey, setSurveyedForSession, currentSurvey } = useContext(SurveyContext);
    const { isTraining } = useContext(TrainingContext);

    const [questionIndex, setQuestionIndex] = useState(0);

    const currentQuestion = currentSurvey?.questions[questionIndex];

    const isLastQuestion = !currentSurvey || currentSurvey.questions.length <= (questionIndex + 1);

    const initialValues = useMemo(() =>
        Object.fromEntries(currentSurvey?.questions.map(({ id }) => [id.toString(), '']) ?? [])
        , [currentSurvey?.questions]);

    const handleSubmit = async (values: typeof initialValues) => {
        if (isLastQuestion) {
            const optionAnswers = currentSurvey?.questions.filter(({ type }) =>
                type === 'OPTION'
            ).map(({ id }) => ({
                optionId: Number(values[id.toString()])
            })) ?? [];

            const writeAnswers = currentSurvey?.questions.filter(({ type }) =>
                type === 'WRITE'
            ).map(({ id }) => ({
                questionId: id,
                text: values[id.toString()]
            })) ?? [];

            await answerSurvey(optionAnswers, writeAnswers);
        } else {
            setQuestionIndex((currentQuestionIndex) => currentQuestionIndex + 1);
        }
    }

    const handleClose = () => {
        if (currentSurvey?.id) {
            setSurveyedForSession(currentSurvey.id);
        }
    }

    return (
        <BasicPopup
            onClose={handleClose}
            darkMode
            open={loggedIn && !updateRequired && !isTraining && !closed && !!currentSurvey}
            fadeIn>
            <AdvancedForm className={styles.survey} initialValues={initialValues} onSubmit={handleSubmit} >
                <h2>{currentSurvey?.title}</h2>
                {currentQuestion &&
                    <SurveyQuestion currentQuestion={currentQuestion} />
                }
                <AdvancedButton className={styles.button}>{isLastQuestion ? 'Submit' : 'Next'}</AdvancedButton>
            </AdvancedForm>
        </BasicPopup>
    );
}