import { useContext, useCallback } from 'react';
import { AuthContext } from '../HigherOrder/AuthController/AuthController';
import { handleFetchErrors } from 'utilities/network';
import { xml2js, js2xml } from 'utilities/xml';

interface ListBiller {
    billerAddr: string
    billerCity: string
    billerId: number
    billerName: string
    billerState: string
    billerZip: string
    payType: string
}

interface FullBiller extends Omit<ListBiller, 'payType'> {
    agentFee: string,
    agentId: number,
    category: string,
    checks: string,
    comment: string,
    custFee: string,
    notes: string,
    ocrH: number,
    ocrType: string,
    ocrW: number,
    ocrX: number,
    ocrY: number,
    processDays: string,
    processFee: string,
    processType: string,
    requiredChars: string,
    specialChars: string,
    suspendedMessage: string
}

interface RDMResult {
    ResponseData: RDMResponseData
}

interface RDMException {
    ResponseData: RDMExceptionData
}

interface RDMResponseData {
    StartType: string,
    TimeStamp: string,
    OperationId: string,
    Sequence: string,
    Notifications: string,

}

export interface RDMSuccessData extends RDMResponseData {
    Item: {
        Irn: string,
        Count: string,
        UserSequence: string,
        UserAmount: string
    },
    ImageFront: {
        Size: string,
        Width: string,
        Height: string,
        JpegQuality: string,
        JpegConstraint: string,
        BilevelIqa: string,
        Base64Data: string,
        Filename: undefined
    }
}

interface ExceptionData {
    Number: string,
    Level: string,
    Code: string,
    Description: string,
    Action: string
}

interface RDMExceptionData extends RDMResponseData {
    Exception: {
        ExceptionData: ExceptionData[] | ExceptionData
    }
}

export interface BillScanResponseData extends RDMSuccessData {
    Ocr: {
        OcrZone0: {
            ZoneId: string,
            Confidence: string,
            OcrLine: string
        }
    }
}

export interface CheckScanResponseData extends RDMSuccessData {
    Micr: {
        MicrLine: string,
        ParsedMicr: {
            Amount: undefined,
            TranCode: string,
            AccountNumber: undefined,
            TransitNumber: undefined,
            BankNumber: undefined,
            EPC: undefined,
            SerialNumber: undefined,
            CheckNumber: string,
            OnUs: undefined
        }
    }
}

const USER_ID = 'abc';

const getDefaultZone = (num: number) => ({
    Enabled: 'false',
    Id: 'Zone' + num,
    Font: 'OcrAAlphaNumeric',
    X: '1800',
    Y: '200',
    W: '5600',
    H: '500',
    Orientation: '0',
    SaveToTiff: 'true'
})

const getZoneJson = (biller: FullBiller) => ({
    Zone0: {
        Enabled: 'true',
        Id: 'Zone0',
        Font: biller.ocrType === 'OCRA' ? 'OcrAAlphaNumeric' : 'OcrBAlphaNumeric',
        X: biller.ocrX.toString(),
        Y: biller.ocrY.toString(),
        W: biller.ocrW.toString(),
        H: biller.ocrH.toString(),
        Orientation: '0',
        SaveToTiff: 'true'
    },
    Zone1: {
        Enabled: 'false',
        Id: 'Zone1',
        Font: 'OcrAAlphaNumeric',
        X: '1200',
        Y: '2000',
        W: '2000',
        H: '230',
        Orientation: '0',
        SaveToTiff: 'true'
    },
    Zone2: getDefaultZone(2),
    Zone3: getDefaultZone(3),
    Zone4: getDefaultZone(4),
    Zone5: getDefaultZone(5),
    Zone6: getDefaultZone(6),
    Zone7: getDefaultZone(7),
    Zone8: getDefaultZone(8),
    Zone9: getDefaultZone(9),
})

export const getImageMarker = (imageName: string) =>
    imageName.substring(0, imageName.length - 4);


const getIRN = (imageName: string, sequence: number) =>
    getImageMarker(imageName) + ',' + sequence;

const sendToScanner = async (data: { Function: string, UserId: string, Parameter?: string, Data?: string }) => {
    try {
        const response = await fetch('https://localhost:736/SCI/7.0/sci.esp', {
            method: 'POST',
            body: new URLSearchParams(data)
        }).then(handleFetchErrors);
        const responseData = await response.text();
        if (!responseData || responseData.includes("No Data") || responseData.includes("Failed")) {
            return Promise.reject("An unexpected error occurred while scanning.");
        } else if (responseData.includes("Exception")) {
            const responseObject = xml2js<RDMException>(responseData).ResponseData;
            const exceptionData = Array.isArray(responseObject.Exception.ExceptionData) ? responseObject.Exception.ExceptionData[0] : responseObject.Exception.ExceptionData;
            return Promise.reject(exceptionData.Description + " " + exceptionData.Action);
        }
        return responseData;
    } catch (error) {
        console.error(error);
        return Promise.reject("An unexpected error occurred while scanning.");
    }
};

const acknowledgeError = async () => {
    try {
        await sendToScanner({
            Function: 'CompleteOperation',
            UserId: USER_ID,
            Parameter: '5'
        });
    } catch (error) {
        console.error(error);
    }
};

const sendAndAcknowledge = async (data: { Function: string, UserId: string, Parameter?: string, Data?: string }) => {
    try {
        return sendToScanner(data);
    } catch (error) {
        await acknowledgeError();
        throw error;
    }
};

/** 
     * Claims the scanner for our User Id.
     * Scanner must be claimed before doing anything.
     */
const claimScanner = async () => {
    const responseData = await sendAndAcknowledge({
        Function: 'ClaimScanner',
        UserId: USER_ID
    })
    if (responseData !== 'Success') {
        return Promise.reject("Scanner could not be claimed. Please try restarting the scanner.");
    }
    return responseData;
}

const releaseScanner = () =>
    sendAndAcknowledge({
        Function: 'ReleaseScanner',
        UserId: USER_ID
    });

const scannerIsReady = async () => {
    const responseData = await sendAndAcknowledge({
        Function: 'GetState',
        UserId: USER_ID
    });
    if (responseData !== "Idle" && responseData !== "Reconnected") {
        return Promise.reject("Scanner isn't ready!");
    }
    return responseData;
}

const getResponseXML = () =>
    sendAndAcknowledge({
        Function: 'GetResponseXml',
        UserId: USER_ID,
        Parameter: '1',
        Data: '0'
    });

const performScan = (command: string) =>
    sendAndAcknowledge({
        Function: 'StartOperation',
        UserId: USER_ID,
        Parameter: '2',
        Data: command
    });

export function useRDM() {

    const { auth } = useContext(AuthContext);

    const getBillScanCommand = useCallback((biller: FullBiller, amount: number, imageName: string, sequence: number) => {
        const irn = getIRN(imageName, sequence);
        const zoneJson = getZoneJson(biller);

        return js2xml({
            StartOperationDetail: {
                StartType: 'ScanSingleItem',
                Directives: {
                    ResponseData: {
                        SendDocDetect: 'false'
                    },
                    UserSequence: {
                        Enabled: 'true',
                        Reset: 'true',
                        Start: '0',
                        Increment: '1',
                        Limit: '0'
                    },
                    UserAmount: amount.toString(),
                    UserIrn: {
                        Data: irn
                    }
                },
                Micr: {
                    Enabled: 'false',
                    BeepOnError: 'true',
                    StopOnError: 'false',
                    Parse: 'true',
                    Font: 'E13b'
                },
                Frank: {
                    Enabled: 'false',
                    Update: 'false'
                },
                PhysicalEndorsement: {
                    Enabled: 'false',
                    ReferenceEdge: 'Lead',
                    XOffset: '3000',
                    Type: 'Standard',
                    Data: {}
                },
                VirtualEndorsement: {
                    Enabled: 'false',
                    ReferenceEdge: 'Lead',
                    XOffset: '1000',
                    YOffset: '750',
                    XLength: '2500',
                    YLength: '1500',
                    Orientation: '0',
                    Type: 'TenPoint',
                    Base64Data: {}
                },
                Image: {
                    Enabled: 'true',
                    Format: 'Tiff',
                    Endian: 'Little',
                    Surfaces: 'Front',
                    Color: 'Bilevel',
                    Merchant: auth?.agentId ?? '',
                    Ownercode: 'RDM0000022060',
                    Compressed: 'true',
                    Resolution: '200',
                    IqaEnabled: 'false',
                    BeepOnError: 'true',
                    StopOnError: 'false',
                    JpegConstraintsFront: {
                        Enabled: 'true',
                        QualityMin: '1',
                        Quality: '90',
                        QualityMax: '100',
                        SizeMin: '0',
                        SizeMax: '3000'
                    },
                    JpegConstraintsBack: {
                        Enabled: 'false',
                        QualityMin: '1',
                        Quality: '90',
                        QualityMax: '100',
                        SizeMin: '0',
                        SizeMax: '3000'
                    },
                    Ocr: {
                        Enabled: 'true',
                        StopOnError: 'false',
                        MinConf: '90',
                        ...zoneJson
                    }
                },
                AuxImage: {
                    Enabled: 'false',
                    Endian: 'Little',
                    Surfaces: 'Both',
                    Color: 'Rgb',
                    Compressed: 'false',
                    Resolution: '300',
                    IqaEnabled: 'false',
                    BeepOnError: 'true',
                    StopOnError: 'false',
                    JpegConstraintsFront: {
                        Enabled: 'true',
                        QualityMin: '1',
                        Quality: '90',
                        QualityMax: '100',
                        SizeMin: '0',
                        SizeMax: '3000'
                    },
                    JpegConstraintsBack: {
                        Enabled: 'false',
                        QualityMin: '1',
                        Quality: '90',
                        QualityMax: '100',
                        SizeMin: '0',
                        SizeMax: '3000'
                    },
                }
            }
        });
    }, [auth?.agentId]);

    const getCheckScanCommand = useCallback((imageName: string, sequence: number, amount: number) => {
        const irn = getIRN(imageName, sequence);
        return js2xml({
            StartOperationDetail: {
                StartType: 'ScanSingleItem',
                Directives: {
                    ResponseData: {
                        SendDocDetect: 'false'
                    },
                    UserSequence: {
                        Enabled: 'true',
                        Reset: 'true',
                        Start: sequence.toString(),
                        Increment: '1',
                        Limit: '0'
                    },
                    UserAmount: amount.toString(),
                    UserIrn: {
                        Data: irn
                    }
                },
                Micr: {
                    Enabled: 'true',
                    BeepOnError: 'true',
                    StopOnError: 'false',
                    Parse: 'true',
                    Font: 'E13b'
                },
                Frank: {
                    Enabled: 'false',
                    Update: 'false'
                },
                PhysicalEndorsement: {
                    Enabled: 'false',
                    ReferenceEdge: 'Lead',
                    XOffset: '3000',
                    Type: 'Standard',
                    Data: {}
                },
                VirtualEndorsement: {
                    Enabled: 'false',
                    ReferenceEdge: 'Lead',
                    XOffset: '1000',
                    YOffset: '750',
                    XLength: '2500',
                    YLength: '1500',
                    Orientation: '0',
                    Type: 'TenPoint',
                    Base64Data: {}
                },
                Image: {
                    Enabled: 'true',
                    Format: 'Tiff',
                    Endian: 'Little',
                    Surfaces: 'Front',
                    Color: 'Bilevel',
                    Merchant: auth?.agentId ?? '',
                    Ownercode: 'RDM0000022060',
                    Compressed: 'true',
                    Resolution: '200',
                    IqaEnabled: 'false',
                    BeepOnError: 'true',
                    StopOnError: 'false',
                    JpegConstraintsFront: {
                        Enabled: 'true',
                        QualityMin: '1',
                        Quality: '90',
                        QualityMax: '100',
                        SizeMin: '0',
                        SizeMax: '3000'
                    },
                    JpegConstraintsBack: {
                        Enabled: 'false',
                        QualityMin: '1',
                        Quality: '90',
                        QualityMax: '100',
                        SizeMin: '0',
                        SizeMax: '3000'
                    }
                },
                AuxImage: {
                    Enabled: 'false',
                    Endian: 'Little',
                    Surfaces: 'Both',
                    Color: 'Rgb',
                    Compressed: 'false',
                    Resolution: '300',
                    IqaEnabled: 'false',
                    BeepOnError: 'true',
                    StopOnError: 'false',
                    JpegConstraintsFront: {
                        Enabled: 'true',
                        QualityMin: '1',
                        Quality: '90',
                        QualityMax: '100',
                        SizeMin: '0',
                        SizeMax: '3000'
                    },
                    JpegConstraintsBack: {
                        Enabled: 'false',
                        QualityMin: '1',
                        Quality: '90',
                        QualityMax: '100',
                        SizeMin: '0',
                        SizeMax: '3000'
                    },
                }
            }
        });
    }, [auth?.agentId])

    const getMetaDataCommand = useCallback((imageName: string, sequence: number, amount: number) => {
        const irn = getIRN(imageName, sequence);
        return js2xml({
            CompleteOperationDetail: {
                MetaData: {
                    Amount: amount.toString(),
                    Irn: irn,
                    Merchant: auth?.agentId ?? ''
                }
            }
        });
    }, [auth?.agentId])

    const sendMetaData = useCallback(async (imageName: string, sequence: number, amount: number) => {
        const xml = getMetaDataCommand(imageName, sequence, amount);
        await sendAndAcknowledge({
            Function: 'CompleteOperation',
            UserId: USER_ID,
            Parameter: '1',
            Data: xml
        })
    }, [getMetaDataCommand]);

    const rdmScan = useCallback(async (command: string, imageName: string, sequence: number, amount: number) => {
        try {
            await claimScanner()
            await scannerIsReady();
            await performScan(command);
            await getResponseXML();
            await sendMetaData(imageName, sequence, amount);
            const responseXML = await getResponseXML();
            return xml2js<RDMResult>(responseXML).ResponseData;
        } finally {
            await releaseScanner();
        }
    }, [sendMetaData])

    const checkScan = useCallback((imageName: string, sequence: number, amount: number) => {
        const xml = getCheckScanCommand(imageName, sequence, amount);
        return rdmScan(xml, imageName, sequence, amount) as Promise<CheckScanResponseData>;
    }, [getCheckScanCommand, rdmScan]);

    const billStubScan = useCallback((imageName: string, biller: FullBiller, amount: number) => {
        const xml = getBillScanCommand(biller, amount, imageName, 0);
        return rdmScan(xml, imageName, 0, amount) as Promise<BillScanResponseData>;
    }, [getBillScanCommand, rdmScan])

    return {
        checkScan,
        billStubScan
    };
}