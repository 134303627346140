import { Fragment, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import styles from './UpdateUserInfo.module.css';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import MessageAggregator from '../../../Common/MessageAggregator/MessageAggregator';
import UserInfoTable from './UserInfoTable/UserInfoTable';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { Field, type FormikProps } from 'formik';

export interface User {
    agentId: number,
    badPassword: boolean,
    clerkId: number,
    hasChallenge: boolean,
    memoId: number,
    type: string,
    username: string
}

const validateUsername = (value: string) => {
    const specialCharacters = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '+', '-', '{', '}', '[', ']', '=', '_', '\\', '|', ';', ':', ',', '<', '>', '.', '?', '/', '`', '~', "'", '"'];
    for (const char of specialCharacters)
        if (value.includes(char)) {
            return 'Special characters are not allowed.';
        }
}

interface FormValues {
    username: string,
    type: string
}

/**
 * Page for Supervisors to manage their user's information.
 */
export default function UpdateUserInfo() {

    const [user, setUser] = useState(null as null | User);
    const [users, setUsers] = useState([] as User[]);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const { failoverFetch } = useContext(APIContext);
    const { auth } = useContext(AuthContext);

    const getUsers = useCallback(async () => {
        if (auth) {
            setUser(null);
            setUsers([]);
            setLoadingUsers(true);
            try {
                const response = await failoverFetch('/Clerks?' + new URLSearchParams({
                    authToken: auth.authToken,
                }));
                const data = JSON.parse(response) as User[];
                setUsers(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingUsers(false);
            }
        }
    }, [auth, failoverFetch])

    const validateUpdate = useCallback((props: FormikProps<FormValues>) => {
        if (!validateUsername(props.values.username)) {
            if (user && auth && user.clerkId.toString() === auth.clerkId) {
                props.setStatus({ message: "You may not edit yourself.", messageType: 'error' });
                return false;
            }
            return true;
        }
        return false;
    }, [auth, user])

    const updateUser = useCallback(async (props: FormikProps<FormValues>) => {
        if (!validateUpdate(props) || !auth || !user) {
            return;
        }

        props.setStatus({ message: "Updating User...", messageType: '' });
        try {
            await failoverFetch('/Clerks', {
                method: 'POST',
                body: new URLSearchParams({
                    authToken: auth.authToken,
                    action: 'update',
                    username: props.values.username,
                    type: props.values.type,
                    clerkId: user.clerkId.toString()
                })
            });
            props.setStatus({ message: "Successfully updated User.", messageType: '' });
            getUsers();
        } catch (error) {
            console.error(error);
            props.setStatus({ message: "Failed to update User.", messageType: 'error' });
        }
    }, [auth, failoverFetch, getUsers, user, validateUpdate])

    const deleteUser = useCallback(async (props: FormikProps<FormValues>) => {
        if (!validateUpdate(props) || !auth || !user) {
            return;
        }

        props.setStatus({ message: "Deleting User...", messageType: '' });
        try {
            await failoverFetch('/Clerks', {
                method: 'POST',
                body: new URLSearchParams({
                    authToken: auth.authToken,
                    action: 'delete',
                    clerkId: user.clerkId.toString()
                })
            });
            props.setStatus({ message: "Successfully deleted User.", messageType: '' });
            getUsers();
        } catch (error) {
            console.error(error);
            props.setStatus({ message: "Failed to delete User.", messageType: 'error' });
        }
    }, [auth, failoverFetch, getUsers, user, validateUpdate])

    const resetUser = useCallback(async (props: FormikProps<FormValues>) => {
        if (!validateUpdate(props) || !auth || !user) {
            return;
        }

        props.setStatus({ message: "Resetting User...", messageType: '' });
        try {
            await failoverFetch('/Clerks', {
                method: 'POST',
                body: new URLSearchParams({
                    authToken: auth.authToken,
                    action: 'reset',
                    clerkId: user.clerkId.toString()
                })
            });
            props.setStatus({ message: "Successfully reset User.", messageType: '' });
        } catch (error) {
            console.error(error);
            props.setStatus({ message: "Failed to reset User.", messageType: 'error' });
        }
    }, [auth, failoverFetch, user, validateUpdate])

    const addUser = useCallback(async (props: FormikProps<FormValues>) => {
        if (!validateUsername(props.values.username) && auth) {
            props.setStatus({ message: "Adding User...", messageType: '' });
            try {
                await failoverFetch('/Clerks', {
                    method: 'POST',
                    body: new URLSearchParams({
                        authToken: auth.authToken,
                        action: 'add',
                        username: props.values.username,
                        type: props.values.type
                    })
                });
                props.setStatus({ message: "Successfully added User.", messageType: '' });
                getUsers();
            } catch (error) {
                console.error(error);
                props.setStatus({ message: "Failed to add User.", messageType: 'error' });
            }
        }
    }, [auth, failoverFetch, getUsers])

    const clearSelectedUser = () => {
        setUser(null);
    }

    const initialValues = useMemo(() => ({
        username: user?.username ?? '',
        type: user?.type ?? 'S'
    }), [user?.type, user?.username])

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(() =>
        <div className={styles.updateUserInfo}>
            <AdvancedForm initialValues={initialValues} enableReinitialize={true} onSubmit={() => { }} >
                {(props: FormikProps<FormValues>) =>
                    <Fragment>
                        <div className={styles.updateUserFormControl}>
                            {user &&
                                <fieldset>
                                    <legend>
                                        Selected User
                                    </legend>
                                    <div>
                                        Username: {user.username}
                                    </div>
                                    <div>
                                        Type: {user.type}
                                    </div>
                                    <div>
                                        <button type='button'
                                            onClick={clearSelectedUser}>Clear Selected User</button>
                                    </div>
                                </fieldset>
                            }
                            <fieldset>
                                <div className={styles.userFields}>
                                    <Field
                                        className={styles.username}
                                        type='text'
                                        name='username'
                                        placeholder='Username'
                                        maxLength={15}
                                        validate={validateUsername}
                                        required />
                                    <Field
                                        component='select'
                                        className={styles.type}
                                        name='type'
                                        required >
                                        <option value='S'>Supervisor</option>
                                        <option value='C'>Standard User</option>
                                        <option value='L'>Limited User</option>
                                    </Field>
                                </div>
                                {user &&
                                    <div>
                                        <button type='button' onClick={() => updateUser(props)}>Update</button>
                                        <button type='button' onClick={() => deleteUser(props)}>Delete</button>
                                        <button type='button' onClick={() => resetUser(props)}>Reset</button>
                                    </div>
                                }
                                {!user &&
                                    <div>
                                        <button type='button' onClick={() => addUser(props)}>Add</button>
                                    </div>
                                }
                            </fieldset>
                        </div>
                        <MessageAggregator />
                    </Fragment>
                }
            </AdvancedForm>
            <UserInfoTable
                loading={loadingUsers}
                setUser={setUser}
                user={user}
                users={users} />
        </div>
        , [addUser, deleteUser, initialValues, loadingUsers, resetUser, updateUser, user, users]);
}