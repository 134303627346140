import { useEffect } from 'react';
import LogoPopup from '../../../../Common/Popups/LogoPopup/LogoPopup';
import Check from '../../../../../images/phnCheckMark.png';
import './PaymentComplete.css';
import type { PaymentHistory } from 'components/hooks/BillPay';

interface Props {
    lastPayment: PaymentHistory,
    onClose: () => void,
    open: boolean
}

export default function PaymentComplete({ lastPayment, onClose, open }: Props) {

    useEffect(() => {
        if (open) {
            const timeout = setTimeout(onClose, 10 * 1000);
            return () => clearTimeout(timeout);
        }
    }, [onClose, open]);

    const primaryBiller = lastPayment.originalBillerName !== lastPayment.finalBillerName;

    return (
        <LogoPopup
            onClose={onClose}
            open={open}
            fadeIn>
            <div className='PaymentComplete'>
                <h4>Payment Complete</h4>
                <img src={Check} width='60' height='60' alt='CheckMark' />
                <div>{lastPayment.originalBillerName}{primaryBiller && " - "}</div>
                {primaryBiller &&
                    <div>{lastPayment.finalBillerName}</div>
                }
                <div>Account: {lastPayment.accountNum}</div>
                <div>Cust Fee: ${lastPayment.totalFee}</div>
                <div>Delivery Time: {lastPayment.postingTime}</div>
                <div>Amount: ${(lastPayment.cashAmt / 100 + lastPayment.checkAmt / 100).toFixed(2)}</div>
            </div>
        </LogoPopup>
    );
}