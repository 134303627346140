import { useState, useEffect, useContext } from 'react';
import type { MouseEvent } from 'react';
import { useLocation, useHistory, Link } from "react-router-dom";
import SettingsIcon from '../../../../images/phnSettings.png';
import BackIcon from '../../../../images/phnBack.png';
import HelpIcon from '../../../../images/phnHelp.png';
import ExitIcon from '../../../../images/phnExit.png';
import OpenIcon from '../../../../images/phnMessageOpen.svg';
import ClosedIcon from '../../../../images/phnMessageClosed.svg';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import styles from './Sidebar.module.css';
import SidebarBanner from './SidebarBanner';
import { getClass } from 'utilities/classnames';
import { getTooltipClass, getTooltipTriggerClass, tooltipButtonClass, tooltipTitleClass } from 'utilities/tooltip';

interface Props {
    openNotifications: () => void,
    notificationsClosed: boolean
}

const initialCompletedTrainings = {
    notifications: false,
    settings: false,
    back: false,
    logout: false
}

/**
 * The common side-bar for most of the application.
 */
export default function SideBar({ openNotifications, notificationsClosed }: Props) {

    const { parms, loggedIn, updateRequired } = useContext(ParmContext);
    const { clerkSettings, setTrainingSetting } = useContext(SettingsContext);
    const { resetTrainingIntro } = useContext(TrainingContext);
    const { handleLogout } = useContext(APIContext);

    const fullyLoggedIn = !!(loggedIn && !updateRequired);

    const isTraining = clerkSettings.completedTrainings.header
        && !clerkSettings.completedTrainings.sidebarBottom
        && fullyLoggedIn;

    const { push, goBack } = useHistory();
    const { pathname } = useLocation();

    const [completedTrainings, setCompletedTrainings] = useState(initialCompletedTrainings);

    const isNotificationTraining = isTraining
        && !completedTrainings.notifications;

    const isSettingsTraining = isTraining
        && !isNotificationTraining
        && !completedTrainings.settings;

    const isBackTraining = isTraining
        && !isNotificationTraining
        && !isSettingsTraining
        && !completedTrainings.back;

    const isLogoutTraining = isTraining
        && !isNotificationTraining
        && !isSettingsTraining
        && !isBackTraining
        && !completedTrainings.logout;

    const isSupportTraining = isTraining
        && !isNotificationTraining
        && !isSettingsTraining
        && !isBackTraining
        && !isLogoutTraining;

    const setTrainingState = (newCompletedTrainings: Partial<typeof initialCompletedTrainings>) => {
        setCompletedTrainings((completedTrainings) => {
            return { ...completedTrainings, ...newCompletedTrainings }
        });
    }

    const handleNotificationsClick = (event: MouseEvent<HTMLElement>) => {
        if (isNotificationTraining) {
            event.preventDefault();
            setTrainingState({ notifications: true });
        } else {
            openNotifications();
        }
    }

    const completeSettingsTraining = (event: MouseEvent<HTMLElement>) => {
        if (isSettingsTraining) {
            event.preventDefault();
            setTrainingState({ settings: true });
        }
    }

    const handleBackClick = (event: MouseEvent<HTMLElement>) => {
        if (isBackTraining) {
            event.preventDefault();
            setTrainingState({ back: true });
            setTrainingSetting({ sidebarTop: true });
        } else {
            goBack();
        }
    }

    const handleLogoutClick = (event: MouseEvent<HTMLElement>) => {
        if (isLogoutTraining) {
            event.preventDefault();
            setTrainingState({ logout: true });
        } else {
            handleLogout();
        }
    }

    const completeSidebarTraining = () => {
        setTrainingSetting({ sidebarBottom: true });
    }

    const startSettingsTraining = (event: MouseEvent<HTMLElement>) => {
        if (isSupportTraining) {
            event.preventDefault();
            completeSidebarTraining();
            push('/Settings');
            resetTrainingIntro();
        }
    }

    const messageIcon = notificationsClosed ? ClosedIcon : OpenIcon;

    const defaultPage = pathname === '/';

    // TODO Refactor away
    useEffect(() => {
        if (!isTraining) {
            setCompletedTrainings(initialCompletedTrainings);
        }
    }, [isTraining]);

    return (
        <nav className={`${styles.sidebar} ${defaultPage ? styles.default : ''}`}>
            <div className={styles.buttonIconContainer}>
                {!(pathname === '/Login') && !updateRequired &&
                    <button className={getClass([
                        getTooltipTriggerClass(isBackTraining)
                    ])}
                        onClick={handleBackClick}
                    >
                        <img src={BackIcon} alt='Back'></img>
                        <div className={getTooltipClass({ placement: 'bottom-right' })}>
                            <div className={tooltipTitleClass}>Back</div>
                            <div>This icon is your back button.</div>
                            <div>This will take you to the previous screen.</div>
                            <div>It functions exactly like a browser back button.</div>
                            <span className={tooltipButtonClass} onClick={handleBackClick}>Continue</span>
                        </div>
                    </button>
                }

                {!updateRequired &&
                    <Link to='/Settings' className={getClass([
                        getTooltipTriggerClass(isSettingsTraining)
                    ])}
                        onClick={completeSettingsTraining}
                    >
                        <img src={SettingsIcon} alt='Settings'></img>
                        <div className={getTooltipClass({ placement: 'bottom-right' })}>
                            <div className={tooltipTitleClass}>Settings</div>
                            <div>This icon opens your Admin functions.</div>
                            <div>Here you can change various printer settings and system settings.</div>
                            <div>You can also manage users, as well as store hours of operation.</div>
                            <div>This is also where you'll find reports, and the ability to void transactions.</div>
                            <span className={tooltipButtonClass} onClick={completeSettingsTraining}>Continue</span>
                        </div>
                    </Link>
                }

                {fullyLoggedIn &&
                    <button className={getClass([
                        styles.notificationIconBtn,
                        getTooltipTriggerClass(isNotificationTraining)
                    ])}
                        onClick={handleNotificationsClick}
                    >
                        {(parms?.unreadMessages ?? 0) > 0 &&
                            <div className={styles.counterCircle}>{parms?.unreadMessages}</div>
                        }
                        <img src={messageIcon} alt='Notifications'></img>
                        <div className={getTooltipClass({ placement: 'bottom-right' })}>
                            <div className={tooltipTitleClass}>Messages</div>
                            <div>This icon opens your notifications.</div>
                            <div>These contain important information,</div>
                            <div>and status updates.</div>
                            <span className={tooltipButtonClass} onClick={handleNotificationsClick}>Continue</span>
                        </div>
                    </button>
                }
            </div>
            <SidebarBanner />
            <div className={styles.buttonIconContainer}>
                {loggedIn &&
                    <button className={getClass([
                        getTooltipTriggerClass(isLogoutTraining)
                    ])}
                        onClick={handleLogoutClick}
                    >
                        <img src={ExitIcon} alt='Exit'></img>
                        <div className={getTooltipClass({ placement: 'top-right' })}>
                            <div className={tooltipTitleClass}>Log Out</div>
                            <div>This icon logs you out.</div>
                            <div>It is recommended that you log out whenever you aren't at the computer.</div>
                            <span className={tooltipButtonClass} onClick={handleLogoutClick}>Continue</span>
                        </div>
                    </button>
                }

                {fullyLoggedIn &&
                    <Link to='/Support' className={getClass([
                        getTooltipTriggerClass(isSupportTraining)
                    ])}
                        onClick={startSettingsTraining}
                    >
                        <img src={HelpIcon} alt='Help'></img>
                        <div className={getTooltipClass({ placement: 'top-right' })}>
                            <div className={tooltipTitleClass}>Support</div>
                            <div>This icon leads to the Support page.</div>
                            <div>This page contains helpful information and frequently asked questions.</div>
                            <div>Additionally, you can use it to contact us for assistance.</div>
                            <span className={tooltipButtonClass} onClick={startSettingsTraining}>Continue</span>
                        </div>
                    </Link>
                }
            </div>
        </nav>
    );
}