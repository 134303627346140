import { useCallback, useContext } from "react";
import { ParmContext } from "components/HigherOrder/ParmController/ParmController";
import { toDataURL } from 'qrcode';

export type Customer = {
    name: string,
    phone: string,
    zip: string,
    email: string
}

export function useQR() {

    const { parms } = useContext(ParmContext);
    const memoId = parms?.clerkInfo.memoId ?? 0;
    const qrOptIn = parms?.parameters.qrOptIn ?? false;

    const getOptInURL = useCallback(async (customer?: Customer) => {
        if (customer && qrOptIn) {
            const agentPath = memoId > 0 ? 'M' : 'F';
            try {
                const customerURL = `${process.env.REACT_APP_SHORT_WEB_PORTAL_CLIENT_URL}/O/${agentPath}?` + new URLSearchParams({
                    n: customer.name,
                    p: customer.phone,
                    z: customer.zip,
                    e: customer.email
                });
                return await toDataURL(customerURL, { errorCorrectionLevel: 'H', scale: 3 });
            } catch (err) {
                console.error(err);
            }
        }
    }, [memoId, qrOptIn]);

    return {
        getOptInURL
    }
}