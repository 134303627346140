import type { ReactNode } from 'react';
import BasicPopup from '../Popups/BasicPopup/BasicPopup';
import styles from './Processing.module.css';

interface Props {
    children?: ReactNode,
    open: boolean,
    hideLoading?: boolean
}

export default function Processing({ children, open, hideLoading }: Props) {
    return (
        <BasicPopup
            className={styles.processingPopup}
            open={open}
            darkMode fadeIn>
            <div className={styles.processing}>
                {!!children &&
                    children
                }
                {!children &&
                    <>
                        <h2 className={styles.title}>PROCESSING PLEASE WAIT</h2>
                        {!hideLoading &&
                            <div>loading...</div>
                        }
                    </>
                }
                <div className={styles.loadingBarContainer}>
                    <div className={styles.loadingBar}></div>
                </div>
            </div>
        </BasicPopup>
    );
}