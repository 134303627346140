import { isElectron, isLinux, isWindows } from "utilities/Environment";

export const restartApp = () => {
    if (isElectron) {
        require('electron').ipcRenderer.send('restartApp');
    } else {
        window.location.reload();
    }
}

export const restartPC = () => {
    if (isElectron) {
        const { promisify } = require('util');
        const exec = promisify(require('child_process').exec);
        if (isWindows) {
            return exec('shutdown -r -t 5');
        } else if (isLinux) {
            return exec('shutdown -r now');
        }
    }
}

export const handleEnter = () => {
    if (document.activeElement) {
        if (document.activeElement.tagName === 'BUTTON' || document.activeElement.tagName === 'A') {
            (document.activeElement as HTMLElement).click();
        } else {
            focusNextElement();
            if (document.activeElement.tagName === 'BUTTON'
                && ((document.activeElement as HTMLButtonElement).type === 'submit'
                    || (document.activeElement as HTMLButtonElement).dataset.type === 'Add')) {
                (document.activeElement as HTMLElement).click();
            }
        }
    }
}

const focussableElements = `a:not([disabled]):not(.disabled), 
button:not([disabled]):not([tabindex="-1"]), 
input:not([disabled]):not([tabindex="-1"]), 
textarea:not([disabled]), 
select:not([disabled]), 
[tabindex]:not([disabled]):not([tabindex="-1"])`;

export const getFocussableElements = () =>
    Array.from(
        document.getElementsByTagName('MAIN')[0]
            .querySelectorAll(focussableElements)
    ).filter((element) =>
        (element as HTMLElement).offsetWidth > 0 || (element as HTMLElement).offsetHeight > 0
    );

export const focusNextElement = () => {
    const focussable = getFocussableElements();
    if (focussable.length > 0 && document.activeElement) {
        const index = (focussable.indexOf(document.activeElement) + 1) % focussable.length;
        (focussable[index] as HTMLElement).focus();
    }
}

export const focusPreviousElement = () => {
    const focussable = getFocussableElements();
    if (focussable.length > 0 && document.activeElement) {
        const index = ((focussable.indexOf(document.activeElement) - 1) + focussable.length) % focussable.length;
        (focussable[index] as HTMLElement).focus();
    }
}

export const handleSpecialEvents = (event: KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleEnter();
    } else if (event.shiftKey && event.code === 'Tab') {
        event.preventDefault();
        focusPreviousElement();
    } else if (event.code === 'Tab') {
        event.preventDefault();
        focusNextElement();
    }
}

const states = ["AL", "AK", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "NE", "NH", "NJ", "NM", "NV", "NY", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WI", "WV", "WY"];
export const stateOptions = states.map((state) =>
    <option key={state} >
        {state}
    </option>
);

export const rewordForMEMO = (message: string) =>
    message
        .replace('800-621-8030', '800-922-8079')
        .replace('8006218030', '8009228079')
        .replace('888-808-1961', '800-922-8079')
        .replace('8888081961', '8009228079')
        .replace('ext. 7021', '')
        .replace('ext. 7023', '')
        .replace('ext. 7025', '')
        .replace('x7029', '')
        .replace('Fdelity Express', 'MEMO Financial Services')
        .replace('FE', 'MEMO')
        .replace('Fidelity', 'MEMO');