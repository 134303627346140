import { useContext, useEffect, useRef, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import Message from '../../../../Common/Message/Message';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import styles from './ChallengeForm.module.css';
import type { FormValues } from '../LoginController';
import { useDebounce } from 'usehooks-ts';

interface ChallengeResponse {
    customerChallenge: string
}

/**
 * Challenge question form.
 * Logs in using the login function provided by the LoginController.
 */
export default function ChallengeForm() {

    const { values, setSubmitting, setFieldValue } = useFormikContext<FormValues>();
    const username = useDebounce(values.username, 250);
    const agent = useDebounce(values.agent, 250);
    const { failoverFetch } = useContext(APIContext);
    const { parms } = useContext(ParmContext);

    const abortRef = useRef(new AbortController());

    const getChallengeQuestion = useCallback(async () => {
        abortRef.current.abort();
        abortRef.current = new AbortController();
        if (username && agent) {
            setSubmitting(true);
            setFieldValue('question', "Grabbing Challenge Question...");
            try {
                const response = await failoverFetch('/getCustChallenge?' + new URLSearchParams({
                    agentId: agent.toString(),
                    username
                }), { signal: abortRef.current.signal });
                const data = JSON.parse(response) as ChallengeResponse;
                setFieldValue('question', data.customerChallenge);
            } catch (error) {
                setFieldValue('question', "Error Occurred Trying to Grab Challenge Question");
            } finally {
                setSubmitting(false);
            }
        }
        else {
            setFieldValue('question', "");
        }
    }, [username, agent, setSubmitting, setFieldValue, failoverFetch]);

    useEffect(() => {
        getChallengeQuestion();
    }, [getChallengeQuestion]);

    return (
        <div className={styles.challengeForm}>
            {!parms &&
                <section>
                    <Field
                        type='text'
                        name='agent'
                        placeholder='Agent Number'
                        maxLength={11}
                        autoFocus={true}
                        required />
                </section>
            }
            <section>
                <Field
                    type='text'
                    name='username'
                    placeholder='Username'
                    maxLength={15}
                    autoFocus={!!parms}
                    required />
            </section>
            <section>
                {values.question}
            </section>
            <section>
                <Field
                    type='password'
                    name='challenge'
                    placeholder='Answer'
                    maxLength={20}
                    required />
            </section>
            <section>
                <AdvancedButton>Submit</AdvancedButton>
            </section>
            <Message />
        </div>
    )
}