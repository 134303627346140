import type { ReactNode } from 'react';
import { useContext } from 'react';
import BasicPopup from '../BasicPopup/BasicPopup';
import { FormikContext } from 'formik';
import styles from './ConfirmationPopup.module.css';

interface Props {
    onClose?: () => void,
    onConfirm?: () => void,
    children?: ReactNode,
    confirmOnly?: boolean,
    open: boolean,
    fadeIn?: boolean
}

export default function ConfirmationPopup({ onClose, onConfirm, children, confirmOnly, open, fadeIn }: Props) {

    const formik = useContext(FormikContext);

    return (
        <BasicPopup
            onClose={onClose}
            darkMode
            open={open}
            fadeIn={fadeIn}>
            <div className={styles.confirmationPopup}>
                <div className={styles.message}>
                    {children}
                </div>
                <div>
                    {confirmOnly &&
                        <button className={styles.button} type='button' onClick={onConfirm}>Confirm</button>
                    }
                    {!confirmOnly &&
                        <>
                            <button className={styles.button} type='button' disabled={formik?.isSubmitting} onClick={onConfirm}>Yes</button>
                            <button className={styles.button} type='button' disabled={formik?.isSubmitting} onClick={onClose}>No</button>
                        </>
                    }
                </div>
            </div>
        </BasicPopup>
    );
}