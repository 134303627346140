import packageJson from '../../package.json';
import UAParser from 'ua-parser-js';

const ua = new UAParser();
export const os = ua.getOS();
export const isWindows = os.name === 'Windows';
export const isChromeOS = os.name === 'Chromium OS';
export const isMacOS = os.name === 'Mac OS';
export const isLinux = os.name === 'Linux';
export const cpu = ua.getCPU();
export const browser = ua.getBrowser();
export const isElectron = browser.name === 'Electron';
export const isLocal = process.env.NODE_ENV === 'development';
export const softwareName = isElectron ? 'SHIVA_ELECTRON' : 'SHIVA_WEB';
export const supportsUSBPrinting = !isWindows && !isElectron && ('usb' in navigator);

export const downloadURL = os.name?.startsWith('Windows')
    ? cpu.architecture === 'amd64'
        ? `${process.env.REACT_APP_ELECTRON_URL}/Windows64/PayHereNetwork+Setup+${packageJson.version}.exe`
        : `${process.env.REACT_APP_ELECTRON_URL}/Windows32/PayHereNetwork+Setup+${packageJson.version}.exe`
    : os.name === 'Mac OS'
        ? `${process.env.REACT_APP_ELECTRON_URL}/Mac/PayHereNetwork-${packageJson.version}.dmg`
        : `${process.env.REACT_APP_ELECTRON_URL}/Linux/${packageJson.productName}-${packageJson.version}.AppImage`;