import type { ChangeEvent } from 'react';
import { useFormikContext } from 'formik';
import ConfirmField from '../../../../Common/ConfirmField/ConfirmField';
import type * as GiftCards from '../DigitalGiftCards';
import type * as CashLoad from '../../CashLoad/CashLoad';
import { tutorialPhone } from '../NamePhoneForm/NamePhoneForm';
import AdvancedField from 'components/Common/AdvancedField/AdvancedField';
import { tooltipBoldClass } from 'utilities/tooltip';

interface Props {
    clearCustomers: () => void,
    isPhoneTutorial: boolean,
    isPhoneConfirmTutorial: boolean,
    searched: boolean
}

export default function PhoneForm({ clearCustomers, isPhoneTutorial, isPhoneConfirmTutorial, searched }: Props) {

    const { values, setFieldValue } = useFormikContext<typeof GiftCards.initialValues | typeof CashLoad.initialValues>();

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (values.firstName || values.lastName) {
            setFieldValue('firstName', '');
            setFieldValue('lastName', '');
            clearCustomers();
        }
    }

    return (
        <fieldset>
            {searched &&
                <AdvancedField
                    name='phone'
                    placeholder='Phone Number'
                    onChange={handlePhoneChange}
                    maxLength={11}
                    required numeric showLabel />
            }
            {!searched &&
                <>
                    <AdvancedField
                        name='phone'
                        placeholder='Phone Number'
                        maxLength={11}
                        trainingTooltip={
                            <>
                                <div>ENTER THIS PHONE NUMBER</div>
                                <b className={tooltipBoldClass}>{tutorialPhone}</b>
                            </>
                        }
                        tooltipPlacement='left'
                        trainingFocused={isPhoneTutorial}
                        autoFocus required numeric />
                    <ConfirmField
                        name='phone'
                        placeholder='Phone Number'
                        maxLength={11}
                        trainingTooltip={
                            <>
                                <div>ENTER PHONE NUMBER AGAIN</div>
                                <b className={tooltipBoldClass}>{tutorialPhone}</b>
                            </>
                        }
                        tooltipPlacement='left'
                        trainingFocused={isPhoneConfirmTutorial}
                        required numeric />
                </>

            }
        </fieldset>
    );
}