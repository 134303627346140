import type { ReactNode } from 'react';
import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { handleEnter, focusNextElement, focusPreviousElement } from '../../../utilities/Tools';
import SimpleKeyboard from 'react-simple-keyboard';
import { isElectron, isLocal } from "utilities/Environment";
import './Keyboard.css';
import { SettingsContext } from '../../HigherOrder/SettingsController/SettingsController';
import { SystemContext } from '../../HigherOrder/SystemConroller/SystemController';
import { MainviewContext } from '../../App/Mainview/Mainview';
import "react-simple-keyboard/build/css/index.css";

const onKeyReleased = (button: string) => {
    if (button === '{bksp}') {
        require('electron').ipcRenderer.send('softKeyPress', { type: 'keyUp', keyCode: 'Backspace' })
    } else if (button === '{up}') {
        require('electron').ipcRenderer.send('softKeyPress', { type: 'keyUp', keyCode: 'Up' })
    } else if (button === '{down}') {
        require('electron').ipcRenderer.send('softKeyPress', { type: 'keyUp', keyCode: 'Down' })
    } else if (button === '{left}') {
        require('electron').ipcRenderer.send('softKeyPress', { type: 'keyUp', keyCode: 'Left' })
    } else if (button === '{right}') {
        require('electron').ipcRenderer.send('softKeyPress', { type: 'keyUp', keyCode: 'Right' })
    }
}

const defaultButtonTheme = [{
    class: 'KeyboardControl',
    buttons: '{hide}'
}, {
    class: 'Backspace',
    buttons: '{bksp}'
}, {
    class: 'Enter',
    buttons: '{enter}'
}, {
    class: 'NumPadEnter',
    buttons: '{numpadEnter}'
}, {
    class: 'Shift',
    buttons: '{shift}'
}, {
    class: 'Space',
    buttons: '{space}'
}, {
    class: 'Tab',
    buttons: '{tab}'
}, {
    class: 'Caps',
    buttons: '{lock}'
}, {
    class: 'Tab',
    buttons: '{shifttab}'
}, {
    class: 'Arrow',
    buttons: '{up}'
}, {
    class: 'Arrow',
    buttons: '{down}'
}, {
    class: 'Arrow',
    buttons: '{left}'
}, {
    class: 'Arrow',
    buttons: '{right}'
}];

const capsButtonTheme = defaultButtonTheme.concat([{
    class: 'Active',
    buttons: '{lock}'
}]);

const shiftButtonTheme = defaultButtonTheme.concat([{
    class: 'Active',
    buttons: '{shift}'
}]);

const comboButtonTheme = defaultButtonTheme.concat([{
    class: 'Active',
    buttons: '{lock}'
}, {
    class: 'Active',
    buttons: '{shift}'
}]);

const numPadLayout = {
    default: [
        "7 8 9",
        "4 5 6",
        "1 2 3",
        "{bksp} 0 {enter}"
    ]
};

const oldKeyboardLayout = {
    'default': [
        '{hide}',
        'q w e r t y u i o p [ ] \\ 1 2 3 -',
        'a s d f g h j k l ; \' {bksp} 4 5 6 =',
        '{shifttab} z x c v b n m , / {enter} 7 8 9 {numpadEnter}',
        '{shift} {space} {up} {down} {left} {right} 0 . {numpadEnter}',
    ],
    'shift': [
        '{hide}',
        'Q W E R T Y U I O P { } | ! @ # _',
        'A S D F G H J K L : " {bksp} $ % ^ +',
        '{shifttab} Z X C V B N M < ? {enter} & * ( {numpadEnter}',
        '{shift} {space} {up} {down} {left} {right} ) > {numpadEnter}',
    ]
};

const defaultKeyboardLayout = {
    'default': [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} q w e r t y u i o p [ ] \\',
        '{lock} a s d f g h j k l ; \' {enter}',
        '{shift} z x c v b n m , . / {shift}',
        '{space} {hide}'
    ],
    'shift': [
        '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
        '{shifttab} Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : " {enter}',
        '{shift} Z X C V B N M < > ? {shift}',
        '{space} {hide}'
    ]
};

export const KeyboardContext = createContext({
    handleInputBlur: () => { },
    handleInputFocus: (numeric?: boolean | undefined) => { }
});

interface Props {
    children: ReactNode,
    numericOnly?: boolean,
    noKeyboard?: boolean,
    dynamicKeyboard?: boolean
}

export default function Keyboard({ numericOnly, noKeyboard, dynamicKeyboard, children }: Props) {

    const { updateKeyboardState, menuClosed } = useContext(MainviewContext);
    const { system: { isPOS } } = useContext(SystemContext);
    const { setSetting, settings: { virtualKeyboard, oldKeyboard } } = useContext(SettingsContext);

    const [inputFocused, setInputFocused] = useState(false);
    const [inputNumeric, setInputNumeric] = useState(false);
    const [caps, setCaps] = useState(false);
    const [shift, setShift] = useState(false);

    const handleInputFocus = useCallback((numeric?: boolean) => {
        setInputFocused(true);
        setInputNumeric(!!numeric);
    }, []);

    const handleInputBlur = useCallback(() => {
        setInputFocused(false);
        setInputNumeric(false);
    }, [])

    const showKeyboard = useCallback(() => {
        setSetting({ virtualKeyboard: true });
    }, [setSetting])

    const hideKeyboard = useCallback(() => {
        setSetting({ virtualKeyboard: false });
    }, [setSetting])

    const onKeyPress = useCallback((button: string) => {
        if (button === '{hide}') {
            hideKeyboard();
        } else if (button === "{shift}") {
            setShift((currentShift) => !currentShift);
        } else if (button === "{lock}") {
            setCaps((currentCaps) => !currentCaps);
        } else if (button === '{enter}' || button === '{numpadEnter}') {
            handleEnter();
        } else if (button === '{tab}') {
            focusNextElement();
        } else if (button === '{shifttab}') {
            focusPreviousElement();
        } else if (button === '{bksp}') {
            require('electron').ipcRenderer.send('softKeyPress', { type: 'keyDown', keyCode: 'Backspace' })
        } else if (button === '{space}') {
            require('electron').ipcRenderer.send('softKeyPress', { type: 'char', keyCode: ' ' })
        } else if (button === '{up}') {
            require('electron').ipcRenderer.send('softKeyPress', { type: 'keyDown', keyCode: 'Up' })
        } else if (button === '{down}') {
            require('electron').ipcRenderer.send('softKeyPress', { type: 'keyDown', keyCode: 'Down' })
        } else if (button === '{left}') {
            require('electron').ipcRenderer.send('softKeyPress', { type: 'keyDown', keyCode: 'Left' })
        } else if (button === '{right}') {
            require('electron').ipcRenderer.send('softKeyPress', { type: 'keyDown', keyCode: 'Right' })
        } else {
            setShift(false);
            require('electron').ipcRenderer.send('softKeyPress', { type: 'char', keyCode: button })
        }
    }, [hideKeyboard])

    const hasKeyboard = (isElectron && isLocal) || isPOS;

    const shouldShowKeyboard = hasKeyboard
        && virtualKeyboard
        && menuClosed
        && !noKeyboard
        && ((dynamicKeyboard && inputFocused)
            || !dynamicKeyboard);

    const shouldShowNumPad = shouldShowKeyboard && (!!(numericOnly) || inputNumeric);
    const shouldShowOldKeyboard = shouldShowKeyboard && !shouldShowNumPad && oldKeyboard;
    const shouldShowDefaultKeyboard = shouldShowKeyboard && !shouldShowNumPad && !oldKeyboard;

    // TODO Refactor this away
    useEffect(() => {
        updateKeyboardState(shouldShowKeyboard);
        return () => updateKeyboardState(false);
    }, [shouldShowKeyboard, updateKeyboardState]);

    return (
        <KeyboardContext.Provider value={{
            handleInputBlur,
            handleInputFocus
        }} >
            <>
                {children}
                {shouldShowKeyboard &&
                    <SimpleKeyboard
                        maxLength={1}
                        tabCharOnTab={false}
                        newLineOnEnter={false}
                        preventMouseDownDefault={true}
                        mergeDisplay={true}
                        disableButtonHold={true}
                        display={{
                            '{bksp}': '⌫',
                            '{enter}': '⏎',
                            '{numpadEnter}': '⏎',
                            '{shift}': 'Shift',
                            '{space}': 'Space',
                            '{tab}': 'Tab',
                            '{lock}': 'Caps',
                            '{hide}': ' ',
                            '{shifttab}': 'Tab',
                            '{up}': '▲',
                            '{down}': '▼',
                            '{left}': '◄',
                            '{right}': '►'
                        }}
                        onKeyPress={onKeyPress}
                        onKeyReleased={onKeyReleased}
                        theme={'hg-theme-default '
                            + (dynamicKeyboard ? 'Dynamic ' : 'Static ')
                            + (shouldShowNumPad ? 'NumPad ' : '')
                            + (shouldShowOldKeyboard ? 'Old ' : '')
                            + (shouldShowDefaultKeyboard ? 'Default' : '')}
                        buttonTheme={(caps && shift) ? comboButtonTheme
                            : caps ? capsButtonTheme
                                : shift ? shiftButtonTheme
                                    : defaultButtonTheme}
                        layoutName={(shouldShowNumPad || caps === shift) ? 'default' : 'shift'}
                        layout={shouldShowNumPad ? numPadLayout
                            : shouldShowOldKeyboard ? oldKeyboardLayout
                                : defaultKeyboardLayout} />
                }
                {!shouldShowKeyboard && hasKeyboard && !virtualKeyboard && !noKeyboard && !dynamicKeyboard &&
                    <button type='button' className='KeyboardControl Closed' tabIndex={-1} onClick={showKeyboard}> </button>
                }
                {shouldShowNumPad &&
                    <button type='button' className='KeyboardControl Open' tabIndex={-1} onClick={hideKeyboard}> </button>
                }
            </>
        </KeyboardContext.Provider>
    );
}