import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';

export default function PrinterSettings() {

    const { parms, loggedIn } = useContext(ParmContext);

    const printerSetupDisabled = !loggedIn;

    return (
        <div className="PrinterSettings">
            <fieldset className='Links'>
                <Link to="/MoneyOrderSettings" className={!("serial" in navigator) || !loggedIn || !parms?.moActive ? 'disabled' : ''}>Money Order Printer Settings</Link>
                <Link to="/PrinterSetup" className={printerSetupDisabled ? 'disabled' : ''}>Receipt & Report Printer Settings</Link>
            </fieldset>
        </div>
    );
}