import { useContext, useState } from 'react';
import NetworkStatus from './NetworkStatus/NetworkStatus';
import IPAddressForm from './IPAddressForm/IPAddressForm';
import WifiForm from './WifiForm/WifiForm';
import { SystemContext } from '../../../HigherOrder/SystemConroller/SystemController';
import styles from './Network.module.css';
import { isElectron, isLocal } from 'utilities/Environment';
import { getClass } from 'utilities/classnames';

/**
 * Page for all things Network related. 
 * Mostly consits of two components.
 * Both of which need to know the current NetworkInterface.
 */
export default function Network() {

    const { system: { isPOS } } = useContext(SystemContext);

    const [tab, setTab] = useState(0);

    return (
        <div className={styles.network}>
            <NetworkStatus />
            {isElectron &&
                <div className={styles.tabs}>
                    <ul className={styles.tabList}>
                        <li className={getClass([
                            styles.tab,
                            tab === 0 && styles.selected
                        ])}
                            onClick={() => setTab(0)}>Static IP Config</li>
                        {(isLocal || isPOS) &&
                            <li className={getClass([
                                styles.tab,
                                tab === 1 && styles.selected
                            ])}
                                onClick={() => setTab(1)}>Wifi Config</li>
                        }
                    </ul>
                    <div className={getClass([
                        styles.tabPanel,
                        tab === 0 && styles.selected
                    ])}><IPAddressForm /></div>
                    {(isLocal || isPOS) &&
                        <div className={getClass([
                            styles.tabPanel,
                            tab === 1 && styles.selected
                        ])}><WifiForm /></div>
                    }
                </div>
            }
        </div>
    );
}