import type { ReactNode } from 'react';
import BasicPopup from '../BasicPopup/BasicPopup';
import Logo from '../../../../images/phnLogo.png';
import styles from './LogoPopup.module.css';

interface Props {
    onClose?: () => void,
    children?: ReactNode,
    open: boolean,
    fadeIn?: boolean,
    className?: string
}

export default function LogoPopup({ onClose, children, open, fadeIn, className }: Props) {
    return (
        <BasicPopup
            className={className}
            onClose={onClose}
            open={open}
            fadeIn={fadeIn}>
            <img src={Logo} alt='PayHereNetwork' />
            <div className={styles.logoPopup}>
                {children}
            </div>
        </BasicPopup>
    );
}