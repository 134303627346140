import { useContext, useState, useCallback } from 'react';
import { useLocation, useHistory, Route, Switch } from "react-router-dom";
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import CartForm from './CartForm/CartForm';
import PhoneForm from './PhoneForm/PhoneForm';
import ProviderProductForm from './ProviderProductForm/ProviderProductForm';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { useMTU, MTUError } from 'components/hooks/MTU';
import type { FullOperator, OperatorPreview, OperatorProps } from 'components/hooks/MTU';
import type { FormikHelpers } from 'formik';
import Promotions from './Promotions/Promotions';
import { useBoolean, useEffectOnce } from 'usehooks-ts';
import { FetchError } from 'utilities/network';
import { serverFormat } from 'components/hooks/TimeZone';

const tutorialOperator = {
    operator: 'Unefon Mexico',
    operatorId: '1415'
};

const tutorialProduct = '1500';

const validate = (values: typeof initialValues) =>
    values.phone.length < 7 ? { phone: 'Please enter a valid phone number.' }
        : {};

export const initialValues = {
    phone: "",
    phoneConfirm: "",
    countryCode: "1"
}

export default function MobileTopUp() {

    const { pathname } = useLocation();
    const { push, goBack } = useHistory();

    const { setTrainingSetting } = useContext(SettingsContext);
    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { parms, setClerkInfo } = useContext(ParmContext);
    const { getOperatorsRequest, getOperatorRequest, topupRequest, printReceipt } = useMTU('TOPUP');

    const [operators, setOperators] = useState<OperatorPreview[]>([]);
    const [selectedOperators, setSelectedOperators] = useState<FullOperator[]>([]);
    const [selectedOperatorIndex, setSelectedOperatorIndex] = useState(0);
    const [selectedProductIndex, setSelectedProductIndex] = useState(0);
    const { value: showingPromotions, setTrue: openPromotions, setFalse: closePromotions } = useBoolean(false);

    const selectedOperator = selectedOperators[selectedOperatorIndex] as FullOperator | undefined;
    const selectedProduct = selectedOperator?.products[selectedProductIndex];

    const getOperatorsFromServer = useCallback(async () => {
        try {
            const operators = await getOperatorsRequest();
            setOperators(operators);
        } catch (err) {
            console.error(err);
        }
    }, [getOperatorsRequest]);

    const getOperatorFromServer = async (values: OperatorProps, { setSubmitting, setStatus }: FormikHelpers<any>) => {
        setSubmitting(true);
        setSelectedOperatorIndex(0);
        try {
            return await getOperatorRequest(values);
        } catch (err) {
            console.error(err);
            if (err instanceof FetchError) {
                const text = await err.response.text();
                setStatus({ message: text, messageType: 'error' });
            } else {
                setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
            }
            return [];
        } finally {
            setSubmitting(false);
        }
    }

    const updateClerkInfo = () => {
        const lastMTUTransaction = serverFormat(new Date(), 'yyyy-MM-dd HH:mm:ss.SSSSSS');
        setClerkInfo({ lastMTUTransaction });
    }

    const makePayment = async (values: typeof initialValues, formik: FormikHelpers<typeof initialValues>, operatorIndex = selectedOperatorIndex, productIndex = selectedProductIndex): Promise<void> => {
        const operator = selectedOperators[operatorIndex];
        const product = operator.products[productIndex];
        try {
            const paymentResponse = await topupRequest(values, operator, product);
            await printReceipt(paymentResponse, operator, product);
            updateClerkInfo();
            push('/MobileTopUp');
            formik.resetForm();
            formik.setStatus({ message: 'Payment Successful', messageType: '' });
        } catch (error) {
            if (operatorIndex < selectedOperators.length - 1) {
                const nextProductIndex = selectedOperators[operatorIndex + 1].products.findIndex(({ localInfoAmount, retailPrice }) =>
                    localInfoAmount === product.localInfoAmount && retailPrice === product.retailPrice);
                if (nextProductIndex >= 0) {
                    formik.setStatus({ message: 'We need to try this another way. One moment.', messageType: '' });
                    return makePayment(values, formik, operatorIndex + 1, nextProductIndex);
                } else {
                    goBack();
                    formik.setStatus({ message: 'We need to try this another way. Please select a new price point.', messageType: '' });
                    setSelectedOperatorIndex(operatorIndex + 1);
                }
            } else {
                setSelectedOperatorIndex(0);
                console.error(error);
                goBack();
                if (error instanceof MTUError) {
                    formik.setStatus({ message: error.message, messageType: 'error' });
                } else {
                    formik.setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
                }
            }
        }
    }

    const handleSubmit = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        if (pathname.includes('/ProviderProduct') && !!selectedOperator) {
            push('/MobileTopUp/Checkout');
        } else if (pathname.includes('/Checkout') && !!selectedProduct && !!selectedOperator) {
            if (!isCurrentPageTraining) {
                actions.setStatus({ message: 'Processing Payment...', messageType: '' });
                await makePayment(values, actions);
            } else {
                setTrainingSetting({ mtu: true });
            }
        } else {
            const operator = await getOperatorFromServer(values, actions);
            setSelectedOperators(operator);
            push('/MobileTopUp/ProviderProduct');
        }
    }

    useEffectOnce(() => {
        getOperatorsFromServer();
    })

    if (parms?.parameters.prePayActive) {
        return (
            <>
                <AdvancedForm className='MobileTopUp'
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validate={validate}
                    numericOnly={true} >
                    <>
                        <Switch>
                            {!!selectedOperator &&
                                <Route path='/MobileTopUp/ProviderProduct' render={() =>
                                    <ProviderProductForm
                                        operators={operators}
                                        getOperatorFromServer={getOperatorFromServer}
                                        tutorialOperator={tutorialOperator}
                                        tutorialProduct={tutorialProduct}
                                        selectedOperator={selectedOperator}
                                        selectedOperators={selectedOperators}
                                        selectedProduct={selectedProduct}
                                        setSelectedProductIndex={setSelectedProductIndex}
                                        setSelectedOperators={setSelectedOperators}
                                    />} />
                            }
                            {!!selectedProduct && !!selectedOperator &&
                                <Route path='/MobileTopUp/Checkout' render={() =>
                                    <CartForm
                                        selectedProduct={selectedProduct}
                                        selectedOperator={selectedOperator}
                                        tutorialProduct={tutorialProduct} />} />
                            }
                            <Route render={() =>
                                <PhoneForm
                                    openPromotions={openPromotions} />} />
                        </Switch>
                    </>
                </AdvancedForm>
                <Promotions
                    onClose={closePromotions}
                    open={showingPromotions} />
            </>
        );
    } else {
        return (
            <div>
                <h3>Due to an issue with our third-party Mobile Top-Up processor, Mobile Top-Up is temporarily unavailable.</h3>
                <h3>We are working on a resolution as quickly as we can.</h3>
                <h3>Thank you for your patience!</h3>
            </div>
        );
    }
}