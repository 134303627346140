import { Fragment, useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import SupportHeader from '../SupportHeader/SupportHeader';
import styles from './SupportSearch.module.css';
import NextArrow from '../../../../../images/NextArrow.svg';
import BackArrow from '../../../../../images/BackArrow.svg';
import { Link } from 'react-router-dom';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { EntryPreview } from '../SupportFAQ/SupportFAQ';

export interface FullEntry extends EntryPreview {
    contentPreview: string,
    videoUrl?: string
}

const entriesPerPage = 3;

/**
 * Page for displaying FAQ Search.
 * Will display 3 search results per page.
 * The buttons at the bottom allow you to go through the "Pages".
 * Forward / Back buttons only show if there are entries for them to show.
 * Clicking on the Title of an entry will bring you to the actual SupportEntries screen for that entry.
 */
export default function SupportSearch() {

    const { failoverFetch } = useContext(APIContext);
    const { auth } = useContext(AuthContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const search = searchParams.get('search') ?? '';

    const [entries, setEntries] = useState<FullEntry[]>([]);
    const [page, setPage] = useState(1);

    const prevSearchRef = useRef<string>();

    const getSearchCount = useCallback((entry: FullEntry) => {
        const titleCount = (entry.title.match(new RegExp(search, "gi")) || []).length;
        const contentCount = (entry.contentPreview.match(new RegExp(search, "gi")) || []).length;
        return titleCount + contentCount;
    }, [search])

    const sortEntries = useCallback((entries: FullEntry[]) => {
        return entries.sort((entry1, entry2) => {
            return getSearchCount(entry2) - getSearchCount(entry1);
        });
    }, [getSearchCount]);

    /** 
     * Perform search on server.
     */
    const loadSearch = useCallback(async () => {
        if (auth) {
            try {
                const response = await failoverFetch('/Support?' + new URLSearchParams({
                    type: 'entriesBySearch',
                    search: search,
                    agentId: auth.agentId,
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as FullEntry[];
                setEntries(sortEntries(data));
            } catch (error) {
                console.error(error);
            }
        }
    }, [auth, failoverFetch, search, sortEntries])

    const getHighlightedContentPreview = useCallback((entry: FullEntry) => {
        const content = entry.contentPreview;
        let lastAppendIndex = 0;
        const highlightedContent = [];
        for (let i = 0; i < (content.length - search.length); i++) {
            const contentSection = content.substring(i, i + search.length);
            if (contentSection.toLowerCase() === search.toLowerCase()) {
                highlightedContent.push(<Fragment key={'A' + i}>{content.substring(lastAppendIndex, i)}</Fragment>);
                highlightedContent.push(<span className='Selected' key={'B' + i}>{contentSection}</span>);
                i += contentSection.length;
                lastAppendIndex = i;
            }
        }

        if (lastAppendIndex < content.length) {
            highlightedContent.push(<Fragment key={content.length}>{content.substring(lastAppendIndex, content.length)}</Fragment>);
        }

        return highlightedContent;
    }, [search])

    const searchEntries = useMemo(() => {
        const start = (page - 1) * entriesPerPage;
        const end = Math.min((page * entriesPerPage), entries.length);
        if (end > 0) {
            return entries.map((entry, index) => {
                if (index >= start && index < end) {
                    return (
                        <Fragment key={entry.id}>
                            <Link to={'/Support/SupportEntries?entryId=' + entry.id}>{entry.title}<br></br></Link>
                            <p>{getHighlightedContentPreview(entry)}</p>
                        </Fragment>
                    );
                } else {
                    return null;
                }
            });
        } else {
            return (
                <Fragment>
                    <p>No search results found. Please try again.</p>
                </Fragment>
            );
        }
    }, [entries, getHighlightedContentPreview, page]);

    const goForward = () => {
        setPage((currentPage) => {
            return currentPage + 1;
        })
    }

    const goBackwards = () => {
        setPage((currentPage) => {
            return currentPage - 1;
        })
    }

    useEffect(() => {
        if (prevSearchRef.current !== search) {
            loadSearch();
        }
        prevSearchRef.current = search;
    }, [loadSearch, search]);


    return (
        <div>
            <SupportHeader />
            <div className={styles.searchResults}>
                <h3>Search Results </h3>
                <span>{search}</span>
            </div>
            <div className={styles.searchResultContent}>
                {searchEntries}
            </div>
            <div className={styles.searchResultFooter}>
                {page > 1 &&
                    <div className={styles.previousPage} onClick={goBackwards}><img alt="<-" src={BackArrow} /><span>Previous Page</span></div>
                }
                {page * entriesPerPage < entries.length &&
                    <div className={styles.nextPage} onClick={goForward}><span>Next Page</span><img alt="->" src={NextArrow} /></div>
                }
            </div>
        </div>
    );
}