import type { ChangeEvent, KeyboardEvent } from 'react';
import { useState, useEffect, useMemo } from 'react';
import './CustomList.css';
import { getTooltipClass, getTooltipTriggerClass } from 'utilities/tooltip';
import { getClass } from 'utilities/classnames';

interface Props {
    id: string,
    data: any[],
    selectedId?: string | number,
    validate?: (element: any | null) => boolean,
    title?: string,
    className?: string,
    display: string,
    autoFocus?: boolean,
    tooltip?: JSX.Element,
    tooltipShown?: boolean,
    onChange: (element: any, index: number) => void
}

export interface ListData {
    isSelected: boolean,
    isValid: boolean
}

const getElementClass = (element: any) =>
    !element.isSelected ? ''
        : element.isValid ? 'Selected'
            : 'Invalid';

const handleRefIfSelected = (element: any) => {
    if (element.isSelected) {
        return (ref: HTMLLIElement | null) =>
            ref?.scrollIntoView({ block: 'nearest' });
    }
}

export default function CustomList({ data, validate, title, className, display, autoFocus, tooltip, tooltipShown, onChange, selectedId, id }: Props) {

    const [search, setSearch] = useState('');
    const lowerCaseSearch = search.toLowerCase();

    const filteredData = useMemo(() =>
        data?.filter((element) => {
            const displaytext = element[display];
            return displaytext.toLowerCase().includes(lowerCaseSearch);
        }).map((element) => {
            const isSelected = element[id] === selectedId;
            return {
                ...element,
                isSelected,
                isValid: !validate || validate(element)
            }

        }) ?? []
        , [data, display, id, lowerCaseSearch, selectedId, validate]);

    const selectedIndex = useMemo(() =>
        filteredData.findIndex(element =>
            element[id] === selectedId
        )
        , [filteredData, id, selectedId]);

    const [hoveredIndex, setHoveredIndex] = useState(selectedIndex);

    const handleClick = async (element: any, index: number) => {
        if (element[id] !== selectedId) {
            onChange(element, index);
        }
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 38 && hoveredIndex > 0) { //Up Arrow
            setHoveredIndex((currentHoveredIndex) => currentHoveredIndex - 1);
        } else if (event.keyCode === 40 && hoveredIndex < filteredData.length - 1) { //Down Arrow
            setHoveredIndex((currentHoveredIndex) => currentHoveredIndex + 1);
        }
    }

    const handleBlur = () => {
        if (selectedIndex !== hoveredIndex) {
            onChange(filteredData[hoveredIndex], hoveredIndex);
        }
    }

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
        setSearch(event.currentTarget.value);

    useEffect(() => {
        setSearch('');
    }, [data]);

    useEffect(() => {
        setHoveredIndex(selectedIndex);
    }, [selectedIndex]);

    return (
        <fieldset className={getClass([
            'CustomList',
            className ?? '',
            getTooltipTriggerClass(tooltipShown)
        ])}
        >
            <input type='search'
                placeholder={title}
                autoFocus={autoFocus}
                value={search}
                onKeyDown={handleKeyDown}
                onChange={handleSearchChange}
                onBlur={handleBlur} />
            <ul>
                {filteredData.map((element, index) =>
                    <li key={element[id]} ref={handleRefIfSelected(element)}>
                        <span className={getElementClass(element)} onClick={() => handleClick(element, index)}>{element[display]}</span>
                    </li>
                )}
            </ul>
            {tooltip &&
                <div className={getTooltipClass({ placement: 'top' })}>
                    {tooltip}
                </div>
            }
        </fieldset >
    );
}