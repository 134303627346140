import PhoneForm from '../PhoneForm/PhoneForm';
import NameForm from '../NameForm/NameForm';
import MessageAggregator from '../../../../Common/MessageAggregator/MessageAggregator';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import type * as GiftCards from '../DigitalGiftCards';
import type * as CashLoad from '../../CashLoad/CashLoad';
import './NamePhoneForm.css';
import { useFormikContext } from 'formik';
import { tooltipBoldClass } from 'utilities/tooltip';

export const tutorialPhone = '9033351533';
export const tutorialFirstName = 'JOHN';
export const tutorialLastName = 'SMITH';

interface Props {
    title: string,
    searched: boolean,
    found: boolean,
    clearCustomers: () => void
}

export default function NamePhoneForm({ title, searched, found, clearCustomers }: Props) {

    const { values: { phone, phoneConfirm, firstName, lastName } } = useFormikContext<typeof GiftCards.initialValues | typeof CashLoad.initialValues>();

    const isPhoneTutorial = phone !== tutorialPhone;

    const isPhoneConfirmTutorial = !isPhoneTutorial
        && phoneConfirm !== tutorialPhone;

    const isFirstNameTutorial = !isPhoneTutorial
        && !isPhoneConfirmTutorial
        && tutorialFirstName !== firstName;

    const isLastNameTutorial = !isPhoneTutorial
        && !isPhoneConfirmTutorial
        && !isFirstNameTutorial
        && tutorialLastName !== lastName;

    const isButtonTutorial = !isPhoneTutorial
        && !isPhoneConfirmTutorial
        && !isPhoneConfirmTutorial
        && ((!isFirstNameTutorial
            && !isLastNameTutorial)
            || !searched);

    return (
        <div className='NamePhoneForm'>
            {!searched &&
                <h1>{title}</h1>
            }
            {searched && found &&
                <h2>Please verify the customer's first and last name</h2>
            }
            {searched && !found &&
                <h2>Please enter this customer's first and last name</h2>
            }
            <div>
                <PhoneForm
                    clearCustomers={clearCustomers}
                    isPhoneTutorial={isPhoneTutorial}
                    isPhoneConfirmTutorial={isPhoneConfirmTutorial}
                    searched={searched} />
                {searched &&
                    <NameForm
                        isFirstNameTutorial={isFirstNameTutorial}
                        isLastNameTutorial={isLastNameTutorial} />
                }
                <div className='NamePhoneNext'>
                    <AdvancedButton
                        tooltipPlacement={searched ? 'left' : 'right'}
                        trainingFocused={isButtonTutorial}
                        trainingTooltip={
                            <>
                                <div>YOU ARE READY</div>
                                <b className={tooltipBoldClass}>CLICK NEXT</b>
                            </>
                        } >Next</AdvancedButton>
                </div>
            </div>
            <MessageAggregator />
        </div>
    );
}