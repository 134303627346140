import type { Dispatch, SetStateAction } from 'react';
import VirtualizedTable from '../../../../Common/VirtualizedTable/VirtualizedTable';
import type { User } from '../UpdateUserInfo';
import styles from "./UserInfoTable.module.css";

const columns = [{
    label: 'ID',
    dataKey: 'clerkId'
}, {
    label: 'Name',
    dataKey: 'username'
}, {
    label: 'Type',
    dataKey: 'type'
}];

interface Props {
    loading: boolean,
    setUser: Dispatch<SetStateAction<User | null>>,
    user: User | null,
    users: User[]
}

export default function UserInfoTable({ loading, setUser, user, users }: Props) {
    return (
        <VirtualizedTable
            className={styles.userInfoTable}
            data={users}
            selectedData={user}
            columns={columns}
            onRowClick={setUser}
            loading={loading} />
    );
}