import { useContext, useState, useCallback } from 'react';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import BasicPopup from 'components/Common/Popups/BasicPopup/BasicPopup';
import styles from '../MobileTopUp.module.css';
import { useEffectOnce } from 'usehooks-ts';
import { xml2js } from 'utilities/xml';

interface FeedItem {
    dateFrom: string,
    dateTo: string,
    description: { _cdata: string },
    operatorId: string,
    operatorName: string,
    pubDate: string,
    subOperatorId: string,
    title: string
}

interface FeedBody {
    description: string,
    item: FeedItem[],
    lastBuildDate: string,
    link: string,
    pubDate: string,
    title: string,
    ttl: string
}

interface Feed {
    rss: {
        channel: FeedBody
    }
}

interface Props {
    onClose: () => void,
    open: boolean
}

export default function Promotions({ onClose, open }: Props) {

    const [promotions, setPromotions] = useState<FeedItem[]>([]);

    const { failoverFetch } = useContext(APIContext);

    const getPromotions = useCallback(async () => {
        try {
            const response = await failoverFetch('/DTOnePromotions');
            const parsedData = xml2js<Feed>(response);
            setPromotions(parsedData.rss.channel.item);
        } catch (error) {
            console.error(error);
        }
    }, [failoverFetch]);

    useEffectOnce(() => {
        getPromotions();
    });

    return (
        <BasicPopup
            onClose={onClose}
            className={styles.promotions}
            open={open}
            fadeIn lightMode>
            {promotions.map(({ dateFrom, dateTo, description, operatorId, operatorName, pubDate, subOperatorId, title }) =>
                <div
                    className={styles.promotionItem}
                    key={operatorId + subOperatorId + pubDate + title}
                    dangerouslySetInnerHTML={{ __html: description._cdata }}></div>
            )}
        </BasicPopup>
    )
}