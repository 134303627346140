import { useContext } from 'react';
import { ParmContext } from '../../ParmController/ParmController';
import { useInterval } from 'usehooks-ts';

/**
 * Component responsible for contiously pinging the PHN Server, getting "status" updates.
 * Parameters, and many other data pieces, come this way.
 */
export function useStatus(statusRequest: () => void) {

    const { parms, loggedIn } = useContext(ParmContext);

    const intervalTime = parms?.parameters.statusInterval ?? (60 * 1000);
    const interval = loggedIn ? intervalTime : null;
    useInterval(statusRequest, interval);
}