import type { ChangeEvent } from 'react';
import { Fragment, useState, useContext, useCallback } from 'react';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import AdvancedButton from '../../../Common/AdvancedButton/AdvancedButton';
import { format } from 'date-fns';
import Message from '../../../Common/Message/Message';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import styles from './HoursOfOperation.module.css';
import { Field, type FormikHelpers, type FormikProps } from 'formik';
import { useEffectOnce } from 'usehooks-ts';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

/** 
 * Server wants times in 24 hour format.
*/
const minutesTo24Hour = (minutes: number) => {
    const hours = Math.trunc(minutes / 60);
    const minutesRemainder = minutes % 60;
    return format(new Date(1970, 0, 1, hours, minutesRemainder), "HH:mm");
}

/** 
 * User wants to see times in 12 hour format.
 */
const minutesTo12Hour = (minutes: number) => {
    const hours = Math.trunc(minutes / 60);
    const minutesRemainder = minutes % 60;
    return format(new Date(1970, 0, 1, hours, minutesRemainder), "h a");
}

/** 
   * It's easiest to work with times in terms of minutes.
   * This generates the HTML options for the page.
   */
const getTimeOptions = (type: string) => {
    const options = [];

    if (type === 'Start') {
        options.push({ display: '24 HRS', value: '00:01' });
        options.push({ display: 'CLOSED', value: '23:59' });
    } else {
        options.push({ display: '24 HRS / CLOSED', value: '23:59' });
    }

    for (let i = 0; i < 1440; i += 60) {
        options.push({ display: minutesTo12Hour(i), value: minutesTo24Hour(i) });
    }

    return options.map((option) => {
        return (
            <option key={option.value} value={option.value}>{option.display}</option>
        );
    });
}

const startTimeOptions = getTimeOptions('Start');
const endTimeOptions = getTimeOptions('End');

/**
 * Page to allow Agents to set their HoursOfOperation.
 */
export default function HoursOfOperation() {

    const [hours, setHours] = useState({
        mondayStart: '00:01',
        tuesdayStart: '00:01',
        wednesdayStart: '00:01',
        thursdayStart: '00:01',
        fridayStart: '00:01',
        saturdayStart: '00:01',
        sundayStart: '00:01',
        mondayEnd: '23:59',
        tuesdayEnd: '23:59',
        wednesdayEnd: '23:59',
        thursdayEnd: '23:59',
        fridayEnd: '23:59',
        saturdayEnd: '23:59',
        sundayEnd: '23:59'
    });

    const { failoverFetch } = useContext(APIContext);
    const { auth } = useContext(AuthContext);

    const getHoursFromServer = useCallback(async () => {
        if (auth) {
            try {
                const response = await failoverFetch('/AgentStoreHours?' + new URLSearchParams({
                    agentId: auth.agentId,
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as typeof hours;
                setHours(data);
            } catch (error) {
                console.error(error);
            }
        }
    }, [auth, failoverFetch])

    const handleSubmit = async (values: typeof hours, { setStatus, setSubmitting }: FormikHelpers<typeof hours>) => {
        if (auth) {
            setStatus({ message: 'Saving...', messageType: '' });
            try {
                await failoverFetch('/AgentStoreHours', {
                    method: 'POST',
                    body: new URLSearchParams({
                        agentId: auth.agentId,
                        authToken: auth.authToken,
                        hours: JSON.stringify(values)
                    })
                });
                setStatus({ message: 'Hours saved successfully!', messageType: '' });
            } catch (error) {
                console.error(error);
                setStatus({ message: 'An unexpected error has occurred while saving your hours.', messageType: 'error' });
            } finally {
                setSubmitting(false);
            }
        }
    }

    useEffectOnce(() => {
        getHoursFromServer();
    });

    return (
        <AdvancedForm
            className={styles.hoursOfOperation}
            initialValues={hours}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            noKeyboard={true} >
            {({ values, setFieldValue, handleChange }: FormikProps<typeof hours>) => {
                const handleStartChange = (event: ChangeEvent<HTMLSelectElement>) => {
                    const startName = event.currentTarget.name as keyof typeof hours;
                    const startValue = event.currentTarget.value;
                    const endName = startName.substring(0, startName.indexOf('Start')) + "End" as keyof typeof hours;
                    const endValue = values[endName];
                    if ((startValue === '23:59' || startValue === '00:01') && endValue !== '23:59') {
                        setFieldValue(endName, '23:59');
                    } else if (endValue === '23:59') {
                        setFieldValue(endName, '23:30');
                    }
                    handleChange(event);
                }

                const handleEndChange = (event: ChangeEvent<HTMLSelectElement>) => {
                    const endName = event.target.name as keyof typeof hours;
                    const endValue = event.target.value;
                    const startName = endName.substring(0, endName.indexOf('End')) + "Start" as keyof typeof hours;
                    const startValue = values[startName];
                    if (startValue !== '23:59' && startValue !== '00:01' && endValue === '23:59') {
                        setFieldValue(startName, '00:01');
                    } else if ((startValue === '23:59' || startValue === '00:01') && endValue !== '23:59') {
                        setFieldValue(startName, '00:00');
                    }
                    handleChange(event);
                }

                return (
                    <>
                        <div>
                            <label>Open</label>
                            <label>Close</label>
                        </div>
                        {days.map((day) =>
                            <div key={day} className={styles.hoursRow}>
                                <label>{day}</label>
                                <Field
                                    component='select'
                                    name={day.toLowerCase() + 'Start'}
                                    onChange={handleStartChange}
                                >
                                    {startTimeOptions}
                                </Field>
                                <Field
                                    component='select'
                                    name={day.toLowerCase() + 'End'}
                                    onChange={handleEndChange}
                                >
                                    {endTimeOptions}
                                </Field>
                            </div>
                        )}
                        <div>
                            <AdvancedButton>Save</AdvancedButton>
                        </div>
                        <div>
                            <Message />
                        </div>
                    </>
                )
            }}
        </AdvancedForm>
    );
}