import { useCallback, useContext } from 'react';
import { PrinterContext } from '../../../../HigherOrder/PrintController/PrintController';
import type { PrintRequest } from '../../../../HigherOrder/PrintController/PrintController';
import { useFormikContext } from 'formik';
import type { FormValues, ParsedAgeVerification } from '../Reports';
import { format } from 'date-fns';

const header = {
    clerkName: 'Clerk Name',
    dlNum: 'DL#',
    dob: 'DOB',
    age: 'Age' as string | number,
    reportDate: 'Report',
    reportTime: 'Report'
};

export function useAgeVerifPrinter() {
    
    const { print } = useContext(PrinterContext);
    const { values: { startDate, endDate } } = useFormikContext<FormValues>();

    const printAgeVerif = useCallback((data: ParsedAgeVerification[]) => {
        const reportData = [header].concat(data);
        return print(([
            { text: startDate === endDate ? `Daily ${format(Number(endDate), 'yyyy-MM-dd')}` : `${format(Number(startDate), 'yyyy-MM-dd')} - ${format(Number(endDate), 'yyyy-MM-dd')}`, style: { emphasized: true } },
            { text: 'Age Verification Report', style: { emphasized: true } }
        ] as PrintRequest).concat(
            reportData.map(({ clerkName, dlNum, dob, age, reportDate, reportTime }) => ({
                columns: [clerkName, dlNum, dob, age.toString(), reportDate, reportTime]
                , style: { smallFont: true }
            }))
        ), { printer: 'Report' });
    }, [endDate, print, startDate]);

    return { printAgeVerif };
}