import { createContext, useState } from 'react';
import { Route, Switch } from "react-router-dom";
import AdminLogin from './AdminLogin/AdminLogin';
import AgeVerification from './AgeVerification/AgeVerification';
import BillPay from './BillPay/BillPay';
import Support from './Support/Support';
import HoursOfOperation from './HoursOfOperation/HoursOfOperation';
import LoginController from './Login/LoginController';
import MobileTopUp from './MobileTopUp/MobileTopUp';
import MoneyOrder from './MoneyOrder/MoneyOrder';
import MoneyOrderSettings from './MoneyOrderSettings/MoneyOrderSettings';
import PrinterSetup from './PrinterSetup/PrinterSetup';
import Reports from './Reports/Reports';
import Settings from './Settings/Settings';
import System from './System/System';
import UpdateSelf from './UpdateSelf/UpdateSelf';
import UpdateStatus from './UpdateStatus/UpdateStatus';
import UpdateUserInfo from './UpdateUserInfo/UpdateUserInfo';
import Network from './Network/Network';
import Preferences from './Preferences/Preferences';
import PrinterSettings from './PrinterSettings/PrinterSettings';
import NotificationPopup from '../../Common/Popups/NotificationPopup/NotificationPopup';
import GlobalTrainingEnd from '../../Common/Popups/GlobalTrainingEnd/GlobalTrainingEnd';
import GlobalTrainingIntro from '../../Common/Popups/GlobalTrainingIntro/GlobalTrainingIntro';
import Banners from '../../Common/Banner/Banners';
import TrainingToggle from '../../HigherOrder/TrainingOverlay/TrainingToggle/TrainingToggle';
import Menu from './Menu/Menu';
import Default from './Default/Default';
import DigitalGiftCards from './DigitalGiftCards/DigitalGiftCards';
import NetSpend from './NetSpend/NetSpend';
import CashLoad from './CashLoad/CashLoad';
import './Mainview.css';

export const MainviewContext = createContext({
    updateKeyboardState: (newState: boolean) => { },
    menuClosed: true,
    shouldShowKeyboard: false
});

interface Props {
    incrementKey: () => void,
    closeNotifications: () => void,
    notificationsClosed: boolean,
    closeMenu: () => void,
    menuClosed: boolean
}

/**
 * Mid-Level component for controlling the routes to most pages.
 * Equivalent to the main card panel of the swing version.
 */
export default function Mainview({ incrementKey, closeNotifications, notificationsClosed, closeMenu, menuClosed }: Props) {

    const [shouldShowKeyboard, setShouldShowKeyboard] = useState(false);

    return (
        <MainviewContext.Provider value={{
            updateKeyboardState: setShouldShowKeyboard,
            shouldShowKeyboard,
            menuClosed
        }} >
            <div className={'MainviewWrapper'}>
                <NotificationPopup
                    onClose={closeNotifications}
                    open={!notificationsClosed} />
                <Menu
                    onClose={closeMenu}
                    incrementKey={incrementKey}
                    open={!menuClosed}
                    fadeIn />
                {!shouldShowKeyboard &&
                    <Route path={['/AgeVerification', '/BillPay', '/MobileTopUp', '/MoneyOrder', '/DigitalGiftCards', '/NetSpend', '/Login']} component={Banners} />
                }
                <div className='TrainingToggleWrapper' >
                    <TrainingToggle />
                </div>
                <main className={`Mainview ${shouldShowKeyboard ? 'VirtualKeyboard' : ''}`}>
                    <GlobalTrainingEnd />
                    <GlobalTrainingIntro />
                    <Switch>
                        <Route path='/AgeVerification' component={AgeVerification} />
                        <Route path='/AdminLogin' component={AdminLogin} />
                        <Route path='/BillPay' component={BillPay} />
                        <Route path='/Support' component={Support} />
                        <Route path='/HoursOfOperation' component={HoursOfOperation} />
                        <Route path='/Login' component={LoginController} />
                        <Route path='/MobileTopUp' component={MobileTopUp} />
                        <Route path='/MoneyOrder' component={MoneyOrder} />
                        <Route path='/MoneyOrderSettings' component={MoneyOrderSettings} />
                        <Route path='/Network' component={Network} />
                        <Route path='/Preferences' component={Preferences} />
                        <Route path='/PrinterSettings' component={PrinterSettings} />
                        <Route path='/PrinterSetup' component={PrinterSetup} />
                        <Route path='/Reports' component={Reports} />
                        <Route path='/Settings' component={Settings} />
                        <Route path='/System' component={System} />
                        <Route path='/UpdateSelf' component={UpdateSelf} />
                        <Route path='/UpdateStatus' component={UpdateStatus} />
                        <Route path='/UpdateUserInfo' component={UpdateUserInfo} />
                        <Route path='/DigitalGiftCards' component={DigitalGiftCards} />
                        <Route path='/CashLoad' component={CashLoad} />
                        <Route path='/NetSpend' component={NetSpend} />
                        <Route component={Default} />
                    </Switch>
                </main>
            </div>
        </MainviewContext.Provider>
    );
}