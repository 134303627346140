import { ReactNode } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import starburst from '../../../../images/starburst.svg';
import styles from './Menu.module.css';

interface Props {
    isNew?: boolean,
    isSoon?: boolean,
    disabled?: boolean,
    path: string,
    children: ReactNode,
    incrementKey?: () => void,
    onClose?: () => void
}

export default function MenuItem({ isNew, isSoon, disabled, path, children, incrementKey, onClose }: Props) {

    const { push } = useHistory();
    const { pathname } = useLocation();

    const handleClick = () => {
        if ((pathname + "/").includes(path + "/")) {
            incrementKey?.();
        } else {
            push(path);
        }
        onClose?.();
    }

    return (
        <div
            className={`${styles.menuItem} ${disabled ? styles.disabled : ''}`}
            onClick={handleClick}>
            {!!isNew && !disabled &&
                <div className={styles.new}>
                    <img src={starburst} alt={'New'} />
                    <h6>NEW</h6>
                </div>
            }
            {!!isSoon && !!disabled &&
                <div className={styles.new}>
                    <img src={starburst} alt={'Soon'} />
                    <h6>SOON</h6>
                </div>
            }
            {children}
        </div>
    );
}