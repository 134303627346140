import type { ReactNode } from 'react';
import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import type { UpdateDownloadedEvent, UpdateInfo } from 'electron-updater';
import { APIContext } from 'components/HigherOrder/APIController/APIController';
import { ParmContext } from 'components/HigherOrder/ParmController/ParmController';
import type { IpcRendererEvent } from 'electron';
import { isElectron } from 'utilities/Environment';

interface ProgressInfo {
    total: number;
    delta: number;
    transferred: number;
    percent: number;
    bytesPerSecond: number;
}

export const UpdateContext = createContext({
    checkingForUpdate: false as boolean,
    downloadingUpdate: false as boolean,
    updateDownloaded: false as boolean,
    updateErrors: [] as Error[],
    updateInfo: null as UpdateInfo | null | UpdateDownloadedEvent,
    progressInfo: null as ProgressInfo | null,
    quitAndInstall: (info: UpdateDownloadedEvent) => { }

});
const updateTimeout = 2 * 60 * 1000; //2 minutes

const dispatchIdleEvent = () => {
    window.dispatchEvent(new CustomEvent('updateIdle'));
}

const quitAndInstall = (info: UpdateDownloadedEvent) => {
    require('electron').ipcRenderer.send('quitAndInstall', info);
}

interface Props {
    children: ReactNode
}

/**
 * Component to manage when the application should update.
 * Gets an 'update ready' signal from the main electron process.
 * Sends restart command to electron process when user is inactive.
 * Inolves a bit of back-and-forth, since it's relying on alot of events.
 */
export default function Updater({ children }: Props) {

    const { failoverFetch } = useContext(APIContext);
    const { parms } = useContext(ParmContext);

    const [checkingForUpdate, setCheckingForUpdate] = useState(false);
    const [downloadingUpdate, setDownloadingUpdate] = useState(false);
    const [updateDownloaded, setUpdateDownloaded] = useState(false);
    const [updateErrors, setUpdateErrors] = useState([] as Error[]);
    const [updateInfo, setUpdateInfo] = useState(null as null | UpdateInfo | UpdateDownloadedEvent);
    const [progressInfo, setProgressInfo] = useState(null as null | ProgressInfo);

    const { isIdle } = useIdleTimer({ onIdle: dispatchIdleEvent, timeout: updateTimeout });

    const logUpdateError = useCallback(async (error: Error) => {
        try {
            await failoverFetch('/UpdateErrors', {
                method: 'POST',
                body: new URLSearchParams({
                    agentId: (parms?.clerkInfo.agentId ?? -1).toString(),
                    errorMsg: error.toString()
                })
            })
        } catch (error) {
            console.error(error);
        }
    }, [failoverFetch, parms?.clerkInfo.agentId])

    const handleUpdateError = useCallback((event: IpcRendererEvent, error: Error) => {
        logUpdateError(error);
        setUpdateErrors((updateErrors) => updateErrors.concat([error]).slice(0, 100));
        setCheckingForUpdate(false);
        setDownloadingUpdate(false);
    }, [logUpdateError]);

    const handleUpdateChecking = () => {
        setCheckingForUpdate(true);
    }

    const handleUpdateAvailable = (event: IpcRendererEvent, info: UpdateInfo) => {
        setCheckingForUpdate(false);
        setDownloadingUpdate(true);
        setUpdateInfo(info);
    }

    const handleUpdateNotAvailable = (event: IpcRendererEvent, info: UpdateInfo) => {
        setCheckingForUpdate(false);
        setUpdateInfo(info);
    }

    const handleDownloadProgress = (event: IpcRendererEvent, progressInfo: ProgressInfo) => {
        setProgressInfo(progressInfo);
    }

    const handleUpdateDownloaded = useCallback((event: IpcRendererEvent, info: UpdateDownloadedEvent) => {
        setCheckingForUpdate(false);
        setDownloadingUpdate(false);
        setUpdateDownloaded(true);
        setUpdateInfo(info);
        if (isIdle()) {
            quitAndInstall(info);
        } else {
            window.addEventListener('updateIdle', () => quitAndInstall(info), { once: true });
        }
    }, [isIdle])

    useEffect(() => {
        if (isElectron) {
            require('electron').ipcRenderer.on('update-error', handleUpdateError);
            require('electron').ipcRenderer.on('checking-for-update', handleUpdateChecking);
            require('electron').ipcRenderer.on('update-available', handleUpdateAvailable);
            require('electron').ipcRenderer.on('update-not-available', handleUpdateNotAvailable);
            require('electron').ipcRenderer.on('download-progress', handleDownloadProgress);
            require('electron').ipcRenderer.on('update-downloaded', handleUpdateDownloaded);
        }
        return () => {
            if (isElectron) {
                require('electron').ipcRenderer.removeListener('update-error', handleUpdateError);
                require('electron').ipcRenderer.removeListener('checking-for-update', handleUpdateChecking);
                require('electron').ipcRenderer.removeListener('update-available', handleUpdateAvailable);
                require('electron').ipcRenderer.removeListener('update-not-available', handleUpdateNotAvailable);
                require('electron').ipcRenderer.removeListener('download-progress', handleDownloadProgress);
                require('electron').ipcRenderer.removeListener('update-downloaded', handleUpdateDownloaded);
            }
        }
    }, [handleUpdateDownloaded, handleUpdateError]);

    if (isElectron) {
        return (
            <UpdateContext.Provider
                value={{
                    checkingForUpdate,
                    downloadingUpdate,
                    updateDownloaded,
                    updateErrors,
                    updateInfo,
                    progressInfo,
                    quitAndInstall
                }} >
                {children}
            </UpdateContext.Provider>
        );
    }
    return <>{children}</>;
}