
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useContext, useEffect } from 'react';
import BasicPopup from '../../../../Common/Popups/BasicPopup/BasicPopup';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { MOContext } from '../../../../HigherOrder/MoneyOrderController/MoneyOrderController';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { useFormikContext } from 'formik';
import Message from '../../../../Common/Message/Message';
import type { initialValues, MOResponseBody } from '../MoneyOrder';

const getCheckForSerial = (serial: string) => {
    return 8 - ((Number(serial) + 8) % 9);
}

interface Props {
    paymentResponse: MOResponseBody | null,
    index: number,
    handleSerialNumberChange: (event: ChangeEvent<HTMLInputElement>) => void,
    handleSerialCheckChange: (event: ChangeEvent<HTMLInputElement>) => void,
    serialNumber: string,
    serialCheck: string,
    setNextValidMO: Dispatch<SetStateAction<string | null>>,
    open: boolean,
    logAction: (action: string) => void
}

export default function Resequence({ paymentResponse, index, handleSerialCheckChange, handleSerialNumberChange, serialNumber, serialCheck, setNextValidMO, open, logAction }: Props) {

    const { setStatus, setSubmitting } = useFormikContext<typeof initialValues>();
    const { auth } = useContext(AuthContext);
    const { setParameter } = useContext(ParmContext);
    const { failoverFetch } = useContext(APIContext);
    const { resetStatus, displaySerialNumber } = useContext(MOContext);

    const getNextMoneyOrder = async () => {
        if (auth) {
            const response = await failoverFetch('/NextMoneyOrder?' + new URLSearchParams({
                moNum: serialNumber,
                authToken: auth.authToken
            }));
            const data = JSON.parse(response) as { nextMo: number };
            if (data.nextMo) {
                return data.nextMo;
            } else {
                return Promise.reject();
            }
        }
    }

    /** Serial number portion of errore'd transaction from cart.
     * The serial we sent to the server, but not the one that is actually in the printer. */
    const getCurrentServerSerial = () => {
        if (paymentResponse && paymentResponse['checkno' + index as keyof MOResponseBody]) {
            const fullSerial = paymentResponse['checkno' + index as keyof MOResponseBody];
            return fullSerial.substring(0, fullSerial.length - 1);
        }
    }

    /** The difference between the serial number from the cart (what was programmed),
     * and the number they just entered, which is what is actually is in the printer. */
    const getNumOff = async () => {
        try {
            const nextMo = await getNextMoneyOrder();
            return Number(nextMo) - Number(serialNumber);
        } catch (error) {
            console.error(error);
            return Number(getCurrentServerSerial()) - Number(serialNumber);
        }

    };

    /**
     * At this point, nothing is voided, cart is still out of sequence, and PACKSTART is at the end of what was just sent to the server.
     */
    const handleResequence = async () => {
        logAction(`Resequence pressed for ${serialNumber} ${serialCheck}`);
        if (!serialNumber || isNaN(Number(serialNumber))) {
            setStatus({ message: 'Please enter the next MO # in the printer.', messageType: 'error' })
        } else if (getCheckForSerial(serialNumber) !== Number(serialCheck)) {
            setStatus({ message: 'Please enter a valid Check Digit', messageType: 'error' })
        } else {
            setStatus(null);
            setSubmitting(true);
            try {
                const numOff = await getNumOff();
                console.debug(`Calculated that we are ${numOff} Money Orders off!`)
                setParameter({ packstart1: serialNumber + "" + serialCheck });
                console.debug('Setting parameter to the input value!');
                if (numOff > 0) { //Server is Ahead of client
                    const newSerial = (Number(serialNumber) + Number(numOff)).toString();
                    console.debug(`Setting next valid MO# ${newSerial}`);
                    setNextValidMO(newSerial);
                    //removePrintedAndResequence(newSerial, getCheckForSerial(newSerial));
                } else if (numOff <= 0) { //Server is Behind client
                    console.debug(`Setting next valid MO# ${serialNumber}`);
                    setNextValidMO(serialNumber);
                    //removePrintedAndResequence(serialNumber, serialCheck);
                }
            } catch (error) {
                console.error(error);
                setStatus({ message: 'An unexpected error occurred while resequencing Money Orders.', messageType: 'error' })
            } finally {
                setSubmitting(false);
            }
        }
    }

    const displaySN = async () => {
        logAction('Display SN button pressed');
        await resetStatus();
        await displaySerialNumber();
    }

    useEffect(() => {
        if (open) {
            logAction('Resequence Popup Shown');
        }
    }, [logAction, open]);

    return (
        <BasicPopup
            className='ErrorPopup'
            open={open}>
            <h3>We have detected you are out of sequence.</h3>
            <h3>Please enter the next MO# in the printer.</h3>
            <div>
                <input type='text' maxLength={9} className='NextMONumber' onChange={handleSerialNumberChange} value={serialNumber} />
                <input type='text' maxLength={1} className='NextMOCheck' onChange={handleSerialCheckChange} value={serialCheck} />
            </div>
            <div>
                <AdvancedButton type='button'
                    onClick={displaySN}>Display SN</AdvancedButton>
                <AdvancedButton type='button'
                    onClick={handleResequence}>Continue</AdvancedButton>
            </div>
            <Message />
        </BasicPopup>
    );
}