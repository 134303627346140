import { useRef, useEffect, useContext } from 'react';
import type { FormikContextType } from 'formik';
import { FormikContext } from 'formik';
import styles from './Message.module.css';
import { getClass } from 'utilities/classnames';

export type MessageType = '' | 'error';

export interface Status {
    message: string,
    messageType?: MessageType
}

interface Props {
    status?: Status
}

const noop = () => { };

export default function Message({ status }: Props) {

    const formik = useContext(FormikContext) as FormikContextType<any> | undefined;

    const messageStatus = status ?? (formik?.status as Status | undefined);

    const currentRef = useRef<HTMLDivElement>(null);
    const lastStatusChange = useRef(Date.now());
    const prevStatusRef = useRef(messageStatus);
    const prevValuesRef = useRef<any>(formik?.values);

    const hasStatus = !!formik?.status;
    const statusChanged = formik?.status !== prevStatusRef.current;
    const valuesChanged = formik?.values !== prevValuesRef.current;
    const setStatus = formik?.setStatus ?? noop;

    useEffect(() => {
        if (!statusChanged && valuesChanged && Date.now() - lastStatusChange.current > 5000) {
            setStatus(null);
        }
    }, [setStatus, statusChanged, valuesChanged]);

    useEffect(() => {
        if (hasStatus && !prevStatusRef.current) {
            currentRef.current?.scrollIntoView({ block: 'nearest' });
        }
    }, [hasStatus]);

    useEffect(() => {
        prevValuesRef.current = formik?.values;
    }, [formik?.values]);

    useEffect(() => {
        prevStatusRef.current = messageStatus;
        lastStatusChange.current = Date.now();
    }, [messageStatus]);

    return (
        <div className={getClass([
            styles.message,
            messageStatus?.messageType === 'error' && styles.error
        ])} ref={currentRef}>
            {messageStatus?.message ?? ''}
        </div>
    );
}