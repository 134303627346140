import { useState, useContext, Fragment, useMemo } from 'react';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import AdvancedField from '../../../Common/AdvancedField/AdvancedField';
import Message from '../../../Common/Message/Message';
import AdvancedButton from '../../../Common/AdvancedButton/AdvancedButton';
import BasicPopup from '../../../Common/Popups/BasicPopup/BasicPopup';
import EjectPopup from './EjectPopup/EjectPopup';
import { MOContext } from '../../../HigherOrder/MoneyOrderController/MoneyOrderController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import EjectingMOonCertexQR from '../../../../images/EjectingMOonCertexQR.svg';
import LoadingMOonCertexQR from '../../../../images/LoadingMOonCertexQR.svg';
import styles from './MoneyOrderSettings.module.css';
import type { FormikHelpers, FormikProps } from 'formik';
export interface FormValues {
    nextMONumber: string,
    nextMOCheck: string,
    numberToEject: string
}

const getCheckForSerial = (serial: string) => {
    return 8 - ((Number(serial) + 8) % 9);
}

/**
 * Page to display various Money Order Settigns, both from the Server and from the MO Printer itself.
 * Also, allows users to perfrom various functions with the MO Printer.
 */
export default function MoneyOrderSettings() {

    const [displayingStatus, setDisplayingStatus] = useState(false);
    const [displayingEject, setDisplayingEject] = useState(false);

    const { nextMONumber, nextMOCheck, currentPrinterInfo, findPrinter, displaySerialNumber, moErrors, resetStatus, moneyOrdersLeft, moEquipment } = useContext(MOContext);
    const { auth } = useContext(AuthContext);
    const { failoverFetch } = useContext(APIContext);
    const { setParameter, parms } = useContext(ParmContext);

    const handleDisplaySN = async ({ setSubmitting }: FormikProps<FormValues>) => {
        setSubmitting(true);
        try {
            await displaySerialNumber(15);
            await new Promise(r => setTimeout(r, 15 * 1000));
            setSubmitting(false);
        } catch (error) {
            console.error(error);
            setSubmitting(false);
        }
    }

    /** 
     * For whatever reason, the printer needs you to send a "Reset" command whenever it gets in an error 
     */
    const handleResetStatus = async ({ setSubmitting }: FormikProps<FormValues>) => {
        setSubmitting(true);
        try {
            await resetStatus();
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    }

    const handleFindPrinter = async ({ setSubmitting }: FormikProps<FormValues>) => {
        setSubmitting(true);
        try {
            await findPrinter();
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    }

    const status = useMemo(() => {
        if (!currentPrinterInfo?.found) {
            return "Printer Not Found!";
        } else if (moErrors.length > 0) {
            return moErrors[0];
        } else if (!currentPrinterInfo.ready) {
            return "Printer Not Ready!";
        } else {
            return "Printer Ready";
        }
    }, [currentPrinterInfo?.found, currentPrinterInfo.ready, moErrors])

    const saveSettings = async ({ nextMONumber, nextMOCheck }: FormValues, { setStatus, setSubmitting }: FormikHelpers<FormValues>) => {
        if (getCheckForSerial(nextMONumber) !== Number(nextMOCheck)) {
            setStatus({ message: 'Please enter a valid Check Digit', messageType: 'error' });
            setSubmitting(false);
        } else {
            if (auth) {
                try {
                    await failoverFetch('/validateMo?' + new URLSearchParams({
                        moNum: nextMONumber,
                        authToken: auth.authToken
                    }));
                    setParameter({ packstart1: nextMONumber + nextMOCheck })
                    //if (moneyOrdersLeft === 'N/A') {
                    //await getParameters();
                    //}
                    setStatus({ message: 'Settings saved!' });
                } catch (err: any) {
                    setStatus({ message: err.response?.data?.validateMo ?? 'Settings not saved!', messageType: 'error' });
                } finally {
                    setSubmitting(false);
                }
            }
        }
    }

    const initialValues = useMemo(() => ({
        nextMONumber: nextMONumber.toString(),
        nextMOCheck: nextMOCheck.toString(),
        numberToEject: ''
    }), [nextMOCheck, nextMONumber])

    const handleDisplayStatus = () => {
        setDisplayingStatus(true);
    }

    const closeStatus = () => {
        setDisplayingStatus(false);
    }

    const indicatorClass = currentPrinterInfo.found ? (currentPrinterInfo.ready ? 'Good' : 'Bad') : 'Unknown';

    const handleDisplayEjection = () => {
        setDisplayingEject(true);
    }

    const closeEjection = () => {
        setDisplayingEject(false);
    }

    return (
        <Fragment>
            <AdvancedForm initialValues={initialValues} onSubmit={saveSettings} enableReinitialize={true} numericOnly={true} >
                {(props: FormikProps<FormValues>) =>
                    <Fragment>
                        <div className={styles.moneyOrderSettings}>
                            <div className={styles.qrCodes}>
                                <fieldset>
                                    <div>Loading Money Orders</div>
                                    <img src={LoadingMOonCertexQR} alt='Loading MO On Certex QR Code'></img>
                                    <span>youtu.be/Yg28S3bhky0</span>
                                </fieldset>
                                <fieldset >
                                    <div>Unloading Money Orders</div>
                                    <img src={EjectingMOonCertexQR} alt='Ejecting MO On Certex QR Code'></img>
                                    <span>youtu.be/kJrCa_pSyug</span>
                                </fieldset>
                            </div>


                            <div className={styles.mainInputBlock}>
                                <fieldset className={styles.printerControls}>
                                    <div className={styles.nextMO}>
                                        <label>Next MO</label>
                                        <AdvancedField name='nextMONumber' numeric={true} maxLength={9} className={styles.nextMONumber} />
                                        <AdvancedField name='nextMOCheck' numeric={true} maxLength={1} className={styles.nextMOCheck} />
                                    </div>
                                    <div>
                                        <label>MO's Left</label>
                                        <input type='text' value={moneyOrdersLeft} readOnly />
                                    </div>
                                    <div>
                                        <span className={'Indicator ' + indicatorClass} />
                                    </div>
                                </fieldset>
                                <div className={styles.serialLimitBlock}>
                                    <fieldset className={styles.printerSerial}>
                                        <label>Printer SN</label>
                                        <input type='text' value={moEquipment.serial ? moEquipment.serial : 'N/A'} readOnly />
                                    </fieldset>
                                    <fieldset className={styles.limits}>
                                        <div>
                                            <label>Max Amt Each</label>
                                            <input type='text' value={parms?.parameters.moMaxOrdAmt} readOnly />
                                        </div>
                                        <div>
                                            <label>Max Amt Per Trans</label>
                                            <input type='text' value={parms?.moLimits?.maxPerCust} readOnly />
                                        </div>
                                    </fieldset>
                                </div>
                                <fieldset>
                                    <label>Max # Per Trans</label>
                                    <input type='text' value={parms?.parameters.moMaxTrnCnt} readOnly />
                                    <label>Daily Max</label>
                                    <input type='text' value={parms?.moLimits?.maxPerDay} readOnly />
                                    <label>FE MO Fee</label>
                                    <input type='text' value={parms?.moLimits?.companyFee.toFixed(2)} readOnly />
                                    <label>Agent Fee</label>
                                    <input type='text' value={parms?.parameters.moFee0} readOnly />
                                </fieldset>
                            </div>
                            <fieldset className={styles.printerButtons}>
                                <legend>Printer Functions</legend>
                                <AdvancedButton>Save</AdvancedButton>
                                <Message />
                                <button type='button'
                                    onClick={() => handleDisplayStatus()}
                                    disabled={!currentPrinterInfo.found}>Display Status</button>
                                <button type='button'
                                    onClick={() => handleFindPrinter(props)}
                                    disabled={currentPrinterInfo.found || props.isSubmitting}>Find Printer</button>
                                <button type='button'
                                    onClick={() => handleResetStatus(props)}
                                    disabled={!currentPrinterInfo.found || currentPrinterInfo.ready || props.isSubmitting}>Reset Status</button>
                                <button type='button'
                                    onClick={() => handleDisplaySN(props)}
                                    disabled={!currentPrinterInfo.found || !currentPrinterInfo.ready || props.isSubmitting}>Display SM</button>
                                <button type='button'
                                    onClick={handleDisplayEjection}
                                    disabled={!currentPrinterInfo.found || !currentPrinterInfo.ready || props.isSubmitting || parms?.clerkInfo.type !== 'S'}>Eject MOs</button>
                            </fieldset>
                            <BasicPopup
                                darkMode
                                fadeIn
                                onClose={closeStatus}
                                open={displayingStatus}>
                                <h3>Printer Status</h3>
                                <h4>{status}</h4>
                            </BasicPopup>

                        </div>
                        <EjectPopup onClose={closeEjection} open={displayingEject} />
                    </Fragment>
                }
            </AdvancedForm>

        </Fragment>
    );
}