import { Route, Switch } from "react-router-dom";
import SupportEntries from './SupportEntries/SupportEntries';
import SupportFAQ from './SupportFAQ/SupportFAQ';
import SupportSearch from './SupportSearch/SupportSearch';
import Help from './Help/Help';
import styles from './Support.module.css';
import AMLGuide from './AMLGuide/AMLGuide';

export const initialTrainings = {
    search: false,
    category: false,
    FAQ: false
};

export default function Support() {
    return (
        <div className={styles.support}>
            <div>
                <Switch>
                    <Route path='/Support/SupportEntries' component={SupportEntries} />
                    <Route path='/Support/SupportSearch' component={SupportSearch} />
                    <Route path='/Support/Help' component={Help} />
                    <Route path='/Support/AMLGuide' component={AMLGuide} />
                    <Route component={SupportFAQ} />
                </Switch>
            </div>
        </div>
    );
}