import { SettingsContext } from "components/HigherOrder/SettingsController/SettingsController";
import { useCallback, useContext } from "react";
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { parse } from 'date-fns';

export const serverFormat = (date: Date | number, formatStr: string) => {
    return formatInTimeZone(date, 'America/Chicago', formatStr);
};

export const serverParse = (dateString: string, formatString: string) => {
    const serverDate = parse(dateString, formatString, new Date());
    return zonedTimeToUtc(serverDate, 'America/Chicago')
};

export function useTimeZone() {

    const { settings: { timeZone } } = useContext(SettingsContext);

    const localFormat = useCallback((date: Date | number, formatStr: string) => {
        return formatInTimeZone(date, timeZone, formatStr);
    }, [timeZone]);

    return {
        localFormat
    }
}