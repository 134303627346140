import { useContext } from 'react';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import Message from '../../../Common/Message/Message';
import AdvancedField from '../../../Common/AdvancedField/AdvancedField';
import SubmitButtom from '../../../Common/AdvancedButton/AdvancedButton';
import { useHistory } from 'react-router-dom';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { SystemContext } from '../../../HigherOrder/SystemConroller/SystemController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { Field, type FormikHelpers } from 'formik';
import styles from './AdminLogin.module.css';
import { FetchError } from 'utilities/network';

interface SetLoginResponse {
    setLogin: string
}

const initialValues = {
    agentId: '',
    adminLogin: '',
    adminPassword: ''
};

/**
 * Page to allow an Agent to switch their Configuration over to that of another Agent.
 * An equivalent page appears on GM when ran without a configuration (SHIVAGENERATORFRAME).
 * Login / Password are present in SHIVAADMIN, and are not normal login / passwords.
 * In addition to switching configuration, will create a new user with the given Agent #.
 */
export default function AdminLogin() {

    const { push } = useHistory();
    const { failoverFetch, statusRequest } = useContext(APIContext);
    const { network: { mac } } = useContext(SystemContext);
    const { clearAllSettings } = useContext(SettingsContext);

    const handleSubmit = async ({ adminLogin, adminPassword, agentId }: typeof initialValues, { setStatus, setSubmitting }: FormikHelpers<typeof initialValues>) => {
        setStatus({ message: 'Authenticating...' });
        try {
            const loginResponse = await failoverFetch('/adminLogin?' + new URLSearchParams({
                userName: adminLogin,
                password: adminPassword,
                custId: agentId,
                mac
            }));

            if (!loginResponse.includes('Success')) {
                throw new Error('Please try again.');
            }

            setStatus({ message: 'Creating New User...' });
            await addDefaultUser(agentId);

            setStatus({ message: 'Downloading Config...' });
            const statusResponse = await statusRequest({ agentId, authToken: '!htrae!' }, -1, true);

            if (statusResponse) {
                clearAllSettings();
                push('/Login');
            } else {
                throw new Error('Please try again.');
            }
        } catch (error: any) { //TODO FIX
            if (error instanceof FetchError) {
                const errorText = await error.response.text();
                setStatus({ message: errorText, messageType: 'error' });
            } else if (error instanceof Error) {
                setStatus({ message: error.message, messageType: 'error' });
            } else if (typeof error === 'string') {
                setStatus({ message: error, messageType: 'error' });
            } else {
                setStatus({ message: 'An unexpected error has occurred.', messageType: 'error' });
            }

            console.error(error);
            setSubmitting(false);
        }
    }

    /** 
     * This really needs to be integrated with the admin login servlet, as creating a user using our Admin token is very insecure.
     */
    const addDefaultUser = async (agent: string) => {
        const response = await failoverFetch('/setLogin?' + new URLSearchParams({
            userName: agent.padStart(7, '0'),
            orgUserName: agent.padStart(7, '0'),
            password: agent.padStart(7, '0'),
            challenge: 'Not Added',
            response: 'Not Added',
            type: 'S',
            custId: agent.padStart(7, '0'),
            authToken: '!htrae!'
        }));

        if (response.includes('Success')) {
            return JSON.parse(response) as SetLoginResponse;
        }
        throw new Error('Failed to add username. The following was returned: ' + response);
    }

    return (
        <AdvancedForm className={styles.adminLogin} initialValues={initialValues} onSubmit={handleSubmit} >
            <AdvancedField name='agentId' placeholder='Agent Number' maxLength={9} numeric required autoFocus />
            <Field name='adminLogin' placeholder='Admin Login' maxLength={16} required />
            <Field type='password' name='adminPassword' placeholder='Admin Password' maxLength={16} required />
            <div><SubmitButtom>Submit</SubmitButtom></div>
            <Message />
        </AdvancedForm>
    )
}