import type { ReactNode } from 'react';
import { createContext, useCallback, useState } from 'react';

export interface Auth {
    agentId: string,
    authToken: string,
    badPassword: boolean,
    challengeQuestion: string,
    challengeResponse: string,
    clerkId: string,
    username: string
}

export const AuthContext = createContext({
    auth: null as null | Auth,
    setAuth: (newAuth: Auth | null) => { }
});

interface Props {
    children: ReactNode
}

const getSavedAuth = () => {
    const auth = sessionStorage.getItem("auth");
    return auth ? (JSON.parse(auth) as Auth) : null;
}

/**
 * Top-level component for keeping track of the Clerk's authentication.
 * This primarily consists of the auth-token, but has some other useful properties.
 * Some properties are duplicated in the parameters, which can be useful ensuring the parameters are for the current user.
 */
export default function AuthController({ children }: Props) {

    const [auth, setAuthState] = useState(getSavedAuth());

    const setAuth = useCallback((auth: Auth | null) => {
        if (auth) {
            sessionStorage.setItem("auth", JSON.stringify(auth));
        } else {
            sessionStorage.removeItem("auth");
        }
        setAuthState(auth);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                auth, setAuth
            }} >
            {children}
        </AuthContext.Provider>
    );
}