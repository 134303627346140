import { useEffect, useCallback, useState } from 'react';
import type { MutableRefObject } from 'react';
import { isElectron, isWindows } from "utilities/Environment";
import { useInterval } from 'usehooks-ts';
import type { PrintOptions } from './PrintController';
import { handleFetchErrors } from 'utilities/network';

export interface Helper {
    version: string,
    receiptPrinter?: string,
    reportPrinter?: string,
    printDuplicate: boolean
}

const interval = (!isElectron && isWindows) ? 30 * 1000 : null;

export function useHelperPrint(containerRef: MutableRefObject<HTMLDivElement | null>, { printer }: PrintOptions) {

    const [helper, setHelper] = useState<Helper | null>(null);

    const statusRequest = useCallback(async () => {
        try {
            const response = await fetch('http://localhost:9999/status').then(handleFetchErrors);
            const data = await response.json() as Helper;
            setHelper(data);
        } catch (error) {
            setHelper(null);
        }
    }, []);

    useEffect(() => {
        if (!isElectron && isWindows) {
            statusRequest();
        }
    }, [statusRequest]);

    useInterval(statusRequest, interval);

    const helperPrint = useCallback(async () => {
        const html = containerRef.current?.outerHTML;
        if (html) {
            await fetch('http://localhost:9999/print', {
                method: 'POST',
                body: JSON.stringify({ printer, html }),
                headers: { "Content-Type": "application/json" }
            }).then(handleFetchErrors);
        }
    }, [containerRef, printer]);

    return { helper, setHelper, helperPrint };
}