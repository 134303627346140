import { useState, useEffect, useCallback, useMemo } from 'react';
import CheckCart from './CheckCart/CheckCart';
import CashField from '../../../../Common/CashField/CashField';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import './CheckForm.css';
import type { initialValues } from '../BillPay';
import type { CheckScanResponseData } from 'components/hooks/RDM';

export default function CheckForm() {

    const { values, setFieldValue } = useFormikContext<typeof initialValues>();
    const { goBack } = useHistory();

    const [pendingChecks, setPendingChecks] = useState(values.checks);
    const [selectedCheck, setSelectedCheck] = useState<null | CheckScanResponseData>(null);

    const addCheck = (checkInfo: CheckScanResponseData) => {
        setPendingChecks((currentChecks) => {
            return currentChecks.concat(checkInfo);
        });
    }

    const removeSelectedCheck = useCallback(() => {
        if (selectedCheck) {
            setPendingChecks((currentChecks) => {
                return currentChecks.filter((currentCheck) => {
                    return currentCheck.Micr.MicrLine !== selectedCheck.Micr.MicrLine;
                });
            });
            setSelectedCheck(null);
        }
    }, [selectedCheck])

    const handleConfirmation = useCallback(() => {
        setFieldValue('checks', pendingChecks);
        goBack();
    }, [goBack, pendingChecks, setFieldValue])

    useEffect(() => {
        if (!values.biller) {
            goBack();
        }
    }, [goBack, values.biller]);

    return useMemo(() =>
        <div className="CheckForm">
            <fieldset className='CheckAmount'>
                <legend>
                    Enter the check amount then place the check in the scanner.
                </legend>
                <CashField name='checkInput'
                    disabled={!!selectedCheck}
                    autoFocus={true} />
            </fieldset>
            <fieldset>
                <CheckCart
                    pendingChecks={pendingChecks}
                    selectedCheck={selectedCheck}
                    setSelectedCheck={setSelectedCheck}
                    addCheck={addCheck}
                    removeSelectedCheck={removeSelectedCheck} />
                <button type='button'
                    className='CheckConfirm'
                    onClick={handleConfirmation} >Confirm</button>
            </fieldset>
        </div>
        , [handleConfirmation, pendingChecks, removeSelectedCheck, selectedCheck]);
}