import type { ReactNode } from 'react';
import { createContext, useContext, useCallback, useState } from 'react';
import type { Auth } from '../AuthController/AuthController';
import { AuthContext } from '../AuthController/AuthController';
import { isElectron } from "utilities/Environment";
import { SystemContext } from '../SystemConroller/SystemController';
import { isLinux, isWindows } from 'utilities/Environment';

const minionPath = isWindows ? 'C:\\salt\\conf\\minion_id'
    : isLinux ? '/etc/salt/minion_id'
        : '';

const updateMinion = async (agentId: number) => {
    const fsPromises = require('fs').promises;
    try {
        await fsPromises.writeFile(minionPath, `PHN-${agentId.toString().padStart(7, '0')}`);
    } catch (error) {
        console.error(error);
    }
};

interface RawParameter {
    key: string,
    value: string
}

export interface ParsedParameters {
    addressRequired: boolean,
    processCheck: boolean,
    scanBill: boolean,
    mtuVideo?: string,
    billPayActive: boolean,
    ageVerifActive: boolean,
    prePayActive: boolean,
    giftCardActive: boolean,
    amazonCashActive: boolean,
    netspendActive: boolean,
    moRoute: string,
    moMaxOrdAmt: number,
    moMaxTrnCnt: number,
    moFee0: number,
    moFee1: number,
    moFee2: number,
    moFee3: number,
    moFeeRange1: number,
    moFeeRange2: number,
    moFeeRange3: number,
    bpRoute: string,
    trailers: Array<string>,
    headers: Array<string>,
    deliveryDisclaimers: Array<string>,
    dataURLs: Array<string>,
    statusInterval: number,
    logoutTime: number,
    logTransTime: boolean,
    packstart1: string,
    qrOptIn: boolean
}

interface ClerkInfo {
    agentId: number,
    badPassword?: boolean,
    clerkId: number,
    hasChallenge?: boolean,
    memoId?: number,
    type: string,
    username: string,
    lastMTUTransaction: string,
    lastAMLDate: string
}

interface MOLimits {
    companyFee: number,
    maxPerCust: number,
    maxPerDay: number,
    maxPerMO: number,
    maxPerWeekend: number
}

interface MOPack {
    firstInSeries: number,
    lastInSeries: number,
    maxPerMO: number
}

export interface Banner {
    id: number,
    color: string,
    actionType: string,
    actionValue: string,
    screen: string
}

interface ReceiptRow {
    type: 'IMAGE' | 'TEXT',
    value: string
}

interface BaseParms {
    addressRequired: boolean,
    banners: Banner[],
    bpActive: boolean,
    messages: string[],
    moActive: boolean,
    promotions: number[],
    unreadMessages: number,
    moLimits?: MOLimits,
    moPacks?: MOPack[],
    surveys?: number[],
    receiptSpace?: ReceiptRow[]
}

export interface RawParms extends BaseParms {
    clerkInfo?: ClerkInfo,
    parameters?: RawParameter[]
}

export interface ParsedParms extends BaseParms {
    clerkInfo: ClerkInfo,
    parameters: ParsedParameters
}

export const ParmContext = createContext({
    parms: null as null | ParsedParms,
    setRawParms: (newParms: RawParms, newAuth?: Pick<Auth, "agentId">) => { },
    setParameter: (parameters: Partial<ParsedParameters> | ((prevState: ParsedParameters) => Partial<ParsedParameters>)) => { },
    setClerkInfo: (clerkInfo: Partial<ClerkInfo>) => { },
    decrementUnreadMessages: () => { },
    removeSurvey: (surveyId: number) => { },
    clearParms: () => { },
    loggedIn: false as boolean,
    updateRequired: true as boolean,
    hasBadUsername: true as boolean,
    companyId: -1
});

const getLastTrailerNumber = (parameterMap: Map<string, string>) => {
    const trailerNumbers = [] as number[];
    for (const key of parameterMap.keys()) {
        if (key.startsWith('BP-TRAILR') && !!parameterMap.get(key)) {
            trailerNumbers.push(Number(key.substring(9)));
        }
    }
    return Math.max(...trailerNumbers);
}

const parseTrailers = (parameterMap: Map<string, string>) => {
    const lastTrailerNumber = getLastTrailerNumber(parameterMap);
    const trailers = [] as string[];
    for (let i = 1; i <= lastTrailerNumber; i++) {
        const trailer = parameterMap.get("BP-TRAILR" + i);
        if (trailer || trailers.length > 0) {
            trailers.push(trailer ?? '');
        }
    }
    return trailers;
}

const parseHeaders = (parameterMap: Map<string, string>) => {
    const headers = [] as string[];
    for (let i = 1; i <= 5; i++) {
        const header = parameterMap.get("BP-HEADER" + i);
        if (header || headers.length > 0) {
            headers.push(header ?? '');
        }
    }
    return headers;
}

const getLastDeliveryDisclaimerNumber = (parameterMap: Map<string, string>) => {
    const disclaimerNumbers = [] as number[];
    for (const key of parameterMap.keys()) {
        if (key.startsWith('BP-DELIVERYDISCLAIMER') && !!parameterMap.get(key)) {
            disclaimerNumbers.push(Number(key.substring(21)));
        }
    }
    return Math.max(...disclaimerNumbers);
}

const parseDeliveryDisclaimers = (parameterMap: Map<string, string>) => {
    const lastDisclaimerNumber = getLastDeliveryDisclaimerNumber(parameterMap);
    const disclaimers = [] as string[];
    for (let i = 1; i <= lastDisclaimerNumber; i++) {
        const disclaimer = parameterMap.get("BP-DELIVERYDISCLAIMER" + i);
        if (disclaimer || disclaimers.length > 0) {
            disclaimers.push(disclaimer ?? '');
        }
    }
    return disclaimers;
}

const getLastDataURLNumber = (parameterMap: Map<string, string>) => {
    const urlNumbers = [] as number[];
    for (const key of parameterMap.keys()) {
        if (key.startsWith('BP-DATAHOST') && !!parameterMap.get(key)) {
            urlNumbers.push(Number(key.substring(11)));
        }
    }
    return Math.max(...urlNumbers);
}

const parseDataURLs = (parameterMap: Map<string, string>) => {
    const lastURLNumber = getLastDataURLNumber(parameterMap);
    const URLs = [] as string[];
    for (let i = 0; i <= lastURLNumber; i++) {
        URLs.push("https://"
            + (parameterMap.get("BP-DATAHOST" + i) ?? process.env.REACT_APP_PHNSERVER_API_URL!)
            + ":"
            + (parameterMap.get("BP-DATAPORT" + i) ?? '443')
            + (parameterMap.get("BP-DATAPATH" + i) ?? ''));
    }
    return URLs;
}

const parsePackStart = ({ moActive, moPacks }: RawParms, parameterMap: Map<string, string>) => {
    if (moActive && moPacks) {
        const packstart = parameterMap.get('MO-PACKSTART1');
        if (packstart) {
            const moNum = Number(packstart.substring(0, packstart.length - 1));
            const validMoNum = moPacks.some(({ firstInSeries, lastInSeries }) =>
                moNum >= firstInSeries && moNum <= lastInSeries
            );
            if (validMoNum) {
                return packstart;
            }
        }
    }
    return '';
}

const getParametersWithDefaults = (newParms: RawParms) => {
    const parameterMap = new Map<string, string>();
    for (const parameter of newParms.parameters ?? []) {
        parameterMap.set(parameter.key, parameter.value);
    }

    return {
        addressRequired: (parameterMap.get('BP-ADDRESSREQUIRED') || "1") === "1",
        processCheck: (parameterMap.get("BP-PROCESSCHECK") || '').toLowerCase() === 'true',
        scanBill: (parameterMap.get("BP-SCANBILL") || '').toLowerCase() === 'true',
        mtuVideo: parameterMap.get('BP-MTUVIDEO'),
        billPayActive: parameterMap.get('BP-BILLPAYACTIVE') === '1',
        ageVerifActive: parameterMap.get('BP-AGEVERIFACTIVE') === '1',
        prePayActive: ['1', '2'].includes(parameterMap.get('BP-PREPAYACTIVE') || ''),
        giftCardActive: parameterMap.get('BP-GIFTCARDACTIVE') === '1',
        amazonCashActive: parameterMap.get('BP-AMAZONCASHACTIVE') === '1',
        netspendActive: parameterMap.get('BP-NETSPENDACTIVE') === '1',
        moRoute: parameterMap.get('MO-ROUTE') ?? '',
        moMaxOrdAmt: Number(parameterMap.get('MO-MAXORDAMT') || '0'),
        moMaxTrnCnt: Number(parameterMap.get('MO-MAXTRNCNT') || '0'),
        moFee0: Number(parameterMap.get('MO-FEE0') || '0'),
        moFee1: Number(parameterMap.get('MO-FEE1') || '0'),
        moFee2: Number(parameterMap.get('MO-FEE2') || '0'),
        moFee3: Number(parameterMap.get('MO-FEE3') || '0'),
        moFeeRange1: Number(parameterMap.get('MO-FEERANGE1') || '0'),
        moFeeRange2: Number(parameterMap.get('MO-FEERANGE2') || '0'),
        moFeeRange3: Number(parameterMap.get('MO-FEERANGE3') || '0'),
        bpRoute: parameterMap.get('BP-ROUTE') ?? '',
        trailers: parseTrailers(parameterMap),
        headers: parseHeaders(parameterMap),
        deliveryDisclaimers: parseDeliveryDisclaimers(parameterMap),
        dataURLs: parseDataURLs(parameterMap),
        statusInterval: Number(parameterMap.get('BP-STATUSINTERVAL') || '900000'),
        logoutTime: Number(parameterMap.get('BP-LOGOUTTIME') || '30') * 60000,
        logTransTime: parameterMap.get('BP-LOGTRANSTIME') === '1',
        packstart1: parsePackStart(newParms, parameterMap),
        qrOptIn: parameterMap.get('BP-QROPTIN') === '1'
    }
}

const isfullParms = (newParms: RawParms) =>
    !!newParms.parameters && newParms.parameters.length > 0;

const parseParameters = (currentParms: ParsedParms | null, newParms: RawParms) => {
    if (!isfullParms(newParms) && !!currentParms?.parameters) {
        return currentParms.parameters;
    }
    return getParametersWithDefaults(newParms);
}

const getDefaultClerkInfo = (agentId?: string) => {
    return {
        agentId: Number(agentId ?? '-1'),
        clerkId: -1,
        type: "S",
        username: agentId?.padStart(7, '0') ?? 'N/A',
        lastMTUTransaction: '',
        lastAMLDate: ''
    };
}

const parseClerkInfo = (currentParms: ParsedParms | null, newParms: RawParms, agentId?: string) =>
    newParms.clerkInfo ?? currentParms?.clerkInfo ?? getDefaultClerkInfo(agentId);

const getLocalStorageParameters = () => {
    const localStorageParms = localStorage.getItem("parameters");
    return localStorageParms ? (JSON.parse(localStorageParms) as ParsedParms) : null;
}

const parsePromotions = (currentPromotions: number[], newPromotions: number[]) => {
    if (currentPromotions.every((promotion, index) => promotion === newPromotions[index])) {
        return currentPromotions;
    }
    return newPromotions;
}

interface Props {
    children: ReactNode
}

export default function ParmController({ children }: Props) {

    const [parms, setParmsState] = useState(getLocalStorageParameters());

    const { auth } = useContext(AuthContext);
    const { system: { isPOS } } = useContext(SystemContext);

    const username = parms?.clerkInfo.username ?? '';
    const agentId = parms?.clerkInfo.agentId ?? -1;
    const memoId = parms?.clerkInfo.memoId ?? -1;
    const isMemo = memoId > 0;
    const companyId = isMemo ? memoId : agentId;
    const hasBadUsername = username.includes(companyId.toString());
    const loggedIn = !!auth && !!parms;
    const updateRequired = !!auth && (auth.badPassword || hasBadUsername);

    const setParms = useCallback((value: ParsedParms | null | ((prevState: ParsedParms | null) => ParsedParms | null)) => {
        setParmsState((currentParms) => {
            const newParms = value instanceof Function ? value(currentParms) : value;
            if (newParms) {
                localStorage.setItem("parameters", JSON.stringify(newParms));
                if (isElectron) {
                    const agentId = newParms.clerkInfo.agentId;
                    if (agentId !== currentParms?.clerkInfo.agentId && agentId > 0 && isPOS) {
                        updateMinion(agentId);
                    }
                }
            } else {
                localStorage.removeItem("parameters");
            }
            return newParms;
        });
    }, [isPOS]);

    const setRawParms = useCallback((newParms: RawParms, newAuth?: Pick<Auth, "agentId">) => {
        const agentId = newAuth?.agentId ?? auth?.agentId;
        setParms((currentParms) => ({
            ...newParms,
            moLimits: isfullParms(newParms) ? newParms.moLimits : currentParms?.moLimits,
            moPacks: isfullParms(newParms) ? newParms.moPacks : currentParms?.moPacks,
            clerkInfo: parseClerkInfo(currentParms, newParms, agentId),
            parameters: parseParameters(currentParms, newParms),
            promotions: parsePromotions(currentParms?.promotions ?? [], newParms.promotions)
        }));
    }, [auth, setParms]);

    const setParm = useCallback((value: Partial<ParsedParms> | ((prevState: ParsedParms) => Partial<ParsedParms>)) => {
        setParms((currentParms) => {
            if (currentParms) {
                const newParms = value instanceof Function ? value(currentParms) : value;
                return {
                    ...currentParms,
                    ...newParms
                }
            }
            return currentParms;
        });
    }, [setParms]);

    const setParameter = useCallback((value: Partial<ParsedParameters> | ((prevState: ParsedParameters) => Partial<ParsedParameters>)) => {
        setParm(({ parameters: currentParameters }) => {
            const newParameters = value instanceof Function ? value(currentParameters) : value;
            return {
                parameters: {
                    ...currentParameters,
                    ...newParameters
                }
            };
        });
    }, [setParm])

    const setClerkInfo = useCallback((newClerkInfo: Partial<ClerkInfo>) => {
        setParm(({ clerkInfo: currentClerkInfo }) => ({
            clerkInfo: {
                ...currentClerkInfo,
                ...newClerkInfo
            }
        }));
    }, [setParm]);

    const decrementUnreadMessages = useCallback(() => {
        setParm(({ unreadMessages: currentUnreadMessages }) =>
            ({ unreadMessages: currentUnreadMessages - 1 }))
    }, [setParm]);

    const removeSurvey = useCallback((surveyId: number) => {
        setParm(({ surveys: currentSurveys }) =>
            ({ surveys: currentSurveys?.filter(survey => survey !== surveyId) }))
    }, [setParm]);

    const clearParms = useCallback(() => {
        setParms(null);
    }, [setParms]);

    return (
        <ParmContext.Provider value={{
            parms,
            setRawParms,
            setParameter,
            setClerkInfo,
            decrementUnreadMessages,
            removeSurvey,
            clearParms,
            loggedIn,
            updateRequired,
            hasBadUsername,
            companyId
        }} >
            {children}
        </ParmContext.Provider>
    );
}