import { useContext } from 'react';
import { TrainingContext } from '../TrainingOverlay';
import styles from './TrainingToggle.module.css';
import { getClass } from 'utilities/classnames';
import { getTooltipClass, getTooltipTriggerClass, tooltipButtonClass, tooltipTitleClass } from 'utilities/tooltip';

/**
 * The little 'toggle' you see for training mode.
 */
export default function TrainingToggle() {

    const { isTrainingToggleTraining,
        isTraining, headerTraining, sidebarTraining, completeToggleTraining, toggleTraining, trainingAvailable } = useContext(TrainingContext);

    if (isTraining || trainingAvailable) {
        return (
            <div className={getClass([
                styles.trainingToggle,
                isTraining && styles.on,
                !isTraining && styles.off,
                headerTraining && styles.headerTraining,
                sidebarTraining && styles.sidebarTraining,
                getTooltipTriggerClass(isTrainingToggleTraining)
            ])}
                data-text={
                    'This is the training mode toggle.\n' +
                    'You can use this to enter and exit training mode.'
                }
                onClick={completeToggleTraining} >
                <span>TRAINING MODE</span>
                <div onClick={() => toggleTraining()}
                    className={getClass([
                        styles.toggle,
                        isTraining && styles.on,
                        !isTraining && styles.off
                    ])}>
                    <div className={styles.toggleIcon} />
                </div>
                <div className={getTooltipClass({ placement: 'bottom-left' })}>
                    <div className={tooltipTitleClass}>Toggle</div>
                    <div>This is the training mode toggle.</div>
                    <div>You can use this to enter and exit training mode.</div>
                    <span className={tooltipButtonClass} onClick={completeToggleTraining}>Continue</span>
                </div>
            </div>
        );
    } else {
        return null;
    }
}