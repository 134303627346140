import AdvancedButton from 'components/Common/AdvancedButton/AdvancedButton';
import MessageAggregator from 'components/Common/MessageAggregator/MessageAggregator';
import { useFormikContext } from 'formik';
import ConfirmField from '../../../../Common/ConfirmField/ConfirmField';
import type { initialValues } from '../NetSpend';
import styles from '../NetSpend.module.css';
import { tooltipBoldClass } from 'utilities/tooltip';
import AdvancedField from 'components/Common/AdvancedField/AdvancedField';

export const tutorialPhone = '9033351533';

export default function PhoneForm() {

    const { values: { phone, phoneConfirm } } = useFormikContext<typeof initialValues>();

    const isPhoneTutorial = phone !== tutorialPhone;
    const isPhoneConfirmTutorial = !isPhoneTutorial && phoneConfirm !== tutorialPhone;
    const isButtonTutorial = !isPhoneTutorial && !isPhoneConfirmTutorial;

    return (
        <div className={styles.cardForm}>
            <div className={styles.cardForm}>
                <h1>Enter Phone Number</h1>
                <AdvancedField
                    name='phone'
                    placeholder='Phone Number'
                    trainingTooltip={
                        <>
                            <div>ENTER THIS PHONE NUMBER</div>
                            <b className={tooltipBoldClass}>{tutorialPhone}</b>
                        </>
                    }
                    tooltipPlacement='left'
                    trainingFocused={isPhoneTutorial}
                    maxLength={11}
                    className={styles.cardFormInput}
                    autoFocus required numeric />
                <ConfirmField
                    name='phone'
                    placeholder='Phone Number'
                    trainingTooltip={
                        <>
                            <div>ENTER PHONE NUMBER AGAIN</div>
                            <b className={tooltipBoldClass}>{tutorialPhone}</b>
                        </>
                    }
                    tooltipPlacement='left'
                    trainingFocused={isPhoneConfirmTutorial}
                    maxLength={11}
                    className={styles.cardFormInput}
                    required numeric />
                <AdvancedButton
                    trainingFocused={isButtonTutorial}
                    className={styles.cardFormNext}
                    trainingTooltip={
                        <>
                            <div>YOU ARE READY</div>
                            <b className={tooltipBoldClass}>CLICK NEXT</b>
                        </>
                    }
                >Next</AdvancedButton>
            </div >
            <MessageAggregator />
        </div >
    );
}