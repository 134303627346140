import type { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import type { TableCellDataGetterParams } from '../../../../Common/VirtualizedTable/VirtualizedTable';
import VirtualizedTable from '../../../../Common/VirtualizedTable/VirtualizedTable';
import { useFormikContext } from 'formik';
import { MOContext } from '../../../../HigherOrder/MoneyOrderController/MoneyOrderController';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { TrainingContext } from '../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { focusPreviousElement } from '../../../../../utilities/Tools';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import styles from './MoneyOrderCart.module.css';
import type { initialValues, MoneyOrderItem } from '../MoneyOrder';

const columns = [{
    label: 'Serial Number',
    dataKey: 'serialNumber',
    minWidth: 150
}, {
    label: 'Amount',
    dataKey: 'amount',
    minWidth: 150,
    cellRenderer: ({ rowData }: TableCellDataGetterParams) => {
        return ('$' + (rowData.amount / 100).toFixed(2));
    }
}];

interface Props {
    moneyOrders: MoneyOrderItem[],
    selectedMoneyOrder: MoneyOrderItem | null,
    setMoneyOrders: Dispatch<SetStateAction<MoneyOrderItem[]>>,
    setSelectedMoneyOrder: Dispatch<SetStateAction<MoneyOrderItem | null>>,
    isAddButtonTutorial: boolean,
    hasError: boolean
}

export default function MoneyOrderCart({ moneyOrders, selectedMoneyOrder, setMoneyOrders, setSelectedMoneyOrder, isAddButtonTutorial, hasError }: Props) {

    const totals = moneyOrders.reduce((accumulator, { amount, fee }) => {
        accumulator.moneyOrders += amount;
        accumulator.fees += fee;
        accumulator.total += (amount + fee);
        return accumulator;
    }, { moneyOrders: 0.0, fees: 0.0, total: 0.0 });

    const formik = useFormikContext<typeof initialValues>();
    const amount = Number(formik.values.cash);
    const { parms } = useContext(ParmContext);
    const maxAmt = parms?.parameters.moMaxOrdAmt ?? 0;
    const maxCnt = parms?.parameters.moMaxTrnCnt ?? 0;
    const maxPerCust = parms?.moLimits?.maxPerCust ?? 0;
    const { getMOFee, nextMONumber, moneyOrdersLeft, getMOSerialNumber, getMOCheckDigit } = useContext(MOContext);
    const { isCurrentPageTraining } = useContext(TrainingContext);

    const removeSelectedMoneyOrder = () => {
        if (selectedMoneyOrder) {
            setSelectedMoneyOrder(null);
            setMoneyOrders((currentMoneyOrders) =>
                currentMoneyOrders.filter(({ serialNumber }) =>
                    serialNumber !== selectedMoneyOrder.serialNumber
                ).map((moneyOrder, index) => ({
                    ...moneyOrder,
                    serialNumber: getMOSerialNumber(index) + "" + getMOCheckDigit(index)
                }))
            );
        }
    }

    const addMoneyOrders = (newMoneyOrders: MoneyOrderItem[]) => {
        formik.setFieldValue('cash', selectedMoneyOrder?.amount.toString() ?? '0');
        focusPreviousElement();
        setMoneyOrders((currentMoneyOrders) => currentMoneyOrders.concat(newMoneyOrders));
    }

    const splitMoneyOrder = () => {
        const splitMoneyOrders = [];
        for (let i = amount, index = 0; i > 0; i -= maxAmt * 100, index++) {
            const serialNumber = getMOSerialNumber(moneyOrders.length + index) + "" + getMOCheckDigit(moneyOrders.length + index);
            if (i >= maxAmt * 100) {
                splitMoneyOrders.push({ amount: maxAmt * 100, fee: getMOFee(maxAmt * 100), serialNumber });
            } else {
                splitMoneyOrders.push({ amount: i, fee: getMOFee(i), serialNumber });
            }
        }
        return splitMoneyOrders;
    }

    const handleAdd = () => {
        const newTotal = totals.moneyOrders + amount;
        const newMoneyOrder = { amount: amount, fee: getMOFee(amount), serialNumber: getMOSerialNumber(moneyOrders.length) + "" + getMOCheckDigit(moneyOrders.length) };

        if (isCurrentPageTraining) {
            addMoneyOrders([newMoneyOrder]);
        } else if (nextMONumber === 0) {
            formik.setStatus({ message: 'Next MO set to zero. Please verify Next MO is set on the settings window.', messageType: 'error' });
        } else if (moneyOrders.length + 1 > Number(moneyOrdersLeft)) {
            formik.setStatus({ message: 'Reload money order printer and update sequence MO# to process more transactions.', messageType: 'error' });
        } else if (moneyOrders.length + 1 > maxCnt) {
            formik.setStatus({ message: 'This would exceed the number of transactions possible in one submission. Please reduce the amount and try again.', messageType: 'error' });
        } else if (newTotal > (maxPerCust * 100)) {
            formik.setStatus({ message: 'The maximum money order amount per customer is ' + maxPerCust + '. Please reduce the amount and try again.', messageType: 'error' });
        } else if (amount > maxAmt * 100) {
            const splitMoneyOrders = splitMoneyOrder();
            if (moneyOrders.length + splitMoneyOrders.length <= maxCnt
                && moneyOrders.length + splitMoneyOrders.length <= Number(moneyOrdersLeft)) {
                formik.setStatus({ message: 'You have exceeded the maximum Money Order amount of ' + maxAmt + '. Multiple Money Orders have been generated.' });
                addMoneyOrders(splitMoneyOrders);
            } else {
                formik.setStatus({ message: 'The maximum money order amount is ' + maxAmt + '. Please reduce the amount and try again.', messageType: 'error' });
            }
        } else {
            addMoneyOrders([newMoneyOrder]);
        }
    }

    const handleRowClick = (rowData: MoneyOrderItem) => {
        if (selectedMoneyOrder && selectedMoneyOrder.serialNumber === rowData.serialNumber) {
            formik.setFieldValue('cash', '0');
            setSelectedMoneyOrder(null);
        } else {
            formik.setFieldValue('cash', rowData.amount.toString());
            setSelectedMoneyOrder(rowData);
        }
    }

    return (
        <div className={styles.moneyOrderCart}>
            <fieldset>
                <AdvancedButton
                    className={styles.button}
                    type="button"
                    onClick={handleAdd}
                    disabled={Number(formik.values.cash) < 0.01 || hasError}
                    trainingFocused={isAddButtonTutorial}
                    trainingTooltip={
                        <>
                            <div>CLICK HERE TO ADD THE MONEY ORDER</div>
                        </>
                    }
                    data-type='Add'>+</AdvancedButton>
                <AdvancedButton
                    className={styles.button}
                    type="button"
                    onClick={removeSelectedMoneyOrder}
                    disabled={!selectedMoneyOrder || hasError}>-</AdvancedButton>
            </fieldset>
            <fieldset className={styles.moneyOrderCartFieldset}>
                <VirtualizedTable
                    data={moneyOrders}
                    selectedData={selectedMoneyOrder}
                    columns={columns}
                    onRowClick={handleRowClick}
                    height={200} />
            </fieldset>
            <div>
                <fieldset>
                    <legend>Money Orders</legend>
                    <span>${(totals.moneyOrders / 100).toFixed(2)}</span>
                </fieldset>
                <fieldset>
                    <legend>Fee</legend>
                    <span>${(totals.fees / 100).toFixed(2)}</span>
                </fieldset>
                <fieldset>
                    <legend>Total</legend>
                    <span>${(totals.total / 100).toFixed(2)}</span>
                </fieldset>
            </div>
        </div>
    )
}