import type { ChangeEvent, FocusEvent, SyntheticEvent, ComponentPropsWithoutRef } from 'react';
import { useContext, useRef, useEffect, useState } from 'react';
import { KeyboardContext } from '../Keyboard/Keyboard';
import { TrainingContext } from '../../HigherOrder/TrainingOverlay/TrainingOverlay';
import type { FieldValidator } from 'formik';
import { useField } from 'formik';
import styles from './AdvancedField.module.css';
import { getClass } from 'utilities/classnames';
import type { TooltipPlacement } from 'utilities/tooltip';
import { getTooltipClass, getTooltipTriggerClass } from 'utilities/tooltip';

export interface Props extends ComponentPropsWithoutRef<'input'> {
    name: string,
    validate?: FieldValidator,
    showLabel?: boolean,
    upperCase?: boolean,
    numeric?: boolean,
    tooltipPlacement?: TooltipPlacement,
    tooltipShown?: boolean,
    trainingFocused?: boolean,
    tooltip?: JSX.Element,
    trainingTooltip?: JSX.Element
}

const moveCaretAtEnd = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
    const length = currentTarget.value.length;
    currentTarget.setSelectionRange(length, length);
}

const numberRegex = new RegExp(/\D/g);

export default function AdvancedField({ name, validate, tooltipShown, numeric, className, upperCase, placeholder,
    trainingFocused, tooltip, trainingTooltip, onFocus, onBlur, onChange, showLabel, tooltipPlacement, ...rest }: Props) {

    const fieldRef = useRef<HTMLInputElement | null>(null);

    const [showing, setShowing] = useState(false);
    const [hiding, setHiding] = useState(false);

    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { handleInputFocus, handleInputBlur } = useContext(KeyboardContext);
    const [field, meta, helpers] = useField<string>({ name, validate });

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        handleInputFocus(!!numeric);
        onFocus?.(event);

        if (event.currentTarget.type === 'search') {
            moveCaretAtEnd(event);
        }
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        handleInputBlur();
        field.onBlur(event);
        onBlur?.(event);
    };

    const isTrainingFocused = isCurrentPageTraining && !!trainingFocused;
    const isErrored = !!meta.error && meta.touched;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const caretStart = event.currentTarget.selectionStart;
        const caretEnd = event.currentTarget.selectionEnd;

        if (numeric) {
            helpers.setValue(event.currentTarget.value.replace(numberRegex, ''));
        } else if (upperCase) {
            helpers.setValue(event.currentTarget.value.toUpperCase());
        } else {
            field.onChange(event);
        }
        onChange?.(event);

        event.currentTarget.setSelectionRange(caretStart, caretEnd);
    }

    useEffect(() => {
        if (isTrainingFocused) {
            fieldRef.current?.focus();
        }
    }, [isTrainingFocused]);

    const hasValue = !!field.value;

    useEffect(() => {
        if (showLabel) {
            if (hasValue) {
                const timeout = setTimeout(() => setShowing(true));
                return () => clearTimeout(timeout);
            } else {
                setShowing(false);
                setHiding(true);
                const timeout = setTimeout(() => setHiding(false), 200);
                return () => clearTimeout(timeout);
            }
        }
    }, [hasValue, showLabel]);

    return (
        <div className={getClass([
            styles.advancedField,
            getTooltipTriggerClass(isCurrentPageTraining ? !!trainingFocused : tooltipShown)
        ])}>
            {showLabel &&
                <label className={getClass([
                    styles.label,
                    showing && styles.showing,
                    hiding && styles.hiding
                ])}
                    htmlFor={name}>{placeholder}</label>
            }
            <input name={field.name}
                value={field.value}
                placeholder={placeholder}
                ref={fieldRef}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete='off'
                className={
                    getClass([
                        !!showLabel && styles.showLabel,
                        className ?? '',
                        isErrored && 'Error',
                        isTrainingFocused && 'Focused',
                        !!upperCase && styles.upperCase
                    ])
                } {...rest} />
            {((isTrainingFocused && !!trainingTooltip) || (!isTrainingFocused && !!tooltip)) &&
                <div className={getTooltipClass({ placement: tooltipPlacement })}>
                    {isTrainingFocused &&
                        trainingTooltip
                    }
                    {!isTrainingFocused &&
                        tooltip
                    }
                </div>
            }
        </div>
    );
}