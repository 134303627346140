import { useContext, useCallback } from 'react';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import styles from './MTUVideo.module.css';
import ReactPlayer from 'react-player';
import BasicPopup from '../BasicPopup/BasicPopup';

interface Props {
    closed: boolean,
    close: () => void
}

export default function UpgradePopup({ closed, close }: Props) {

    const { parms } = useContext(ParmContext);
    const { setClerkSetting } = useContext(SettingsContext);

    const handleWatched = useCallback(() => {
        setClerkSetting({ mtuVideoWatched: true });
        close();
    }, [close, setClerkSetting])

    return (
        <BasicPopup
            className={styles.mtuPopup}
            onClose={close}
            open={!closed}
            fadeIn>
            <ReactPlayer
                url={parms?.parameters.mtuVideo}
                onEnded={handleWatched}
                onError={close}
                width={854}
                height={480}
                controls={true}
                config={{
                    youtube: {
                        playerVars: {
                            autoplay: 1
                        }
                    },
                    vimeo: {
                        playerOptions: {
                            autoplay: true
                        }
                    }
                }} />
        </BasicPopup>
    );
}