import type { FocusEvent, KeyboardEvent, ChangeEvent, SyntheticEvent, ComponentPropsWithoutRef } from 'react';
import { useContext, useRef, useEffect } from 'react';
import { KeyboardContext } from '../Keyboard/Keyboard';
import { TrainingContext } from '../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { useFormikContext } from 'formik';
import { getTooltipClass, getTooltipTriggerClass } from 'utilities/tooltip';
import { getClass } from 'utilities/classnames';

interface Props extends ComponentPropsWithoutRef<'input'> {
    name: string,
    trainingFocused?: boolean,
    trainingTooltip?: JSX.Element,
    placement?: 'top' | 'bottom' | 'right' | 'left'
}

const moveCaretAtEnd = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
    const length = currentTarget.value.length;
    currentTarget.setSelectionRange(length, length);
}

const numberRegex = new RegExp(/\D/g);

const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const parsedValue = Number(event.currentTarget.value.replace(numberRegex, '') || '0');
    if (event.code === 'Backspace' && parsedValue === 0) {
        moveCaretAtEnd(event);
    }
}

export default function CashField({ name, className, placement, trainingFocused, trainingTooltip, ...rest }: Props) {

    const { handleInputFocus, handleInputBlur } = useContext(KeyboardContext);
    const { isCurrentPageTraining } = useContext(TrainingContext);
    const formik = useFormikContext<any>();

    const fieldRef = useRef<HTMLInputElement | null>(null);

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        handleInputFocus(true);
        moveCaretAtEnd(event);
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        formik.handleBlur(event);
        handleInputBlur();
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        const parsedValue = value.replace(numberRegex, '');
        formik.setFieldValue(name, parsedValue);
    }

    const parsedValue = Number(formik.values[name] || '0');
    const formattedValue = '$' + (parsedValue / 100).toFixed(2)

    const isTrainingFocused = isCurrentPageTraining && !!trainingFocused;

    useEffect(() => {
        if (isTrainingFocused) {
            fieldRef.current?.focus();
        }
    }, [isTrainingFocused]);

    return (
        <div className={getTooltipTriggerClass(isTrainingFocused)}>
            <input {...rest}
                name={name}
                ref={fieldRef}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                value={formattedValue}
                autoComplete="off"
                className={getClass([
                    className ?? '',
                    isTrainingFocused && 'Focused'
                ])}
                onKeyUp={handleKeyUp}
            />
            {!!trainingTooltip &&
                <div className={getTooltipClass({ placement })}>
                    {trainingTooltip}
                </div>
            }
        </div>
    );
}