import { useFormikContext, getIn } from 'formik';
import AdvancedField from '../AdvancedField/AdvancedField';
import type { Props as FieldProps } from '../AdvancedField/AdvancedField';

export default function ConfirmField({ name, placeholder, validate, ...rest }: FieldProps) {

    const { values } = useFormikContext();

    const validateMatch = (confirmValue: string) => {
        if (!confirmValue || getIn(values, name) !== confirmValue) {
            return `${placeholder} fields must match.`;
        }
    }

    const handleConfirmValidate = (value: string) =>
        validate?.(value) || validateMatch(value);

    return (
        <AdvancedField
            {...rest}
            name={`${name}Confirm`}
            placeholder={`Confirm ${placeholder}`}
            validate={handleConfirmValidate} />
    );
}