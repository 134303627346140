import type { Status } from '../Message/Message';
import Message from '../Message/Message';
import type { FormikErrors, FormikTouched } from 'formik';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';

const getStatus = (errors: FormikErrors<any>, touched: FormikTouched<any>): Status | undefined => {
    for (const [key, error] of Object.entries(errors)) {
        const touch = touched[key];
        if (!!touch) {
            if (typeof error === 'string') {
                return {
                    message: error,
                    messageType: 'error'
                }
            } else if (typeof error === 'object' && !Array.isArray(error) && typeof touch === 'object' && !Array.isArray(touch)) {
                const status = getStatus(error, touch);
                if (status) {
                    return status;
                }
            }
        }
    }
}

export default function MessageAggregator() {

    const { errors, touched } = useFormikContext();

    const status = useMemo(() => getStatus(errors, touched), [errors, touched])

    return (
        <Message status={status} />
    );
}