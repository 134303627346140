import { useContext, useState, useEffect, useCallback } from 'react';
import BasicPopup from '../BasicPopup/BasicPopup';
import NotificationList from './NotificationList';
import NotificationMessage from './NotificationMessage';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import styles from './NotificationPopup.module.css';
import { serverParse } from 'components/hooks/TimeZone';

interface RawPreview {
    id: number,
    preview: string,
    sentTime: string,
    unread: boolean
}

export interface Preview {
    id: number,
    preview: string,
    sentTime: Date,
    unread: boolean
}

export interface Message extends Preview {
    html: string,
    index: number
}

interface Props {
    onClose: () => void,
    open: boolean
}

export default function NotificationPopup({ onClose, open }: Props) {

    const { decrementUnreadMessages } = useContext(ParmContext);
    const { auth } = useContext(AuthContext);
    const { failoverFetch } = useContext(APIContext);
    const { loggedIn } = useContext(ParmContext);

    const [previews, setPreviews] = useState([] as Preview[]);
    const [message, setMessage] = useState(null as null | Message);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [loadingPreviews, setLoadingPreviews] = useState(false);
    const [showingAll, setShowingAll] = useState(false);

    const showAll = () => {
        setShowingAll(true);
        setMessage(null);
    }

    const getMessage = async (preview: Omit<Message, 'html'>) => {
        if (auth) {
            setLoadingMessage(true);
            setMessage({
                html: 'Loading Message...',
                ...preview,
            });
            try {
                const response = await failoverFetch('/Messages?' + new URLSearchParams({
                    type: 'message',
                    agentId: auth.agentId,
                    authToken: auth.authToken,
                    id: preview.id.toString()
                }));

                setMessage({
                    html: response,
                    ...preview,
                });

                if (preview.unread) {
                    decrementUnreadMessages();
                    setPreviews((currentPreviews) => {
                        const newPreviews = [...currentPreviews];
                        newPreviews[preview.index].unread = false;
                        return newPreviews;
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingMessage(false);
            }
        }
    }

    const goForward = () => {
        if (message) {
            const index = message.index + 1;
            getMessage({ ...previews[index], index: index });
        }
    }

    const goBackwards = () => {
        if (message) {
            const index = message.index - 1;
            getMessage({ ...previews[index], index: index });
        }
    }

    const getPreviews = useCallback(async () => {
        if (auth) {
            setLoadingPreviews(true);
            try {
                const response = await failoverFetch('/Messages?' + new URLSearchParams({
                    type: 'previews',
                    agentId: auth.agentId,
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as RawPreview[];
                const parsedPreviews = data.map((preview) => ({
                    ...preview,
                    sentTime: serverParse(preview.sentTime, 'MMM d, yyyy h:mm:ss a')
                }));
                setPreviews(parsedPreviews);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingPreviews(false);
            }
        }
    }, [auth, failoverFetch])

    useEffect(() => {
        if (loggedIn) {
            getPreviews();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    return (
        <BasicPopup
            className={styles.notificationPopup}
            onClose={onClose}
            open={open && loggedIn}
            darkMode noOverlay fadeIn noCenter>
            <div className={styles.title}>Messages</div>
            {(loadingPreviews || (!message && !loadingMessage)) &&
                <NotificationList
                    previews={previews}
                    loading={loadingPreviews}
                    getMessage={getMessage}
                    showingAll={showingAll}
                    showAll={showAll} />
            }
            {!!message &&
                <NotificationMessage
                    message={message}
                    loading={loadingMessage}
                    previews={previews}
                    goForward={goForward}
                    goBackwards={goBackwards}
                    showAll={showAll} />
            }
        </BasicPopup>
    )
}