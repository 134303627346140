import type { ChangeEvent } from 'react';
import { useState, useContext, useCallback } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import type { FormikContextType } from 'formik';
import { useFormikContext } from 'formik';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import VirtualizedTable from '../../../../Common/VirtualizedTable/VirtualizedTable';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { TrainingContext } from '../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { useBillPay } from 'components/hooks/BillPay';
import styles from './CustomerSearch.module.css';
import type { initialValues } from '../BillPay';
import { tutorialCustomer } from '../BillPay';
import { useDebounce, useEffectOnce, useUpdateEffect } from 'usehooks-ts';
import { tooltipBoldClass } from 'utilities/tooltip';

const columns = [{
    label: 'Phone',
    dataKey: 'phone',
    width: 100
}, {
    label: 'Address',
    dataKey: 'address1',
    width: 220,
    flexGrow: 1
}, {
    label: 'State',
    dataKey: 'state',
    width: 10
}, {
    label: 'Zip Code',
    dataKey: 'zip',
    width: 30
}, {
    label: 'E-mail',
    dataKey: 'email',
    width: 80,
    flexGrow: 1
}, {
    label: 'Name',
    dataKey: 'name',
    width: 200
}];

export interface CustomerPreview {
    active: string,
    address1: string,
    address2: string,
    city: string,
    email: string,
    id: string,
    name: string,
    phone: string,
    state: string,
    zip: string,
    billers?: CustomerBiller[]
}

interface CustomerAccount {
    ocr: string,
    acct: string
}

interface CustomerBiller {
    billerAccounts: CustomerAccount[]
    billerAddr: string,
    billerCity: string,
    billerId: number,
    billerName: string,
    billerState: string,
    billerZip: string,
    payType: string,
}

interface Props {
    onSearchChange: (newSearch: string, formik: FormikContextType<any>) => void,
    onSearchEvent: (event: ChangeEvent<HTMLInputElement>) => void,
    isCustomerSelectionTutorial: boolean
}

export default function CustomerSearch({ onSearchChange, onSearchEvent, isCustomerSelectionTutorial }: Props) {

    const { search, pathname } = useLocation();
    const { push } = useHistory();
    const formik = useFormikContext<typeof initialValues>();
    const customerSearch = useDebounce(formik.values.customerSearch, 250);
    const { auth } = useContext(AuthContext);
    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { customerSearchRequest, getCustomerBillersRequest } = useBillPay();

    const [customers, setCustomers] = useState<CustomerPreview[]>([]);
    const [loadingBillers, setLoadingBillers] = useState(false);
    const [loadingCustomers, setLoadingCustomers] = useState(false);

    const getCustomers = useCallback(async () => {
        setCustomers([]);
        setLoadingCustomers(true);
        try {
            const response = await customerSearchRequest(customerSearch);
            if (response) {
                if (isCurrentPageTraining && tutorialCustomer.phone.includes(customerSearch)) {
                    response.customerInfo.push(tutorialCustomer);
                }
                setCustomers(response.customerInfo);
            }
        } catch (error) {

        } finally {
            setLoadingCustomers(false);
        }
    }, [customerSearch, customerSearchRequest, isCurrentPageTraining])

    const firstSegment = pathname.split('/')[1];

    const getCustomerBillers = async (customer: CustomerPreview) => {
        if (auth) {
            setLoadingBillers(true);
            try {
                const response = await getCustomerBillersRequest(customer);
                if (customer.id !== tutorialCustomer.id) {
                    customer.billers = response;
                }
                push(`/${firstSegment}`);
                formik.setFieldValue('originalCustomer', { ...customer });
                formik.setFieldValue('customer', customer);
                formik.setFieldValue('customerSearch', '');
                formik.setFieldValue('accountNum', '');
                formik.setFieldValue('accountNumConfirm', '');
                formik.setFieldValue('billStubImage', null);
                formik.setFieldValue('cash', '0');
                formik.setFieldValue('checkInput', '0');
                formik.setFieldValue('checks', []);
            } catch (error) {
                console.error(error);
                formik.setStatus({ message: 'Failed to load customer information!', messageType: 'error' });
            }
        } else {
            setLoadingBillers(false);
        }
    }

    const handleRowClick = (rowData: CustomerPreview) => {
        if (!loadingBillers) {
            getCustomerBillers(rowData);
        }
    }

    useEffectOnce(() => {
        const searchParams = new URLSearchParams(search);
        const urlCustomerSearch = searchParams.has('search') ? decodeURIComponent(searchParams.get('search')!) : customerSearch;
        if (urlCustomerSearch !== customerSearch) {
            onSearchChange(urlCustomerSearch, formik);
            formik.setFieldValue('customerSearch', urlCustomerSearch);
        } else {
            getCustomers();
        }
    });

    useUpdateEffect(() => {
        getCustomers();
    }, [getCustomers]);

    return (
        <div>
            <div className={styles.searchFormControl}>
                <label className={styles.searchLabel}>Customer Search</label>
                <AdvancedField name='customerSearch'
                    type='search'
                    onChange={onSearchEvent}
                    placeholder='SEARCH CUSTOMER INFO'
                    autoFocus={true}
                    upperCase={true}
                    maxLength={35}
                    trainingFocused={isCustomerSelectionTutorial}
                    trainingTooltip={
                        <>
                            <div>SEARCH FOR THE CUSTOMER</div>
                            <b className={tooltipBoldClass}>{tutorialCustomer.phone}</b>
                        </>
                    }
                    tooltipPlacement='top' />
            </div>
            <VirtualizedTable
                alwaysFocused={true}
                data={customers}
                loading={loadingCustomers || loadingBillers}
                columns={columns}
                onRowClick={handleRowClick}
                trainingRow={tutorialCustomer}
                trainingFocused={isCustomerSelectionTutorial}
                trainingTooltip={
                    <>
                        <div>SELECT THE CUSTOMER</div>
                        <b className={tooltipBoldClass}>{tutorialCustomer.name}</b>
                    </>
                }
            />
        </div>
    )
}