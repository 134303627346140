import type { ChangeEvent, FocusEvent, ComponentPropsWithoutRef } from 'react';
import { useContext, useRef, useEffect } from 'react';
import { TrainingContext } from '../../HigherOrder/TrainingOverlay/TrainingOverlay';
import type { FieldValidator } from 'formik';
import { useField } from 'formik';
import { getClass } from 'utilities/classnames';
import styles from '../AdvancedField/AdvancedField.module.css';
import type { TooltipPlacement } from 'utilities/tooltip';
import { getTooltipClass, getTooltipTriggerClass } from 'utilities/tooltip';

interface Props extends ComponentPropsWithoutRef<'select'> {
    name: string,
    validate?: FieldValidator,
    tooltipPlacement?: TooltipPlacement,
    tooltipShown?: boolean,
    trainingFocused?: boolean,
    tooltip?: JSX.Element,
    trainingTooltip?: JSX.Element,
    showLabel?: boolean
}

export default function Select({ children, name, validate, tooltipShown, className, showLabel, placeholder,
    trainingFocused, tooltip, trainingTooltip, onBlur, onChange, tooltipPlacement, ...rest }: Props) {

    const fieldRef = useRef<HTMLSelectElement | null>(null);

    const { isCurrentPageTraining } = useContext(TrainingContext);
    const [field, meta] = useField<string>({ name, validate });

    const handleBlur = (event: FocusEvent<HTMLSelectElement>) => {
        field.onBlur(event);
        onBlur?.(event);
    };

    const isTrainingFocused = isCurrentPageTraining && !!trainingFocused;
    const isErrored = !!meta.error && meta.touched;

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        field.onChange(event);
        onChange?.(event);
    }

    useEffect(() => {
        if (isTrainingFocused) {
            fieldRef.current?.focus();
        }
    }, [isTrainingFocused]);

    return (
        <div className={getClass([
            styles.advancedField,
            getTooltipTriggerClass(isCurrentPageTraining ? !!trainingFocused : tooltipShown)
        ])}>
            {showLabel &&
                <label className={getClass([
                    styles.label,
                    styles.showing
                ])}
                    htmlFor={name}>{placeholder}</label>
            }
            <select name={name}
                ref={fieldRef}
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete='off'
                placeholder={placeholder}
                className={
                    getClass([
                        !!showLabel && styles.showLabel,
                        className ?? '',
                        isErrored && 'Error',
                        isTrainingFocused && 'Focused'
                    ])
                } {...rest} >
                {children}
            </select>
            {((isTrainingFocused && !!trainingTooltip) || (!isTrainingFocused && !!tooltip)) &&
                <div className={getTooltipClass({ placement: tooltipPlacement })}>
                    {isTrainingFocused &&
                        trainingTooltip
                    }
                    {!isTrainingFocused &&
                        tooltip
                    }
                </div>
            }
        </div>
    );
}