import LeftArrow from '../../../../images/phnLeft.png';
import RightArrow from '../../../../images/phnRight.png';
import NewIcon from '../../../../images/phnNew.svg';
import type { Message, Preview } from './NotificationPopup';
import styles from './NotificationPopup.module.css';
import { useTimeZone } from 'components/hooks/TimeZone';

interface Props {
    message: Message,
    loading: boolean,
    previews: Preview[],
    goForward: () => void,
    goBackwards: () => void,
    showAll: () => void
}

export default function NotificationMesage({ message, loading, previews, goForward, goBackwards, showAll }: Props) {
    const { localFormat } = useTimeZone();
    return (
        <div className={styles.notificationMessage}>
            <div className={styles.messageWindow} />
            <div className={styles.messageContainer}  >
                <div className={styles.messageContents} dangerouslySetInnerHTML={{ __html: message.html }} />
                <div className={styles.messageContentsFooter}>
                    {!loading &&
                        <>
                            <span className={styles.footerDate}>{localFormat(message.sentTime, 'MM/dd/yy')}</span>
                            {message.unread &&
                                <img className={styles.newIcon + ' ' + styles.footerImage} src={NewIcon} alt="New" />
                            }
                        </>
                    }
                </div>
            </div>
            <div className={styles.messageControlFooter}>
                {message.index > 0 &&
                    <img src={LeftArrow} className={styles.leftArrow + ' ' + styles.controlFooterImage} alt='Left' onClick={goBackwards} />
                }
                <button className={styles.button} type='button' onClick={showAll}>See all</button>
                {message.index < previews.length - 1 &&
                    <img src={RightArrow} className={styles.rightArrow + ' ' + styles.controlFooterImage} alt='Right' onClick={goForward} />
                }
            </div>
        </div>
    );
}