import { useState, useContext, useMemo } from "react";
import PHNSideLogo from '../../../../images/phnSideLogo.png';
import PHNLogo from '../../../../images/phnLogo.png';
import { ParmContext } from "components/HigherOrder/ParmController/ParmController";
import PromotionImage from "./PromotionImage";
import styles from './Sidebar.module.css';
import { useInterval } from "usehooks-ts";

const getNextIndicies = (currentIndex: number, length: number) => {
    const indicies = [];
    for (let i = 1; i <= Math.min(3, length); i++) {
        indicies.push((currentIndex + i) % length);
    }
    return indicies;
}

const shufflePromotions = (promotions: number[]) => {
    const newPromotions = [...promotions];
    for (let i = 0; i < newPromotions.length - 1; i++) {
        const min = i + 1;
        const max = newPromotions.length;
        const randomIndex = Math.floor(Math.random() * (max - min)) + min;
        const temp = newPromotions[i];
        newPromotions[i] = newPromotions[randomIndex];
        newPromotions[randomIndex] = temp;
    }
    return newPromotions;
}

export default function SidebarBanner() {

    const { parms } = useContext(ParmContext);
    const promotions = useMemo(() =>
        shufflePromotions(parms?.promotions ?? [])
        , [parms?.promotions]);

    const [currentIndices, setCurrentIndices] = useState(getNextIndicies(-1, promotions.length));
    const currentIndex = currentIndices[currentIndices.length - 1] ?? -1;
    const currentPromotions = currentIndices.map(index => promotions[index]);

    const nextIndicies = getNextIndicies(currentIndex, promotions.length);

    const interval = promotions.length > 0 ? 1000 * 15 : null;
    useInterval(() => setCurrentIndices(nextIndicies), interval);

    return (
        <>
            {currentPromotions.length > 0 &&
                <div className={styles.bannerImageContainer}>
                    <div className={styles.bannerImageHeader}>
                        <div className={styles.bannerImageLogoContainer}>
                            <img src={PHNLogo} alt={'PayHereNetwork'} />
                        </div>
                        <span>Now Accepting<br />Payments for:</span>
                    </div>
                    <div className={styles.bannerImageList} >
                        {promotions.map((promotion, index) =>
                            <PromotionImage
                                key={promotion}
                                promotion={promotion}
                                current={currentIndices.includes(index)} />
                        )}
                    </div>
                </div>
            }
            {currentPromotions.length === 0 &&
                <div className={`${styles.bannerImageContainer} ${styles.single}`}>
                    <img src={PHNSideLogo} alt={'PayHereNetwork'} />
                </div>
            }
        </>
    );
}