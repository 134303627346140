import { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import Message from '../../../../Common/Message/Message';
import AdvancedForm from '../../../../Common/AdvancedForm/AdvancedForm';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { Field } from 'formik';
import type { FormikHelpers, FormikProps } from 'formik';
import { PrinterContext } from '../../../../HigherOrder/PrintController/PrintController';
import { SettingsContext } from '../../../../HigherOrder/SettingsController/SettingsController';
import type { PrinterInfo } from 'electron';
import { supportsUSBPrinting, isElectron } from 'utilities/Environment';
import { getPrinterKey } from 'components/HigherOrder/PrintController/ThermalPrint';
import type { Helper } from 'components/HigherOrder/PrintController/HelperPrint';
import { handleFetchErrors } from 'utilities/network';

const getPrintersElectron = () => {
    return require('electron').ipcRenderer.invoke('getPrinters') as Promise<PrinterInfo[]>;
}

const getPrintersHelper = async () => {
    const response = await fetch('http://localhost:9999/printers').then(handleFetchErrors);
    const data = await response.json() as PrinterInfo[];
    return data;
}

const setHelperSettings = async (values: Helper) =>
    fetch('http://localhost:9999/printers', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: { "Content-Type": "application/json" }
    }).then(handleFetchErrors)


/**
 * Page for working with Printer Settings.
 * Currently has functionality for both the Printer Helper, and running in Electron.
 * Printer Helper functionality to eventually be removed.
 */
export default function PrinterSettings() {

    const [electronPrinters, setElectronPrinters] = useState<PrinterInfo[]>([]);

    const { helper, setHelper, allowedPrinters, requestPrinter } = useContext(PrinterContext);
    const hasHelper = !!helper;
    const { settings, setSetting } = useContext(SettingsContext);

    const handleSubmit = async (values: Helper, { setStatus, setSubmitting }: FormikHelpers<typeof initialValues>) => {
        setStatus({ message: "Saving..." });
        try {
            if (helper) {
                await setHelperSettings(values);
                setHelper((currentHelper) => ({
                    ...currentHelper, ...values
                }));
            }
            setSetting({ ...values });
            setStatus({ message: "Settings Saved Successfully." });
        } catch (error) {
            console.error(error);
            setStatus({ message: "Failed to Save Settings.", messageType: "error" });
        } finally {
            setSubmitting(false);
        }
    }

    const getPrinters = useCallback(async () => {
        if (isElectron || hasHelper) {
            try {
                const response = await (isElectron ? getPrintersElectron() : getPrintersHelper());
                setElectronPrinters(response);
            } catch (error) {
                console.error(error);
            }
        }
    }, [hasHelper])

    const electronPrinterOptions = useMemo(() =>
        electronPrinters.map((printer) =>
            <option key={printer.name} value={printer.name} >
                {printer.name}
            </option>
        )
        , [electronPrinters]);

    const thermalPrinterOptions = useMemo(() =>
        allowedPrinters.map((printer) =>
            <option key={getPrinterKey(printer)} value={getPrinterKey(printer)} >
                {printer.productName ?? 'Thermal Printer'}
            </option>
        )
        , [allowedPrinters]);

    const initialValues = helper ?? {
        version: 'N/A',
        receiptPrinter: settings.receiptPrinter,
        reportPrinter: settings.reportPrinter,
        printDuplicate: settings.printDuplicate
    };

    useEffect(() => {
        getPrinters();
    }, [getPrinters]);

    const requestReceiptPrinter = async () => {
        try {
            await requestPrinter('Receipt');
        } catch (error) {
            console.error(error);
        }
    }

    const requestReportPrinter = async () => {
        try {
            await requestPrinter('Report');
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <AdvancedForm className='PrinterSettings' initialValues={initialValues} onSubmit={handleSubmit} noKeyboard={true} >
            {(props: FormikProps<typeof initialValues>) =>
                <>
                    <h1>ALMOST DONE!</h1>
                    <h3>Select your printer and you are all set.</h3>
                    <div>
                        <label>Receipt Printer</label>
                        <Field component='select' name='receiptPrinter' required >
                            {supportsUSBPrinting &&
                                <option value={'BROWSER'} >
                                    Browser Default / Other
                                </option>
                            }
                            {electronPrinterOptions}
                            {thermalPrinterOptions}
                        </Field>
                    </div>
                    {supportsUSBPrinting &&
                        <div>
                            <button onClick={requestReceiptPrinter}>Search for receipt printers</button>
                        </div>
                    }
                    <div>
                        <label>Report Printer</label>
                        <Field component='select' name='reportPrinter' required >
                            {supportsUSBPrinting &&
                                <option value={'BROWSER'} >
                                    Browser Default / Other
                                </option>
                            }
                            {electronPrinterOptions}
                            {thermalPrinterOptions}
                        </Field>
                    </div>
                    {supportsUSBPrinting &&
                        <div>
                            <button onClick={requestReportPrinter}>Search for report printers</button>
                        </div>
                    }
                    <div>
                        <label>Print Duplicate Receipt On Transactions</label>
                        <Field type='checkbox' name='printDuplicate' checked={props.values?.printDuplicate} />
                    </div>
                    <div>
                        <AdvancedButton>Save</AdvancedButton>
                    </div>
                    <Message />
                </>
            }
        </AdvancedForm>
    );
}