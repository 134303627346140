import { useEffect, useContext, useState, useMemo } from 'react';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import Message, { Status } from '../../../Common/Message/Message';
import { Link } from 'react-router-dom';
import { restartApp, restartPC } from '../../../../utilities/Tools';
import styles from './System.module.css';
import type { Systeminformation } from 'systeminformation';
import { browser, cpu, isElectron, isLinux, isWindows, os } from 'utilities/Environment';

const getSoftwareType = () => {
    return isElectron ? 'Electron' : 'Web';
}

const shutdownPC = () => {
    if (isElectron) {
        const { promisify } = require('util');
        const exec = promisify(require('child_process').exec);
        if (isWindows) {
            exec('shutdown /s /f');
        } else if (isLinux) {
            exec('shutdown -h now');
        }
    }
}

interface DynamicData {
    currentLoad: Systeminformation.CurrentLoadData,
    mem: Systeminformation.MemData
}

/**
 * Page for all things System related.
 * Mostly to help CE troubleshoot. 
 */
export default function System() {

    const [dynamicData, setDynamicData] = useState(null as null | DynamicData);
    const [loading, setLoading] = useState(false);
    const [downloadingConfig, setDownloadingConfig] = useState(false);
    const [status, setStatus] = useState<Status>();

    const { loggedIn } = useContext(ParmContext);
    const { getParameters } = useContext(APIContext);

    const loadSystemInformation = () => {
        loadDynamicInformation();
    }

    const loadDynamicInformation = async () => {
        setLoading(true);
        const [currentLoad, mem] = await require('electron').ipcRenderer.invoke('getDynamicData') as [Systeminformation.CurrentLoadData, Systeminformation.MemData];
        setDynamicData({
            currentLoad: currentLoad,
            mem: mem,
        });
        setLoading(false);
    }

    const memoryPercent = useMemo(() => {
        if (dynamicData) {
            return ((dynamicData.mem.used / dynamicData.mem.total) * 100).toFixed(0) + '%';
        }
        return 'N/A';
    }, [dynamicData])

    const CPUPercent = useMemo(() => {
        if (dynamicData) {
            return dynamicData.currentLoad.currentLoad.toFixed(0) + '%';
        }
        return 'N/A';
    }, [dynamicData])

    const handleConfigDownload = async () => {
        setStatus({ message: "Downloading Configuration..." });
        setDownloadingConfig(true);
        try {
            await getParameters();
            setStatus({ message: "Configuration Download Successfully" });
        } catch (error) {
            console.error(error);
            setStatus({ message: "Failed to Download Configuration", messageType: "error" });
        } finally {
            setDownloadingConfig(false);
        }
    }

    useEffect(() => {
        if (isElectron) {
            loadSystemInformation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.system}>
            <Message status={status} />
            <div className={styles.systemBody} >
                <fieldset className={styles.info}>
                    <div>
                        <label>Operating System</label>
                        <input value={os.name + ' ' + os.version} disabled />
                    </div>
                    <div>
                        <label>Arch</label>
                        <input value={cpu.architecture} disabled />
                    </div>
                    <div>
                        <label>Memory Usage</label>
                        <input value={memoryPercent} disabled />
                    </div>
                    <div>
                        <label>CPU Usage</label>
                        <input value={CPUPercent} disabled />
                    </div>
                    <div>
                        <label>SW Type</label>
                        <input value={getSoftwareType()} disabled />
                    </div>
                    <div>
                        <label>Platform Name</label>
                        <input value={browser.name + ' ' + browser.version} disabled />
                    </div>
                    {isElectron &&
                        <div>
                            <button type='button' disabled={loading} onClick={loadDynamicInformation}>Refresh</button>
                        </div>
                    }
                </fieldset>
                <fieldset className='Links'>
                    <button type='button' onClick={restartApp}>Restart App</button>
                    <button type='button' onClick={restartPC} disabled={!isElectron}>Restart PC</button>
                    <button type='button' onClick={shutdownPC} disabled={!isElectron}>Shutdown PC</button>
                    <Link to="/AdminLogin">Reset Configuration</Link>
                    <button type='button' onClick={handleConfigDownload} disabled={downloadingConfig || !loggedIn}>Download Configuration</button>
                    <Link to="/Network">Network</Link>
                </fieldset>
            </div>
        </div>
    );
}