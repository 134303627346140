import type { ChangeEvent } from 'react';
import { useContext, useState } from 'react';
import { differenceInYears } from 'date-fns';
import Message from '../../../Common/Message/Message';
import AdvancedField from '../../../Common/AdvancedField/AdvancedField';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import AgeVerifiedPopup from './AgeVerifiedPopup/AgeVerifiedPopup';
import AdvancedButton from '../../../Common/AdvancedButton/AdvancedButton';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { focusNextElement } from '../../../../utilities/Tools';
import styles from './AgeVerification.module.css';
import type { FormikHelpers, FormikProps } from 'formik';
import { tooltipBoldClass } from 'utilities/tooltip';

const tutorialDL = '12345678';
const tutorialMonth = '11';
const tutorialDay = '12';
const tutorialYear = '1980';

export const initialValues = {
    driversLicense: '',
    month: '',
    day: '',
    year: ''
};

const getAge = ({ month, day, year }: typeof initialValues) =>
    differenceInYears(new Date(), new Date(Number(year), Number(month) - 1, Number(day)));

const handleDayChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (Number(value) > 3 || value.length === 2) {
        focusNextElement();
    }
}

const handleMonthChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (Number(value) > 1 || value.length === 2) {
        focusNextElement();
    }
}

export default function AgeVerification() {

    const [ageVerifiedPopupClosed, setAgeVerifiedPopupClosed] = useState(true);

    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { setTrainingSetting } = useContext(SettingsContext);
    const { failoverFetch } = useContext(APIContext);
    const { auth } = useContext(AuthContext);

    const handleSubmit = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
        if (isNaN(Date.UTC(Number(values.year), Number(values.month), Number(values.day)))) {
            actions.setStatus({ message: 'Invalid Date', messageType: 'error' });
            actions.setSubmitting(false);
        } else if (isCurrentPageTraining) {
            setTrainingSetting({ ageVerif: true });
        } else if (auth) {
            try {
                const response = await failoverFetch('/AgeVerif?' + new URLSearchParams({
                    agent: auth.agentId,
                    dl: values.driversLicense,
                    dob: values.year + values.month + values.day,
                    cleckid: auth.username,
                    authToken: auth.authToken
                }));

                if (response.includes('Success')) {
                    setAgeVerifiedPopupClosed(false);
                } else {
                    actions.setStatus({ message: 'Failed to verify age.', messageType: 'error' });
                }
            } catch (error) {
                actions.setStatus({ message: 'An unexpected error occurred while saving the verification to the server.', messageType: 'error' });
                console.error(error);
            } finally {
                actions.setSubmitting(false);
            }
        }
    }

    return (
        <AdvancedForm
            className={styles.ageVerification}
            initialValues={initialValues}
            onSubmit={handleSubmit} >
            {({ values, setValues }: FormikProps<typeof initialValues>) => {

                const isDLTutorial = values.driversLicense !== tutorialDL;

                const isMonthTutorial = !isDLTutorial
                    && values.month !== tutorialMonth;

                const isDayTutorial = !isDLTutorial && !isMonthTutorial
                    && values.day !== tutorialDay;

                const isYearTutorial = !isDLTutorial && !isMonthTutorial && !isDayTutorial
                    && values.year !== tutorialYear;

                const isButtonTutorial = !isDLTutorial && !isMonthTutorial && !isDayTutorial && !isYearTutorial;

                const onClose = () => {
                    setAgeVerifiedPopupClosed(true);
                    setValues(initialValues);
                }

                return (
                    <>
                        <AgeVerifiedPopup
                            age={getAge(values)}
                            onClose={onClose}
                            open={!ageVerifiedPopupClosed} />
                        <div className={styles.ageForm}>
                            <AdvancedField
                                name='driversLicense'
                                placeholder="DRIVER'S LICENSE #"
                                maxLength={20}
                                autoFocus={true}
                                trainingFocused={isDLTutorial}
                                trainingTooltip={
                                    <>
                                        <div>ENTER THIS DRIVER'S LICENSE</div>
                                        <b className={tooltipBoldClass}>{tutorialDL}</b>
                                    </>
                                }
                                required />
                            <AdvancedField
                                name='month'
                                placeholder='MM'
                                className={styles.month}
                                maxLength={2}
                                trainingFocused={isMonthTutorial}
                                trainingTooltip={
                                    <>
                                        <div>ENTER THIS BIRTH MONTH</div>
                                        <b className={tooltipBoldClass}>{tutorialMonth}</b>
                                    </>
                                }
                                onChange={handleMonthChange}
                                required />
                            <AdvancedField
                                name='day'
                                placeholder='DD'
                                className={styles.day}
                                maxLength={2}
                                trainingFocused={isDayTutorial}
                                trainingTooltip={
                                    <>
                                        <div>ENTER THIS BIRTH DAY</div>
                                        <b className={tooltipBoldClass}>{tutorialDay}</b>
                                    </>
                                }
                                onChange={handleDayChange}
                                required />
                            <AdvancedField
                                name='year'
                                placeholder='YYYY'
                                className={styles.year}
                                maxLength={4}
                                trainingFocused={isYearTutorial}
                                trainingTooltip={
                                    <>
                                        <div>ENTER THIS BIRTH YEAR</div>
                                        <b className={tooltipBoldClass}>{tutorialYear}</b>
                                    </>
                                }
                                required />
                        </div>
                        <div className={styles.ageButton}>
                            <AdvancedButton
                                trainingFocused={isButtonTutorial}
                                trainingTooltip={
                                    <>
                                        <div>YOU ARE READY</div>
                                        <b className={tooltipBoldClass}>CLICK SUBMIT</b>
                                    </>
                                }
                                tooltipPlacement='left'>Submit</AdvancedButton>
                        </div>
                        <Message />
                    </>
                )
            }}
        </AdvancedForm>
    );
}