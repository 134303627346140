import { useContext, useMemo, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useFormikContext } from 'formik';
import VirtualizedTable from '../../../../Common/VirtualizedTable/VirtualizedTable';
import type { TableCellDataGetterParams } from '../../../../Common/VirtualizedTable/VirtualizedTable';
import { useAgeVerifPrinter } from './AgeVerifPrinter';
import { useReportPrinter } from './ReportPrinter';
import { useMTUPrinter } from '../../../../Common/Receipts/MTUPrinter';
import { useBillPayPrinter } from '../../../../Common/Receipts/BillPayPrinter';
import TransactionVoider from './TransactionVoider';
import { useSummaryPrinter } from './SummaryPrinter';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { SystemContext } from '../../../../HigherOrder/SystemConroller/SystemController';
import { useReportExporter } from './ReportExporter';
import { isMoneyOrderTransaction } from '../Reports';
import type { ParsedAgeVerification, ParsedTransaction, User, FormValues } from '../Reports';
import { AuthContext } from 'components/HigherOrder/AuthController/AuthController';
import { APIContext } from 'components/HigherOrder/APIController/APIController';
import { useGenericPrinter } from 'components/Common/Receipts/GenericPrinter';
import styles from './ReportTable.module.css';

interface Props {
    data: ParsedTransaction[] | ParsedAgeVerification[],
    setRow: Dispatch<SetStateAction<ParsedAgeVerification | ParsedTransaction | null>>,
    row: ParsedTransaction | ParsedAgeVerification | null,
    getUser: (clerkId: number) => User | undefined,
    searched: boolean
}

const emptyTotals = {
    cashTotal: '0.00',
    checkTotal: '0.00',
    processFeeTotal: '0.00',
    agentFeeTotal: '0.00',
    allFeeTotal: '0.00',
    registerAmount: '0.00',
    grandTotal: '0.00',
    depositAmount: '0.00'
}

export type TransactionTotals = typeof emptyTotals;

const getTotals = (transactions: ParsedTransaction[]) => {
    const totals = transactions.reduce((accumulator, { cashAmt, checkAmt, processFee, storeFee }) => {
        accumulator.cashAmt += (Number(cashAmt) * 100);
        accumulator.checkAmt += (Number(checkAmt) * 100);
        accumulator.processFee += (Number(processFee) * 100);
        accumulator.storeFee += (Number(storeFee) * 100);
        return accumulator;
    }, { cashAmt: 0, checkAmt: 0, processFee: 0, storeFee: 0 });

    return {
        cashTotal: (totals.cashAmt / 100).toFixed(2),
        checkTotal: (totals.checkAmt / 100).toFixed(2),
        processFeeTotal: (totals.processFee / 100).toFixed(2),
        agentFeeTotal: (totals.storeFee / 100).toFixed(2),
        allFeeTotal: ((totals.processFee + totals.storeFee) / 100).toFixed(2),
        registerAmount: ((totals.cashAmt + totals.processFee + totals.storeFee) / 100).toFixed(2),
        grandTotal: ((totals.checkAmt + totals.cashAmt + totals.processFee + totals.storeFee) / 100).toFixed(2),
        depositAmount: ((totals.cashAmt + totals.processFee) / 100).toFixed(2)
    } as TransactionTotals
};

const ageVerifColumns = [{
    label: 'Clerk Name',
    dataKey: 'clerkName'
}, {
    label: 'DL#',
    dataKey: 'dlNum'
}, {
    label: 'DOB',
    dataKey: 'dob'
}, {
    label: 'Age',
    dataKey: 'age'
}, {
    label: 'Report Date',
    dataKey: 'reportDate'
}, {
    label: 'Report Time',
    dataKey: 'reportTime'
}];

const transactionColumnsStart = [{
    label: 'Type',
    dataKey: 'dercd',
    width: 10
}, {
    label: 'Receipt#',
    dataKey: 'trace',
    width: 100
}, {
    label: 'Biller',
    id: 'billerName',
    dataKey: 'N/A',
    cellRenderer: ({ rowData }: TableCellDataGetterParams) =>
        rowData.billerName ? rowData.billerName : rowData.billerId
    ,
    width: 250,
    flexGrow: 2
}, {
    label: 'Cash',
    dataKey: 'cashAmt',
    width: 100,
    flexGrow: 1
}];

const transactionColumnsEnd = [{
    label: 'Proc',
    dataKey: 'processFee',
    width: 50,
    flexGrow: 1
}, {
    label: 'Agent',
    dataKey: 'storeFee',
    width: 50,
    flexGrow: 1
}, {
    label: 'Date',
    dataKey: 'transDate',
    width: 80
}, {
    label: 'Time',
    dataKey: 'transTime',
    width: 50
}, {
    label: 'Clerk',
    dataKey: 'clerkName',
    width: 50
}];

const checkColumns = [{
    label: 'Check',
    dataKey: 'checkAmt',
    width: 100,
    flexGrow: 1
}];

const cashTransactionColumns = [
    ...transactionColumnsStart,
    ...transactionColumnsEnd
];

const checkTransactionColumns = [
    ...transactionColumnsStart,
    ...checkColumns,
    ...transactionColumnsEnd
];

const emptyData = [] as ParsedAgeVerification[] | ParsedTransaction[];

export default function ReportTable({ data, setRow, row, getUser, searched }: Props) {

    const { values: { type }, submitForm, isSubmitting } = useFormikContext<FormValues>();

    const [printing, setPrinting] = useState(false);
    const [voiding, setVoiding] = useState(false);

    const { system: { isPOS } } = useContext(SystemContext);
    const { parms } = useContext(ParmContext);
    const hasParms = !!parms;
    const { auth } = useContext(AuthContext);
    const { failoverFetch } = useContext(APIContext);
    const { printAgeVerif } = useAgeVerifPrinter();
    const { printReport } = useReportPrinter();
    const { printTransaction: printGiftCardTransaction } = useMTUPrinter('GIFTCARD');
    const { printTransaction: printTopupTransaction } = useMTUPrinter('TOPUP');
    const { printTransaction: printBillPayTransaction } = useBillPayPrinter(false);
    const { printSummary } = useSummaryPrinter(getUser);
    const { genericPrint } = useGenericPrinter();

    const ageVerifs = type === 'ageVerif' ? data as ParsedAgeVerification[] : emptyData as ParsedAgeVerification[];
    const transactions = type !== 'ageVerif' ? data as ParsedTransaction[] : emptyData as ParsedTransaction[];
    const transaction = type !== 'ageVerif' ? row as ParsedTransaction : null;

    const containsCheckTransaction = useMemo(() =>
        transactions.some(({ paymentType }) =>
            paymentType.trimEnd() === 'CHK'
        )
        , [transactions]);

    const columns = type === 'ageVerif' ? ageVerifColumns
        : containsCheckTransaction ? checkTransactionColumns
            : cashTransactionColumns;

    const getReceipt = async () => {
        if (auth && hasParms && transaction) {
            try {
                const response = await failoverFetch('/getReceipt?' + new URLSearchParams({
                    tracNum: transaction.trace,
                    authToken: auth.authToken,
                }));
                return response;
            } catch (error) {
                console.error(error);
            }
        }
    }

    const printRegenReceipt = async () => {
        if (transaction) {
            if (transaction.product === 'BP') {
                return printBillPayTransaction(transaction, transactions);
            } else if (transaction.product === 'PPM') {
                return printTopupTransaction(transaction);
            } else if (transaction.product === 'GFT') {
                return printGiftCardTransaction(transaction);
            }
        }
    };

    const handlePrintReceipt = async () => {
        setPrinting(true);
        try {
            const receipt = await getReceipt();
            if (receipt && !receipt.toLowerCase().includes('not found')) {
                await genericPrint(receipt);
            } else {
                await printRegenReceipt();
            }
        } catch (error) {
            await printRegenReceipt();
        } finally {
            setPrinting(false);
        }
    }

    const totals = useMemo(() =>
        type === 'ageVerif' ? emptyTotals
            : getTotals(transactions)
        , [transactions, type]);

    const handlePrintReport = async () => {
        setPrinting(true);
        try {
            if (type === 'ageVerif') {
                await printAgeVerif(ageVerifs);
            } else {
                await printReport(transactions, totals, containsCheckTransaction);
            }
        } finally {
            setPrinting(false);
        }
    }

    const handlePrintSummary = async () => {
        setPrinting(true);
        try {
            await printSummary(totals, containsCheckTransaction);
        } finally {
            setPrinting(false);
        }
    }

    const handleVoidTransaction = async () => {
        setVoiding(true);
    }

    const onVoidingComplete = () => {
        setVoiding(false);
        submitForm();
    }

    const { csvTitle, csvURL } = useReportExporter({ transactions, totals, containsCheckTransaction });

    return (
        <div>
            <div>
                {searched && type !== 'voidable' &&
                    <>
                        <button type='button' onClick={handlePrintReport} disabled={printing || voiding}>Print Report</button>
                        {type !== 'ageVerif' &&
                            <>
                                {!isPOS &&
                                    <a className='Button' href={csvURL} download={csvTitle} >Export Report</a>
                                }
                                <button type='button' onClick={handlePrintSummary} disabled={printing || voiding}>Print Summary</button>
                            </>
                        }
                    </>
                }
                {transaction && !isMoneyOrderTransaction(transaction) && type !== 'voidable' && type !== 'ageVerif' &&
                    <button type='button' disabled={printing || voiding} onClick={handlePrintReceipt}>Print Receipt</button>
                }
                {transaction && type === 'voidable' && parms?.clerkInfo.type !== 'L' &&
                    <button
                        type='button'
                        disabled={printing || voiding}
                        onClick={handleVoidTransaction}>Void Transaction</button>
                }
                {voiding && transaction &&
                    <TransactionVoider
                        transaction={transaction}
                        onVoidingComplete={onVoidingComplete} />
                }
            </div>
            <VirtualizedTable
                data={data}
                selectedData={transaction}
                loading={isSubmitting}
                columns={columns}
                onRowClick={setRow}
                height={370}
            />
            <VirtualizedTable
                className={styles.reportTableFooter}
                data={type !== 'ageVerif'
                    ? [{
                        trace: 'Totals',
                        cashAmt: totals.cashTotal,
                        checkAmt: totals.checkTotal,
                        processFee: totals.processFeeTotal,
                        storeFee: totals.agentFeeTotal,
                        transDate: 'Count',
                        transTime: '#',
                        clerkName: transactions.length
                    }, {
                        trace: 'Deposit Amt',
                        cashAmt: totals.depositAmount,
                        transDate: 'Register',
                        transTime: 'Amt',
                        clerkName: totals.registerAmount
                    }]
                    : [{
                        trace: 'Totals',
                        transDate: 'Count',
                        transTime: '#',
                        clerkName: ageVerifs.length
                    }]}
                columns={columns}
                height={65}
                hideHeader={true}
                rowHeight={30}
                displayOnly={true}
            />
        </div>
    )
}