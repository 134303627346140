import type { ListChildComponentProps } from 'react-window';
import CellRenderer from './CellRenderer';
import type { CustomColumnProps } from './VirtualizedTable';
import { getClass } from 'utilities/classnames';
import styles from './VirtualizedTable.module.css';
import { getTooltipClass, getTooltipTriggerClass } from 'utilities/tooltip';

interface DataProps {
    className?: string,
    onRowClick?: ({ rowData, index }: { rowData: any, index: number }) => void,
    data: any[],
    selectedData?: any,
    scrollToIndex?: number,
    displayOnly?: boolean,
    trainingRow?: any,
    trainingFocused?: boolean,
    trainingTooltip?: JSX.Element,
    errored?: boolean,
    headerRow?: boolean,
    columns: CustomColumnProps[]
}

export default function RowRenderer({
    index, style, data: { className, columns, onRowClick, data, selectedData, scrollToIndex,
        displayOnly, trainingRow, trainingFocused, trainingTooltip, errored, headerRow } }: ListChildComponentProps<DataProps>) {

    const rowData = data[index];

    const selected = selectedData === rowData;
    const hovered = !selected && index === scrollToIndex;

    const controlClass = selected ? 'Selected'
        : hovered && trainingFocused && errored ? 'Errored'
            : hovered ? 'Hovered'
                : '';

    const handleRowClick = () => {
        onRowClick?.({ rowData, index });
    };

    const isTrainingRow = rowData === trainingRow;

    return (
        <div
            onClick={handleRowClick}
            className={getClass([
                className ?? '',
                styles.row,
                !!headerRow && styles.header,
                selectedData === rowData && 'Selected',
                selectedData !== rowData && index === scrollToIndex && !!trainingFocused && !!errored && 'Errored',
                !displayOnly && controlClass,
                getTooltipTriggerClass(!!trainingFocused && isTrainingRow)
            ])}
            role="row"
            style={style}>
            {columns.map((column) =>
                <CellRenderer key={column.dataKey} column={column} rowData={rowData} headerRow={headerRow} />
            )}
            {!!trainingTooltip &&
                <div className={getTooltipClass({ placement: 'bottom' })}>
                    {trainingTooltip}
                </div>
            }
        </div>
    );
}