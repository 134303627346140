import { createContext, useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import type { ReactNode, SetStateAction, SyntheticEvent } from 'react';
import { useHelperPrint } from './HelperPrint';
import type { Helper } from './HelperPrint';
import { isElectron, supportsUSBPrinting } from "utilities/Environment";
import { SettingsContext } from '../SettingsController/SettingsController';
import { APIContext } from '../APIController/APIController';
import { AuthContext } from '../AuthController/AuthController';
import { ParmContext } from '../ParmController/ParmController';
import { SystemContext } from '../SystemConroller/SystemController';
import { useThermalPrint } from './ThermalPrint';
import { createPortal } from 'react-dom';
import { getClass } from 'utilities/classnames';

export interface LineStyle {
    smallFont?: boolean,
    emphasized?: boolean,
    underline?: boolean,
    doubleSize?: boolean
};

export interface TextLine {
    text: string,
    style?: LineStyle
}

export interface TableLine {
    columns: string[],
    style?: LineStyle
}

export interface ImageLine {
    url: string
}

export type RequestLine = TextLine | TableLine | ImageLine;

export const instanceOfTextLine = (line: RequestLine): line is TextLine =>
    (line as TextLine).text !== undefined;

export const instanceOfTableLine = (line: RequestLine): line is TableLine =>
    (line as TableLine).columns !== undefined;

export const instanceOfImageLine = (line: RequestLine): line is ImageLine =>
    (line as ImageLine).url !== undefined;

export type PrintRequest = RequestLine[];

export interface PrintOptions {
    printer: 'Receipt' | 'Report',
    noDuplicate: boolean,
    reprint: boolean,
    trace?: string,
    transTime?: number
}

const defaultPrintOptions = {
    printer: 'Receipt',
    noDuplicate: false,
    reprint: false,
    trace: undefined,
    transTime: undefined
} as PrintOptions;

const defaultPrintResolve = (value: unknown) => { };
const defaultPrintReject = (reason?: any) => { };

export const PrinterContext = createContext({
    helper: null as null | Helper,
    print: (request: PrintRequest, options: Partial<PrintOptions>) => Promise.resolve(),
    setHelper: (value: SetStateAction<Helper | null>) => { },
    allowedPrinters: [] as USBDevice[],
    requestPrinter: (printerType?: "Receipt" | "Report") => Promise.reject() as Promise<USBDevice>
});

const browserPrint = () => {
    return new Promise((resolve, reject) => {
        window.onafterprint = resolve;
        window.print();
    });
}

const applyDoubleSizeStyle = (element: JSX.Element, { doubleSize }: LineStyle = {}) =>
    doubleSize ? <span style={{ fontSize: 'x-large' }}>{element}</span> : element;

const applyEmphasizedStyle = (element: JSX.Element, { emphasized }: LineStyle = {}) =>
    emphasized ? <b>{element}</b> : element;

const applySmallFontStyle = (element: JSX.Element, { smallFont }: LineStyle = {}) =>
    smallFont ? <small>{element}</small> : element;

const applyUnderlineStyle = (element: JSX.Element, { underline }: LineStyle = {}) =>
    underline ? <u>{element}</u> : element;

const applyStyles = (text: string, style?: LineStyle) =>
    applyDoubleSizeStyle(
        applyEmphasizedStyle(
            applySmallFontStyle(
                applyUnderlineStyle(<>{text}</>, style)
                , style)
            , style)
        , style);

const applyTextStyles = ({ text, style }: TextLine) =>
    applyStyles(text, style);

const applyTableStyles = (cell: string, { style }: TableLine) =>
    applyStyles(cell, style);

interface Props {
    children: ReactNode
}

export default function PrintController({ children }: Props) {

    const { system: { isMini } } = useContext(SystemContext);
    const { settings: { receiptPrinter, reportPrinter, printDuplicate } } = useContext(SettingsContext);
    const { parms } = useContext(ParmContext);
    const logTransTime = parms?.parameters.logTransTime;
    const { auth } = useContext(AuthContext);
    const { failoverFetch } = useContext(APIContext);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const printResolveRef = useRef(defaultPrintResolve);
    const printRejectRef = useRef(defaultPrintReject);
    const [printing, setPrinting] = useState(false);
    const [printRequest, setPrintRequest] = useState<PrintRequest>([]);
    const [printOptions, setPrintOptions] = useState<PrintOptions>(defaultPrintOptions);
    const shouldSaveReceipt = !!printOptions.trace;
    const readyToSaveReceipt = shouldSaveReceipt && !!printOptions.reprint;

    const { helper, setHelper, helperPrint } = useHelperPrint(containerRef, printOptions);
    const hasHelper = !!helper;
    const { thermalPrint, allowedPrinters, requestPrinter, loadImage, clearImages } = useThermalPrint(printRequest, printOptions);

    const [imageCount, setImageCount] = useState(0);
    const [loadedCount, setLoadedCount] = useState(0);

    const incrementLoadedCount = useCallback(() => {
        setLoadedCount((currentLoadedCount) => currentLoadedCount + 1);
    }, [])

    const handleImageLoad = useCallback((event: SyntheticEvent<HTMLImageElement, Event>) => {
        incrementLoadedCount();
        loadImage(event.currentTarget);
    }, [incrementLoadedCount, loadImage]);

    const sendReceiptToServer = useCallback(async () => {
        const html = containerRef.current?.outerHTML;

        if (html && auth) {
            const searchParams = new URLSearchParams({
                authToken: auth.authToken,
                custId: auth.agentId,
                trace: printOptions.trace!,
                receipt: html
            });
            if (logTransTime && printOptions.transTime) {
                searchParams.set('transTime', printOptions.transTime.toString());
            }

            await failoverFetch('/saveReceipt?' + searchParams);
        }
    }, [auth, failoverFetch, logTransTime, printOptions.trace, printOptions.transTime]);

    const printerName = printOptions.printer === 'Receipt' ? receiptPrinter
        : printOptions.printer === 'Report' ? reportPrinter
            : undefined;

    const shouldPrintDuplicate = printDuplicate
        && printOptions.printer === 'Receipt'
        && !printOptions.noDuplicate
        && !printOptions.reprint;

    const electronPrint = useCallback(async () => {
        let count = shouldPrintDuplicate ? 2 : 1;
        while (count-- > 0) {
            await require('electron').ipcRenderer.invoke('print', printerName);
        }
    }, [printerName, shouldPrintDuplicate]);

    const print = useCallback(async (request: PrintRequest, options: Partial<PrintOptions>) => {
        await new Promise((resolve, reject) => {
            setLoadedCount(0);
            setImageCount(request.filter((line) => instanceOfImageLine(line)).length);
            setPrintRequest(request);
            setPrintOptions({ ...defaultPrintOptions, ...options });
            setPrinting(true);
            printResolveRef.current = resolve;
            printRejectRef.current = reject;
        });
    }, []);

    const handlePrinterUpdate = useCallback(async () => {
        if (printing && loadedCount === imageCount) {
            try {
                if (readyToSaveReceipt) {
                    await sendReceiptToServer();
                } else if (isElectron) {
                    await electronPrint();
                } else if (hasHelper) {
                    await helperPrint();
                } else if (supportsUSBPrinting && printerName && printerName !== 'BROWSER') {
                    await thermalPrint();
                } else {
                    await browserPrint();
                }
            } catch (error) {
                printRejectRef.current(error);
            }

            if (shouldSaveReceipt && !readyToSaveReceipt) {
                setPrintOptions((currentOptions) => ({
                    ...currentOptions,
                    reprint: true
                }));
            } else {
                setPrinting(false);
                clearImages();
                printResolveRef.current('Success!');
            }
        }
    }, [clearImages, electronPrint, hasHelper, helperPrint, imageCount, loadedCount, printerName, printing, readyToSaveReceipt, sendReceiptToServer, shouldSaveReceipt, thermalPrint]);

    useEffect(() => {
        handlePrinterUpdate();
    }, [handlePrinterUpdate]);

    const receipt = useMemo(() => {
        const receipt = [] as JSX.Element[];
        for (let i = 0; i < printRequest.length; i++) {
            const requestLine = printRequest[i];
            if (instanceOfTextLine(requestLine)) {
                if (requestLine.text) {
                    receipt.push(
                        <div key={i}>{applyTextStyles(requestLine)}</div>
                    );
                } else {
                    receipt.push(
                        <br key={i} />
                    );
                }
            } else if (instanceOfImageLine(requestLine) && !printOptions.reprint) {
                receipt.push(
                    <img alt='PayHereNetwork'
                        crossOrigin='anonymous'
                        src={requestLine.url}
                        onLoad={handleImageLoad}
                        onError={incrementLoadedCount}
                        key={i} />
                );
            } else if (instanceOfTableLine(requestLine)) {
                const tableRows = [] as JSX.Element[];
                for (let j = i; j < printRequest.length && instanceOfTableLine(printRequest[j]); j++) {
                    const tableLine = printRequest[j] as TableLine;
                    tableRows.push(
                        <tr key={j}>
                            {tableLine.columns.map((cell, index) =>
                                <td key={index}>{applyTableStyles(cell, tableLine)}</td>
                            )}
                        </tr>
                    );
                }
                receipt.push(
                    <table key={i}>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </table>
                );
                i += (tableRows.length - 1);
            }
        }
        return receipt;
    }, [handleImageLoad, incrementLoadedCount, printOptions.reprint, printRequest]);

    return (
        <>
            <div className='PrintWrapper'>
                <PrinterContext.Provider value={{
                    print,
                    helper,
                    setHelper,
                    allowedPrinters,
                    requestPrinter
                }} >
                    {children}
                </PrinterContext.Provider>
            </div>
            {createPortal(
                <div
                    className={getClass([
                        'PrintContainer',
                        isMini && 'Mini',
                        printOptions.printer
                    ])}
                    ref={containerRef} >
                    <>
                        {(!!printOptions.reprint) &&
                            <div><b>REPRINT</b></div>
                        }
                        {receipt}
                    </>
                </div>
                , document.body)}
        </>
    );
}