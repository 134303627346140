import { useContext, useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { differenceInMilliseconds } from 'date-fns';
import { ParmContext } from '../../ParmController/ParmController';
import { useEventListener } from 'usehooks-ts';

interface PageTime {
    page: string,
    time: number
}

export function usePageTimer(failoverFetch: (input: string, init: RequestInit) => Promise<string>) {

    const { pathname } = useLocation();
    const firstSegment = pathname.split('/')[1];

    const { parms, loggedIn } = useContext(ParmContext);
    const hasParms = !!parms;

    const prevLoggedInRef = useRef(loggedIn);
    const prevFirstSegmentRef = useRef(firstSegment);

    const pageTimeMap = useRef(new Map<string, PageTime>());
    const startTime = useRef<Date | null>(null);

    const handleTimerStart = () => {
        startTime.current = new Date();
    }

    const handleTimerStop = (page: string) => {
        if (startTime.current) {
            if (pageTimeMap.current.has(page)) {
                pageTimeMap.current.get(page)!.time += differenceInMilliseconds(new Date(), startTime.current);
            } else {
                pageTimeMap.current.set(page, {
                    page,
                    time: differenceInMilliseconds(new Date(), startTime.current)
                });
            }
            startTime.current = null;
        }
    }

    const sendTimerInfo = useCallback(async () => {
        if (hasParms) {
            const filteredTimes = Array.from(pageTimeMap.current.values())
                .filter((pageTime) =>
                    pageTime.page && pageTime.time > 100
                );
            pageTimeMap.current.clear();
            if (filteredTimes.length > 0) {
                try {
                    await failoverFetch('/PageTimes', {
                        method: 'POST',
                        body: new URLSearchParams({
                            times: JSON.stringify(filteredTimes),
                            agentId: parms.clerkInfo.agentId.toString()
                        })
                    })
                } catch (err) {
                    console.error(err);
                }
            }
        }
    }, [failoverFetch, hasParms, parms?.clerkInfo.agentId])

    const handlePageClose = useCallback(() => {
        handleTimerStop(firstSegment);
        sendTimerInfo();
    }, [firstSegment, sendTimerInfo]);

    const handleVisibilityChange = useCallback(() => {
        if (document.visibilityState === 'hidden') {
            handleTimerStop(firstSegment);
        } else if (document.visibilityState === 'visible') {
            handleTimerStart();
        }
    }, [firstSegment])

    useEffect(() => {
        sendTimerInfo();
        prevLoggedInRef.current = loggedIn;
    }, [loggedIn, sendTimerInfo]);

    useEffect(() => {
        handleTimerStop(prevFirstSegmentRef.current);
        if (document.visibilityState === 'visible') {
            handleTimerStart();
        }
        prevFirstSegmentRef.current = firstSegment;
    }, [firstSegment]);

    const documentRef = useRef(document);
    useEventListener('visibilitychange', handleVisibilityChange, documentRef);
    useEventListener('beforeunload', handlePageClose);
}