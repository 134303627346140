import { useContext } from 'react';
import BasicPopup from '../BasicPopup/BasicPopup';
import { Link, useLocation } from "react-router-dom";
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import styles from '../../../HigherOrder/TrainingOverlay/TrainingOverlay.module.css';

export default function GlobalTrainingIntro() {

    const { isGlobalTraining, globalTrainingIntroComplete, completeGlobalTrainingIntro, isNavigationTraining } = useContext(TrainingContext);

    const { pathname } = useLocation();
    const firstSegment = pathname.split('/')[1];

    const onCashLoad = firstSegment === 'CashLoad';
    const onDigitalGiftCards = firstSegment === 'DigitalGiftCards';
    const onMobileTopUp = firstSegment === 'MobileTopUp';
    const onMoneyOrder = firstSegment === 'MoneyOrder';
    const onBillPay = firstSegment === 'BillPay';
    const onAgeVerification = firstSegment === 'AgeVerification';
    const onSupport = firstSegment === 'Support';
    const onSettings = firstSegment === 'Settings';
    const onNetSpend = firstSegment === 'NetSpend';

    const introTitle = isNavigationTraining ? "Welcome to the PayHereNetwork!"
        : onMobileTopUp ? "Welcome to Mobile Top-Up!"
            : onMoneyOrder ? "Welcome to Money Orders!"
                : onBillPay ? "Welcome to Bill Pay!"
                    : onAgeVerification ? "Welcome to Age Verification!"
                        : onSupport ? "Welcome to Support!"
                            : onSettings ? "Welcome to Settings!"
                                : onDigitalGiftCards ? "Welcome to Digital Gift Cards!"
                                    : onCashLoad ? "Welcome to Amazon Cash!"
                                        : onNetSpend ? "Welcome to NetSpend!"
                                            : null;

    return (
        <BasicPopup
            className={styles.globalTrainingPopup}
            darkMode
            noOverlay
            fadeIn
            open={isGlobalTraining && !globalTrainingIntroComplete && !!introTitle}>
            <h2>{introTitle}</h2>
            {onNetSpend &&
                <div>
                    <h4>Be aware of scams!</h4>
                    <ul className={styles.scams}>
                        <li>Netspend will never call your location to run “tests.” </li>
                        <li>Do not load transactions over the phone.</li>
                        <li>A card must be present for all transactions.</li>
                        <li>See <Link to="/Support" className={styles.faqlink} >FAQs</Link> for supplies and helpful tips.</li>
                    </ul>
                </div>
            }
            {!onNetSpend &&
                <div>Let's show you around!</div>
            }
            <div className={styles.globalTrainingPopupButtons}>
                <button type='button' onClick={completeGlobalTrainingIntro}>Continue</button>
            </div>
        </BasicPopup>
    );
}