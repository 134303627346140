import { useFormikContext } from 'formik';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import ConfirmField from '../../../../Common/ConfirmField/ConfirmField';
import MessageAggregator from '../../../../Common/MessageAggregator/MessageAggregator';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import './PhoneForm.css';
import type { initialValues } from '../MobileTopUp';
import { tooltipBoldClass } from 'utilities/tooltip';

const tutorialCountryCode = '52';
const tutorialPhone = '5585266304';

const countryCodeSet = new Set(["93", "358", "355", "213", "1684", "376", "244", "1264", "672", "1268", "54", "374", "297", "61", "43", "994", "1242", "973", "880", "1246", "375", "32", "501", "229", "1441", "975", "591", "387", "267", "47", "55", "246", "1284", "673", "359", "226", "257", "855", "237", "1", "238", "599", "1345", "236", "235", "56", "86", "61", "61", "57", "269", "243", "242", "682", "506", "225", "385", "53", "599", "357", "420", "45", "253", "1767", "1", "593", "20", "503", "240", "291", "372", "251", "500", "298", "679", "358", "33", "594", "689", "262", "241", "220", "995", "49", "233", "350", "30", "299", "1473", "590", "1671", "502", "44", "224", "245", "592", "509", "672", "504", "852", "36", "354", "91", "62", "98", "964", "353", "44", "972", "39", "1876", "81", "44", "962", "7", "254", "686", "383", "965", "996", "856", "371", "961", "266", "231", "218", "423", "370", "352", "853", "389", "261", "265", "60", "960", "223", "356", "692", "596", "222", "230", "262", "52", "691", "373", "377", "976", "382", "1664", "212", "258", "95", "264", "674", "977", "31", "687", "64", "505", "227", "234", "683", "672", "850", "1670", "47", "968", "92", "680", "970", "507", "675", "595", "51", "63", "64", "48", "351", "1", "974", "262", "40", "7", "250", "590", "290", "1869", "1758", "590", "508", "1784", "685", "378", "239", "966", "221", "381", "248", "232", "65", "1721", "421", "386", "677", "252", "27", "500", "82", "211", "34", "94", "249", "597", "47", "268", "46", "41", "963", "886", "992", "255", "66", "670", "228", "690", "676", "1868", "216", "90", "993", "1649", "688", "1340", "256", "380", "971", "44", "1", "1", "598", "998", "678", "39", "58", "84", "681", "212", "967", "260", "263"]);
const isValidCountryCode = (countryCode: string) =>
    countryCodeSet.has(countryCode);

const validateCountryCode = (value: string) => {
    if (!isValidCountryCode(value)) {
        return 'Please enter a valid country code.';
    }
}

interface Props {
    openPromotions: () => void
}

/**
 * Initial page for MTU, for entering phone numbers.
 */
export default function PhoneForm({ openPromotions }: Props) {

    const { values: { phone, phoneConfirm, countryCode } } = useFormikContext<typeof initialValues>();

    const isCountryCodeTutorial = countryCode !== tutorialCountryCode;

    const isPhoneTutorial = !isCountryCodeTutorial
        && phone !== tutorialPhone;

    const isPhoneConfirmTutorial = !isCountryCodeTutorial
        && !isPhoneTutorial
        && phoneConfirm !== tutorialPhone;

    const isButtonTutorial = !isCountryCodeTutorial
        && !isPhoneTutorial
        && !isPhoneConfirmTutorial;

    return (
        <div className='PhoneForm'>
            <div className='FormSection'>
                <h1>WELCOME TO MOBILE TOP-UP</h1>
                <fieldset>
                    <div className='PromoSection'>
                        <button type='button' onClick={openPromotions}>See Promotions</button>
                    </div>
                </fieldset>
                <fieldset>
                    <AdvancedField
                        name='countryCode'
                        className='CountryCode'
                        placeholder='+1'
                        trainingFocused={isCountryCodeTutorial}
                        trainingTooltip={
                            <>
                                <div>ENTER THIS COUNTRY CODE</div>
                                <b className={tooltipBoldClass}>{tutorialCountryCode}</b>
                            </>
                        }
                        maxLength={5}
                        validate={validateCountryCode}
                        numeric={true} />
                    <div>
                        <div>Country</div>
                        <div>Code</div>
                    </div>
                </fieldset>
                <fieldset>
                    <AdvancedField
                        name='phone'
                        placeholder='Phone Number'
                        trainingTooltip={
                            <>
                                <div>ENTER THIS PHONE NUMBER</div>
                                <b className={tooltipBoldClass}>{tutorialPhone}</b>
                            </>
                        }
                        trainingFocused={isPhoneTutorial}
                        tooltipPlacement='left'
                        autoFocus required numeric
                        maxLength={11} />
                    <ConfirmField
                        name='phone'
                        placeholder='Phone Number'
                        trainingTooltip={
                            <>
                                <div>ENTER PHONE NUMBER AGAIN</div>
                                <b className={tooltipBoldClass}>{tutorialPhone}</b>
                            </>
                        }
                        trainingFocused={isPhoneConfirmTutorial}
                        tooltipPlacement='left'
                        required numeric
                        maxLength={11} />
                    <div>
                        <AdvancedButton trainingFocused={isButtonTutorial}
                            trainingTooltip={
                                <>
                                    <div>YOU ARE READY</div>
                                    <b className={tooltipBoldClass}>CLICK NEXT</b>
                                </>
                            } >Next</AdvancedButton>
                    </div>
                </fieldset>
                <MessageAggregator />
            </div>
        </div>
    );
}