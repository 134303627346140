import { useContext } from 'react';
import BasicPopup from '../BasicPopup/BasicPopup';
import styles from './AMLPopup.module.css';
import { SystemContext } from 'components/HigherOrder/SystemConroller/SystemController';

interface Props {
    closed: boolean,
    close: () => void
}

export default function AMLPopup({ closed, close }: Props) {

    const { system: { isPOS } } = useContext(SystemContext);

    return (
        <BasicPopup
            onClose={close}
            className={styles.amlPopup}
            open={!closed}
            fadeIn>
            <div>
                <h2 className={styles.title}>Your annual AML exam is due.</h2>
                <p>If you are the owner, please proceed to <strong>{isPOS ? process.env.REACT_APP_PUBLIC_WEB_PORTAL_CLIENT_DOMAIN : <a href={process.env.REACT_APP_PUBLIC_WEB_PORTAL_CLIENT_URL} target='_blank' rel="noreferrer">{process.env.REACT_APP_PUBLIC_WEB_PORTAL_CLIENT_DOMAIN}</a>}</strong> to read the training and take the exam.</p>
                <p><i>Failure to take the exam will result in your access to Fidelity Express services being suspended.</i></p>
            </div>
        </BasicPopup>
    );
}