import { useContext, useRef, useState } from 'react';
import packageJson from '../../../../../../package.json';
import type { MessageType } from '../../../../Common/Message/Message';
import Message from '../../../../Common/Message/Message';
import Processing from '../../../../Common/Processing/Processing';
import ConfirmationPopup from '../../../../Common/Popups/ConfirmationPopup/ConfirmationPopup';
import { useBillPayVoidPrinter } from '../../../../Common/Receipts/BillPayVoidPrinter';
import { useFormikContext } from 'formik';
import { SystemContext } from '../../../../HigherOrder/SystemConroller/SystemController';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { MOContext } from '../../../../HigherOrder/MoneyOrderController/MoneyOrderController';
import type { FormValues, ParsedTransaction } from '../Reports';
import { softwareName } from 'utilities/Environment';
import { js2xml, xml2js } from 'utilities/xml';

interface MOVoidResponseBody {
    routeid: string,
    agent: string,
    mpf: string,
    trancode: string,
    response_code: string,
    response_msg1: string,
    response_msg2: string,
    vcenter_dttm: string,
    trandate: string,
    trantime: string,
    busdate: string,
    tracer: string,
    ovrmfee: string
}

export interface MOVoidResponse {
    moneyorder_response: MOVoidResponseBody
}

interface BPVoidResponseBody {
    response_code: string,
    response_message: string,
    response_message2: string,
    debilr: string,
    detnsrt: string,
    deagnt: string,
    rcptdt: string,
    rcpttm: string,
    debsdt: string,
    deamtv: string,
    detrac: string,
    vfee: string,
    decust: string,
    receipt_text: string
}

interface BPVoidResponse {
    billpay: {
        bp_void: BPVoidResponseBody,
        customerId: string,
        customerinfo: string,
        tranPathId: string
    }
}

export interface VoidData {
    date: string,
    time: string,
    trace: string,
    cashAmt: string,
    totalFee: string
}

interface Props {
    onVoidingComplete: () => void,
    transaction: ParsedTransaction
}

/**
 * Component that displays a confirmation pop-up, and handles voiding, given a transaction.
 */
export default function TransactionVoider({ transaction, onVoidingComplete }: Props) {

    const [submitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState({ message: '', messageType: '' as MessageType });

    const { auth } = useContext(AuthContext);
    const { parms } = useContext(ParmContext);
    const isSupervisor = parms?.clerkInfo.type === 'S';
    const { failoverFetch } = useContext(APIContext);
    const { system: { serial } } = useContext(SystemContext);
    const { getMOFee } = useContext(MOContext);
    const { printBillPayVoid } = useBillPayVoidPrinter();

    const formik = useFormikContext<FormValues>();

    const trace = useRef<HTMLInputElement | null>(null);

    const voidBillPayment = async () => {
        if (auth) {
            const xml = js2xml({
                billpay: {
                    bp_void: {
                        detnsrt: 'FDX001',
                        deagnt: String(auth.agentId).padStart(7, '0'),
                        authToken: auth.authToken,
                        device_sn: serial,
                        desv: softwareName,
                        depv: packageJson.version,
                        demseq: '0',
                        declrk: auth.clerkId,
                        devtrc: transaction.trace
                    }
                }
            });

            const response = await failoverFetch('/gateway', {
                method: 'POST',
                body: xml,
                headers: { 'Content-Type': 'text/xml' }
            });

            return xml2js<BPVoidResponse>(response).billpay.bp_void;
        }
    }

    const voidMoneyOrder = async () => {
        if (parms && auth) {
            const xml = 'bigField=' + js2xml({
                moneyorder: {
                    mo_req_header: {
                        routeid: parms.parameters.bpRoute,
                        agent: parms.clerkInfo.agentId.toString().padStart(7, '0'),
                        devserno: serial,
                        serno: '',
                        softname: softwareName,
                        softrel: packageJson.version,
                        seqno: '0',
                        trandate: '',
                        trantime: '',
                        voidflag: '',
                        trancode: '94',
                        upflag: '0',
                        clerkid: parms.clerkInfo.clerkId.toString(),
                        authToken: auth.authToken
                    },
                    mo_req_detail: {
                        mo_req: {
                            moserno: transaction.billerId.replace(/\D/g, ''),
                            movtrac: transaction.trace,
                            feeamt: getMOFee(transaction.amount).toString()
                        }
                    }
                }
            });

            const response = await failoverFetch('/mogateway', {
                method: 'POST',
                body: xml,
                headers: { 'Content-Type': 'text/xml' }
            })

            return xml2js<MOVoidResponse>(response).moneyorder_response;
        }
    }

    const voidTransaction = async () => {
        const promise = transaction.paymentType !== 'MO' ? voidBillPayment() : voidMoneyOrder();
        setSubmitting(true);
        try {
            const data = await promise;
            if (data) {
                if (data.response_code !== '00') {
                    if (transaction.paymentType !== 'MO') {
                        throw new Error((data as BPVoidResponseBody).response_message ?? '');
                    } else {
                        throw new Error((data as MOVoidResponseBody).response_msg1 ?? '');
                    }
                }
                formik.setStatus({ message: 'Void Successful.' });
                if (transaction.paymentType !== 'MO') {
                    await printBillPayVoid({
                        date: (data as BPVoidResponseBody).rcptdt,
                        time: (data as BPVoidResponseBody).rcpttm,
                        trace: (data as BPVoidResponseBody).detrac,
                        cashAmt: (data as BPVoidResponseBody).deamtv,
                        totalFee: (data as BPVoidResponseBody).vfee
                    });
                }
            }
        } catch (error) {
            formik.setStatus({ message: 'An error occurred while voiding. ' + error, messageType: 'error' });
        } finally {
            setSubmitting(false);
            onVoidingComplete();
        }
    }

    const verifyTrace = () => {
        if (trace.current?.value === transaction.trace) {
            voidTransaction();
        } else {
            setStatus({ message: 'Invalid Trace', messageType: 'error' });
        }
    }

    if (submitting) {
        return (
            <Processing open />
        );
    } else {
        return (
            <div className='TransactionVoider'>
                {isSupervisor &&
                    <ConfirmationPopup
                        onConfirm={voidTransaction}
                        onClose={onVoidingComplete}
                        open fadeIn>
                        <h3>Are you sure you want to void this transaction?</h3>
                    </ConfirmationPopup>
                }
                {!isSupervisor &&
                    <ConfirmationPopup
                        onConfirm={verifyTrace}
                        onClose={onVoidingComplete}
                        confirmOnly open fadeIn>
                        <h3>Void a Transaction</h3>
                        <h4>Please Enter the Receipt/Trace #</h4>
                        <input type='text' ref={trace} />
                        <Message status={status} />
                    </ConfirmationPopup>
                }
            </div>
        );
    }
}