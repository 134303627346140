import BasicPopup from '../../../../Common/Popups/BasicPopup/BasicPopup';
import styles from './AgeVerifiedPopup.module.css';

interface Props {
    onClose: () => void,
    age: number,
    open: boolean
}

/**
 * Popup to display an Age, and whether it was verified or not.
 */
export default function AgeVerifiedPopup({ onClose, age, open }: Props) {

    const ageVerified = age > 18;

    return (
        <BasicPopup
            className={styles.ageVerifiedPopup}
            onClose={onClose}
            open={open}
            darkMode noOverlay fadeIn>
            <div className={styles.ageResult}>
                <div className={`${styles.grey} ${styles.circle}`}>
                    <div className={`${styles.white} ${styles.circle}`}>
                        <div className={`${styles.green} ${styles.circle}`}>
                            <span>{age}</span>
                        </div>
                    </div>
                </div>
                <span className={`${ageVerified ? '' : styles.error}`}>
                    {ageVerified ? 'Age verified.' : 'AGE'}
                </span>
            </div>
        </BasicPopup>
    );
}