import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import BasicPopup from '../../../../Common/Popups/BasicPopup/BasicPopup';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import MessageAggregator from '../../../../Common/MessageAggregator/MessageAggregator';
import { validateAddress, validateCity, validateEmail, validateState, validateZip } from '../../../../../utilities/Validation';
import { differenceInYears } from 'date-fns';
import { stateOptions, focusNextElement } from '../../../../../utilities/Tools';
import './Civitek.css';
import type { initialValues } from '../BillPay';

interface Props {
    onClose: () => void,
    open: boolean
}

export default function Civitek({ onClose, open }: Props) {

    const { values, touched, setTouched, setFieldValue } = useFormikContext<typeof initialValues>();

    const sortedCounties = useMemo(() => {
        return values.biller?.countyOptions?.sort(({ label: a }, { label: b }) => {
            return a.localeCompare(b);
        });
    }, [values.biller?.countyOptions])

    const countyOptions = useMemo(() => {
        return sortedCounties?.map(({ value, label }) => {
            return <option key={value} value={value.toUpperCase()}>{label}</option>
        });
    }, [sortedCounties])

    const isChildSupport = values.biller?.billerId === 3290;

    const isTrafficCitation = values.biller?.billerId === 3289;

    const handleValidateAccount = useCallback((account = values.accountNum) => {
        if (!account.trim()) {
            if (isChildSupport) {
                return 'Child Support Case Number Required';
            } else if (isTrafficCitation) {
                return 'Traffic Citation Number Required';
            }
        }
    }, [isChildSupport, isTrafficCitation, values.accountNum])

    const handleValidateDLNumber = useCallback((dlNumber = values.dlNumber) => {
        if (!dlNumber?.trim() && isTrafficCitation) {
            return "Driver's License Number Required";
        }
    }, [isTrafficCitation, values.dlNumber])

    const handleValidateDLState = useCallback((dlState = values.dlState) => {
        if (!dlState?.trim() && isTrafficCitation) {
            return "Driver's License State Required";
        }
    }, [isTrafficCitation, values.dlState])

    const handleValidateBirthdate = useCallback(() => {
        const { birthdate } = values;
        if (isTrafficCitation) {
            if (!birthdate?.day?.trim() || !birthdate?.month?.trim() || !birthdate?.year?.trim()) {
                return "Birthdate Required";
            } else {
                const parsedDate = Date.UTC(Number(birthdate.year), Number(birthdate.month) - 1, Number(birthdate.day));
                if (isNaN(parsedDate) || differenceInYears(Date.now(), parsedDate) >= 120) {
                    return "Please enter a valid MM/DD/YYYY Birthdate";
                }
            }
        }
    }, [isTrafficCitation, values])

    const handleValidateFirstName = (firstName = values.firstName) => {
        if (!firstName.trim()) {
            return 'First Name Required';
        }
    }

    const handleValidateLastName = (lastName = values.lastName) => {
        if (!lastName.trim()) {
            return 'Last Name Required';
        }
    }

    const handleValidateEmail = (email = values.email) => {
        if (!email.trim()) {
            return 'Email Required';
        }
        return validateEmail(email);
    }

    const handleValidateAddress = (address = values.street) => {
        if (!address.trim()) {
            return 'Address Required';
        }
        return validateAddress(address);
    }

    const handleValidateCity = (city = values.city) => {
        if (!city.trim()) {
            return 'City Required';
        }
        return validateCity(city);
    }

    const handleValidateState = (state = values.state) => {
        if (!state.trim()) {
            return 'State Required';
        }
        return validateState(state);
    }

    const handleValidateCounty = useCallback((county = values.county) => {
        if (!county?.trim() && isChildSupport) {
            return 'County Required';
        }
    }, [isChildSupport, values.county])

    const handleValidateZip = (zip = values.zip) => {
        if (!zip.trim()) {
            return 'Zip Required';
        }
        return validateZip(zip);
    }

    const allValid = () => {
        return !(handleValidateAccount()
            || handleValidateDLNumber()
            || handleValidateDLState()
            || handleValidateBirthdate()
            || handleValidateFirstName()
            || handleValidateLastName()
            || handleValidateEmail()
            || handleValidateAddress()
            || handleValidateCity()
            || handleValidateState()
            || handleValidateZip()
            || handleValidateCounty());
    }

    const handleClose = () => {
        onClose();
        setFieldValue('biller', null);
        setFieldValue('billerSearch', '');
    }

    const triggerValidation = () => {
        setTouched({
            ...touched,
            accountNum: true,
            dlNumber: true,
            dlState: true,
            birthdate: {
                day: true,
                month: true,
                year: true
            },
            firstName: true,
            lastName: true,
            email: true,
            street: true,
            city: true,
            state: true,
            zip: true,
            county: true
        });
    }

    const handleContinue = () => {
        if (allValid()) {
            if (!values.customer.email) {
                setFieldValue('customer.email', values.email);
            }
            onClose();
        } else {
            triggerValidation();
        }
    }

    const handleDayChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (Number(value) > 3 || value.length === 2) {
            focusNextElement();
        }
    }

    const handleMonthChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (Number(value) > 1 || value.length === 2) {
            focusNextElement();
        }
    }

    const billerFeilds = useMemo(() => {
        if (isChildSupport) {
            return (
                <fieldset>
                    <AdvancedField
                        type='text'
                        name='accountNum'
                        placeholder='CHILD SUPPORT CASE NUMBER'
                        maxLength={20}
                        validate={handleValidateAccount}
                        required={true}
                        upperCase={true}
                        tooltip={<>{values.biller?.notes}</>}
                        tooltipPlacement='top'
                    />
                    <Field
                        component='select'
                        name='county'
                        validate={handleValidateCounty}
                    >
                        <option value=''>COUNTY</option>
                        {countyOptions}
                    </Field>
                </fieldset >
            );
        } else if (isTrafficCitation) {
            return (
                <fieldset>
                    <AdvancedField
                        type='text'
                        name='accountNum'
                        placeholder='TRAFFIC CITATION NUMBER'
                        maxLength={20}
                        validate={handleValidateAccount}
                        required={true}
                        upperCase={true}
                        tooltip={<>{values.biller?.notes}</>}
                        tooltipPlacement='top' />
                    <AdvancedField type='text'
                        name='dlNumber'
                        validate={handleValidateDLNumber}
                        placeholder="DRIVER'S LICENSE NUMBER"
                        upperCase={true} />
                    <Field
                        component='select'
                        name='dlState'
                        validate={handleValidateDLState}
                    >
                        <option value=''>DRIVER'S LICENSE STATE</option>
                        {stateOptions}
                    </Field>
                    <div className='Birthdate'>
                        <div>
                            <div>DRIVER'S LICENSE</div>
                            <div>BIRTHDATE</div>
                        </div>
                        <AdvancedField
                            name='birthdate.month'
                            placeholder='MM'
                            className='Month'
                            maxLength={2}
                            onChange={handleMonthChange}
                            validate={handleValidateBirthdate} />
                        <AdvancedField
                            name='birthdate.day'
                            placeholder='DD'
                            className='Day'
                            maxLength={2}
                            onChange={handleDayChange}
                            validate={handleValidateBirthdate} />
                        <AdvancedField
                            name='birthdate.year'
                            placeholder='YYYY'
                            className='Year'
                            maxLength={4}
                            validate={handleValidateBirthdate} />
                    </div>
                </fieldset >
            );
        }
    }, [countyOptions, handleValidateAccount, handleValidateBirthdate, handleValidateCounty, handleValidateDLNumber, handleValidateDLState, isChildSupport, isTrafficCitation, values.biller?.notes])

    return (
        <BasicPopup
            onClose={handleClose}
            className='CivitekPopup'
            open={open}
            fadeIn>
            {isTrafficCitation &&
                <div>
                    <h4>Please request the driver’s license for the person on this traffic citation.</h4>
                </div>
            }
            {isChildSupport &&
                <div>
                    <h4>Please enter some additional information for this child support payment.</h4>
                </div>
            }
            <MessageAggregator />
            <div className='Civitek' >
                <div className='FormSection'>
                    <fieldset>
                        <div>
                            <div className='Name'>
                                <AdvancedField type='text'
                                    name='firstName'
                                    placeholder="FIRST NAME"
                                    validate={handleValidateFirstName}
                                    maxLength={35}
                                    upperCase={true} />
                            </div>
                            <div className='Name'>
                                <AdvancedField type='text'
                                    name='lastName'
                                    placeholder="LAST NAME"
                                    validate={handleValidateLastName}
                                    maxLength={35}
                                    upperCase={true} />
                            </div>
                        </div>
                        <AdvancedField type='text'
                            name='email'
                            placeholder="EMAIL"
                            validate={handleValidateEmail}
                            maxLength={35}
                            upperCase={true} />
                        <AdvancedField type='text'
                            name='street'
                            placeholder="STREET"
                            validate={handleValidateAddress}
                            maxLength={35}
                            upperCase={true} />
                        <AdvancedField type='text'
                            name='city'
                            placeholder="CITY"
                            maxLength={20}
                            validate={handleValidateCity}
                            upperCase={true} />
                        <div>
                            <div className='State'>
                                <Field
                                    component='select'
                                    name='state'
                                    validate={handleValidateState}
                                >
                                    <option value=''>STATE</option>
                                    {stateOptions}
                                </Field>
                            </div>
                            <div className='Zip'>
                                <AdvancedField type='text'
                                    name='zip'
                                    placeholder="ZIP"
                                    maxLength={5}
                                    validate={handleValidateZip}
                                    upperCase={true} />
                            </div>
                        </div>
                    </fieldset >
                    {billerFeilds}
                </div>
                <div>
                    <AdvancedButton type='button' onClick={handleContinue}>Continue</AdvancedButton>
                </div>
            </div>
        </BasicPopup>
    );
}