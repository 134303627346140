export class FetchError extends Error {
    response;

    constructor(response: Response) {
        super(response.statusText || response.status.toString());

        this.response = response;
    }
}

export const handleFetchErrors = (response: Response) => {
    if (!response.ok) {
        throw new FetchError(response);
    }
    return response;
}

export const getPublicIP = async () => {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), 15000);
    try {
        const response = await fetch('https://ipv4.icanhazip.com/', { signal: abortController.signal })
            .then(handleFetchErrors);
        return response.text();
    } catch (error) {
        console.error(error);
    }
    return '';
}

export const isOnline = async () => {
    const ip = await getPublicIP();
    return !!ip;
}