import { useContext, useMemo } from 'react';
import MessageAggregator from '../../../Common/MessageAggregator/MessageAggregator';
import ConfirmField from '../../../Common/ConfirmField/ConfirmField';
import AdvancedForm from '../../../Common/AdvancedForm/AdvancedForm';
import AdvancedButton from '../../../Common/AdvancedButton/AdvancedButton';
import { AuthContext } from '../../../HigherOrder/AuthController/AuthController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import styles from './UpdateSelf.module.css';
import { Field, type FormikHelpers } from 'formik';

/**
 * Page presented to user when they need to update their account information.
 * If they used the challenge question to log-in, they must enter a new password.
 * If it's a new Agent, they'll need to change their username, password, and setup a challenge question.
 * If you have an invalid Username, or missing challenge question, the form will make you fix them.
 */
export default function UpdateSelf() {

    const { parms, hasBadUsername, setClerkInfo } = useContext(ParmContext);
    const { auth } = useContext(AuthContext);
    const { failoverFetch, handleLogout } = useContext(APIContext);

    const onSubmit = async (values: typeof initialValues, { setStatus, setSubmitting }: FormikHelpers<typeof initialValues>) => {
        if (auth && parms) {
            setStatus({ message: 'Updating your user...', messageType: '' });
            try {
                await failoverFetch('/Clerks', {
                    method: 'POST',
                    body: new URLSearchParams({
                        authToken: auth.authToken,
                        action: 'updateself',
                        username: values.username,
                        type: parms.clerkInfo.type,
                        password: values.password,
                        challenge: values.question,
                        response: values.response
                    })
                });
                setClerkInfo({ username: values.username });
                handleLogout();
            } catch (error) {
                console.error(error);
                setStatus({ message: 'An unexpected error has occurred while updating your user.', messageType: 'error' });
            } finally {
                setSubmitting(false);
            }
        }
    }

    const initialValues = useMemo(() => {
        return {
            username: auth?.username ?? '',
            password: '',
            passwordConfirm: '',
            question: auth?.challengeQuestion ?? '',
            response: auth?.challengeResponse ?? ''
        }
    }, [auth?.challengeQuestion, auth?.challengeResponse, auth?.username])

    const needsChallenge = !auth?.challengeQuestion && !auth?.challengeResponse;

    const validate = ({ password, username, response }: typeof initialValues) => {
        if (!password) {
            return { password: "Password Required" };
        } else if (password.length < 8) {
            return { password: "Password must be at least 8 characters." };
        } else if (password.includes(username)) {
            return { password: "Password must not contain username." };
        } else if (needsChallenge && !response) {
            return { response: "Challenge Response Required" };
        } else if (needsChallenge && response === password) {
            return { response: "Challenge response cannot match password" };
        } else if (!username) {
            return { username: "Username Required." };
        } else if (parms?.clerkInfo.memoId && parms.clerkInfo.memoId > 0 && username.includes(parms.clerkInfo.memoId.toString())) {
            return { username: "Username must not contain MEMO ID." };
        } else if (username.includes((auth?.agentId ?? -1).toString())) {
            return { username: "Username must not contain Agent Number." };
        }
        return {};
    }

    return (
        <div className={styles.updateSelf}>
            <AdvancedForm initialValues={initialValues} onSubmit={onSubmit} validate={validate} >
                {({ values }) =>
                    <>
                        <fieldset>
                            <legend>USERNAME</legend>
                            <div>
                                <Field
                                    type='text'
                                    name='username'
                                    placeholder='Username'
                                    maxLength={15}
                                    required={true}
                                    autoFocus={hasBadUsername}
                                    disabled={!hasBadUsername} />
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>RESET PASSWORD</legend>
                            <Field
                                type='password'
                                name='password'
                                placeholder='Password'
                                minLength={8}
                                maxLength={25}
                                autoFocus={!hasBadUsername}
                                required />
                            <ConfirmField
                                type='password'
                                name='password'
                                placeholder='Password'
                                minLength={8}
                                maxLength={25}
                                required />
                            <div className={styles.tip}>(Passwords must be at least 8 characters)<br />(Both password fields must match)</div>
                        </fieldset>
                        {needsChallenge &&
                            <fieldset>
                                <legend>CHALLENGE QUESTION</legend>
                                <div>
                                    <Field
                                        name="question"
                                        component="select"
                                        required>
                                        <option></option>
                                        <option>Mother's Maiden Name</option>
                                        <option>Name of first pet</option>
                                        <option>Fathers middle name</option>
                                        <option>Name of childhood best friend</option>
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        type='password'
                                        name='response'
                                        placeholder='Response To Challenge'
                                        maxLength={25}
                                        required />
                                </div>
                                <div className={styles.tip}>Cannot match password</div>
                            </fieldset>
                        }
                        <div>
                            <AdvancedButton>Submit</AdvancedButton>
                        </div>
                        <MessageAggregator />
                    </>
                }
            </AdvancedForm>
        </div>
    );
}