import { forwardRef } from 'react';
import { useField } from 'formik';
import { subYears } from 'date-fns';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from './DatePicker.module.css';

const CustomInput = forwardRef<HTMLInputElement>((props, ref) => {
    return (
        <input
            {...props}
            type="text"
            readOnly={true}
            ref={ref}
        />
    )
});

interface Props {
    onChange?: () => void,
    name: string,
    className?: string
    calendarClassName?: string
    popperClassName?: string
}

/**
 * Custom datepicker class that hooks into formik.
 */
export default function DatePicker({ onChange, name, className, calendarClassName, popperClassName }: Props) {

    const [field, , helpers] = useField<string>(name);

    const handleChange = (date: Date | [Date, Date] | null) => {
        if (date) {
            if (Array.isArray(date)) {
                helpers.setValue(date[0].valueOf().toString());
            } else {
                helpers.setValue(date.valueOf().toString());
            }
        } else {
            helpers.setValue('0');
        }
        onChange?.();
    };

    const maxDate = new Date();
    const minDate = subYears(maxDate, 1);
    const selectedDate = new Date(Number(field.value));

    return (
        <ReactDatePicker
            customInput={<CustomInput />}
            className={`${styles.inputField} ${className ?? ''}`}
            calendarClassName={`${styles.calendar} ${calendarClassName ?? ''}`}
            popperClassName={`${styles.popper} ${popperClassName ?? ''}`}
            maxDate={maxDate}
            minDate={minDate}
            selected={selectedDate}
            onChange={handleChange}
            popperPlacement='right'
        />
    );
}