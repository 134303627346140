import type { ChangeEvent } from 'react';
import { useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { stateOptions } from '../../../../../utilities/Tools';
import { validateAddress, validateCity, validateEmail, validateName, validatePhone, validateState, validateZip } from '../../../../../utilities/Validation';
import './CustomerForm.css';
import type { CustomerPreview } from '../CustomerSearch/CustomerSearch';
import type { initialValues } from '../BillPay';
import { tutorialCustomer } from '../BillPay';
import { tooltipBoldClass } from 'utilities/tooltip';
import Select from 'components/Common/Select/Select';

interface Props {
    onSearchEvent: (event: ChangeEvent<HTMLInputElement>) => void,
    isCustomerSearchTutorial: boolean,
    isCustomerNameTutorial: boolean,
    isCustomerPhoneTutorial: boolean,
    isCustomerAddressTutorial: boolean,
    isCustomerCityTutorial: boolean,
    isCustomerStateTutorial: boolean,
    isCustomerZipTutorial: boolean
}

const validateCustomerPhone = (phone: string) => {
    const value = phone.trim?.();
    if (!value) {
        return 'Phone Required';
    } else {
        return validatePhone(phone);
    }
}

const validateCustomerEmail = (email: string) => {
    const value = email.trim?.();
    return validateEmail(value);
}

const noNameCopyPaste = (name: string, { city, address1, phone }: CustomerPreview) => {
    return !((name.toLowerCase().trim() === city.toLowerCase().trim() && city)
        || (name.toLowerCase().trim() === address1.toLowerCase().trim() && address1)
        || (name.toLowerCase().trim() === phone.toLowerCase().trim() && phone));
}

const validateCustomerName = (name: string, customer: CustomerPreview) => {
    const value = name.trim?.();
    if (!value) {
        return 'Name Required';
    } else if (!noNameCopyPaste(name, customer)) {
        return 'Name must not match other fields'
    } else {
        return validateName(name);
    }
}

export default function CustomerForm({ onSearchEvent, isCustomerSearchTutorial, isCustomerNameTutorial,
    isCustomerPhoneTutorial, isCustomerAddressTutorial, isCustomerCityTutorial, isCustomerStateTutorial, isCustomerZipTutorial }: Props) {

    const { values } = useFormikContext<typeof initialValues>();
    const { parms } = useContext(ParmContext);
    const hasParms = !!parms;

    const addressRequired = useMemo(() => {
        if (hasParms) {
            return (parms.addressRequired || parms.parameters.addressRequired)
                || ['AUTOINS', 'UNKNOWN'].includes(values.biller?.category ?? "");
        } else {
            return true;
        }
    }, [hasParms, parms?.addressRequired, parms?.parameters.addressRequired, values.biller?.category])

    const validateNameField = (name: string) => {
        return validateCustomerName(name, values.customer)
    }

    const validateCustomerAddress = (address: string) => {
        const value = address.trim?.();
        if (!value) {
            if (addressRequired) {
                return 'Address Required';
            } else {
                return '';
            }
        }
        return validateAddress(address);
    }

    const validateCustomerCity = (city: string) => {
        const value = city.trim?.();
        if (!value) {
            if (addressRequired) {
                return 'City Required';
            } else {
                return '';
            }
        }
        return validateCity(city);
    }

    const validateCustomerState = (state: string) => {
        const value = state.trim?.();
        if (!value) {
            if (addressRequired) {
                return 'State Required';
            } else {
                return '';
            }
        }
        return validateState(state);
    }

    const validateCustomerZip = (zip: string) => {
        const value = zip.trim?.();
        if (!value) {
            if (addressRequired) {
                return 'Zip Required';
            } else {
                return '';
            }
        }
        return validateZip(zip);
    }

    const customerChanged = values.originalCustomer && JSON.stringify(values.originalCustomer) !== JSON.stringify(values.customer);

    const shouldFocusCustomerField = !((values.billerSearch?.length ?? 0) > 0) && !values.biller && !values.customer.name;

    return (
        <fieldset className='CustomerForm'>
            <AdvancedField name='customerSearch'
                type='search'
                onChange={onSearchEvent}
                placeholder='SEARCH CUSTOMER INFO'
                autoFocus={shouldFocusCustomerField}
                upperCase={true}
                maxLength={35}
                trainingFocused={isCustomerSearchTutorial}
                trainingTooltip={
                    <>
                        <div>SEARCH FOR THE CUSTOMER</div>
                        <b className={tooltipBoldClass}>{tutorialCustomer.phone}</b>
                    </>
                }
                tooltipPlacement='top' />
            <AdvancedField type='text'
                name='customer.name'
                placeholder='NAME'
                maxLength={35}
                validate={validateNameField}
                disabled={!!values.originalCustomer?.name && (!validateCustomerName(values.originalCustomer.name, values.originalCustomer) && parms?.clerkInfo.type === 'L')}
                upperCase={true}
                trainingFocused={isCustomerNameTutorial}
                trainingTooltip={
                    <>
                        <div>ENTER THE NAME</div>
                        <b className={tooltipBoldClass}>{tutorialCustomer.name}</b>
                    </>
                }
            />
            <AdvancedField type='tel'
                name='customer.phone'
                placeholder='PHONE'
                maxLength={15}
                validate={validateCustomerPhone}
                disabled={!!values.originalCustomer?.phone && (!validateCustomerPhone(values.originalCustomer.phone) && parms?.clerkInfo.type === 'L')}
                upperCase={true}
                numeric={true}
                trainingFocused={isCustomerPhoneTutorial}
                trainingTooltip={
                    <>
                        <div>ENTER THE PHONE</div>
                        <b className={tooltipBoldClass}>{tutorialCustomer.phone}</b>
                    </>
                }
            />
            <AdvancedField
                name='customer.email'
                placeholder='EMAIL'
                maxLength={35}
                validate={validateCustomerEmail}
                disabled={!!values.originalCustomer?.email && (!validateCustomerEmail(values.originalCustomer.email) && parms?.clerkInfo.type === 'L')}
                upperCase={true} />
            <AdvancedField type='text'
                name='customer.address1'
                placeholder='ADDRESS'
                maxLength={35}
                validate={validateCustomerAddress}
                disabled={!!values.originalCustomer?.address1 && (!validateCustomerAddress(values.originalCustomer.address1) && parms?.clerkInfo.type === 'L')}
                upperCase={true}
                trainingFocused={isCustomerAddressTutorial}
                trainingTooltip={
                    <>
                        <div>ENTER THE ADDRESS</div>
                        <b className={tooltipBoldClass}>{tutorialCustomer.address1}</b>
                    </>
                }
            />
            <AdvancedField type='text'
                name='customer.city'
                placeholder='CITY'
                maxLength={20}
                validate={validateCustomerCity}
                disabled={!!values.originalCustomer?.city && (!validateCustomerCity(values.originalCustomer.city) && parms?.clerkInfo.type === 'L')}
                upperCase={true}
                trainingFocused={isCustomerCityTutorial}
                trainingTooltip={
                    <>
                        <div>ENTER THE CITY</div>
                        <b className={tooltipBoldClass}>{tutorialCustomer.city}</b>
                    </>
                }
            />
            <div className='StateZip'>
                <Select
                    className='State'
                    name='customer.state'
                    validate={validateCustomerState}
                    disabled={!!values.originalCustomer?.state && (!validateCustomerState(values.originalCustomer.state) && parms?.clerkInfo.type === 'L')}
                    trainingFocused={isCustomerStateTutorial}
                    trainingTooltip={
                        <>
                            <div>ENTER THE STATE</div>
                            <b className={tooltipBoldClass}>{tutorialCustomer.state}</b>
                        </>
                    }
                >
                    <option value=''>STATE</option>
                    {stateOptions}
                </Select>
                <AdvancedField type='text'
                    className='Zip'
                    name='customer.zip'
                    placeholder='ZIP CODE'
                    maxLength={5}
                    validate={validateCustomerZip}
                    upperCase={true}
                    numeric={true}
                    disabled={!!values.originalCustomer?.zip && (!validateCustomerZip(values.originalCustomer.zip) && parms?.clerkInfo.type === 'L')}
                    trainingFocused={isCustomerZipTutorial}
                    trainingTooltip={
                        <>
                            <div>ENTER THE ZIP</div>
                            <b className={tooltipBoldClass}>{tutorialCustomer.zip}</b>
                        </>
                    }
                />
            </div>
            {customerChanged &&
                <div className='LegalMessage'>Customer information will be saved upon payment.</div>
            }
        </fieldset>
    );
}