import { useEffect, useState } from "react";
import styles from './Sidebar.module.css';

interface Props {
    promotion: number,
    current?: boolean
}

export default function PromotionImage({ promotion, current }: Props) {

    const [opening, setOpening] = useState(false);
    const [closing, setClosing] = useState(!current);

    const promotionURL = `${process.env.REACT_APP_ASSET_URL}/${process.env.REACT_APP_PROMOTION_PATH}/${promotion}`

    useEffect(() => {
        if (current) {
            setTimeout(() => setOpening(true), 2000);
        } else {
            setOpening(false);
            setClosing(true);
            setTimeout(() => setClosing(false), 2000);
        }
    }, [current]);

    return (
        <img
            src={promotionURL}
            alt={'PayHereNetwork'}
            className={
                closing ? styles.closing
                    : opening ? styles.opening
                        : ''
            } />
    );
}