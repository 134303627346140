import NewIcon from '../../../../images/phnNew.svg';
import type { Preview, Message } from './NotificationPopup';
import styles from './NotificationPopup.module.css';
import { getClass } from 'utilities/classnames';
import { useTimeZone } from 'components/hooks/TimeZone';

const loadingList = Array.from({ length: 6 }, (v, index) =>
    <li key={index} className={styles.preview}>Loading...</li>
);

interface Props {
    previews: Preview[],
    loading: boolean,
    getMessage: (preview: Omit<Message, 'html'>) => Promise<void>,
    showingAll: boolean,
    showAll: () => void
}

export default function NotificationList({ previews, loading, getMessage, showingAll, showAll }: Props) {
    const { localFormat } = useTimeZone();
    const limit = showingAll ? previews.length : 6;

    return (
        <>
            <ul className={styles.previewList}>
                {!loading &&
                    previews.slice(0, limit).map((preview, index) =>
                        <li key={preview.id}
                            onClick={() => getMessage({ ...preview, index: index })}
                            className={getClass([
                                styles.preview,
                                preview.unread && styles.new
                            ])}>
                            <div className='PreviewListMarker' >
                                {preview.unread &&
                                    <img className={styles.newIcon + ' ' + styles.previewImage} src={NewIcon} alt="New"></img>
                                }
                                {!preview.unread &&
                                    <span className={styles.previewDate}>{localFormat(preview.sentTime, 'MM/dd/yy')}</span>
                                }
                            </div>
                            <span>{preview.preview}</span>
                        </li>
                    )
                }
                {loading && loadingList}
            </ul>
            {!showingAll &&
                <div>
                    <button className={styles.button} type='button' onClick={showAll}>See all</button>
                </div>
            }
        </>
    );
}