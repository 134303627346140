import { APIContext } from "components/HigherOrder/APIController/APIController";
import { AuthContext } from "components/HigherOrder/AuthController/AuthController";
import type { Banner } from "components/HigherOrder/ParmController/ParmController";
import { ParmContext } from "components/HigherOrder/ParmController/ParmController";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BasicPopup from "../Popups/BasicPopup/BasicPopup";
import Processing from "../Processing/Processing";
import { isElectron } from "utilities/Environment";
import styles from './Banner.module.css';
import { getClass } from "utilities/classnames";

interface Props {
    banner: Banner,
    current: boolean
}

interface PostResponse {
    message: string
}

export default function BannerComponent({ banner: { actionType, actionValue, id }, current }: Props) {

    const [posting, setPosting] = useState(false);
    const [message, setMessage] = useState('');
    const { push } = useHistory();
    const { parms } = useContext(ParmContext);
    const { failoverFetch } = useContext(APIContext);
    const { auth } = useContext(AuthContext);

    const [entering, setEntering] = useState(false);
    const [exiting, setExiting] = useState(!current);

    const bannerURL = `${process.env.REACT_APP_ASSET_URL}/${process.env.REACT_APP_BANNER_PATH}/${id}`

    const bannerActionSupported =
        (actionType === 'TAB'
            && ((actionValue === '/BillPay' && parms?.bpActive && parms.parameters.billPayActive)
                || (actionValue === '/AgeVerification' && parms?.parameters.ageVerifActive)
                || (actionValue === '/CashLoad' && parms?.parameters.amazonCashActive)
                || (actionValue === '/NetSpend' && parms?.parameters.netspendActive)
                || (actionValue === '/MoneyOrder' && (("serial" in navigator) && parms?.moActive))
                || (actionValue === '/MobileTopUp' && parms?.parameters.prePayActive)
                || (actionValue === '/DigitalGiftCards' && parms?.parameters.giftCardActive)
                || (actionValue === '/Support')))
        || (actionType === 'URL' && !isElectron)
        || (actionType === 'API' && !!auth);

    const handleBannerClick = async () => {
        if (bannerActionSupported) {
            if (actionType === 'TAB') {
                push(actionValue);
            } else if (actionType === 'URL') {
                window.open(actionValue, '_blank');
            } else if (actionType === 'API') {
                setPosting(true);
                try {
                    const response = await failoverFetch(actionValue, {
                        method: 'POST',
                        body: new URLSearchParams({
                            authToken: auth!.authToken
                        })
                    })
                    const data = JSON.parse(response) as PostResponse;
                    setMessage(data.message);
                } catch (error) {
                    console.error(error);
                } finally {
                    setPosting(false);
                }
            }
        }
    };

    const clearMessage = useCallback(() => {
        setMessage('');
    }, []);

    useEffect(() => {
        if (current) {
            setEntering(true);
        } else {
            setEntering(false);
            setExiting(true);
            setTimeout(() => setExiting(false), 2000);
        }
    }, [current]);

    return (
        <>
            <Processing open={posting} hideLoading />
            <BasicPopup
                className={styles.bannerMessage}
                onClose={clearMessage}
                noOverlay
                fadeIn
                open={!!message}>
                <div>{message}</div>
            </BasicPopup>
            <div className={getClass([
                styles.banner,
                bannerActionSupported && styles.action,
                exiting && styles.exiting,
                entering && styles.entering
            ])}
                onClick={handleBannerClick}
                style={{ backgroundImage: `url(${bannerURL})` }} />
        </>
    );
}