import { useContext } from 'react';
import BasicPopup from '../../../../Common/Popups/BasicPopup/BasicPopup';
import { TrainingContext } from '../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import './TrainingSelectionPopup.css';

interface Props {
    onClose: () => void,
    startnewCustomerTraining: () => void,
    startPreviousCustomerTraining: () => void,
    newCustomerTrainingComplete: boolean,
    previousCustomerTrainingComplete: boolean,
    open: boolean
}

export default function TrainingSelectionPopup({ onClose, startnewCustomerTraining, startPreviousCustomerTraining,
    newCustomerTrainingComplete, previousCustomerTrainingComplete, open }: Props) {

    const { toggleTraining, isGlobalTraining, isCurrentPageTraining } = useContext(TrainingContext);

    const handleClose = () => {
        onClose?.();
        toggleTraining();
    }

    const newCustomerTrainingClass = `${(isGlobalTraining && newCustomerTrainingComplete) ? 'disabled' : ''}`;

    const previousCustomerTrainingClass = `${(isGlobalTraining && previousCustomerTrainingComplete) ? 'disabled' : ''}`;

    return (
        <BasicPopup
            className='TrainingSelectionPopup'
            onClose={handleClose}
            darkMode noOverlay fadeIn
            open={isCurrentPageTraining && open}>
            <div className='TrainingSelectionButtons'>
                <button type='button' className={newCustomerTrainingClass} onClick={startnewCustomerTraining}>NEW BILL PAY CUSTOMER</button>
                <button type='button' className={previousCustomerTrainingClass} onClick={startPreviousCustomerTraining}>PREVIOUS BILL PAY CUSTOMER</button>
            </div>
            <h4>SELECT THE TUTORIAL YOU WOULD LIKE TO VIEW</h4>
        </BasicPopup>
    );
}