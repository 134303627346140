import type { ReactNode } from 'react';
import { createContext, useState, useContext, useCallback, useMemo } from 'react';
import { restartApp, restartPC } from '../../../utilities/Tools';
import packageJson from '../../../../package.json';
import { useTimeout } from './Timeout/Timeout';
import { useStatus } from './Status/Status';
import { useUptimeStatus } from './UptimeStatus/UptimeStatus';
import { usePageTimer } from './PageTimer/PageTimer';
import type { Auth } from '../AuthController/AuthController';
import { AuthContext } from '../AuthController/AuthController';
import type { RawParms } from '../ParmController/ParmController';
import { ParmContext } from '../ParmController/ParmController';
import { useHistory } from 'react-router-dom';
import { useEventListener } from 'usehooks-ts';
import { FetchError, isOnline } from 'utilities/network';

const mockStatusRequest = (inAuth?: Pick<Auth, "authToken" | "agentId"> | null, statusCode?: number, shouldClearParameters?: boolean) =>
    Promise.resolve() as Promise<RawParms | undefined>

export const APIContext = createContext({
    forceServerChange: () => { },
    failoverFetch: (input: string, init?: RequestInit | undefined, currentServerNum?: number) => Promise.resolve(''),
    getParameters: (auth?: Auth) => Promise.resolve() as Promise<RawParms | undefined>,
    statusRequest: mockStatusRequest,
    handleLogout: () => { },
    serverURL: process.env.REACT_APP_PHNSERVER_API_URL!,
    serverDown: false
});

const processMessages = (messages: string[]) => {
    messages.forEach((message) => {
        if (message.includes('RESTART_PC')) {
            restartPC();
        } else if (message.includes('FORCE_APP_RESTART')) {
            restartApp();
        }
    });
}

const validErrorSet = new Set([400, 409, 403, 560]);

interface Props {
    children: ReactNode
}

/**
 * Top-level component for working with PHN server calls.
 * Main job is to manage failovers.
 */
export default function APIController({ children }: Props) {

    const { auth, setAuth } = useContext(AuthContext);
    const hasAuth = !!auth;
    const authToken = auth?.authToken;
    const agentId = auth?.agentId;
    const { parms, loggedIn, clearParms, setRawParms } = useContext(ParmContext);

    const { push } = useHistory();

    const [serverNum, setServerNum] = useState(0);
    const [serverDown, setServerDown] = useState(false);

    const availableUrls = useMemo(() => {
        const urls = parms?.parameters.dataURLs ?? [];
        const defaultURLs = urls.length ? [] : [process.env.REACT_APP_PHNSERVER_API_URL!];
        return [
            ...urls,
            ...defaultURLs
        ];
    }, [parms?.parameters.dataURLs]);

    const logoutIfOffline = useCallback(async () => {
        const online = await isOnline();
        if (!online) {
            setAuth(null);
        }
    }, [setAuth]);

    const failoverFetch = useCallback(async (input: string, init?: RequestInit | undefined, currentServerNum = serverNum): Promise<string> => {
        const serverURL = availableUrls[currentServerNum] ?? process.env.REACT_APP_PHNSERVER_API_URL!;
        try {
            const response = await fetch(serverURL + input, init);
            if (response.ok) {
                const text = await response.text();
                if (text.includes('Failed to Authenticate')) {
                    setAuth(null);
                    throw new FetchError(response);
                }
                setServerNum(currentServerNum);
                setServerDown(false);
                return text;
            } else if (response.status === 560) {
                setAuth(null);
                setServerDown(true);
            }
            throw new FetchError(response);
        } catch (error) {
            const isAbortError = error instanceof Error && error.name === 'AbortError';
            if (!isAbortError) {
                const isValidError = error instanceof FetchError && validErrorSet.has(error.response.status);
                if (!isValidError) {
                    const nextServerNum = (currentServerNum + 1) % availableUrls.length;
                    if (nextServerNum > 0) {
                        return failoverFetch(input, init, nextServerNum);
                    }
                    logoutIfOffline();
                }
                console.error(error);
            }
            throw error;
        }
    }, [availableUrls, logoutIfOffline, serverNum, setAuth]);

    /** 
     * Status request resides up here, since it's used throughout the program.
     */
    const statusRequest = useCallback(async (inAuth?: Pick<Auth, "authToken" | "agentId"> | null, statusCode = 0, shouldClearParameters = false) => {
        if (inAuth || hasAuth) {
            const response = await failoverFetch('/statusNew?' + new URLSearchParams({
                authToken: inAuth?.authToken ?? authToken!,
                agentId: inAuth?.agentId ?? agentId!,
                moPrinterState: '0',
                bpPrinterState: '0',
                swVersion: packageJson.version,
                shivaState: statusCode.toString()
            }));

            if (response) {
                const data = JSON.parse(response) as RawParms;
                if (Number(inAuth?.agentId ?? agentId!) > -1) {
                    if (shouldClearParameters) {
                        setAuth(null);
                        clearParms();
                    }
                    processMessages(data.messages);
                    setRawParms(data, inAuth ?? undefined);
                }
                return data;
            }
        }
    }, [hasAuth, failoverFetch, authToken, agentId, setRawParms, setAuth, clearParms]) as typeof mockStatusRequest;

    /** 
     * Logs out and sends status to our server.
     */
    const handleLogout = useCallback(() => {
        if (hasAuth) {
            statusRequest(undefined, 2);
            setAuth(null);
            push('/Login');
        }
    }, [hasAuth, push, setAuth, statusRequest]);

    const getParameters = useCallback((inAuth?: Auth | null) => {
        localStorage.setItem("swVersion", packageJson.version);
        return statusRequest(inAuth, -1);
    }, [statusRequest]);

    const forceServerChange = useCallback(() => {
        setServerNum((currentServerNum) => {
            const nextServerNum = (currentServerNum + 1) % availableUrls.length;
            return nextServerNum;
        });
    }, [availableUrls.length])

    const handlePageClose = useCallback(() => {
        if (loggedIn) {
            statusRequest(undefined, 2);
        }
    }, [loggedIn, statusRequest])

    useEventListener('beforeunload', handlePageClose);

    useStatus(statusRequest);
    useTimeout(handleLogout);
    useUptimeStatus(failoverFetch, serverDown);
    usePageTimer(failoverFetch);

    return (
        <APIContext.Provider value={{
            forceServerChange,
            failoverFetch,
            getParameters,
            statusRequest,
            handleLogout,
            serverURL: availableUrls[serverNum] ?? process.env.REACT_APP_PHNSERVER_API_URL!,
            serverDown
        }}>
            {children}
        </APIContext.Provider>
    );
}