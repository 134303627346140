import type { ReactNode } from 'react';
import { createContext, useContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { SettingsContext } from '../SettingsController/SettingsController';
import { ParmContext } from '../ParmController/ParmController';
import { useEventListener } from 'usehooks-ts';
import { serverParse, useTimeZone } from 'components/hooks/TimeZone';

export const MOContext = createContext({
    printMoneyOrder: (amount: number, serialNumber: number, tracer: string, agentId: number, date: string, time: string, force?: boolean) => Promise.resolve(),
    displaySerialNumber: (seconds?: number) => Promise.reject('Port Not Found!') as Promise<void>,
    resetStatus: () => Promise.resolve(),
    getStatus: () => Promise.resolve(),
    findPrinter: () => Promise.reject('Port Not Found!') as Promise<void>,
    ejectMoneyOrder: (tracer?: string, serialNumber?: number) => Promise.resolve(),
    getMOCheckDigit: (offset: number, moneyOrderCheck?: number) => 0 as number,
    getMOSerialNumber: (offset: number, moneyOrderNumber?: number) => 0 as number,
    incrementMOSerialNumber: (offset: number) => { },
    getMOFee: (amount: number | string) => 0 as number,
    currentPrinterInfo: {
        found: false as boolean,
        ready: false as boolean | undefined
    },
    moneyOrdersLeft: '',
    moErrors: [] as string[],
    moEquipment: {
        serial: null as string | null,
        model: null as string | null,
        date: null as string | null,
        revision: null as string | null
    },
    nextMONumber: 0,
    nextMOCheck: 0
});

// Status codes sent by printer, in order by the index of the returned binary string.
const statusCodes = [
    { status: "Printer failed (print head jam)", resetRequired: true },
    { status: "TOF mark not found (paper jam)", resetRequired: true },
    { status: "Incorrect password", resetRequired: true },
    { status: "Invalid amount", resetRequired: true },
    { status: "Right sensor on mark", resetRequired: false },
    { status: "Graphics logo available", resetRequired: false },
    { status: "Printer failed (watch dog timed out)", resetRequired: true },
    { status: "Power failed (restarted)", resetRequired: true },
    { status: "MOs out of Seq", resetRequired: true },
    { status: "Door has been unlocked", resetRequired: true },
    { status: "Test mode", resetRequired: true },
    { status: "Power failed during command", resetRequired: true },
    { status: "Item voided", resetRequired: false }, // It really does require a reset, but it isn't considered an error...
    { status: "Memory was initialized (first-time power-up)", resetRequired: true },
    { status: "Door is unlocked (this is the current condition of the door)", resetRequired: false },
    { status: "Mid-mark error", resetRequired: true },
    { status: "Printer on hold (error detected - plunger down)", resetRequired: true },
    { status: "", resetRequired: false },
    { status: "", resetRequired: false },
    { status: "", resetRequired: false },
    { status: "", resetRequired: false },
    { status: "", resetRequired: false },
    { status: "Left sensor on Top Of Form mark (TOF)", resetRequired: false },
    { status: "Solenoid energized", resetRequired: false },
    { status: "ROM failed", resetRequired: true },
    { status: "RAM failed", resetRequired: true },
    { status: "LOAD button pressed", resetRequired: true },
    { status: "TEST button pressed", resetRequired: false }
]

const getTimeoutPromise = (milliseconds: number): Promise<never> =>
    new Promise((resolve, reject) => {
        setTimeout(reject, milliseconds, 'Error: Timed out waiting for response from printer.');
    });

const getCheckSum = (message: string) =>
    [...message].reduce((checkSum, character) =>
        checkSum + character.charCodeAt(0), 0);

const addCheckSum = (message: string) =>
    message + getCheckSum(message) + '\r\n';

const validCheckSum = (message: string, checkSum: number) =>
    getCheckSum(message) === checkSum;

const getMark = (serialNumber: number, force: boolean) => {
    if (!force) {
        return (serialNumber) % 3 === 0 ? 'Y' : 'N';
    }
    return '';
}

const readFromPrinter = async (port: SerialPort) => {
    const textDecoder = new TextDecoderStream();
    const readableStreamClosed = port.readable!.pipeTo(textDecoder.writable);
    const reader = textDecoder.readable.getReader();

    const timeoutPromise = getTimeoutPromise(10000);
    let buffer = '';
    try {
        while (true) {
            const { value, done } = await Promise.race([reader.read(), timeoutPromise]);
            if (value) {
                buffer += value;
            }
            const messages = buffer.split('\r\n');
            if (messages.length > 1) {
                const messageStart = messages[0].indexOf('@');
                const message = messages[0].substring(messageStart);
                const segments = message.split('\\');
                const messageToTest = `${segments[0]}\\${segments[1]}\\`;
                const checkSumToTest = Number(segments[2]);
                if (segments.length === 3 && messageStart >= 0) {
                    if (validCheckSum(messageToTest, checkSumToTest)) {
                        return segments[1].split(',');
                    }
                    throw new Error('Bad CheckSum From Certex Printer!');
                }
                throw new Error('Unexpected response format from Certex Printer!')
            }
            if (done) {
                throw new Error('Printer response ended prematurely.');
            }
        }
    } finally {
        await reader.cancel().catch((error) => console.error(error));
        await readableStreamClosed.catch((error) => console.error(error));
    }
};

const getNextMONumber = (packstart1: string) =>
    Number(packstart1.substring(0, packstart1.length - 1));

const getNextMOCheck = (packstart1: string) =>
    Number(packstart1.charAt(packstart1.length - 1));

const initialState = {
    printer: null as null | SerialPort,
    moErrors: [] as string[],
    moEquipment: {
        serial: null as null | string,
        model: null as null | string,
        date: null as null | string,
        revision: null as null | string
    }
};

interface Props {
    children: ReactNode
}

/**
 * Top-level component for working with, and keeping the state of, the Money Order printer.
 * Relies heavily on serialport, which is a very low-level form of communication.
 */
export default function MoneyOrderController({ children }: Props) {

    const { parms, setParameter } = useContext(ParmContext);
    const packstart1 = parms?.parameters.packstart1 ?? '';
    const { setSettings } = useContext(SettingsContext);
    const { localFormat } = useTimeZone();

    const [printer, setPrinter] = useState(initialState.printer);
    const printerRef = useRef(initialState.printer);
    const [moErrors, setMOErrors] = useState(initialState.moErrors);
    const moErrorsRef = useRef(initialState.moErrors);
    const [moEquipment, setMOEquipment] = useState(initialState.moEquipment);
    const moEquipmentRef = useRef(initialState.moEquipment);

    const updateParameters = useCallback((parameters: string[]) => {
        const moEquipment = {
            serial: parameters[0],
            model: parameters[1],
            date: parameters[2],
            revision: parameters[3]
        };

        let printerChanged = false;
        setSettings((currentSettings) => {
            if (currentSettings.lastMOSerial !== moEquipment.serial) {
                if (currentSettings.lastMOSerial) {
                    setParameter({ packstart1: '' });
                    printerChanged = true;
                }
                return { ...currentSettings, lastMOSerial: moEquipment.serial }
            }
            return currentSettings;
        });

        setMOEquipment(moEquipment);
        moEquipmentRef.current = moEquipment;
        return printerChanged;
    }, [setParameter, setSettings])

    const updateStatus = useCallback((status: string) => {
        const moErrors = [] as string[];
        [...status].forEach((character, index) => {
            if (character === '1' && statusCodes[index].resetRequired) {
                moErrors.push(statusCodes[index].status);
                if (index === 2) { //Incorrect Password Error, clear equipment
                    setMOEquipment(initialState.moEquipment);
                    moEquipmentRef.current = initialState.moEquipment;
                }
            }
        });
        setMOErrors(moErrors);
        moErrorsRef.current = moErrors;
        return moErrors;
    }, []);

    const processStatusResponse = useCallback((values: string[]) => {
        if (values?.[0] === 'S') {
            return updateStatus(values[1]);
        }
        throw new Error('Failed to parse printer status');
    }, [updateStatus]);

    const processParametersResponse = useCallback((values: string[]) => {
        if (values?.[0] === 'P') {
            return updateParameters(values.slice(1));
        }
        throw new Error('Failed to parse printer parameters');
    }, [updateParameters]);

    const findPrinter = useCallback(async () => {
        if (!printerRef.current) {
            const port = await navigator.serial.requestPort({
                filters: [
                    { usbVendorId: 0x0403, usbProductId: 0x6001 }, //Usb to Usb Connection
                    { usbVendorId: 0x0403, usbProductId: 0x6015 }, //DB25 to Usb Connection
                ]
            });
            await port.open({ baudRate: 9600 });
            await port.setSignals({ break: false });
            setPrinter(port);
            printerRef.current = port;
        }
        return printerRef.current;
    }, []);

    const writeToPrinter = useCallback(async (data: string) => {
        const port = await findPrinter();
        const textEncoder = new TextEncoderStream();
        const writableStreamClosed = textEncoder.readable.pipeTo(port.writable!);
        const writer = textEncoder.writable.getWriter();
        try {
            await writer.write(data);
        } finally {
            await writer.close().catch((error) => console.error(error));
            await writableStreamClosed.catch((error) => console.error(error));
        }
        return port;
    }, [findPrinter])

    const getParameters = useCallback(async () => {
        if (!moEquipmentRef.current.serial) {
            const message = addCheckSum("@\\P\\");
            const port = await writeToPrinter(message);
            const response = await readFromPrinter(port);
            const printerChanged = processParametersResponse(response);
            if (printerChanged) {
                throw new Error("MO Printer Changed Detected. Please re-enter Next MO configuration.");
            }
        }
        return moEquipmentRef.current;
    }, [processParametersResponse, writeToPrinter])

    const getParametersWithTimeout = useCallback(async () => {
        await Promise.race([getParameters(), getTimeoutPromise(10000)]);
    }, [getParameters]);

    const displaySerialNumber = useCallback(async (seconds = 15) => {
        const message = addCheckSum("@\\D,+ " + seconds + "\\");
        await writeToPrinter(message);
    }, [writeToPrinter])

    const displaySerialNumberWithTimeout = useCallback((seconds = 15) => {
        return Promise.race([displaySerialNumber(seconds), getTimeoutPromise(20000)]);
    }, [displaySerialNumber]);

    const getStatus = useCallback(async () => {
        const message = addCheckSum("@\\S,0\\");
        const port = await writeToPrinter(message);
        const response = await readFromPrinter(port);
        const errors = processStatusResponse(response);
        if (errors.length > 0) {
            throw new Error(errors[0]);
        }
    }, [processStatusResponse, writeToPrinter])

    const getStatusWithTimeout = useCallback(async () => {
        return Promise.race([getStatus(), getTimeoutPromise(10000)]);
    }, [getStatus]);

    const resetStatus = useCallback(async () => {
        const message = addCheckSum("@\\S,1\\");
        await writeToPrinter(message)
        await getStatus();
    }, [getStatus, writeToPrinter])

    const resetStatusWithTimeout = useCallback(() => {
        return Promise.race([resetStatus(), getTimeoutPromise(10000)]);
    }, [resetStatus]);

    const getPassword = useCallback(async () => {
        const moEquipment = await getParameters();
        const swaps = [[4, 8], [6, 0], [3, 9], [1, 2], [4, 5], [10, 5], [7, 2], [11, 1]];
        let password = Number(moEquipment.serial);
        password += 854753685743;
        password %= 1000000000000;
        const passwordArray = Array.from(password.toString()).map(Number);
        swaps.forEach((swap) => {
            const temp = passwordArray[swap[0]];
            passwordArray[swap[0]] = passwordArray[swap[1]];
            passwordArray[swap[1]] = temp;
        });
        password = Number(passwordArray.join(''));
        password += 71199733671;
        return password;
    }, [getParameters]);

    const getMOFee = useCallback((amount: string | number) => {
        const fee0 = (parms?.parameters.moFee0 ?? 0) * 100;
        const fee1 = (parms?.parameters.moFee1 ?? 0) * 100;
        const fee2 = (parms?.parameters.moFee2 ?? 0) * 100;
        const fee3 = (parms?.parameters.moFee3 ?? 0) * 100;
        const feeRange1 = (parms?.parameters.moFeeRange1 ?? 0) * 100;
        const feeRange2 = (parms?.parameters.moFeeRange2 ?? 0) * 100;
        const feeRange3 = (parms?.parameters.moFeeRange3 ?? 0) * 100;

        if (feeRange1 <= .01) {
            return fee0;
        } else if (feeRange1 >= 0 && Number(amount) <= feeRange1) {
            return fee0;
        } else if (feeRange2 <= feeRange1 || (feeRange2 > 0 && Number(amount) <= feeRange2)) {
            return fee1;
        } else if (feeRange3 <= feeRange2 || (feeRange3 > 0 && Number(amount) <= feeRange3)) {
            return fee2;
        } else if (feeRange3 > 0 && Number(amount) > feeRange3) {
            return fee3;
        } else {
            return fee0;
        }
    }, [parms?.parameters.moFee0, parms?.parameters.moFee1, parms?.parameters.moFee2, parms?.parameters.moFee3, parms?.parameters.moFeeRange1, parms?.parameters.moFeeRange2, parms?.parameters.moFeeRange3]);

    const printMoneyOrder = useCallback(async (amount: number, serialNumber: number, tracer: string, agentId: number, date: string, time: string, force = false) => {
        if (moErrorsRef.current.length > 0) {
            await resetStatus();
        }

        const parsedDate = serverParse(date + time, 'yyyyMMddHHmmss');
        const formattedDate = localFormat(parsedDate, 'MM/dd/yyyy');
        const password = await getPassword();
        const serialString = serialNumber.toString();
        const serialWithoutMark = Number(serialString.substring(0, serialString.length - 1));
        const message = addCheckSum(
            "@\\A"
            + "," + password      //PASSWORD
            + "," + amount                  //AMOUNT
            + "," + getMark(serialWithoutMark, force)  //MARK
            + "," + serialNumber            //COMMENT LINE 1
            + ",Trace:" + tracer            //COMMENT LINE 2
            + ",2"                          //FORMAT
            + "," + agentId                 //COMMENT LINE 0
            + "," + formattedDate //DATE
            + ","                           //TYPE
            + ","                           //LOGO
            + ",Amount: " + (amount / 100).toFixed(2) //STUB PRINT 1
            + ",Date: " + formattedDate //STUB PRINT 2
            + ",MO#: " + serialNumber       //STUB PRINT 3
            + ","                           //STUB PRINT 4
            + ","                           //PAYEE
            + ","                           //PURCHASER_NAME
            + ","                           //PURCHASER_ADDRESS
            + ",Agent:  " + agentId         //STUB PRINT 5
            + ",Fee:  " + (getMOFee(amount) / 100).toFixed(2) //STUB PRINT 6
            + "\\"
        );
        await writeToPrinter(message)
        await getStatus();
    }, [getMOFee, getPassword, getStatus, localFormat, resetStatus, writeToPrinter])

    const printMoneyOrderWithTimeout = useCallback((amount: number, serialNumber: number, tracer: string, agentId: number, date: string, time: string, force = false) => {
        return Promise.race([printMoneyOrder(amount, serialNumber, tracer, agentId, date, time, force), getTimeoutPromise(10000)]);
    }, [printMoneyOrder]);

    const printLine = useCallback(async (lineNum: number, data: string) => {
        const password = await getPassword();
        const message = addCheckSum(
            "@\\K"
            + "," + password
            + `,${lineNum}`
            + `,${data}`
            + "\\"
        );
        await writeToPrinter(message)
        await getStatus();
    }, [getPassword, getStatus, writeToPrinter])

    const nextMONumber = getNextMONumber(packstart1);

    const ejectMoneyOrder = useCallback(async (tracer = 'MANUAL VOID', serialNumber = nextMONumber) => {
        await printLine(25, '  ----------VOID------------');
        await printLine(26, `  Agent: ${parms?.clerkInfo.agentId}`);
        await printLine(27, `  Date: ${localFormat(new Date(), 'yyyy-MM-dd')}`);
        await printLine(28, `  Time: ${localFormat(new Date(), 'HH:mm:ss zzz')}`);
        await printLine(29, `  Tracer: ${tracer}`);
        await printLine(30, `  Serial: ${serialNumber}`);
        await printLine(31, '  ----------VOID------------');
        await printLine(36, '');
    }, [localFormat, nextMONumber, parms?.clerkInfo.agentId, printLine])

    const ejectMoneyOrderWithTimeout = useCallback((tracer = 'MANUAL VOID', serialNumber = nextMONumber) => {
        return Promise.race([ejectMoneyOrder(tracer, serialNumber), getTimeoutPromise(10000)]);
    }, [ejectMoneyOrder, nextMONumber]);

    const currentPrinterInfo = useMemo(() => ({
        found: !!printer,
        ready: !!printer?.writable && !!printer?.readable && moErrors.length === 0
    }), [moErrors.length, printer]);

    const getMoneyOrdersLeft = useCallback((nextMONumber: number) => {
        if (parms?.moPacks) {
            for (const pack of parms.moPacks) {
                if (nextMONumber >= pack.firstInSeries && nextMONumber <= pack.lastInSeries) {
                    return (pack.lastInSeries - (nextMONumber - 1)).toString();
                }
            }
        }
        return 'N/A';
    }, [parms?.moPacks]);

    const moneyOrdersLeft = getMoneyOrdersLeft(nextMONumber);

    const getMOSerialNumber = useCallback((offset: number, serialNumber = nextMONumber) => {
        return serialNumber + offset;
    }, [nextMONumber])

    const nextMOCheck = getNextMOCheck(packstart1);

    const getMOCheckDigit = useCallback((offset: number, moneyOrderCheck = nextMOCheck) => {
        let checkDigit = moneyOrderCheck - offset;
        if (checkDigit > 8) {
            checkDigit %= 9;
        } else if (checkDigit < 0) {
            checkDigit = (8 + (checkDigit % 9 + 1)) % 9;
        }
        return checkDigit;
    }, [nextMOCheck])

    const incrementMOSerialNumber = useCallback((offset: number) => {
        setParameter((currentParameters) => {
            const nextMONumber = getNextMONumber(currentParameters.packstart1);
            const nextMOCheck = getNextMOCheck(currentParameters.packstart1);
            const moneyOrdersLeft = getMoneyOrdersLeft(nextMONumber);

            if (Number(moneyOrdersLeft) <= offset) {
                return { packstart1: '' };
            }
            return { packstart1: getMOSerialNumber(offset, nextMONumber) + '' + getMOCheckDigit(offset, nextMOCheck) }
        });
    }, [getMOCheckDigit, getMOSerialNumber, getMoneyOrdersLeft, setParameter])

    const handleDisconnect = (event: Event) => {
        console.warn('Printer closed', event);
        setPrinter(initialState.printer);
        printerRef.current = initialState.printer;
        setMOErrors(initialState.moErrors);
        moErrorsRef.current = initialState.moErrors;
        setMOEquipment(initialState.moEquipment);
        moEquipmentRef.current = initialState.moEquipment;
    }

    useEffect(() => {
        if ("serial" in navigator) {
            navigator.serial.addEventListener('disconnect', handleDisconnect);
            return () => navigator.serial.removeEventListener('disconnect', handleDisconnect);
        }
    }, []);

    const handlePageClose = useCallback(async () => {
        if (printerRef.current) {
            try {
                await printerRef.current.close();
            } catch (error) {
                console.error(error);
            }
        }
    }, [])

    useEventListener('beforeunload', handlePageClose);

    return (
        <MOContext.Provider value={{
            printMoneyOrder: printMoneyOrderWithTimeout,
            displaySerialNumber: displaySerialNumberWithTimeout,
            resetStatus: resetStatusWithTimeout,
            getStatus: getStatusWithTimeout,
            findPrinter: getParametersWithTimeout,
            ejectMoneyOrder: ejectMoneyOrderWithTimeout,
            getMOCheckDigit,
            getMOSerialNumber,
            incrementMOSerialNumber,
            getMOFee,
            currentPrinterInfo,
            moneyOrdersLeft,
            moErrors,
            moEquipment,
            nextMONumber,
            nextMOCheck
        }}>
            {children}
        </MOContext.Provider>
    );
}