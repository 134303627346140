import type { TableLine } from "components/HigherOrder/PrintController/PrintController";
import { instanceOfImageLine, instanceOfTextLine, type LineStyle, type PrintOptions, type PrintRequest, instanceOfTableLine } from "components/HigherOrder/PrintController/PrintController";
import { SettingsContext } from "components/HigherOrder/SettingsController/SettingsController";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";
import { supportsUSBPrinting } from "utilities/Environment";

const getTextLineBytes = (text: string) =>
    text.split('').map(char => char.charCodeAt(0));

const getStyleByte = (style: LineStyle = {}) => {
    const smallFontBytes = style.smallFont ? (1 << 0) : 0;
    const emphasizedBytes = style.emphasized ? (1 << 3) : 0;
    const doubleHeightBytes = style.doubleSize ? (1 << 4) : 0;
    const doubleWidthBytes = style.doubleSize ? (1 << 5) : 0;
    const underlineBytes = style.underline ? (1 << 7) : 0;
    return smallFontBytes | emphasizedBytes | doubleHeightBytes | doubleWidthBytes | underlineBytes;
}

const ESC = 0x1B;
const LF = 0x0A;
const GS = 0x1D;

const initializeCommand = [ESC, 0x40]; //ESC @
const setDefaultLineSpacingCommand = [ESC, 0x32]; //ESC 2
const setNoLineSpacingCommand = [ESC, 0x33, 1]; //ESC 3
const setCenteredCommand = [ESC, 0x61, 1]; // ESC a
const feedAndPartialCutCommand = [GS, 0x56, 66, 1]; // GS V
const getSetStyleCommand = (style?: LineStyle) => [ESC, 0x21, getStyleByte(style)]; //ESC i

const getBitImageCommand = (dotMatrix: number[][]) => {
    const height = dotMatrix.length;
    const command = [] as number[];
    for (let verticalSlice = 0; verticalSlice < height; verticalSlice += 3) {
        const width = dotMatrix[verticalSlice].length;
        command.push(ESC, 0x2a, 33, width % 256, Math.floor(width / 256)); // ESC * 
        for (let x = 0; x < width; x++) {
            for (let y = verticalSlice; y < verticalSlice + 3; y++) {
                if (y < height) {
                    command.push(dotMatrix[y][x]);
                } else {
                    command.push(0);
                }
            }
        }
        command.push(LF);
    }
    return command;
}

const getColumnWidths = (tableLines: TableLine[]) => {
    const widths = Array(tableLines[0].columns.length).fill(1) as number[];
    for (const { columns } of tableLines) {
        columns.forEach((column, index) =>
            widths[index] = Math.max(widths[index], column.length)
        );
    }
    return widths;
};

const tableToTextLines = (tableLines: TableLine[], columnWidths: number[]) =>
    tableLines.map(({ columns, style }) => ({
        text:
            columns.map((column, index) =>
                column.padEnd(columnWidths[index])
            ).join(' '),
        style
    }));

const getTableLines = (printRequest: PrintRequest, startIndex: number) => {
    const tableLines = [] as TableLine[];
    for (let i = startIndex; i < printRequest.length && instanceOfTableLine(printRequest[i]); i++) {
        tableLines.push(printRequest[i] as TableLine);
    }
    return tableLines;
};

const getPrintBytes = (printRequest: PrintRequest, dotMatrixMap: Map<string, number[][]>) => {
    const printBytes = [...initializeCommand, ...setCenteredCommand] as number[];
    for (let i = 0; i < printRequest.length; i++) {
        const requestLine = printRequest[i];
        if (instanceOfTextLine(requestLine)) {
            printBytes.push(...getSetStyleCommand(requestLine.style));
            printBytes.push(...getTextLineBytes(requestLine.text));
        } else if (instanceOfImageLine(requestLine) && dotMatrixMap.has(requestLine.url)) {
            const dotMatrix = dotMatrixMap.get(requestLine.url)!;
            printBytes.push(...setNoLineSpacingCommand);
            printBytes.push(...getBitImageCommand(dotMatrix));
            printBytes.push(...setDefaultLineSpacingCommand);
        } else if (instanceOfTableLine(requestLine)) {
            const tableLines = getTableLines(printRequest, i);
            const columnWidths = getColumnWidths(tableLines);
            const textLines = tableToTextLines(tableLines, columnWidths);
            for (const { text, style } of textLines) {
                printBytes.push(...getSetStyleCommand(style));
                printBytes.push(...getTextLineBytes(text));
                printBytes.push(LF);
            }
            i += (tableLines.length - 1);
        }
        printBytes.push(LF);
    }
    printBytes.push(...feedAndPartialCutCommand);
    printBytes.push(LF);
    return printBytes;
}

const filters = [
    { vendorId: 0x1d90, productId: 0x20f0 }, //Citizen CT-E351
    { vendorId: 0x076c, productId: 0x0302 } //Partner Tech RP-700
];
const vendorIdSet = new Set(filters.map(({ vendorId }) => vendorId));

const openPrinter = async (printer: USBDevice) => {
    if (!printer.opened) {
        await printer.open();
    }

    return printer;
}

const closePrinter = async (printer: USBDevice) => {
    if (printer.opened) {
        try {
            await printer.close();
        } catch (error) {
            console.error(error);
        }
    }
}

const claimInterface = async (printer: USBDevice) => {
    const config = printer.configurations[0];
    if (printer.configuration !== config) {
        await printer.selectConfiguration(config.configurationValue);
    }
    const iface = config.interfaces[0];
    if (!iface.claimed) {
        await printer.claimInterface(iface.interfaceNumber);
    }
    return iface;
}

const releaseInterface = async (printer: USBDevice, iface: USBInterface) => {
    if (iface.claimed) {
        try {
            await printer.releaseInterface(iface.interfaceNumber);
        } catch (error) {
            console.error(error);
        }
    }
}

const getEndpoint = ({ alternate: { endpoints } }: USBInterface) => {
    for (const endpoint of endpoints) {
        if (endpoint.direction === 'out') {
            return endpoint;
        }
    }
    throw new Error('Outward endpoint not found!');
}

export const getPrinterKey = ({ vendorId, productId }: USBDevice) =>
    vendorId + '_' + productId;

export function useThermalPrint(printRequest: PrintRequest, printOptions: PrintOptions) {

    const { settings: { printDuplicate, receiptPrinter, reportPrinter }, setSetting } = useContext(SettingsContext);
    const preferredPrinter = printOptions.printer === 'Receipt' ? receiptPrinter : reportPrinter;

    const [allowedPrinters, setAllowedPrinters] = useState<USBDevice[]>([]);
    const removeAllowedPrinter = useCallback((printer: USBDevice) => {
        const printerKey = getPrinterKey(printer);
        setAllowedPrinters((currentAllowedPrinters) =>
            currentAllowedPrinters.filter((allowedPrinter) =>
                getPrinterKey(allowedPrinter) !== printerKey
            )
        );
    }, [])
    const addAllowedPrinter = useCallback((printer: USBDevice) => {
        const printerKey = getPrinterKey(printer);
        setAllowedPrinters((currentAllowedPrinters) =>
            currentAllowedPrinters.filter((allowedPrinter) =>
                getPrinterKey(allowedPrinter) !== printerKey
            ).concat([printer])
        );
    }, []);

    const printerRef = useRef<USBDevice | null>(null);
    const dotMatrixMapRef = useRef(new Map<string, number[][]>());

    const loadImage = useCallback((img: HTMLImageElement) => {
        const width = img.naturalWidth * 2;
        const height = img.naturalHeight * 2;
        if (!dotMatrixMapRef.current.has(img.src) && width > 0 && height > 0) {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');
            if (context) {
                context.drawImage(img, 0, 0, width, height);
                const { data } = context.getImageData(0, 0, width, height);
                const greyscaleData = [] as number[];
                for (let i = 0; i < data.length; i += 4) {
                    const red = data[i];
                    const green = data[i + 1];
                    const blue = data[i + 2];
                    const alpha = data[i + 3];
                    const alphaRatio = alpha / 255;
                    const luminosity = red * 0.3 + green * 0.59 + blue * 0.11;
                    greyscaleData.push(Math.round((alphaRatio * luminosity) + ((1 - alphaRatio) * 255)));
                }
                const pixelGrid = Array.from(Array(height)).map(() => Array(width) as number[]);
                for (let i = 0; i < greyscaleData.length; i++) {
                    const x = i % width;
                    const y = Math.floor(i / width);
                    pixelGrid[y][x] = greyscaleData[i];
                }
                // Each bit of a byte represents a dot, aligned vertically. So we parse in vertical slices of 8.
                const dotMatrix = Array.from(Array(Math.ceil(height / 8))).map(() => Array(width) as number[]);
                for (let y = 0; y < height; y += 8) {
                    for (let x = 0; x < width; x++) {
                        let slice = 0;
                        for (let bit = 0; bit < 8 && y + bit < height; bit++) {
                            if (pixelGrid[y + bit][x] < 128) {
                                slice |= 1 << (7 - bit);
                            }
                        }
                        dotMatrix[y / 8][x] = slice;
                    }
                }
                dotMatrixMapRef.current.set(img.src, dotMatrix);
            }
        }
    }, []);

    const clearImages = useCallback(() => {
        dotMatrixMapRef.current.clear();
    }, []);

    const requestPrinter = useCallback(async (printerType = printOptions.printer) => {
        const printer = await navigator.usb.requestDevice({ filters });
        const printerKey = getPrinterKey(printer);
        if (printerType === 'Receipt') {
            setSetting({ receiptPrinter: printerKey });
        } else {
            setSetting({ reportPrinter: printerKey });
        }
        addAllowedPrinter(printer);
        if (printerType === printOptions.printer) {
            printerRef.current = printer;
        }
        return openPrinter(printer);
    }, [addAllowedPrinter, printOptions.printer, setSetting])

    const getPrinter = useCallback(async () => {
        if (!printerRef.current) {
            return requestPrinter();
        }
        return openPrinter(printerRef.current);
    }, [requestPrinter]);

    const sendBytes = useCallback(async (bytes: number[]) => {
        const printer = await getPrinter();
        const iface = await claimInterface(printer);
        try {
            const endpoint = getEndpoint(iface);
            const result = await printer.transferOut(endpoint.endpointNumber, new Uint8Array(bytes));
            if (result.status === 'stall') {
                //TODO
                await printer.clearHalt(endpoint.direction, endpoint.endpointNumber);
            }
        } finally {
            releaseInterface(printer, iface);
        }
    }, [getPrinter]);

    const shouldPrintDuplicate = printDuplicate
        && printOptions.printer === 'Receipt'
        && !printOptions.noDuplicate
        && !printOptions.reprint;

    const thermalPrint = useCallback(async () => {
        const printBytes = getPrintBytes(printRequest, dotMatrixMapRef.current);
        try {
            await sendBytes(printBytes);
            if (shouldPrintDuplicate) {
                await sendBytes(printBytes);
            }
        } catch (error) {
            console.error(error);
        }
    }, [printRequest, sendBytes, shouldPrintDuplicate]);

    const getAllowedPrinters = useCallback(async () => {
        try {
            const printers = (await navigator.usb.getDevices())
                .filter(({ vendorId }) => vendorIdSet.has(vendorId));

            setAllowedPrinters(printers);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleDisconnect = useCallback((event: USBConnectionEvent) => {
        const disconnectedKey = getPrinterKey(event.device);
        if (printerRef.current && getPrinterKey(printerRef.current) === disconnectedKey) {
            printerRef.current = null;
        }
        removeAllowedPrinter(event.device);
    }, [removeAllowedPrinter]);

    const handleConnect = useCallback((event: USBConnectionEvent) => {
        if (getPrinterKey(event.device) === preferredPrinter) {
            printerRef.current = event.device;
        }
        if (vendorIdSet.has(event.device.vendorId)) {
            addAllowedPrinter(event.device);
        }
    }, [addAllowedPrinter, preferredPrinter]);

    const handleUnload = () => {
        if (printerRef.current) {
            closePrinter(printerRef.current);
            printerRef.current = null;
        }
    };

    useEffect(() => {
        printerRef.current = allowedPrinters.find((printer) =>
            getPrinterKey(printer) === preferredPrinter
        ) ?? null;
    }, [allowedPrinters, preferredPrinter]);

    useEffect(() => {
        if (supportsUSBPrinting) {
            getAllowedPrinters();
        }
    }, [getAllowedPrinters]);

    useEffect(() => {
        if (supportsUSBPrinting) {
            navigator.usb.ondisconnect = handleDisconnect;
            return () => {
                navigator.usb.ondisconnect = null
            };
        }
    }, [handleDisconnect]);

    useEffect(() => {
        if (supportsUSBPrinting) {
            navigator.usb.onconnect = handleConnect;
            return () => {
                navigator.usb.onconnect = null;
            };
        }
    }, [handleConnect]);

    useEffect(() => {
        return () => handleUnload();
    }, []);

    useEventListener('beforeunload', handleUnload);

    return { thermalPrint, allowedPrinters, requestPrinter, loadImage, clearImages };
}