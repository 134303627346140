import type { ComponentPropsWithoutRef } from 'react';
import { useContext, useEffect, useRef } from 'react';
import { TrainingContext } from '../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { useFormikContext } from 'formik';
import { getTooltipClass, getTooltipTriggerClass } from 'utilities/tooltip';
import { getClass } from 'utilities/classnames';

interface Props extends ComponentPropsWithoutRef<'button'> {
    trainingFocused?: boolean,
    trainingTooltip?: JSX.Element,
    tooltipPlacement?: 'top' | 'bottom' | 'right' | 'left'
}

export default function AdvancedButton({ disabled, trainingFocused, className, children, type,
    trainingTooltip, tooltipPlacement, ...rest }: Props) {

    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const { isSubmitting } = useFormikContext();
    const { isCurrentPageTraining } = useContext(TrainingContext);

    const isTrainingFocused = isCurrentPageTraining && !!trainingFocused;

    useEffect(() => {
        if (isTrainingFocused) {
            buttonRef.current?.focus();
        }
    }, [isTrainingFocused]);

    return (
        <button  {...rest}
            ref={buttonRef}
            type={type ?? 'submit'}
            className={getClass([
                className ?? '',
                getTooltipTriggerClass(isTrainingFocused)
            ])}
            disabled={disabled || isSubmitting}
        >
            {children}
            {!!trainingTooltip &&
                <div className={getTooltipClass({ placement: tooltipPlacement })}>
                    {trainingTooltip}
                </div>
            }
        </button>
    );
}