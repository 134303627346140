import type { ReactNode } from 'react';
import { useContext, createContext, useState, useCallback } from 'react';
import type { ParsedParms, RawParms } from '../ParmController/ParmController';
import { ParmContext } from '../ParmController/ParmController';
import { APIContext } from '../APIController/APIController';
import type { Auth } from '../AuthController/AuthController';
import { AuthContext } from '../AuthController/AuthController';
import { SettingsContext } from '../SettingsController/SettingsController';

interface SurveyOption {
    id: number,
    text: string
}

export interface SurveyQuestion {
    id: number,
    required: 'Y' | 'N',
    text: string,
    type: 'OPTION' | 'WRITE',
    options: SurveyOption[]
}

interface Survey {
    id: number,
    title: string,
    questions: SurveyQuestion[]
}

interface OptionAnswer {
    optionId: number
}

interface WriteAnswer {
    questionId: number,
    text: string
}

export const SurveyContext = createContext({
    currentSurvey: null as null | Survey,
    answerSurvey: (optionAnswers: OptionAnswer[], writeAnswers: WriteAnswer[]) => Promise.resolve(),
    setSurveyedForSession: (surveyId: number) => { },
    getNextSurvey: (auth: Auth, parms: RawParms | ParsedParms) => Promise.resolve()
});

interface Props {
    children: ReactNode
}

export default function SurveyController({ children }: Props) {

    const [currentSurvey, setCurrentSurvey] = useState<Survey | null>(null);

    const { removeSurvey } = useContext(ParmContext);
    const { failoverFetch } = useContext(APIContext);
    const { auth } = useContext(AuthContext);
    const { clerkSettings: { closedSurveys }, incrementSurveyCount } = useContext(SettingsContext);

    const setSurveyedForSession = useCallback((surveyId: number) => {
        if (surveyId > -1) {
            incrementSurveyCount(surveyId);
        }
        setCurrentSurvey(null);
    }, [incrementSurveyCount])

    const getNextSurvey = useCallback(async (auth: Auth, rawParms: RawParms | ParsedParms) => {
        const currentSurveyId = rawParms.surveys?.find((surveyId) =>
            (closedSurveys[surveyId.toString()] ?? 0) < 6
        ) ?? -1;

        if (currentSurveyId > -1) {
            try {
                const response = await failoverFetch('/Surveys?' + new URLSearchParams({
                    id: currentSurveyId.toString(),
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as Survey;
                setCurrentSurvey(data);
            } catch (error) {
                console.error(error);
                setSurveyedForSession(currentSurveyId);
            }
        }
    }, [closedSurveys, failoverFetch, setSurveyedForSession])

    const answerSurvey = useCallback(async (optionAnswers: OptionAnswer[], writeAnswers: WriteAnswer[]) => {
        if (auth && currentSurvey?.id) {
            try {
                await failoverFetch('/Surveys', {
                    method: 'POST',
                    body: new URLSearchParams({
                        optionAnswers: JSON.stringify(optionAnswers),
                        writeAnswers: JSON.stringify(writeAnswers),
                        authToken: auth.authToken
                    })
                })

                setCurrentSurvey(null);
                removeSurvey(currentSurvey.id);
            } catch (error) {
                console.error(error);
                setSurveyedForSession(currentSurvey.id);
            }
        }
    }, [auth, currentSurvey?.id, failoverFetch, removeSurvey, setSurveyedForSession])

    return (
        <SurveyContext.Provider value={{
            currentSurvey,
            answerSurvey,
            setSurveyedForSession,
            getNextSurvey
        }} >
            {children}
        </SurveyContext.Provider>
    );
}