import { useContext } from 'react';
import { useFormikContext } from 'formik';
import type { ChangeEvent, ComponentPropsWithoutRef, FocusEvent } from 'react';
import { KeyboardContext } from '../Keyboard/Keyboard';

interface Props extends ComponentPropsWithoutRef<'input'> {
    name: string
}

const numberRegex = new RegExp(/\D/g);

export default function PhoneField({ name, ...rest }: Props) {

    const { handleInputFocus, handleInputBlur } = useContext(KeyboardContext);
    const formik = useFormikContext<any>();

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        handleInputFocus(true);
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        formik.handleBlur(event);
        handleInputBlur();
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;
        const parsedValue = value.replace(numberRegex, '');
        const formattedValue = [
            parsedValue.substring(0, 3),
            parsedValue.substring(3, 6),
            parsedValue.substring(6, 10)
        ].filter((segment) => !!segment).join('-');
        formik.setFieldValue(name, formattedValue);
    }

    return (
        <input {...rest}
            name={name}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            value={formik.values[name]}
            autoComplete="off"
        />
    );
}