import { useContext, useCallback } from 'react';
import { useLocation, Redirect } from "react-router-dom";
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import Mainview from '../Mainview/Mainview';
import { ParmContext } from '../../HigherOrder/ParmController/ParmController';
import { SystemContext } from '../../HigherOrder/SystemConroller/SystemController';
import { TrainingContext } from '../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { handleSpecialEvents } from '../../../utilities/Tools';
import ErrorBoundary from '../../Common/ErrorBoundary/ErrorBoundary';
import { useBoolean, useCounter, useEventListener } from 'usehooks-ts';
import { SettingsContext } from 'components/HigherOrder/SettingsController/SettingsController';

const preventTabEvent = (event: KeyboardEvent) => {
    if (event.code === 'Tab') {
        event.preventDefault();
    }
}

const offlinePageSet = new Set(['Login', 'Settings', 'Network', 'AdminLogin', 'System', 'PrinterSettings', 'Preferences', 'UpdateStatus']);
const offlineOnlyPageSet = new Set(['Login', 'UpdateSelf']);

/**
 * Top-level component for managing redirects in the application.
 * This is the last top-level component before items start actually being rendered.
 */
export default function Navigator() {

    const { pathname } = useLocation();
    const basePath = pathname.split('/')[1];

    const { parms, loggedIn, updateRequired } = useContext(ParmContext);
    const hasParms = !!parms;
    const { trainingKey, isTraining, isInBetweenTrainings, trainingToggleTrainingComplete, globalTrainingIntroComplete } = useContext(TrainingContext);
    const { system: { isPOS } } = useContext(SystemContext);
    const { clerkSettings } = useContext(SettingsContext);

    const { value: menuClosed, setTrue: closeMenu, setFalse: openMenu } = useBoolean(true);
    const { value: notificationsClosed, setTrue: closeNotifications, setFalse: openNotifications } = useBoolean(true);
    const { count: navKey, increment: incrementKey } = useCounter(0);

    const onOfflinePage = offlinePageSet.has(basePath);
    const onOfflineOnlyPage = offlineOnlyPageSet.has(basePath);

    const key = trainingKey + navKey;

    const fullyLoggedIn = loggedIn && !updateRequired;

    const isHeaderTraining = (!clerkSettings.completedTrainings.header || (isInBetweenTrainings && trainingToggleTrainingComplete))
        && globalTrainingIntroComplete
        && fullyLoggedIn;

    const isRightSectionTraining = isHeaderTraining && (!isInBetweenTrainings || (isInBetweenTrainings && menuClosed));

    const handleMenuOpen = useCallback(() => {
        if (!isRightSectionTraining || (isInBetweenTrainings && menuClosed)) {
            openMenu();
        }
    }, [isInBetweenTrainings, isRightSectionTraining, menuClosed, openMenu]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (isTraining) {
            preventTabEvent(event);
        } else {
            handleSpecialEvents(event);
        }
    }, [isTraining]);

    useEventListener('keydown', handleKeyDown);

    return (
        <>
            <ErrorBoundary >
                <Sidebar
                    openNotifications={openNotifications}
                    notificationsClosed={notificationsClosed} />
            </ErrorBoundary>
            <div className='MainSection'>
                <ErrorBoundary >
                    <Header
                        openMenu={handleMenuOpen}
                        menuClosed={menuClosed}
                        fullyLoggedIn={fullyLoggedIn}
                        isHeaderTraining={isHeaderTraining}
                        isRightSectionTraining={isRightSectionTraining} />
                </ErrorBoundary>
                <ErrorBoundary >
                    {(isPOS && !hasParms && basePath === 'Login') ? <Redirect to='/AdminLogin' />
                        : (!loggedIn && !onOfflinePage) ? <Redirect to='/Login' />
                            : (loggedIn && updateRequired && basePath !== 'UpdateSelf') ? <Redirect to='/UpdateSelf' />
                                : (loggedIn && onOfflineOnlyPage && !updateRequired) ? <Redirect to='/' />
                                    : null}
                    <Mainview
                        key={key}
                        incrementKey={incrementKey}
                        closeNotifications={closeNotifications}
                        notificationsClosed={notificationsClosed}
                        closeMenu={closeMenu}
                        menuClosed={menuClosed} />
                </ErrorBoundary>
            </div>
        </ >
    );
}