//@ts-ignore
import { useCallback, useContext, useEffect, useState } from 'react';
import SupportHeader from '../SupportHeader/SupportHeader';
import './AMLGuide.css'
import { APIContext } from 'components/HigherOrder/APIController/APIController';
import { AuthContext } from 'components/HigherOrder/AuthController/AuthController';
import FELogo from '../../../../../images/feLogoAML.jpg';

interface Employee {
    employeeName: string,
    employeeSignature: string,
    employeeDate: string
}

interface AMLPolicyGuide {
    complianceOfficer: string,
    independentAuditor: string,
    AMLName: string,
    AMLDBA: string,
    AMLDate: string,
    AMLBy: string,
    AMLTitle: string,
    AMLCompany: string,
    complianceOfficerDate: string,
    complianceOfficerName: string,
    complianceOfficerTitle: string,
    complianceOfficerDBA: string,
    IRName: string,
    IRDate: string,
    IRDBA: string,
    IRResult: string,
    IR_Acceptable_Actions: string,
    IR_Unacceptable_Actions: string,
    IRSignature: string,
    employeeDBA: string,
    employees: Employee[]
}

export default function AMLGuide() {

    const [amlPolicyGuide, setAMLPolicyGuide] = useState<AMLPolicyGuide | null>(null);

    const { auth } = useContext(AuthContext);
    const { failoverFetch } = useContext(APIContext);

    const getAMLPolicyGuide = useCallback(async () => {
        if (auth) {
            try {
                const response = await failoverFetch('/AMLPolicyGuide?' + new URLSearchParams({
                    agentId: auth.agentId,
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as AMLPolicyGuide;
                setAMLPolicyGuide(data);
            } catch (error) {
                console.error(error);
            }
        }

    }, [auth, failoverFetch]);

    useEffect(() => {
        getAMLPolicyGuide();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SupportHeader />
            <div className='AMLPolicyGuide'>
                <img src={FELogo} alt='Fidelity Express' />
                <br />
                <div className='Center'>
                    <h1>Anti-Money Laundering Compliance</h1>
                    <h1>Policy Program Guide</h1>
                    <h2>for Fidelity Express Agents</h2>
                    <h2>Money Orders, Walk-In Bill Payment, Mobile Top-Up, Amazon Cash, Digital Gift Cards, Netspend</h2>
                    <br />
                    <h6>1301 Main Street, Sulphur Springs, Texas 75482 | 800-621-8030 | fidelityexpress.com</h6>
                    <div>Volume 01012023</div>
                    <br />
                    <div>As a Fidelity Express authorized delegate, you are considered a Money Service Business and this anti-money laundering compliance guide was created to be your written policy or at least a supplement to your AML policy.</div>
                </div>
                <br />
                <h4>IMPORTANT INFORMATION</h4>
                <h5>All store personnel should readily know the following AML information in the event a state or IRS auditor visits your location.</h5>
                <div>AML = Anti-Money Laundering</div>
                <div>BSA = Bank Secrecy Act</div>
                <div>MSB = Money Service Business [anyone who sells money orders, offers walk-in bill payments] </div>
                <div>CTR = Currency Transaction Report [above the $10,000 amount]</div>
                <div>SAR = Suspicious Activity Report [above the $2,000 amount]</div>
                <br />
                <div>If you are in District of Columbia, Georgia, Illinois, Kentucky, Maryland, Mississippi, North Carolina, Oklahoma, or Texas, you must have a state required sign visible to the public paying bills or buying money orders. </div>
                <br />
                <div>By law, if you sell more than $3,000.00 in money orders to anyone, you must record it in the $3,000 transaction log (3K Log).  A copy of this log can be found on page 14.
                    <span className='Underline'><mark>As a company however, we do not allow the sale of more than $1,999.99 (total sale) in money orders to one person in 24-hours. If you break this rule, you risk losing your money order program.</mark></span>
                </div>
                <br />
                <div>The name of my Compliance Officer is:
                    <span className='Underline Field'>{amlPolicyGuide?.complianceOfficer}</span>
                </div>
                <div><small>Responsible for day-to-day anti-money laundering program and employee training, should keep a training log. [trusted employee or owner]</small></div>
                <br />
                <div>The name of my Independent Auditor is:
                    <span className='Underline Field'>{amlPolicyGuide?.independentAuditor}</span>
                </div>
                <div><small>Monitors the anti-money laundering program, review sales tapes for any payments over $1,999.99 once a month. Review employee training logs. [trusted employee or co-owner, cannot be the same person as Compliance Officer]</small></div>
                <br />
                <h5><strong>Dear Fidelity Express Account Owner:</strong></h5>
                <br />
                <div>Please carefully review this Anti-Money Laundering Compliance Guide for your business. The importance of your compliance is outlined on the following pages.</div>
                <br />
                <div>Money Service Businesses include our pay locations selling money orders and offering walk-in bill payments. It is necessary to establish an anti-money laundering compliance program with a compliance officer and designate an independent auditor responsible for the review of the program as well as employee training.</div>
                <br />
                <div>The following list outlines the Fidelity Express policy for in-person bill payments:</div>
                <br />
                <ul>
                    <li>Any payment in cash of more than $10,000 requires you to e-file a Currency Transaction Report on page 8.</li>
                    <li>Any payment in cash of $3,000 or more requires the completion of a log as presented on page 14. It will be necessary to obtain identification and personal information at the time of the transaction. <span className='Underline'><mark>As a company however, we do not allow the sale of more than $1,999.99 (total sale) in money orders to one person in 24-hours. If you break this rule, you risk losing your money order program.</mark></span></li>
                    <li>Any suspicious activity should be reported with a Suspicious Activity Report (SAR) by e-file. Transactions deemed to be suspicious would include attempting to make a cash payment of $2,000 or more without 	a bill stub or paying cash in an amount considerably larger than the utility bill in an attempt to receive a refund from the utility company on page 9.</li>
                    <li>Copies of all the reports mentioned above should be maintained for a period of five years.</li>
                    <li>No non-contracted payments over $3,000 (must be cash).</li>
                </ul>
                <br />
                <div>Additional helpful information is also available at www.fidelityexpress.com select agent resources. Please feel free to contact us at 800-621-8030 to get in touch with our Equipment and Services Hotline, regarding the use of your equipment, anti-money laundering compliance, adding services, or other questions.</div>
                <br />
                <div><strong><mark>Complete Sections:</mark> 1-Adoption of Compliance Program, 2-Compliance Officer, 3-Independent Review & 4-Employee Training.  This may be required by your bank or needed if you are audited.</strong></div>
                <br />
                <h5><strong>Fidelity Express Anti-Money Laundering Policy</strong></h5>
                <br />
                <div>Fidelity Express has a policy against selling money orders in the amount of $2,000 or more to an individual in a 24-hour period. This policy is for your benefit. If you were to violate this policy, you could also be violating the Bank Secrecy Act (BSA) and the USA PATRIOT Act.</div>
                <br />
                <div><strong>Why you should comply with these regulations: It’s the law.</strong></div>
                <ul>
                    <li>You could lose your business. </li>
                    <li>You could go to jail for up to 10 years.</li>
                    <li>You could be personally fined as much as $500,000.</li>
                    <li>Banks may close your accounts and refuse to do business with you.</li>
                </ul>
                <br />
                <div>The intent of this Compliance Guide is to help you know and comply with the law. </div>
                <div>We cannot be responsible for your program, this is your responsibility, but we will do all we can to help by providing training at installation as well as regular updates and revisions. </div>
                <br />
                <div>Online training is completed through our website. Go to www.fidelityexpress.com, select “Agent Resources”. There you’ll find all available anti-money laundering documents.</div>
                <br />
                <div>If we see any violations of the required anti-money laundering procedures, Fidelity Express could cancel your money order or walk-in bill payment service.</div>
                <br />
                <div><strong><mark><span className='Underline'>You are required to have the following established procedures in place:</span></mark></strong></div>
                <ul>
                    <li>Have a written anti-money laundering compliance program. </li>
                    <li>Designate a compliance officer for your business. </li>
                    <li>Train your employees on a regular schedule, at least annually. </li>
                    <li>Have an independent review performed every year to make sure your program is working.</li>
                    <li>Financial Institutions follow Customer Due Diligence guidelines or CDD rules.</li>
                    <li>Report any suspicious activity by e-filing a Suspicious Activity Report (SAR).</li>
                    <li>Maintain proper records for any cash transactions over $3,000 and e-file Currency Transaction Reports (CTRs) for amounts more than $10,000.</li>
                </ul>
                <br />
                <div>The following guide provides detailed information and explanation of the above requirements as well as examples to help you identify suspicious activities. Please utilize the forms included in the guide, and available at www.fidelityexpress.com select agent resources, to establish your own written program. </div>
                <br />
                <div>Many times, banks, as well as auditors, will request copies of your written anti-money laundering program. </div>
                <br />
                <div>As a Fidelity Express account owner and a seller of money orders and provider of walk-in bill payment service, which is a Money Service Business (MSB), you are required by state and federal law to complete AML training and examination at least annually to remain in compliance and continue to process transactions.</div>
                <br />
                <ul>
                    <li>We will send a reminder every year to complete your training and examination. </li>
                    <li>In addition to the federal requirements, please be advised some states have filing and reporting responsibilities at the state level. Please refer to your state regulations or to any additional information provided by Fidelity Express.</li>
                    <li>This Compliance Guide is available in its entirety at fidelityexpress.co on the agent resources page. </li>
                    <li>For the most up-to-date information visit www.fincen.gov or contact Fidelity Express at 800-621-8030 ext. 7029. </li>
                </ul>
                <br />
                <h5><strong>FinCEN Regulations on Anti-Money Laundering (AML) Compliance Programs for Money Service Businesses</strong></h5>
                <br />
                <div>Retailers selling money orders and providing other money services such as money transfers, check cashing, currency exchange services, and the sale or redemption of traveler’s checks face legal and regulatory requirements to help ensure these services are not used as an aid to money laundering. USA PATRIOT Act and Department of Treasury regulations place requirements on money services businesses (MSBs) which include businesses engaging in any money service in an amount totaling $1,000 or more to one individual in a single day.</div>
                <br />
                <div><strong>What is an MSB?</strong></div>
                <div>FinCEN defines a “money service business” as an agent, branch, or office of any person or entity engaging in any of the following activities: currency dealing or exchanging; check cashing; issuing traveler’s checks or money orders; selling or redeeming traveler’s checks or money orders; money transmitting; and providing or selling of prepaid access devices. Many convenience stores are considered MSBs for the purposes of FinCEN’s money laundering enforcement activities because they issue or cash money orders and/or accept walk-in bill payments.</div>
                <br />
                <div><strong>MSB Responsibilities under the USA PATRIOT Act</strong></div>
                <div>The USA Patriot Act states MSBs must adopt an “appropriate anti-money laundering program”.</div>
                <div>It is important to know the program you design requires the following:</div>
                <br />
                <ul>
                    <li><span className='Underline'>Have written policies and procedures reasonably designed to comply with Bank Secrecy Act (BSA) requirements such as verifying customer identification where appropriate, filing required reports, creating and retaining certain records, responding to law enforcement requests and preventing laundering of money; </span></li>
                    <li><span className='Underline'>Have a compliance officer responsible for day-to-day compliance with AML policies and applicable laws and regulations;</span></li>
                    <li><span className='Underline'>Implement an independent review or audit function to evaluate the company’s compliance with AML policies as well as applicable laws and regulations; </span></li>
                    <li><span className='Underline'>Provide training to employees on the company’s AML policies as well as applicable laws and regulations.</span></li>
                </ul>
                <br />
                <div>This Compliance Guide presents policies and procedures retailers who fall under the definition of an MSB may adopt to meet the USA PATRIOT Act and comply. The policies and procedures cover the responsibilities listed and the requirements for the maintenance of a money order log, the filing of Currency Transaction Report (CTR), the filing of Suspicious Activity Report (SAR), training, as well as additional rules for businesses offering money transfer services. A statement is included in Section 1, page 11, of this Compliance Guide by which your company can certify it has adopted these policies and procedures to comply with its responsibilities under the Bank Secrecy Act (BSA) and USA PATRIOT Act.</div>
                <br />
                <div><strong>Sharing Regulatory Responsibility with Your Issuers Can Alleviate Compliance Burden</strong></div>
                <div>The USA PATRIOT Act’s implementation regulations allow MSBs to divide the tasks associated with anti-money laundering compliance. This may be an avenue for your business to explore to help alleviate compliance burdens. Regardless of how individual compliance tasks are divided, keep in mind each party remains responsible for its own compliance.</div>
                <br />
                <div><strong>Designation of Compliance Officer </strong></div>
                <div>Each MSB must designate a compliance officer who is responsible for the MSB’s day-to-day compliance with anti-money laundering policies and procedures as well as applicable laws and regulations. Section 2, page 12, of this guide includes a statement that may be used to designate a compliance officer. The same individual may serve as compliance officer for more than one MSB and more than one location. This individual may, for example, be a store manager or a corporate employee who works at a centralized location rather than an individual retail outlet. It is important to know the MSB’s independent review or audit must be conducted by someone other than the compliance officer for that location. </div>
                <div>The compliance officer should periodically review whether the policies and procedures set forth in this guide have been followed and complete a checklist demonstrating such a review has been completed. An example has been provided in this guide on page 15. If any problems are identified, the compliance officer should determine the cause of the problem and implement any appropriate corrective action.</div>
                <br />
                <div><strong>Independent Auditor Review </strong></div>
                <div>Every MSB is responsible for conducting an independent review of compliance with its anti-money laundering program as well as applicable laws and regulations. Independent reviews may be conducted by a company employee (other than the compliance officer) or an outside contractor. Independent reviews must be conducted as frequently as the risk of money laundering through an MSB. For most locations, an annual review will be sufficient. Locations with consistently good reviews may be reviewed less frequently (18 months maximum), while locations with issues to address may need to be reviewed more frequently until they demonstrate such issues have been corrected.
                    <span className='Underline'>Result reports of independent reviews must be maintained for five years.</span> If any compliance problems are found, the independent auditor should determine the cause, identify any appropriate corrective action, and work with the compliance officer to implement the corrective action. An Independent Review through Fidelity Express will be done electronically on an annual basis or within 18 months. </div>
                <br />
                <div>The reviews should cover each of the items set forth in this Compliance Guide and ensure the MSB being reviewed maintains all required paperwork and files all required reports. This guide may be used as a checklist for the reviews including: adoption of policies and procedures; designation of a compliance officer; completion of a money order and/or bill pay log; completion of Currency Transaction Reports (CTR); completion of Suspicious Activity Reports (SAR); collection of information on money transfers; and completion of employee training. Any independent reviewer may use the statement in Section 3 (pg. 12) of this guide to demonstrate a review was completed and to record any findings. An independent review through Fidelity Express will be done electronically on an annual basis or within 18 months.</div>
                <br />
                <div><strong>Money Order and Bill Pay Logs </strong></div>
                <div>MSBs are required to keep a log of all money order and bill payment transactions to a single customer in a single day that total between $3,000 and $10,000.
                    <mark><span className='Underline'>As a company however, we do not allow the sale of more than $1,999.99 (total sale) in money orders to one person in 24-hours. If you break this rule, you risk losing your money order program. </span></mark> Sample forms are included in this Compliance Guide on pages 14. For transactions required to be recorded on the log, the MSB must record all the information noted on the log including the:
                </div>
                <br />
                <ul>
                    <li>Customer’s name, address, social security number, and birth date;</li>
                    <li>Transaction date; </li>
                    <li>Money order serial number(s);</li>
                    <li>Total number of money orders purchased</li>
                    <li>Amount of the money order and/or bill payment transaction;</li>
                    <li>Type and government issuer of identification used by the purchaser and its number; </li>
                    <li>Clerk’s initials.</li>
                </ul>
                <br />
                <div><span className='Underline'>MSBs must maintain their money order and bill payment transaction logs for at least five (5) years</span> and give law enforcement access to the logs upon request.</div>
                <br />
                <div><strong>Currency Transaction Reporting</strong></div>
                <div>If one consumer uses more than $10,000 in cash for one or more transactions in a single day, then the MSB must e-file a Currency Transaction Report (CTR). This requirement applies to money transfers as well as to money orders. The CTR must be completed in its entirety and all information necessary to complete the form must be obtained before completing the transaction. The CTR form calls for information regarding the person(s) for whom the transaction is conducted, the individual(s) conducting the transaction, the amount of money involved in the transaction, and the MSB where the transaction took place. </div>
                <br />
                <div><strong><span className='Underline'>CTR must be e-filed with the BSA E-filing System at http://bsaefiling.fincen.treas.gov/main.html within 15 days of the transaction. The MSB must keep a copy of the CTR for five (5) years</span> and give law enforcement access to the CTR upon request. </strong></div>
                <br />
                <div><strong>Suspicious Activity Reporting</strong></div>
                <div><strong>MSBs must e-file a suspicious activity report (SAR) when a transaction is both suspicious and $2,000 or more.</strong> Although it is not legally required, SAR should also be e-filed for suspicious transactions of less than $2,000.
                    <span className='Underline'>Consumers are not to be informed SAR will be completed. Informing the consumer amounts to tipping them off to a potential investigation and is a violation of the law. </span>
                </div>
                <br />
                <div><strong><span className='Underline'>SAR must be e-filed with the BSA E-filing System within 30 days of detection. The MSB must keep a copy of the CTR for five (5) years </span> and give law enforcement access to the SAR upon request.  </strong></div>
                <br />
                <div>Suspicious transactions include attempts to launder funds derived from illegal activity; transactions structured to avoid reporting requirements; transactions serving no business or lawful purpose or are unusual for the consumer without any reasonable explanation; and any other transaction involving potential criminal activity.</div>
                <br />
                <div>Specific examples of suspicious activity include:</div>
                <br />
                <ul>
                    <li>Consumer attempting to keep the transaction from being reported; </li>
                    <li>Consumer providing false or expired identification;</li>
                    <li>Two or more individuals who are together split up to conduct separate transactions for less than $3,000, but with a combined total of more than $3,000; </li>
                    <li>Consumer refuses to proceed with the transaction once informed a CTR will need to be completed or 	the transaction will be recorded on the money order or bill payment log;</li>
                    <li>Consumer asks how to avoid reporting and recordkeeping requirements; </li>
                    <li>Consumer attempts to threaten or bribe the employee involved in the transaction; </li>
                    <li>Consumer buys several money orders in a day without an apparent purpose for breaking up the transactions and never buys more than $3,000 in a single visit, even though the total for the day may be more than $3,000; </li>
                    <li>Consumer buys multiple money orders in even hundred-dollar denominations or in unusual quantities.</li>
                </ul>
                <br />
                <div><strong>SARs and CTRs must be electronically filed via the BSA E-filing System</strong> located at </div>
                <div>http://bsaefiling.fincen.treas.gov/main.html. </div>
                <br />
                <div>First-time filers must enroll their organization in BSA E-Filing as follows: </div>
                <br />
                <ol>
                    <li>Go to http://bsaefiling.fincen.treas.gov/main.html </li>
                    <li>Select Enroll, Becoming a Registered E-Filer </li>
                </ol>
                <br />
                <div><strong>Additional Rules for Money Transfers </strong></div>
                <div>Although Fidelity Express does not offer money transfer, should you provide the service via another vendor, there are additional rules. For transfers of more than $1,000, both senders and receivers must log the following information for their consumer (either the sender or the receiver of the funds): </div>
                <br />
                <ul>
                    <li>Name and address </li>
                    <li>Type and government issuer of identification used by the purchaser and its number</li>
                    <li>Social security number or taxpayer identification number or, if none, alien identification number or passport number and country of issuance (note if there is none)</li>
                    <li>Date of birth</li>
                    <li>Occupation</li>
                    <li>Amount of the transfer; </li>
                    <li>Reference number for the transaction; </li>
                    <li>Date of the transfer;</li>
                    <li>Any money transfer instructions given by the sender; and </li>
                    <li>A signed copy of the send or receive form. </li>
                </ul>
                <br />
                <div>In addition, if a person is sending the funds on behalf of another individual or entity, similar information for that individual or entity must be obtained. The information collected concerning senders and receivers of money transfers of more than $3,000 should be retained for five years.</div>
                <br />
                <div>You should also be aware many money transfer companies recommend or require their agents to verify senders’ and receivers’ identification even for transactions under $3,000. Typically, this is done by viewing identification that includes the person’s name, address and/or photograph. Documents used for verifying identity include driver’s licenses, passports, and alien identification cards, among others. As a practical matter, these requirements may be imposed on MSBs by money transfer companies. You should check with your money transfer company to determine whether they have such a policy. </div>
                <br />
                <div><strong>Training</strong></div>
                <div>All employees must be trained on the compliance requirements set forth in this Compliance Guide and their individual responsibilities in fulfilling these requirements. Each employee must read this guide carefully and agree to follow it prior to engaging in any money service activities. In addition, there is a certification included in Section 4, page 13, of this Compliance Guide for each employee to sign indicating he or she has read this compliance policy and understands it.</div>
                <br />
                <div><span className='Underline'>Authorized Delegate shall train all its employees responsible for Fidelity Express MSB services (Money Orders, Walk-in Bill Payment, Mobile Top-Up, Amazon Cash, Digital Gift Card, Netspend). You must keep documentation of employee training in case of an audit. Continuation of Fidelity Express services after March 6, 2020, constitutes your agreement to and the responsibility for the training requirements.</span></div>
                <br />
                <div><strong>Record Retention and Sharing with Law Enforcement </strong></div>
                <div>Bank Secrecy Act (BSA) regulations require records be kept for a period of five (5) years. This includes Suspicious Activity Report (SAR), Currency Transactions Report (CTR), and Transaction Log of $3,000 or more. Any request from an appropriate federal, state, or local law enforcement agency is allowed, with the proper paperwork, to have access to this information. This also applies to regulatory and supervisory agencies with authority to examine this information.  </div>
                <br />
                <div><strong>Required State Signage </strong></div>
                <div>Some states require a sign to be posted in full view of where the customer transacts business. The following states require a sign to be posted: Georgia, Illinois, Kentucky, Maryland, Mississippi, North Carolina, Oklahoma, Texas and the District of Columbia.  If you do not have a sign, please contact the compliance department at 800-621-8030 ext. 7029.</div>
                <br />
                <h5><mark>Section One</mark> Anti-Money Laundering Compliance Program</h5>
                <div>CERTIFICATION of Adoption of Compliance Program </div>
                <div>I,
                    <span className='Underline Field'>{amlPolicyGuide?.AMLName}</span> [name], on behalf of
                    <span className='Underline Field'>{amlPolicyGuide?.AMLDBA}</span> [legal name and d.b.a. of your business] certify that this entity has adopted the attached Anti-Money Laundering Compliance Policy as its anti-money laundering program. This compliance program complies with the requirements of the USA PATRIOT Act, and this entity complies, on an ongoing basis, with all the relevant requirements imposed by the Bank Secrecy Act, the USA PATRIOT Act, and other applicable
                </div>
                <div className='Row'>
                    <div>Date:
                        <span className='Underline Field'>{amlPolicyGuide?.AMLDate}</span>
                    </div>
                    <div>
                        <div>By:
                            <span className='Underline Field'>{amlPolicyGuide?.AMLBy}</span>
                        </div>
                        <div>Title:
                            <span className='Underline Field'>{amlPolicyGuide?.AMLTitle}</span>
                        </div>
                        <div>Company:
                            <span className='Underline Field'>{amlPolicyGuide?.AMLCompany}</span>
                        </div>
                    </div>
                </div>
                <br />
                <h5><mark>Section Two</mark></h5>
                <div>DESIGNATION of Compliance Officer</div>
                <div>As of
                    <span className='Underline Field'>{amlPolicyGuide?.complianceOfficerDate}</span> [date],
                    <span className='Underline Field'>{amlPolicyGuide?.complianceOfficerName}</span> [name],
                    <span className='Underline Field'>{amlPolicyGuide?.complianceOfficerTitle}</span> [title] is hereby designated as the Anti-Money Laundering Compliance Officer (“Compliance Officer”) for
                    <span className='Underline Field'>{amlPolicyGuide?.complianceOfficerDBA}</span> [legal name, d.b.a. of your business and relevant location(s)]. The Compliance Officer is responsible for ensuring ongoing compliance with all state & federal anti-money laundering laws, and for ensuring all employees are trained on anti-money laundering requirements before conducting any Money Service Business (“MSB”) activity.
                </div>
                <br />
                <h5><mark>Section Three</mark></h5>
                <div>CERTIFICATION of Independent Review </div>
                <div>I,
                    <span className='Underline Field'>{amlPolicyGuide?.IRName}</span> [name of auditor] hereby certify that as of
                    <span className='Underline Field'>{amlPolicyGuide?.IRDate}</span> [date] I have completed an anti-money laundering compliance audit of
                    <span className='Underline Field'>{amlPolicyGuide?.IRDBA}</span> [name and d.b.a. of entity]. Among other things, this review focused on the requirements of the USA PATRIOT Act, which mandates an anti-money laundering compliance program that includes policies, employee training, and the designation of a compliance officer. The result of my audit is I find this entity’s anti-money laundering compliance program:
                </div>
                <div>
                    Acceptable {amlPolicyGuide?.IRResult === 'ACCEPTABLE' ? 'X' : '_'}
                </div>
                <div>
                    Acceptable {amlPolicyGuide?.IRResult === 'ACCEPTABLE_WITH_ACTIONS' ? 'X' : '_'} , but recommend the following actions for improvement:
                </div>
                <div className='Underline'>{amlPolicyGuide?.IR_Acceptable_Actions}</div>
                <div>
                    Unacceptable {amlPolicyGuide?.IRResult === 'UNACCEPTABLE' ? 'X' : '_'}  for the following reasons with the following recommended corrective actions:
                </div>
                <div className='Underline'>{amlPolicyGuide?.IR_Unacceptable_Actions}</div>
                <br />
                <div>Signature of Auditor:
                    <span className='Underline Field'>{amlPolicyGuide?.IRSignature}</span>
                </div>
                <br />
                <h5><mark>Section Four</mark></h5>
                <div><mark>CERTIFICATION of Employee Training</mark></div>
                <div>I acknowledge receiving a copy of
                    <span className='Underline Field'>{amlPolicyGuide?.employeeDBA}</span> [legal name and d.b.a. of your business] Anti-Money Laundering Compliance Program. I have read the Compliance Program prior to conducting any Money Services Business for this entity and I understand the reporting and recordkeeping requirements such as the need to report suspicious activity as explained in the Compliance Program.
                </div>
                <br />
                {amlPolicyGuide?.employees.map((employee, index) => (
                    <div className='Row' key={index} >
                        <div>
                            <div>Employee’s Name:
                                <span className='Underline Field'>{employee.employeeName}</span>
                            </div>
                            <br />

                            <div>Employee’s Signature:
                                <span className='Underline Field'>{employee.employeeSignature}</span>
                            </div>
                            <br />

                            <div>Date:
                                <span className='Underline Field'>{employee.employeeDate}</span>
                            </div>
                            <br />
                        </div>
                    </div>
                ))}
            </div>
        </>

    );
}