import type { FormikHelpers } from 'formik';
import { useFormikContext } from 'formik';
import type { ListData } from '../../CustomList/CustomList';
import CustomList from '../../CustomList/CustomList';
import './OperatorList.css'
import type { FullOperator, OperatorPreview, OperatorProps } from 'components/hooks/MTU';
import { TrainingContext } from '../../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import type { Dispatch, SetStateAction } from 'react';
import { useContext, useMemo } from 'react';
import { tooltipBoldClass } from 'utilities/tooltip';
import type { initialValues } from '../../MobileTopUp';
import { focusNextElement } from 'utilities/Tools';
import { getClass } from 'utilities/classnames';

interface Props {
    operators: OperatorPreview[],
    getOperatorFromServer: (values: OperatorProps, actions: FormikHelpers<typeof initialValues>) => Promise<FullOperator[] | undefined>,
    isOperatorTutorial?: boolean,
    tutorialOperator: { operator: string, operatorId: string },
    setSelectedProductIndex: Dispatch<SetStateAction<number>>,
    selectedOperators: FullOperator[],
    setSelectedOperators: Dispatch<SetStateAction<FullOperator[]>>
}

export default function OperatorList({ operators, getOperatorFromServer, isOperatorTutorial, tutorialOperator, setSelectedProductIndex, selectedOperators, setSelectedOperators }: Props) {

    const { isCurrentPageTraining } = useContext(TrainingContext);
    const formik = useFormikContext<typeof initialValues>();

    const validate = (element: FullOperator) =>
        !(isOperatorTutorial && element.operatorId !== tutorialOperator.operatorId);

    const getOperatorDetail = async ({ billerId }: OperatorPreview) => {
        return getOperatorFromServer({
            ...formik.values,
            billerId
        }, formik);
    }

    const handleOperatorChange = async (operator: OperatorPreview & ListData) => {
        try {
            const fullOperators = await getOperatorDetail(operator);
            if (fullOperators) {
                setSelectedOperators(fullOperators);
                setSelectedProductIndex(0);
                if (operator.isValid) {
                    focusNextElement();
                }
            }
        } catch (err) {
            console.error(err);
            formik.setStatus({ message: 'An unexpected error has occurred', messageType: 'error' });
        }
    }

    const selectedIdSet = useMemo(() =>
        new Set(selectedOperators.map(({ billerId }) => billerId))
        , [selectedOperators])

    const selectedId = useMemo(() =>
        operators.find(({ billerId }) => selectedIdSet.has(billerId))?.billerId
        , [operators, selectedIdSet]);

    return (
        <CustomList
            selectedId={selectedId}
            id='billerId'
            display='operator'
            title='SEARCH PROVIDER'
            data={operators}
            tooltip={
                <>
                    <div>CHOOSE THE PROVIDER</div>
                    <b className={tooltipBoldClass}>{tutorialOperator.operator}</b>
                </>
            }
            tooltipShown={isOperatorTutorial}
            className={getClass([
                'OperatorList',
                !!isOperatorTutorial && 'Focused'
            ])}
            validate={validate}
            autoFocus={isOperatorTutorial || (!isCurrentPageTraining && !selectedOperators.length)}
            onChange={handleOperatorChange} />
    );
}