import AdvancedButton from "components/Common/AdvancedButton/AdvancedButton";
import ConfirmField from "components/Common/ConfirmField/ConfirmField";
import MessageAggregator from "components/Common/MessageAggregator/MessageAggregator";
import type { BillerDetail } from "components/hooks/BillPay";
import { useFormikContext } from "formik";
import type { initialValues } from "../NetSpend";
import styles from '../NetSpend.module.css';
import { tooltipBoldClass } from "utilities/tooltip";
import AdvancedField from "components/Common/AdvancedField/AdvancedField";

export const tutorialCard = '1234567812345678';
export const tutorialAccount = '12345678123';

interface Props {
    billerDetail: BillerDetail
}

export default function CardForm({ billerDetail }: Props) {

    const { values: { card, cardConfirm } } = useFormikContext<typeof initialValues>();

    const tutorialValue = billerDetail.billerId === 3327 ? tutorialAccount : tutorialCard;
    const isCardTutorial = card !== tutorialValue;
    const isCardConfirmTutorial = !isCardTutorial && cardConfirm !== tutorialValue;
    const isButtonTutorial = !isCardTutorial && !isCardConfirmTutorial;

    const productName = billerDetail.billerId === 3327 ? 'Account #' : 'Card #';
    const title = billerDetail.billerId === 3327 ? 'Enter Account #' : 'Enter Card #';
    const maxLength = billerDetail.billerId === 3327 ? 11 : 16;

    return (
        <div className={styles.cardForm}>
            <div className={styles.cardForm}>
                <h1>{title}</h1>
                <AdvancedField
                    name='card'
                    placeholder={productName}
                    trainingTooltip={
                        <>
                            <div>ENTER THIS {productName.toUpperCase()}</div>
                            <b className={tooltipBoldClass}>{tutorialValue}</b>
                        </>
                    }
                    trainingFocused={isCardTutorial}
                    maxLength={maxLength}
                    className={styles.cardFormInput}
                    autoFocus required numeric />
                <ConfirmField
                    name='card'
                    placeholder={productName}
                    trainingTooltip={
                        <>
                            <div>ENTER {productName.toUpperCase()} AGAIN</div>
                            <b className={tooltipBoldClass}>{tutorialValue}</b>
                        </>
                    }
                    trainingFocused={isCardConfirmTutorial}
                    maxLength={maxLength}
                    className={styles.cardFormInput}
                    required numeric />
                <AdvancedButton
                    trainingFocused={isButtonTutorial}
                    className={styles.cardFormNext}
                    trainingTooltip={
                        <>
                            <div>YOU ARE READY</div>
                            <b className={tooltipBoldClass}>CLICK NEXT</b>
                        </>
                    }
                >Next</AdvancedButton>
            </div >
            <MessageAggregator />
        </div >
    );
}