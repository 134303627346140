import { Fragment, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import type { MouseEvent } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import NetSpendIcon from '../../../../../images/NetSpendIcon.svg'
import AmazonIcon from '../../../../../images/AmazonIcon.svg';
import BillPayIcon from '../../../../../images/BillPayIcon.svg';
import ContactIcon from '../../../../../images/ContactIcon.svg';
import EquipIcon from '../../../../../images/EquipIcon.svg';
import MagnifyIcon from '../../../../../images/Magnify.svg';
import MoneyOrderIcon from '../../../../../images/MoneyOrderIcon.svg';
import MTUIcon from '../../../../../images/MTUIcon.svg';
import AdvancedForm from '../../../../Common/AdvancedForm/AdvancedForm';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import styles from './SupportHeader.module.css';
import type { FormikHelpers } from 'formik';
import { getClass } from 'utilities/classnames';
import { getTooltipClass, getTooltipTriggerClass, tooltipButtonClass } from 'utilities/tooltip';

interface Category {
    id: number,
    name: string
}

const AMLCategoryName = 'AML Guide';
const BillPayCategoryName = 'Bill Pay';
const MoneyOrderCategoryName = 'Money Order';
const MTUCategoryName = 'Mobile Top-Up';
const amazonCashCategoryName = 'Amazon Cash';
const EquipmentCategoryName = 'Equipment';
const ContactUsCategoryName = 'Contact Us';
const netSpendCategoryName = 'NetSpend';

const initialValues = {
    search: ''
};

interface Props {
    fullSize?: boolean,
    isSearchTraining?: boolean,
    isCategoryTraining?: boolean,
    completeSearchTraining?: (event: MouseEvent<HTMLElement>) => void,
    completeCategoryTraining?: (event: MouseEvent<HTMLElement>) => void
}

/** 
 * Common header / navigation throughout all support pages.
 */
export default function SupportHeader({ fullSize, isSearchTraining, isCategoryTraining, completeSearchTraining, completeCategoryTraining }: Props) {

    const { parms } = useContext(ParmContext);
    const { auth } = useContext(AuthContext);
    const { failoverFetch } = useContext(APIContext);
    const { push } = useHistory();
    const { pathname, search } = useLocation();

    const [categories, setCategories] = useState<Category[]>([]);

    const loadCategoryData = async () => {
        if (auth) {
            try {
                const response = await failoverFetch('/Support?' + new URLSearchParams({
                    type: 'parentCategories',
                    agentId: auth.agentId,
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as Category[];
                setCategories(data);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const handleSearch = useCallback(({ search }: typeof initialValues, { setFieldValue, setSubmitting }: FormikHelpers<typeof initialValues>) => {
        if (search?.length >= 3) {
            push('/Support/SupportSearch?search=' + search);
        }
        setFieldValue('search', '');
        setSubmitting(false);
    }, [push])

    const getCategoryByName = useCallback((name: string) => {
        return categories.find((element) => {
            return element.name === name;
        });
    }, [categories])

    const redirectCategory = useCallback((category?: Category) => {
        if (category && !isCategoryTraining) {
            push('/Support/SupportEntries?categoryId=' + category.id);
        }
    }, [isCategoryTraining, push])

    const amlBtnClicked = useCallback(() => {
        if (!isCategoryTraining) {
            push('/Support/AMLGuide');
        }
    }, [isCategoryTraining, push]);

    const billPayBtnClicked = useCallback(() => {
        const category = getCategoryByName(BillPayCategoryName);
        redirectCategory(category);
    }, [getCategoryByName, redirectCategory])

    const moneyOrderBtnClicked = useCallback(() => {
        const category = getCategoryByName(MoneyOrderCategoryName);
        redirectCategory(category);
    }, [getCategoryByName, redirectCategory])

    const mtuBtnClicked = useCallback(() => {
        const category = getCategoryByName(MTUCategoryName);
        redirectCategory(category);
    }, [getCategoryByName, redirectCategory])

    const amazonCashBtnClicked = useCallback(() => {
        const category = getCategoryByName(amazonCashCategoryName);
        redirectCategory(category);
    }, [getCategoryByName, redirectCategory])

    const netSpendBtnClicked = useCallback(() => {
        const category = getCategoryByName(netSpendCategoryName);
        redirectCategory(category);
    }, [getCategoryByName, redirectCategory]);

    const equipBtnClicked = useCallback(() => {
        const category = getCategoryByName(EquipmentCategoryName);
        redirectCategory(category);
    }, [getCategoryByName, redirectCategory])

    const contactUsBtnClicked = useCallback(() => {
        push('/Support/Help');
    }, [push])

    const getSelectedClass = useCallback((categoryName: string) => {
        if (categoryName === ContactUsCategoryName) {
            if (pathname.includes('/Help')) {
                return 'Selected';
            }
        } else if (categoryName === AMLCategoryName) {
            if (pathname.includes('/AMLGuide')) {
                return 'Selected';
            }
        } else {
            const className = categoryName === 'Money Order' ? styles.moneyOrder : '';
            const searchParams = new URLSearchParams(search);
            const categoryId = searchParams.get("categoryId");
            const category = getCategoryByName(categoryName);
            if (categoryId && category && Number(categoryId) === category.id) {
                return `${className} Selected`;
            } else {
                return className;
            }
        }

        return categoryName;
    }, [getCategoryByName, pathname, search])

    const searchClass = isSearchTraining ? 'Focused' : '';

    const searchComponent = useMemo(() => {
        return (
            <div className={getClass([
                styles.search,
                getTooltipTriggerClass(!!isSearchTraining)
            ])}
                onClick={completeSearchTraining}
            >
                <AdvancedForm className={searchClass} initialValues={initialValues} onSubmit={handleSearch} dynamicKeyboard={true} >
                    <div className={styles.searchContainer}>
                        <AdvancedField className={searchClass} name='search' placeholder='SEARCH HERE' upperCase={true} />
                        <AdvancedButton className={searchClass}>
                            <img src={MagnifyIcon} alt='Search' />
                        </AdvancedButton>
                    </div>
                </AdvancedForm>
                <div className={getTooltipClass({ placement: 'bottom' })}>
                    <div>Here you can search for various questions you may have.</div>
                    <span className={tooltipButtonClass} onClick={completeSearchTraining}>Continue</span>
                </div>
            </div>
        );
    }, [completeSearchTraining, handleSearch, isSearchTraining, searchClass])

    const pageContent = useMemo(() => {
        return (
            <Fragment>
                {fullSize && searchComponent}
                <div className={getClass([
                    styles.categoryButtons,
                    getTooltipTriggerClass(isCategoryTraining)
                ])}
                    onClick={completeCategoryTraining}
                >
                    <div className={`${styles.categoryButton} ${getSelectedClass(BillPayCategoryName)}`}>
                        <img src={BillPayIcon} alt={BillPayCategoryName} onClick={billPayBtnClicked} />
                    </div>
                    {!((parms?.clerkInfo.memoId ?? 0) > 0) &&
                        <div className={`${styles.categoryButton} ${getSelectedClass(MoneyOrderCategoryName)}`}>
                            <img src={MoneyOrderIcon} alt={MoneyOrderCategoryName} onClick={moneyOrderBtnClicked} />
                        </div>
                    }
                    <div className={`${styles.categoryButton} ${getSelectedClass(MTUCategoryName)}`}>
                        <img src={MTUIcon} alt={MTUCategoryName} onClick={mtuBtnClicked} />
                    </div>
                    <div className={`${styles.categoryButton} ${getSelectedClass(amazonCashCategoryName)}`}>
                        <img src={AmazonIcon} alt={amazonCashCategoryName} onClick={amazonCashBtnClicked} />
                    </div>
                    <div className={`${styles.categoryButton} ${getSelectedClass(netSpendCategoryName)}`}>
                        <img src={NetSpendIcon} alt={netSpendCategoryName} onClick={netSpendBtnClicked} />
                    </div>
                    <div className={`${styles.categoryButton} ${getSelectedClass(EquipmentCategoryName)}`}>
                        <img src={EquipIcon} alt={EquipmentCategoryName} onClick={equipBtnClicked} />
                    </div>
                    <div className={`${styles.categoryButton} ${getSelectedClass(AMLCategoryName)}`}>
                        <div onClick={amlBtnClicked}>AML Policy Program Guide</div>
                    </div>
                    <div className={`${styles.categoryButton} ${getSelectedClass(ContactUsCategoryName)}`}>
                        <img src={ContactIcon} alt={ContactUsCategoryName} onClick={contactUsBtnClicked} />
                    </div>
                    <div className={getTooltipClass({ placement: 'top' })}>
                        <div>Here you can select different categories, depending on what you're looking for.</div>
                        <div>If you can't find what you're looking for, you can use the "Contact Us" feature.</div>
                        <span className={tooltipButtonClass} onClick={completeCategoryTraining}>Continue</span>
                    </div>
                </div>
                {!fullSize && searchComponent}
            </Fragment>
        );
    }, [amazonCashBtnClicked, amlBtnClicked, billPayBtnClicked, completeCategoryTraining, contactUsBtnClicked, equipBtnClicked, fullSize, getSelectedClass, isCategoryTraining, moneyOrderBtnClicked, mtuBtnClicked, netSpendBtnClicked, parms?.clerkInfo.memoId, searchComponent])

    useEffect(() => {
        loadCategoryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${styles.supportHeader} ${fullSize ? styles.fullSize : ''}`}>
            <div className={styles.supportTitle}>
                <Link to="/Support">PayHereNetwork Support</Link>
            </div>
            {fullSize &&
                <h2>Hi. How can we help?</h2>
            }
            <div className={styles.supportNav}>
                {pageContent}
            </div>
        </div>
    );
}