import { useContext } from 'react';
import PrinterSettings from './PrinterSettings/PrinterSettings';
import PrinterDownload from './PrinterDownload/PrinterDownload';
import { PrinterContext } from '../../../HigherOrder/PrintController/PrintController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { SystemContext } from '../../../HigherOrder/SystemConroller/SystemController';
import downloadstyles from './PrinterDownload/PrinterDownload.module.css';
import setupstyles from './PrinterSettings/PrinterSettings.module.css';
import { isElectron, isLinux, isMacOS, isWindows, supportsUSBPrinting } from 'utilities/Environment';

const driverLink = isWindows ? `${process.env.REACT_APP_ASSET_URL}/${process.env.REACT_APP_DRIVER_PATH}/cte351_lm_v3000.exe`
    : isMacOS ? `${process.env.REACT_APP_ASSET_URL}/${process.env.REACT_APP_DRIVER_PATH}/ctzpos-cups-1.2.8-0.dmg`
        : isLinux ? 'https://www.citizen-systems.co.jp/en/printer/download/driver-upos_cups_sap/Linux_POS_CUPSdriver_V1250E.zip'
            : '';

export default function PrinterSetup() {

    const { helper } = useContext(PrinterContext);
    const { system: { isPOS } } = useContext(SystemContext);
    const { parms } = useContext(ParmContext);

    return (
        <div className={setupstyles.printerSetup}>
            {(!!helper || isElectron || supportsUSBPrinting) &&
                <PrinterSettings />
            }
            {(!helper && !isElectron && !supportsUSBPrinting) &&
                <PrinterDownload />
            }
            {(parms?.clerkInfo.memoId ?? 0) <= 0 && !isPOS && !supportsUSBPrinting &&
                <div className={downloadstyles.printerDownload}>
                    <div>If you need the printer driver, you may click the below link to download it.</div>
                    <a href={driverLink}>Install Printer Driver</a>
                </div>
            }
        </div>
    );
}