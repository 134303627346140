import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { TransactionTotals } from './ReportTable';
import { FormValues, ParsedTransaction } from '../Reports';
import { format } from 'date-fns';

const checkIndex = 4;

interface Props {
    transactions: ParsedTransaction[],
    totals: TransactionTotals,
    containsCheckTransaction: boolean
}

export function useReportExporter({ transactions, totals, containsCheckTransaction }: Props) {

    const { values: { startDate, endDate } } = useFormikContext<FormValues>();

    const csvTitle = startDate === endDate ? `Daily ${format(Number(endDate), 'yyyyMMdd') }.csv`
        : `${format(Number(startDate), 'yyyyMMdd')} - ${format(Number(endDate), 'yyyyMMdd') }.csv`;

    const csvURL = useMemo(() => {
        const csvData = [[
            "Type",
            "Receipt#",
            "Biller",
            "Cash",
            "Check",
            "Proc",
            "Agent",
            "Date",
            "Time",
            "Clerk"
        ]].concat(
            transactions.map((transaction) => [
                transaction.dercd,
                transaction.trace,
                transaction.billerName,
                transaction.cashAmt,
                transaction.checkAmt,
                transaction.processFee,
                transaction.storeFee,
                transaction.transDate,
                transaction.transTime,
                transaction.clerkName
            ])
        ).concat([[
            "",
            "Totals",
            "",
            totals.cashTotal,
            totals.checkTotal,
            totals.processFeeTotal,
            totals.agentFeeTotal,
            "",
            "Count",
            transactions.length.toString()
        ], [
            "",
            "Deposit Amt",
            "",
            totals.depositAmount,
            "",
            "",
            "",
            "",
            "Register Amt",
            totals.registerAmount
        ]]).map((row) => {
            if (!containsCheckTransaction) {
                row.splice(checkIndex, 1);
            }
            return row.join(",");
        }).join("\r\n")

        const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
        return URL.createObjectURL(csvBlob);
    }, [containsCheckTransaction, transactions, totals.agentFeeTotal, totals.cashTotal, totals.checkTotal, totals.depositAmount, totals.processFeeTotal, totals.registerAmount]);

    useEffect(() => {
        return () => URL.revokeObjectURL(csvURL);
    }, [csvURL]);

    return { csvTitle, csvURL };
}