import { useContext, useRef, useEffect } from 'react';
import { AnalyticsContext } from '../../../HigherOrder/AnalyticsController/AnalyticsController';
import { useFormikContext } from 'formik';

export default function AnalyticsNotifier() {

    const { values } = useFormikContext();
    const { startTransaction } = useContext(AnalyticsContext);
    const lastUpdate = useRef(0);

    useEffect(() => {
        if (Date.now() - lastUpdate.current > 15000) {
            startTransaction();
        }
        lastUpdate.current = Date.now();
    }, [values, startTransaction]);

    return null;
}