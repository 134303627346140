import { ChangeEvent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Message, { MessageType } from '../../../Common/Message/Message';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import styles from './Preferences.module.css';
import { isElectron } from 'utilities/Environment';

const timeZones = [
    { display: "Eastern", value: "America/New_York" },
    { display: "Central", value: "America/Chicago" },
    { display: "Mountain (Denver)", value: "America/Denver" },
    { display: "Mountain (Phoenix)", value: "America/Phoenix" },
    { display: "Pacific", value: "America/Los_Angeles" }
];

const timeZoneOptions = timeZones.map((zone) => {
    return <option key={zone.value} value={zone.value}>{zone.display}</option>;
});

export default function Preferences() {

    const { setSetting, settings } = useContext(SettingsContext);
    const { parms, loggedIn } = useContext(ParmContext);

    const [status, setStatus] = useState({
        message: '',
        messageType: '' as MessageType,
    });

    const changeTimeZone = (event: ChangeEvent<HTMLSelectElement>) => {
        setStatus({ message: "Time Zone saved successfully", messageType: '' });
        return setSetting({ timeZone: event.target.value });
    }

    const changeSoftKeyboard = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus({ message: "Keyboard settings saved successfully", messageType: '' });
        setSetting({ virtualKeyboard: event.target.checked });
    }

    const changeOldKeyboard = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus({ message: "Keyboard settings saved successfully", messageType: '' });
        setSetting({ oldKeyboard: event.target.checked });
    }

    const changeTrainingMode = (event: ChangeEvent<HTMLInputElement>) => {
        setStatus({ message: "Training mode settings saved successfully", messageType: '' });
        setSetting({ trainingModeDisabled: event.target.checked });
    }

    return (
        <div className={styles.preferences}>
            <fieldset className='Links'>
                <div>
                    <label>Time Zone</label>
                </div>
                <div>
                    <select value={settings.timeZone} onChange={changeTimeZone} >
                        {timeZoneOptions}
                    </select>
                </div>
                <div className={styles.trainingModeSetting}>
                    <label htmlFor='TrainingModeCheckbox'>Disable Training Mode</label>
                    <input id='TrainingModeCheckbox' type='checkbox' checked={settings.trainingModeDisabled} onChange={changeTrainingMode} />
                </div>
                {isElectron &&
                    <>
                        <div className={styles.softKeyboardSetting}>
                            <label htmlFor='SoftKeyboardCheckbox'>Use Soft Keyboard</label>
                            <input id='SoftKeyboardCheckbox' type='checkbox' checked={settings.virtualKeyboard} onChange={changeSoftKeyboard} />
                        </div>
                        {settings.virtualKeyboard &&
                            <div className={styles.oldKeyboardSetting}>
                                <label htmlFor='OldKeyboardCheckbox'>Use Keyboard With Numpad</label>
                                <input id='OldKeyboardCheckbox' type='checkbox' checked={settings.oldKeyboard} onChange={changeOldKeyboard} />
                            </div>
                        }
                    </>
                }
                <Message status={status} />
            </fieldset>
            <fieldset className='Links'>
                <Link to="/UpdateUserInfo" className={!(parms && parms.clerkInfo.type === 'S' && loggedIn) ? 'disabled' : ''}>Update Users</Link>
                <Link to="/HoursOfOperation" className={!loggedIn ? 'disabled' : ''}>Hours Of Operation</Link>
            </fieldset>
        </div>
    );
}