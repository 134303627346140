import type { ChangeEvent } from 'react';
import { useContext } from 'react';
import { Field, useFormikContext } from 'formik';
import DatePicker from '../../../../Common/DatePicker/DatePicker';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import styles from './ReportForm.module.css';
import type { User, FormValues } from '../Reports';

interface Props {
    clearData: () => void,
    users: User[]
}

/**
 * The form for the various filters / selections you can make for reports.
 */
export default function ReportForm({ clearData, users }: Props) {

    const { parms } = useContext(ParmContext);
    const { values, handleChange, setFieldValue } = useFormikContext<FormValues>();

    const handleChangeEvent = (event?: ChangeEvent) => {
        clearData();
        if (event) {
            handleChange(event);
        }
    }

    const changeType = (type: string) => {
        setFieldValue('type', type);
        handleChangeEvent();
    }

    const getTypeClass = (type: string) =>
        type === values.type ? 'Selected' : '';

    return (
        <div className={styles.reportForm}>
            <div className={styles.reportType}>
                <button type='button' onClick={() => changeType('daily')}
                    className={getTypeClass('daily')}>Daily Report</button>
                <button type='button' onClick={() => changeType('dateRange')}
                    className={getTypeClass('dateRange')}>Date Range Report</button>
                <button type='button' onClick={() => changeType('voidable')}
                    className={getTypeClass('voidable')}>Voidable Transactions</button>
                {!!parms?.parameters.ageVerifActive &&
                    <button type='button' onClick={() => changeType('ageVerif')}
                        className={getTypeClass('ageVerif')}>Age Verification</button>
                }
            </div>
            {values.type !== 'ageVerif' &&
                <div className={styles.reportProduct}>
                    <fieldset>
                        <label>Users:</label>
                        <Field component='select' name='user' onChange={handleChangeEvent}>
                            {users.map(({ clerkId, username }) =>
                                <option key={clerkId} value={clerkId}>{username}</option>
                            )}
                        </Field>
                    </fieldset>
                    {!!parms?.bpActive && parms.parameters.billPayActive &&
                        <fieldset>
                            <Field type="checkbox" name="billPayReport" id='billPayReport'
                                checked={values.billPayReport} onChange={handleChangeEvent} />
                            <label htmlFor='billPayReport'>Bill Pay</label>
                        </fieldset>
                    }
                    {parms?.moActive &&
                        <fieldset>
                            <Field type="checkbox" name="moneyOrderReport" id='moneyOrderReport'
                                checked={values.moneyOrderReport} onChange={handleChangeEvent} />
                            <label htmlFor='moneyOrderReport'>Money Orders</label>
                        </fieldset>
                    }
                    {parms?.parameters.prePayActive &&
                        <fieldset>
                            <Field type="checkbox" name="mobileTopUpReport" id='mobileTopUpReport'
                                checked={values.mobileTopUpReport} onChange={handleChangeEvent} />
                            <label htmlFor='mobileTopUpReport'>Mobile Top-Up</label>
                        </fieldset>
                    }
                    {parms?.parameters.giftCardActive &&
                        <fieldset>
                            <Field type="checkbox" name="giftCardReport" id='giftCardReport'
                                checked={values.giftCardReport} onChange={handleChangeEvent} />
                            <label htmlFor='giftCardReport'>Gift Cards</label>
                        </fieldset>
                    }
                    {parms?.parameters.amazonCashActive &&
                        <fieldset>
                            <Field type="checkbox" name="cashLoadReport" id='cashLoadReport'
                                checked={values.cashLoadReport} onChange={handleChangeEvent} />
                            <label htmlFor='cashLoadReport'>Amazon Cash</label>
                        </fieldset>
                    }
                    {parms?.parameters.netspendActive &&
                        <fieldset>
                            <Field type="checkbox" name="netSpendReport" id='netSpendReport'
                                checked={values.netSpendReport} onChange={handleChangeEvent} />
                            <label htmlFor='netSpendReport'>NetSpend</label>
                        </fieldset>
                    }
                </div>
            }
            <div className={styles.reportControl}>
                {values.type !== 'voidable' &&
                    <fieldset>
                        <label htmlFor='startDate'>Start Date:</label>
                        <DatePicker
                            onChange={handleChangeEvent}
                            name='startDate'
                            className={styles.dateInput} />
                    </fieldset>
                }
                {(values.type === 'dateRange' || values.type === 'ageVerif') &&
                    <fieldset>
                        <label htmlFor='endDate'>End Date:</label>
                        <DatePicker
                            onChange={handleChangeEvent}
                            name='endDate'
                            className={styles.dateInput} />
                    </fieldset>
                }
                <fieldset>
                    <AdvancedButton>Search</AdvancedButton>
                </fieldset>
            </div>
        </div>
    );
}