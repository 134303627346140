
import { useEffect } from 'react';
import ConfirmationPopup from '../../../../Common/Popups/ConfirmationPopup/ConfirmationPopup';

interface Props {
    clearErrors: () => void,
    open: boolean,
    logAction: (action: string) => void
}

export default function Reprint({ clearErrors, open, logAction }: Props) {

    useEffect(() => {
        if (open) {
            logAction('Final Info Popup Shown');
        }
    }, [logAction, open]);

    return (
        <div className='ErrorPopup'>
            <ConfirmationPopup
                onConfirm={clearErrors}
                open={open}
                confirmOnly>
                <h3>You are back in sequence.</h3>
            </ConfirmationPopup>
        </div>
    );
}