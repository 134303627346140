import { getClass } from './classnames';
import styles from './tooltip.module.css';

export type TooltipPlacement = 'top' | 'bottom' | 'right' | 'left' | 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

interface TooltipOptions {
    placement?: TooltipPlacement,
    highlight?: boolean
}

export const getTooltipTriggerClass = (visible?: boolean) =>
    getClass([
        styles.tooltipTrigger,
        visible === undefined && styles.automatic,
        visible !== undefined && styles.manual,
        !!visible && styles.visible
    ]);

export const getTooltipClass = ({ placement, highlight }: TooltipOptions = {}) =>
    getClass([
        styles.tooltip,
        placement === 'top' && styles.top,
        placement === 'bottom' && styles.bottom,
        placement === 'left' && styles.left,
        (placement === 'right' || !placement) && styles.right,
        placement === 'top-left' && styles.topLeft,
        placement === 'top-right' && styles.topRight,
        placement === 'bottom-left' && styles.bottomLeft,
        placement === 'bottom-right' && styles.bottomRight,
        !!highlight && styles.highlight,
    ]);

export const tooltipButtonClass = styles.tooltipButton;
export const tooltipTitleClass = styles.tooltipTitle;
export const tooltipBoldClass = styles.bold;