import { useContext } from 'react';
import { ParmContext } from '../../ParmController/ParmController';
import { useIdleTimer } from 'react-idle-timer';

/**
 * Component responsible for timing out the application on idle. Does not render anything to the DOM.
 */
export function useTimeout(handleLogout: () => void) {

    const { parms } = useContext(ParmContext);

    const timeoutTime = parms?.parameters.logoutTime ?? (30 * 1000 * 60);
    useIdleTimer({ onIdle: handleLogout, timeout: timeoutTime });
}