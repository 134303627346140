
import { useState, ChangeEvent, Fragment, useCallback, useEffect } from 'react';
import { FormikContextType } from 'formik';
import Retry from './Retry';
import Resequence from './Resequence';
import Reprint from './Reprint';
import Eject from './Eject';
import './ErrorPopup.css';
import { initialValues, MOResponseBody } from '../MoneyOrder';

interface Props {
    onContinue: (actions: FormikContextType<typeof initialValues>) => Promise<void>,
    onRetry: (actions: FormikContextType<typeof initialValues>) => Promise<void>
    paymentResponse: MOResponseBody | null
    index: number,
    message: any,
    outOfSequence: boolean,
    clearErrors: () => void,
    open: boolean,
    logAction: (action: string) => void
}

export default function ErrorPopup({ onContinue, onRetry, paymentResponse, index, message, outOfSequence, clearErrors, open, logAction }: Props) {

    const [serialNumber, setSerialNumber] = useState('');
    const [serialCheck, setSerialCheck] = useState('');
    const [nextValidMO, setNextValidMO] = useState<null | string>(null);

    const numberToEject = Number(nextValidMO) - Number(serialNumber);

    const handleSerialNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSerialNumber(event.target.value);
    }, [])

    const handleSerialCheckChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSerialCheck(event.target.value);
    }, [])

    const setSerial = useCallback(({ serialNumber, serialCheck }: { serialNumber: string, serialCheck: string }) => {
        setSerialNumber(serialNumber);
        setSerialCheck(serialCheck);
    }, []);

    useEffect(() => {
        if (!open) {
            setSerialNumber('');
            setSerialCheck('');
            setNextValidMO(null);
        }
    }, [open]);

    return (
        <Fragment>
            <Eject
                numberToEject={numberToEject}
                nextValidMO={nextValidMO}
                setNextValidMO={setNextValidMO}
                setSerial={setSerial}
                index={index}
                paymentResponse={paymentResponse}
                open={outOfSequence && !!nextValidMO && numberToEject > 0 && open}
                logAction={logAction} />
            <Reprint
                clearErrors={clearErrors}
                open={outOfSequence && !!nextValidMO && numberToEject === 0 && open}
                logAction={logAction} />
            <Resequence
                paymentResponse={paymentResponse}
                index={index}
                handleSerialNumberChange={handleSerialNumberChange}
                handleSerialCheckChange={handleSerialCheckChange}
                serialNumber={serialNumber}
                serialCheck={serialCheck}
                setNextValidMO={setNextValidMO}
                open={outOfSequence && !nextValidMO && open}
                logAction={logAction} />
            <Retry
                paymentResponse={paymentResponse}
                index={index}
                message={message}
                onContinue={onContinue}
                onRetry={onRetry}
                open={!outOfSequence && open}
                logAction={logAction} />
        </Fragment>
    );
}