import type { BillerDetail } from 'components/hooks/BillPay';
import { useBillPay } from 'components/hooks/BillPay';
import { TrainingContext } from 'components/HigherOrder/TrainingOverlay/TrainingOverlay';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useContext, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useFormikContext } from 'formik';
import styles from '../NetSpend.module.css';
import type { initialValues } from '../NetSpend';
import MessageAggregator from 'components/Common/MessageAggregator/MessageAggregator';
import { getTooltipClass, getTooltipTriggerClass } from 'utilities/tooltip';

interface Props {
    setBillerDetail: Dispatch<SetStateAction<BillerDetail | null>>,
    isReloadTraining: boolean
}

export default function TypeForm({ setBillerDetail, isReloadTraining }: Props) {

    const [loading, setLoading] = useState(false);

    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { getBillerDetailRequest } = useBillPay();
    const { push } = useHistory();
    const { setStatus } = useFormikContext<typeof initialValues>();

    const newCardTooltipShown = isCurrentPageTraining && !isReloadTraining;
    const reloadTooltipShown = isCurrentPageTraining && isReloadTraining;

    const handleNewCardStart = useCallback(async () => {
        setLoading(true);
        try {
            const billerDetail = await getBillerDetailRequest({ billerId: 3327 });
            if (billerDetail) {
                setBillerDetail(billerDetail);
                setStatus({ message: '' });
                push('/NetSpend/PhoneForm');
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }, [getBillerDetailRequest, push, setBillerDetail, setStatus]);

    const handleReloadStart = useCallback(async () => {
        setLoading(true);
        try {
            const billerDetail = await getBillerDetailRequest({ billerId: 3328 });
            if (billerDetail) {
                setBillerDetail(billerDetail);
                setStatus({ message: '' });
                push('/NetSpend/PhoneForm');
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }, [getBillerDetailRequest, push, setBillerDetail, setStatus]);


    return (
        <div className={styles.home}>
            <div className={styles.main}>
                <h1>WELCOME TO NETSPEND</h1>
                <div className={styles.menu}>
                    <div className={
                        styles.menuItem + ' ' +
                        (loading ? styles.disabled : '') + ' ' +
                        getTooltipTriggerClass(newCardTooltipShown)
                    }
                        data-text='CLICK NEW CARD'
                        onClick={handleNewCardStart}>
                        <span>NEW CARD</span>
                        <b className={getTooltipClass({ placement: 'top' })}>CLICK NEW CARD</b>
                    </div>
                    <div className={
                        styles.menuItem + ' ' +
                        (loading ? styles.disabled : '') + ' ' +
                        getTooltipTriggerClass(reloadTooltipShown)
                    }
                        data-text='CLICK RELOAD'
                        onClick={handleReloadStart}>
                        <span>RELOAD</span>
                        <b className={getTooltipClass({ placement: 'top' })}>CLICK RELOAD</b>
                    </div>
                </div>
                <MessageAggregator />
            </div>
            <div className={styles.tips}>
                <div className={styles.tip}>
                    <h4>Be aware of scams!</h4>
                    <ul className={styles.scams}>
                        <li>Netspend will never call your location to run “tests.” </li>
                        <li>Do not load transactions over the phone.</li>
                        <li>A card must be present for all transactions.</li>
                        <li>See <Link to="/Support" className={styles.faqlink} >FAQs</Link> for supplies and helpful tips.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}