import React, { Fragment, useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import SupportHeader from '../SupportHeader/SupportHeader';
import { useLocation } from 'react-router-dom';
import { APIContext } from '../../../../HigherOrder/APIController/APIController';
import { AuthContext } from '../../../../HigherOrder/AuthController/AuthController';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import { rewordForMEMO } from '../../../../../utilities/Tools';
import styles from './SupportEntries.module.css';
import { FullEntry } from '../SupportSearch/SupportSearch';
import { EntryPreview } from '../SupportFAQ/SupportFAQ';
import ReactPlayer from 'react-player'

/**
 * Page for displaying the entries in a category.
 * Additionally, will display a single entry if comming from the search.
 */
export default function SupportEntries() {

    const { auth } = useContext(AuthContext);
    const { parms } = useContext(ParmContext);
    const { failoverFetch } = useContext(APIContext);

    const [entries, setEntries] = useState<EntryPreview[]>([]);
    const [selectedEntry, setSelectedEntry] = useState<null | FullEntry>(null);

    const prevSearchRef = useRef<string>();
    const prevEntryRef = useRef<HTMLDivElement>();

    const { search } = useLocation();

    const loadEntries = useCallback(async (categoryId: string) => {
        if (auth) {
            try {
                const response = await failoverFetch('/Support?' + new URLSearchParams({
                    type: 'entries',
                    categoryId: categoryId,
                    agentId: auth.agentId,
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as EntryPreview[];
                setEntries(data);
            } catch (error) {
                console.error(error);
            }
        }
    }, [auth, failoverFetch]);

    const filterCompany = useCallback((entry: FullEntry) => {
        if ((parms?.clerkInfo.memoId ?? 0) > 0) {
            return {
                ...entry,
                contentPreview: rewordForMEMO(entry.contentPreview)
            }
        } else {
            return entry;
        }
    }, [parms?.clerkInfo.memoId]);

    const loadEntry = useCallback(async (entryId: string | number) => {
        if (auth) {
            try {
                const response = await failoverFetch('/Support?' + new URLSearchParams({
                    type: 'entryContent',
                    entryId: entryId.toString(),
                    agentId: auth.agentId,
                    authToken: auth.authToken
                }));
                const data = JSON.parse(response) as FullEntry;
                if (entries.length === 0) {
                    setEntries([{
                        id: data.id,
                        readCount: data.readCount,
                        title: data.title
                    }]);
                }
                setSelectedEntry(filterCompany(data));
            } catch (error) {
                console.error(error);
            }
        }
    }, [auth, entries.length, failoverFetch, filterCompany]);

    const loadData = useCallback(() => {
        const searchParams = new URLSearchParams(search);
        const categoryId = searchParams.get('categoryId');
        const entryId = searchParams.get('entryId');
        if (categoryId) {
            loadEntries(categoryId);
        } else if (entryId) {
            loadEntry(entryId);
        }
    }, [loadEntries, loadEntry, search])

    const selectEntry = useCallback((entry: EntryPreview) => {
        setSelectedEntry({ ...entry, contentPreview: 'Loading...' });
        loadEntry(entry.id);
    }, [loadEntry])

    const clearSelectedEntry = useCallback(() => {
        setSelectedEntry(null);
    }, [])

    const handleEntryRef = useCallback((ref: HTMLDivElement) => {
        if (ref && prevEntryRef.current !== ref) {
            ref.scrollIntoView();
        }
        prevEntryRef.current = ref;
    }, [])

    const entryList = useMemo(() => {
        return entries.map((entry) => {
            if (selectedEntry && selectedEntry.id === entry.id) {
                return (
                    <div key={entry.id} onClick={clearSelectedEntry} ref={handleEntryRef} className={styles.supportEntry}>
                        <h4 className={styles.supportEntryTitle}>{entry.title}</h4>
                        <p>{selectedEntry.contentPreview}</p>
                        {selectedEntry.videoUrl &&
                            <ReactPlayer
                                controls={true}
                                url={selectedEntry.videoUrl}
                                className={styles.supportEntryVideo} />
                        }
                    </div>
                );
            } else {
                return (
                    <div key={entry.id} onClick={() => selectEntry(entry)} className={styles.supportEntry}>
                        <span className={styles.supportEntryTitle}>{entry.title}</span>
                    </div>
                );
            }
        });
    }, [clearSelectedEntry, entries, handleEntryRef, selectEntry, selectedEntry]);

    useEffect(() => {
        if (prevSearchRef.current !== search) {
            setEntries([]);
            setSelectedEntry(null);
            loadData();
        }
        prevSearchRef.current = search;
    }, [loadData, search]);

    return useMemo(() =>
        <Fragment>
            <SupportHeader />
            <div className={styles.supportEntries}>
                {entryList}
            </div>
        </Fragment>
        , [entryList]);
}