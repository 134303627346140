import { useCallback, useContext } from 'react';
import { ParmContext } from '../../HigherOrder/ParmController/ParmController';
import { PrinterContext } from '../../HigherOrder/PrintController/PrintController';
import type { PrintOptions, PrintRequest } from '../../HigherOrder/PrintController/PrintController';
import type { ParsedTransaction } from '../../App/Mainview/Reports/Reports';
import type { PaymentHistory } from 'components/hooks/MTU';
import { getHeaderRows } from './BillPayPrinter';
import { serverParse, useTimeZone } from 'components/hooks/TimeZone';
import type { Customer } from 'components/hooks/QR';
import { useQR } from 'components/hooks/QR';

interface PaymentData {
    date: string,
    time: string,
    trace: string,
    amount: number,
    totalFee: number,
    authCode: string,
    providerName: string,
    accountNum: string,
    receiptText: string,
    localCurrency: string,
    localAmt: string,
    productName: string,
    customer?: Customer
}

const emptyArr = [] as never[];

export function useMTUPrinter(service: 'GIFTCARD' | 'TOPUP') {

    const { companyId, parms } = useContext(ParmContext);
    const { localFormat } = useTimeZone();
    const { print } = useContext(PrinterContext);
    const { getOptInURL } = useQR();

    const headers = parms?.parameters.headers ?? emptyArr;

    const printPaymentData = useCallback(async (paymentData: PaymentData, printOptions: Partial<PrintOptions>) => {
        const qrUrl = await getOptInURL(paymentData.customer);

        return print(([
            { text: service === 'GIFTCARD' ? 'Digital Gift Card' : 'Mobile Top-Up', style: { emphasized: true } },
            { text: '' },
        ] as PrintRequest).concat(
            getHeaderRows(headers)
        ).concat([
            { text: '' },
            { text: `Date: ${paymentData.date} Time: ${paymentData.time}` },
            { text: '' },
            { text: `Agent Number: ${companyId}` },
            { text: '' },
            { text: `Receipt Num: ${paymentData.trace}`, style: { emphasized: true, doubleSize: true } },
            { text: '' },
            { text: `Business Date: ${paymentData.date}` },
            { text: '' },
            { text: `Payment: $${paymentData.amount.toFixed(2)}` }
        ]).concat(
            paymentData.localCurrency !== "USD" ? [{
                text: `Local Payment Amount: ${paymentData.localAmt} ${paymentData.localCurrency}`
            }] : []
        ).concat(
            paymentData.localCurrency === 'USD' ? [{
                text: `Cust Fee: $${paymentData.totalFee.toFixed(2)}`
            }] : []
        ).concat([
            { text: '' },
            { text: `Collect Cash: $${((paymentData.amount * 100 + paymentData.totalFee * 100) / 100).toFixed(2)}`, style: { emphasized: true } },
            { text: '' },
            { text: 'Proof Of Payment', style: { emphasized: true } },
            { text: paymentData.authCode },
            { text: '' },
            { text: `${paymentData.productName ? 'Product' : 'Provider'}: ${paymentData.providerName} ${paymentData.productName}`, style: { emphasized: true } },
            { text: `Phone Number: ${paymentData.accountNum.trim()}` },
            { text: '' },
        ]).concat(paymentData.receiptText.trim() ? [
            { text: paymentData.receiptText.trim() },
            { text: '' }
        ] : []).concat([
            { text: `${service === 'GIFTCARD' ? 'Digital Gift Card' : 'Mobile Top-Up'} transactions may take up to 10 minutes to process.`, style: { emphasized: true } },
            { text: '' },
            { text: 'Please be advised that all sales are final.', style: { emphasized: true } },
            { text: 'No refunds are available.', style: { emphasized: true } },
            { text: '' },
            { text: 'TRANSACTION APPROVED', style: { emphasized: true } },
            { text: '' },
        ]).concat(
            !!qrUrl ? [
                { url: qrUrl }
            ] : []
        ), printOptions);
    }, [companyId, getOptInURL, headers, print, service])

    const printLastPayment = useCallback((lastPayment: PaymentHistory) => {
        const parsedDate = serverParse(lastPayment.date + lastPayment.time, 'yyyyMMddHHmmss');
        return printPaymentData({
            ...lastPayment,
            date: localFormat(parsedDate, 'MM/dd/yyyy'),
            time: localFormat(parsedDate, "HH:mm:ss zzz"),
            amount: (lastPayment.cashAmt / 100),
            totalFee: parseFloat(lastPayment.totalFee.toString()),
            customer: {
                name: lastPayment.customerName,
                phone: lastPayment.customerPhone,
                zip: lastPayment.customerZip,
                email: lastPayment.customerEmail
            }
        }, { reprint: false, trace: lastPayment.trace });
    }, [localFormat, printPaymentData]);

    const printTransaction = useCallback((transaction: ParsedTransaction) => {
        return printPaymentData({
            date: transaction.transDate,
            time: transaction.transTime,
            trace: transaction.trace,
            amount: Number(transaction.amount),
            totalFee: Number(transaction.customerFee),
            authCode: transaction.authCode,
            providerName: transaction.billerName,
            accountNum: transaction.accountNum,
            receiptText: '',
            localCurrency: 'USD',
            localAmt: transaction.amount,
            productName: ''
        }, { reprint: true });
    }, [printPaymentData]);

    return { printLastPayment, printTransaction }
}