import type { ChangeEvent } from 'react';
import { useContext, useState, useCallback } from 'react';
import type { FormikContextType } from 'formik';
import { useFormikContext } from 'formik';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import VirtualizedTable from '../../../../Common/VirtualizedTable/VirtualizedTable';
import { TrainingContext } from '../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import { useLocation } from 'react-router-dom';
import styles from './BillerSearch.module.css';
import type { CustomerBillerDetailProps, initialValues } from '../BillPay';
import { tutorialBiller } from '../BillPay';
import type { BillerDetail, BillerPreview } from 'components/hooks/BillPay';
import { useBillPay } from 'components/hooks/BillPay';
import { useDebounce, useEffectOnce, useUpdateEffect } from 'usehooks-ts';
import { tooltipBoldClass } from 'utilities/tooltip';

const columns = [{
    label: 'Biller',
    dataKey: 'billerName',
    width: 320,
    flexGrow: 1
}, {
    label: 'Remit Zip',
    dataKey: 'billerZip',
    width: 100
}, {
    label: 'Remit Address',
    dataKey: 'billerAddr',
    width: 200
}, {
    label: 'Remit City',
    dataKey: 'billerCity',
    width: 190
}, {
    label: 'Remit State',
    dataKey: 'billerState',
    width: 45
}];

interface Props {
    onSearchChange: (newSearch: string, formik: FormikContextType<typeof initialValues>) => void,
    onSearchEvent: (event: ChangeEvent<HTMLInputElement>) => void,
    isBillerSelectionTutorial: boolean,
    getBillerDetail: (props: CustomerBillerDetailProps, formik?: FormikContextType<typeof initialValues>) => Promise<BillerDetail | undefined>
}

export default function BillerSearch({ onSearchChange, onSearchEvent, isBillerSelectionTutorial, getBillerDetail }: Props) {

    const { search } = useLocation();
    const formik = useFormikContext<typeof initialValues>();
    const billerSearch = useDebounce(formik.values.billerSearch, 250);
    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { billerSearchRequest } = useBillPay();

    const [billers, setBillers] = useState<BillerPreview[]>([]);
    const [loadingBillerDetail, setLoadingBillerDetail] = useState(false);
    const [loadingBillers, setLoadingBillers] = useState(false);

    const getBillers = useCallback(async () => {
        setBillers([]);
        setLoadingBillers(true);
        try {
            const response = await billerSearchRequest(billerSearch);
            if (response) {
                if (isCurrentPageTraining
                    && tutorialBiller.billerName.includes(billerSearch)) {
                    response.push(tutorialBiller);
                }
                setBillers(response);
            }
        } catch (error) {

        } finally {
            setLoadingBillers(false);
        }
    }, [billerSearch, billerSearchRequest, isCurrentPageTraining])

    const handleRowClick = async (rowData: BillerPreview) => {
        if (!loadingBillerDetail) {
            setLoadingBillerDetail(true);
            try {
                await getBillerDetail(rowData, formik)
            } catch (error) {
                console.error(error);
                setLoadingBillerDetail(false);
            }
        }
    }

    useEffectOnce(() => {
        const searchParams = new URLSearchParams(search);
        const urlBillerSearch = searchParams.has('search') ? decodeURIComponent(searchParams.get('search')!) : billerSearch;
        if (urlBillerSearch !== billerSearch) {
            onSearchChange(urlBillerSearch, formik);
            formik.setFieldValue('billerSearch', urlBillerSearch);
        } else {
            getBillers();
        }
    });

    useUpdateEffect(() => {
        getBillers();
    }, [getBillers]);

    return (
        <div>
            <div className={styles.billerSearchFormControl} >
                <label className={styles.billerSearchLabel}>Biller Search</label>
                <AdvancedField type='search'
                    className={styles.billerSearchInput}
                    name='billerSearch'
                    onChange={onSearchEvent}
                    placeholder='SEARCH OVER 30,000 BILLERS'
                    autoFocus={true}
                    upperCase={true}
                    maxLength={120}
                    trainingFocused={isBillerSelectionTutorial}
                    trainingTooltip={
                        <>
                            <div>NOW THAT YOU HAVE SELECTED THE CUSTOMER, SEARCH FOR THE BILLER</div>
                            <b className={tooltipBoldClass}>{tutorialBiller.billerName}</b>
                        </>
                    }
                    tooltipPlacement='top' />
            </div>
            <VirtualizedTable
                alwaysFocused={true}
                data={billers}
                loading={loadingBillerDetail || loadingBillers}
                columns={columns}
                onRowClick={handleRowClick}
                trainingRow={tutorialBiller}
                trainingFocused={isBillerSelectionTutorial}
                trainingTooltip={
                    <>
                        <div>SELECT THE BILLER</div>
                        <b className={tooltipBoldClass}>{tutorialBiller.billerName}</b>
                    </>
                } />
        </div>
    );
}