import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import WifiSignalIcon from './WifiSignalIcon/WifiSignalIcon';
import AdvancedForm from '../../../../Common/AdvancedForm/AdvancedForm';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { SystemContext } from '../../../../HigherOrder/SystemConroller/SystemController';
import Message from '../../../../Common/Message/Message';
import styles from './WifiForm.module.css';
import { Field, type FormikHelpers } from 'formik';
import { isLocal } from 'utilities/Environment';

interface Connection {
    iface?: string,
    mac: string,
    quality: number,
    security: string,
    ssid: string
}

const getLoadingConnections = () => {
    let connections = [];
    for (let i = 0; i < 6; i++) {
        connections.push({
            mac: i.toString(),
            quality: 0,
            security: "Secure",
            ssid: "Searching..."
        });
    }
    return connections;
};

const getFilteredConnections = (networks: Connection[]) => {
    let networkSet = new Set();
    return networks.filter(network => {
        if (networkSet.has(network.ssid)) {
            return false;
        } else {
            networkSet.add(network.ssid);
            return true;
        }
    });
}

const isSelectedConnected = (network: Connection, connections: Connection[]) => {
    return connections.some((connection) => {
        return connection.ssid === network?.ssid;
    });
}

const sortConnections = (networks: Connection[], current: Connection[]) => {
    networks.sort((a, b) => {
        if (isSelectedConnected(a, current) && !isSelectedConnected(b, current)) {
            return -1;
        } else if (!isSelectedConnected(a, current) && isSelectedConnected(b, current)) {
            return 1;
        } else {
            return b.quality - a.quality;
        }
    });
}

/**
 * Page for displaying the current status of the Network, and what the client can / cannot talk to.
 * Useful for troubleshooting purposes.
 */
export default function WifiForm() {

    const [networks, setNetworks] = useState<Connection[]>(getLoadingConnections());
    const [currentConnections, setCurrentConnections] = useState<Connection[]>([]);
    const [selectedNetwork, setSelectedNetwork] = useState<null | Connection>(null);

    const { system: { isGM } } = useContext(SystemContext);
    const wifiRef = useRef(require('node-wifi-fixes'));

    const updateConnections = async () => {
        try {
            const currentConnectionResponse = await wifiRef.current.getCurrentConnections() as Connection[];
            console.log(currentConnectionResponse);
            setCurrentConnections(currentConnectionResponse);
            const networks = await wifiRef.current.scan();
            sortConnections(networks, currentConnectionResponse);
            const filteredNetworks = getFilteredConnections(networks);
            console.log(filteredNetworks);
            setNetworks(filteredNetworks);
        } catch (error) {
            console.error(error);
        }
    }

    const getNetworkInfo = useCallback((network: Connection) => {
        let info = [];
        if (isSelectedConnected(network, currentConnections)) {
            info.push('Connected');
        }
        if (!network.security || network.security === "Open") {
            info.push("Open");
        } else {
            info.push("Secured");
        }
        return info.join(', ');
    }, [currentConnections])

    const getRowForm = useCallback((network: Connection) => {
        if (selectedNetwork?.ssid === network.ssid) {
            if (isSelectedConnected(selectedNetwork, currentConnections)) {
                return (
                    <Fragment key={selectedNetwork.mac}>
                        <AdvancedButton>Disconnect</AdvancedButton>
                        <Message />
                    </Fragment>
                );
            } else if (selectedNetwork.security === "Open" || !selectedNetwork.security) {
                return (
                    <Fragment key={selectedNetwork.mac}>
                        <AdvancedButton>Connect</AdvancedButton>
                        <Message />
                    </Fragment>
                );
            } else {
                return (
                    <Fragment key={selectedNetwork.mac}>
                        <Field name={`${selectedNetwork.ssid}-Password`} placeholder='Wifi Password' />
                        <AdvancedButton>Connect</AdvancedButton>
                        <Message />
                    </Fragment>
                );
            }
        }
        return null;
    }, [currentConnections, selectedNetwork])

    const getRowClass = useCallback((network: Connection) => {
        return selectedNetwork?.ssid === network.ssid ? 'Selected' : '';
    }, [selectedNetwork?.ssid]);

    const networkList = useMemo(() => {
        return networks.map((network) => {
            return (
                <li key={network.mac} className={getRowClass(network)} onClick={() => handleRowClick(network)}>
                    <div className={styles.wifiDisplayBlock}>
                        <WifiSignalIcon quality={network.quality} />
                        <div className={styles.wifiInfoBlock}>
                            <div><b>{network.ssid}</b></div>
                            <div>{getNetworkInfo(network)}</div>
                        </div>
                    </div>
                    <div className={styles.wifiRowForm}>
                        {getRowForm(network)}
                    </div>
                </li>
            );
        });
    }, [getNetworkInfo, getRowClass, getRowForm, networks])

    const handleRowClick = (network: Connection) => {
        setSelectedNetwork(network);
    }

    const initialValues = useMemo(() => {
        let values = {} as { [key: string]: string };
        networks.forEach((network) => {
            values[`${network.ssid}-Password`] = '';
        });
        return values;
    }, [networks])

    const seletedIface = currentConnections.find((connection) => {
        return connection.ssid === selectedNetwork?.ssid;
    })?.iface;

    const handleSubmit = async (values: typeof initialValues, { setStatus, setSubmitting }: FormikHelpers<typeof initialValues>) => {
        if (selectedNetwork) {
            if (isSelectedConnected(selectedNetwork, currentConnections)) {
                setStatus({ message: 'Disconnecting...' });
                wifiRef.current.init({
                    iface: seletedIface
                });
                try {
                    await wifiRef.current.disconnect()
                    setStatus({ message: 'Successfully disconnected!' });
                } catch (error) {
                    setStatus({ message: 'Failed to disconnect from network!', messageType: 'error' });
                    console.error(error);
                } finally {
                    updateConnections();
                    setSubmitting(false);
                }
            } else {
                setStatus({ message: 'Connecting...' });
                if ((isLocal || isGM)) {
                    process.chdir('C:\\billPayHere');
                }

                const password = (selectedNetwork.security === "Open" || !selectedNetwork.security) ? undefined : values[`${selectedNetwork.ssid}-Password`];
                try {
                    await wifiRef.current.connect({ ssid: selectedNetwork.ssid, password: password })
                    setStatus({ message: 'Successfully connected!' });
                } catch (error) {
                    setStatus({ message: 'Failed to connect to network!', messageType: 'error' });
                    console.error(error);
                } finally {
                    updateConnections();
                    setSubmitting(false);
                }
            }
        }
    }

    useEffect(() => {
        wifiRef.current.init({
            iface: null
        });

        updateConnections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdvancedForm className={styles.wifiForm} initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
            <ul className={styles.wifiList}>
                {networkList}
            </ul>
        </AdvancedForm>
    );
}