import { Fragment, useCallback, useContext, useMemo } from 'react';
import { useLocation } from "react-router-dom";
import { ParmContext } from '../../../HigherOrder/ParmController/ParmController';
import { APIContext } from '../../../HigherOrder/APIController/APIController';
import { SettingsContext } from '../../../HigherOrder/SettingsController/SettingsController';
import { TrainingContext } from '../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import styles from './Header.module.css';
import { getClass } from 'utilities/classnames';
import { getTooltipClass, getTooltipTriggerClass, tooltipButtonClass, tooltipTitleClass } from 'utilities/tooltip';

interface Props {
    openMenu: () => void,
    menuClosed: boolean,
    fullyLoggedIn: boolean,
    isHeaderTraining: boolean,
    isRightSectionTraining: boolean
}

/**
 * The common header for the majority of the application.
 */
export default function Header({ openMenu, menuClosed, fullyLoggedIn, isHeaderTraining, isRightSectionTraining }: Props) {

    const { pathname } = useLocation();
    const { serverURL, forceServerChange } = useContext(APIContext);
    const { parms, loggedIn, companyId } = useContext(ParmContext);
    const { setTrainingSetting } = useContext(SettingsContext);
    const { isInBetweenTrainings } = useContext(TrainingContext);

    const serverName = useMemo(() => {
        if (serverURL.includes("dev.")) {
            if (serverURL.includes("phnserver")) {
                return "*AWS*";
            }
            return "*DEV*";
        } else if (serverURL.includes("localhost")) {
            return 'LOCAL';
        }
        return "";
    }, [serverURL])

    const completeHeaderTraining = useCallback(() => {
        if (isRightSectionTraining && !isInBetweenTrainings) {
            setTrainingSetting({ header: true });
        }
    }, [isInBetweenTrainings, isRightSectionTraining, setTrainingSetting])

    return (
        <header className={styles.header}>
            <div className={styles.leftSection}>
                <div className={styles.server} onClick={forceServerChange}>{serverName}</div>
                <div className={styles.userInformation}>
                    {fullyLoggedIn &&
                        <>
                            <div>User : {parms?.clerkInfo.username ?? 'N/A'}</div>
                            <div>Agent : {companyId}</div>
                        </>
                    }
                </div>
                {loggedIn &&
                    <div className={`${styles.logo} ${(parms?.clerkInfo.memoId ?? 0) > 0 ? styles.MEMO : ''}`} ></div>
                }
            </div>
            <div className={getClass([
                styles.rightSection,
                getTooltipTriggerClass(isRightSectionTraining)
            ])}
                onClick={completeHeaderTraining}  >
                {fullyLoggedIn && menuClosed && pathname !== '/' &&
                    <button className={styles.headerButton} onClick={openMenu}>Menu</button>
                }
                {!isInBetweenTrainings &&
                    <div className={getTooltipClass({ placement: 'bottom-left' })}>
                        <div className={tooltipTitleClass}>Navigation</div>
                        <div>This is your primary navigation.</div>
                        <div>It will take you to your main products.</div>
                        <span className={tooltipButtonClass} onClick={completeHeaderTraining}>Continue</span>
                    </div>
                }
                {isInBetweenTrainings && menuClosed &&
                    <div className={getTooltipClass({ placement: 'bottom-left' })}>
                        <div className={tooltipTitleClass}>You've completed this training, but there's still more to learn!</div>
                        <div>Use the navigation to explore another product.</div>
                    </div>
                }
            </div>
        </header>
    );
}