import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import type { ListData } from '../../CustomList/CustomList';
import CustomList from '../../CustomList/CustomList';
import type { Product } from 'components/hooks/MTU';
import { tooltipBoldClass } from 'utilities/tooltip';

interface Props {
    isProductTutorial?: boolean,
    tutorialProduct: string,
    products: Product[],
    selectedProduct?: Product,
    setSelectedProductIndex: Dispatch<SetStateAction<number>>
}

interface FormattedProduct extends Product {
    formattedName: string
}

export default function ProductList({ isProductTutorial, tutorialProduct, products, selectedProduct, setSelectedProductIndex }: Props) {

    const validate = (element: FormattedProduct) =>
        !(isProductTutorial && element.retailPrice.toString() !== tutorialProduct)

    const formattedProducts = useMemo(() => {
        return products.map((product) => {
            if (product.localInfoCurrency === 'USD') {
                return {
                    ...product,
                    formattedName: '$' + product.localInfoAmount
                }
            } else {
                return {
                    ...product,
                    formattedName: product.localInfoAmount + ' ' + product.localInfoCurrency
                }
            }
        });
    }, [products])

    const handleProductChange = async (product: FormattedProduct & ListData, index: number) => {
        setSelectedProductIndex(index);
    }

    return (
        <CustomList
            selectedId={selectedProduct?.retailPrice}
            id='retailPrice'
            display='formattedName'
            title='SEARCH PRODUCT'
            data={formattedProducts}
            tooltip={
                <>
                    <div>CHOOSE THE PRODUCT</div>
                    <b className={tooltipBoldClass}>{'$' + (Number(tutorialProduct) / 100).toFixed(2)}</b>
                </>
            }
            tooltipShown={isProductTutorial}
            className={isProductTutorial ? 'Focused' : ''}
            validate={validate}
            autoFocus={isProductTutorial}
            onChange={handleProductChange} />
    );
}