import CashField from "components/Common/CashField/CashField";
import MessageAggregator from "components/Common/MessageAggregator/MessageAggregator";
import type { BillerDetail } from "components/hooks/BillPay";
import { TrainingContext } from "components/HigherOrder/TrainingOverlay/TrainingOverlay";
import { useFormikContext } from "formik";
import { useContext } from "react";
import CartForm from "../CartForm.tsx/CartForm";
import type { initialValues } from "../NetSpend";
import styles from '../NetSpend.module.css';
import { tooltipBoldClass } from "utilities/tooltip";

export const tutorialAmount = '3000';

interface Props {
    billerDetail: BillerDetail
}

export default function AmountForm({ billerDetail }: Props) {

    const { isCurrentPageTraining } = useContext(TrainingContext);
    const { values } = useFormikContext<typeof initialValues>();

    const isAmountTutorial = isCurrentPageTraining
        && values.amount !== tutorialAmount;

    return (
        <div className={styles.amount}>
            <div>
                <h1>Please Enter an Amount</h1>
                <CashField
                    autoFocus={true}
                    name='amount'
                    trainingFocused={isAmountTutorial}
                    trainingTooltip={
                        <>
                            <div>ENTER THE AMOUNT</div>
                            <b className={tooltipBoldClass}>{'$' + (Number(tutorialAmount) / 100).toFixed(2)}</b>
                        </>
                    }
                    placement="bottom" />
                <div className={styles.legalMessage}>Between $20.00 and $500.00</div>
                <MessageAggregator />
            </div>
            <div>
                <CartForm billerDetail={billerDetail} />
            </div>
        </div>
    );
}