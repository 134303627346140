import type { ReactNode, SetStateAction } from 'react';
import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { ParmContext } from '../ParmController/ParmController';
import { SystemContext } from '../SystemConroller/SystemController';
import type { PrinterInfo } from 'electron';
import { isElectron } from "utilities/Environment";

interface StaticIP {
    ipType: string,
    ipAddress: string,
    subnetMask: string,
    gateway: string,
    dns1: string,
    dns2: string,
    interfaceName: string
}

const initialSettings = {
    lastMOSerial: null as null | string,
    virtualKeyboard: false,
    oldKeyboard: false,
    trainingModeDisabled: false,
    timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    printDuplicate: false,
    version: 'N/A',
    receiptPrinter: undefined as undefined | string,
    reportPrinter: undefined as undefined | string,
    upgradeDownloaded: false,
    ignoreUpgrade: false,
    staticIP: undefined as undefined | StaticIP
}

export const defaultTrainingState = {
    global: false,
    header: false,
    sidebarTop: false,
    sidebarBottom: false,
    support: false,
    settings: false,
    mtu: false,
    bp: false,
    mo: false,
    ageVerif: false,
    giftCards: false,
    amazonCash: false,
    netspend: false
};

export const initialClerkSettings = {
    completedTrainings: defaultTrainingState,
    closedSurveys: {} as Record<string, number | undefined>,
    mtuVideoWatched: false
}

/**
     * Gets the saved settings, and returns defaults if they've never been set.
     * Merges defaults with existing settings, so that new settings may be added.
     */
const getSavedSettings = () => {
    const settingsString = localStorage.getItem("settings");
    const settings = settingsString ? JSON.parse(settingsString) as Partial<typeof initialSettings> : {};

    return {
        ...initialSettings,
        ...settings
    };
}

const getSavedClerkSettings = () => {
    const savedClerkSettings = {} as { [key: string]: typeof initialClerkSettings | undefined };
    Object.entries<string>(localStorage)
        .filter(([key, value]) => key.startsWith("clerkSettings-") && value)
        .forEach(([key, value]) => {
            const savedPartialSettings = JSON.parse(value) as Partial<typeof initialClerkSettings>;
            savedClerkSettings[key.substring(14)] = { ...initialClerkSettings, ...savedPartialSettings };
        });
    return savedClerkSettings;
}

export const SettingsContext = createContext({
    settings: initialSettings,
    clerkSettings: initialClerkSettings,
    setSettings: (value: SetStateAction<typeof initialSettings>) => { },
    setSetting: (setting: Partial<typeof initialSettings>) => { },
    setClerkSetting: (clerkSetting: Partial<typeof initialClerkSettings>) => { },
    setTrainingSetting: (trainingSetting: Partial<typeof defaultTrainingState>) => { },
    clearAllSettings: () => { },
    incrementSurveyCount: (surveyId: number) => { }
});

interface Props {
    children: ReactNode
}

/**
 * Top-level component responsible for the Clerk / Agents' settings.
 * This is client-side configuration, separate from the parameters.
 * Some settings aren't necessarily user-set, such as if they've taken a tutorial.
 */
export default function SettingsController({ children }: Props) {

    const { parms } = useContext(ParmContext);
    const clerkId = parms?.clerkInfo?.clerkId ?? -1;
    const { system: { isPOS } } = useContext(SystemContext);

    const [settings, setSettingsState] = useState(getSavedSettings());
    const [allClerkSettings, setAllClerkSettings] = useState(getSavedClerkSettings());

    const clerkSettings = allClerkSettings[clerkId.toString()] ?? initialClerkSettings;

    const setSettings = useCallback((value: typeof initialSettings | ((prevState: typeof initialSettings) => typeof initialSettings)) => {
        setSettingsState((currentSettings) => {
            const newSettings = value instanceof Function ? value(currentSettings) : value;
            localStorage.setItem("settings", JSON.stringify(newSettings));
            return newSettings;
        });
    }, [])

    const getDefaultPrinterSettings = useCallback(async () => {
        const printers = await require('electron').ipcRenderer.invoke('getPrinters') as PrinterInfo[];
        const defaultPrinter = printers.find(({ isDefault }) => isDefault) || printers[0];
        if (defaultPrinter) {
            setSettings((currentSettings) => ({
                ...currentSettings,
                receiptPrinter: currentSettings.receiptPrinter || defaultPrinter.name,
                reportPrinter: currentSettings.reportPrinter || defaultPrinter.name,
            }))
        }
    }, [setSettings])

    const setSetting = useCallback((setting: Partial<typeof initialSettings>) => {
        setSettings((currentSettings) => {
            return { ...currentSettings, ...setting };
        });
    }, [setSettings])

    const setClerkSettings = useCallback((value: typeof initialClerkSettings | ((prevState: typeof initialClerkSettings) => typeof initialClerkSettings)) => {
        setAllClerkSettings((currentAllClerkSettings) => {
            if (clerkId > -1) {
                const currentClerkSettings = currentAllClerkSettings[clerkId.toString()] ?? initialClerkSettings;
                const newClerkSettings = value instanceof Function ? value(currentClerkSettings) : value;
                localStorage.setItem(`clerkSettings-${clerkId}`, JSON.stringify(newClerkSettings));
                return {
                    ...currentAllClerkSettings,
                    [clerkId.toString()]: newClerkSettings
                };
            }
            return currentAllClerkSettings;
        });
    }, [clerkId])

    const setClerkSetting = useCallback((clerkSetting: Partial<typeof initialClerkSettings>) => {
        setClerkSettings((currentClerkSettings) => ({
            ...currentClerkSettings,
            ...clerkSetting
        }))
    }, [setClerkSettings])

    const setTrainingSetting = useCallback((trainingSetting: Partial<typeof defaultTrainingState>) => {
        setClerkSettings((currentClerkSettings) => ({
            ...currentClerkSettings,
            completedTrainings: { ...currentClerkSettings.completedTrainings, ...trainingSetting }
        }))
    }, [setClerkSettings])

    const incrementSurveyCount = useCallback((surveyId: number) => {
        setClerkSettings((currentClerkSettings) => {
            const currentSurveyCount = Number(currentClerkSettings.closedSurveys[surveyId.toString()] ?? '0');
            return { ...currentClerkSettings, closedSurveys: { ...currentClerkSettings.closedSurveys, [surveyId.toString()]: currentSurveyCount + 1 } };
        })
    }, [setClerkSettings])

    const clearClerkSettings = useCallback(() => {
        Object.entries(localStorage)
            .map(entry => entry[0])
            .filter(key => key.startsWith("clerkSettings"))
            .forEach(key => localStorage.removeItem(key));

        setAllClerkSettings({});
    }, [])

    const clearAllSettings = useCallback(() => {
        localStorage.removeItem("settings");
        setSettings(initialSettings);
        clearClerkSettings();
    }, [clearClerkSettings, setSettings])

    useEffect(() => {
        if (isPOS) {
            setSettings((currentSettings) => ({
                ...currentSettings,
                timeZone: currentSettings.timeZone || 'America/Chicago'
            }))
        }
    }, [isPOS, setSettings]);

    useEffect(() => {
        if (isElectron) {
            getDefaultPrinterSettings();
        }
    }, [getDefaultPrinterSettings]);

    return (
        <SettingsContext.Provider value={{
            settings,
            clerkSettings,
            setSettings,
            setSetting,
            setClerkSetting,
            setTrainingSetting,
            clearAllSettings,
            incrementSurveyCount
        }} >
            {children}
        </SettingsContext.Provider>
    );
}