import type { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import type { FormikHelpers } from 'formik';
import { useFormikContext } from 'formik';
import { Redirect, useLocation } from "react-router-dom";
import ProductList from './ProductList/ProductList';
import OperatorList from './OperatorList/OperatorList'
import CartForm from '../CartForm/CartForm';
import { TrainingContext } from '../../../../HigherOrder/TrainingOverlay/TrainingOverlay';
import Processing from '../../../../Common/Processing/Processing';
import './ProviderProductForm.css';
import type { FullOperator, OperatorPreview, OperatorProps, Product, TopupProps } from 'components/hooks/MTU';
import BasicPopup from 'components/Common/Popups/BasicPopup/BasicPopup';
import { useBoolean } from 'usehooks-ts';
import type { initialValues } from '../MobileTopUp';

interface Props {
    operators: OperatorPreview[],
    getOperatorFromServer: (values: OperatorProps, actions: FormikHelpers<typeof initialValues>) => Promise<FullOperator[] | undefined>,
    tutorialProduct: string,
    tutorialOperator: { operator: string, operatorId: string },
    selectedProduct?: Product,
    selectedOperator?: FullOperator,
    selectedOperators: FullOperator[],
    setSelectedProductIndex: Dispatch<SetStateAction<number>>,
    setSelectedOperators: Dispatch<SetStateAction<FullOperator[]>>
}

/**
 * Form container for MTU. This is essnetially the second page for MTU.
 */
export default function ProviderProductForm({ operators, getOperatorFromServer, tutorialOperator, tutorialProduct, selectedProduct, selectedOperator, selectedOperators, setSelectedProductIndex, setSelectedOperators }: Props) {

    const { isCurrentPageTraining } = useContext(TrainingContext);

    const { value: showingPromotion, setTrue: openPromotion, setFalse: closePromotion } = useBoolean(false);

    const { pathname } = useLocation();
    const { values, isSubmitting } = useFormikContext<TopupProps>();

    const isOperatorTutorial = isCurrentPageTraining
        && (selectedOperator?.operatorId !== tutorialOperator.operatorId);

    const isProductTutorial = isCurrentPageTraining
        && !isOperatorTutorial
        && (selectedProduct?.retailPrice.toString() !== tutorialProduct);

    const products = selectedOperator?.products ?? [];

    const firstSegment = pathname.split('/')[1];


    if (!values.phone) {
        return (
            <Redirect to={`/${firstSegment}`} />
        );
    } else {
        return (
            <div className='ProviderProductForm'>
                {selectedOperator?.promotions && !!selectedOperator.promotions.length &&
                    <>
                        <fieldset className='PromoSection'>
                            <button type='button' onClick={openPromotion}>Promotion Available!</button>
                        </fieldset>
                        <BasicPopup
                            onClose={closePromotion}
                            open={showingPromotion}
                            className='PromoPopup'
                            fadeIn lightMode>
                            <pre>
                                {selectedOperator.promotions[0].terms.replaceAll('\\n', '\n')}
                            </pre>
                        </BasicPopup>
                    </>
                }
                <OperatorList
                    operators={operators}
                    isOperatorTutorial={isOperatorTutorial}
                    tutorialOperator={tutorialOperator}
                    setSelectedOperators={setSelectedOperators}
                    setSelectedProductIndex={setSelectedProductIndex}
                    selectedOperators={selectedOperators}
                    getOperatorFromServer={getOperatorFromServer} />
                <ProductList
                    products={products}
                    isProductTutorial={isProductTutorial}
                    tutorialProduct={tutorialProduct}
                    setSelectedProductIndex={setSelectedProductIndex}
                    selectedProduct={selectedProduct} />
                <CartForm
                    tutorialProduct={tutorialProduct}
                    selectedOperator={selectedOperator}
                    selectedProduct={selectedProduct} />
                <Processing open={isSubmitting} />
            </div>
        );
    }
}