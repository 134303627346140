import type { ChangeEvent } from 'react';
import { useContext, useMemo } from 'react';
import AdvancedField from '../../../../Common/AdvancedField/AdvancedField';
import AdvancedForm from '../../../../Common/AdvancedForm/AdvancedForm';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import Message from '../../../../Common/Message/Message';
import { SettingsContext } from '../../../../HigherOrder/SettingsController/SettingsController';
import { SystemContext } from '../../../../HigherOrder/SystemConroller/SystemController';
import styles from './IPAddressForm.module.css';
import type { FormikHelpers, FormikProps } from 'formik';
import { isLinux, isWindows } from 'utilities/Environment';
import Select from 'components/Common/Select/Select';

/**
 * Page for setting up Static / Dynamic IP Addresses.
 * Only possible in Electron. Browsers probably won't let you do this stuff for the forseeable future.
 */
export default function IPAddressForm() {

    const { settings: { staticIP }, setSetting } = useContext(SettingsContext);
    const { network: { iface }, onNetworkChange } = useContext(SystemContext);

    const saveDynamicIP = async (values: typeof initialValues) => {
        if (isWindows) {
            return saveDynamicIPWindows(values);
        } else if (isLinux) {
            return saveDynamicIPLinux(values);
        }
    }

    const saveDynamicIPLinux = async (values: typeof initialValues) => {
        const util = require('util');
        const exec = util.promisify(require('child_process').exec);
        await exec('nmcli con down "' + values.interfaceName + '"');
        await exec('nmcli con delete "' + values.interfaceName + '"');
        await exec('nmcli connection add type ethernet con-name "' + values.interfaceName + '" ifname ' + iface);
        await exec('nmcli con up "' + values.interfaceName + '"');
    }

    const saveDynamicIPWindows = async (values: typeof initialValues) => {
        const util = require('util');
        const exec = util.promisify(require('child_process').exec);
        await exec('netsh interface ip set address name="' + values.interfaceName + '" source=dhcp');
        await exec('netsh interface ip set dnsservers name="' + values.interfaceName + '" source=dhcp');
    }

    const saveStaticIP = (values: typeof initialValues) => {
        if (isWindows) {
            return saveStaticIPWindows(values);
        } else if (isLinux) {
            return saveStaticIPLinux(values);
        }
    }

    const saveStaticIPLinux = async (values: typeof initialValues) => {
        const util = require('util');
        const exec = util.promisify(require('child_process').exec);
        const { Netmask } = require('netmask');
        const block = new Netmask(values.ipAddress + '/' + values.subnetMask);
        try {
            await exec('nmcli con down "' + values.interfaceName + '"');
        } catch (err) {
            console.warn(err);
        }
        await exec('nmcli con mod "' + values.interfaceName + '"' +
            ' ipv4.addresses "' + values.ipAddress + '/' + block.bitmask + '"' +
            ' ipv4.gateway "' + values.gateway + '"' +
            ' ipv4.routes "' + values.ipAddress + '/' + block.bitmask + ' ' + values.gateway + '"' +
            ' ipv4.dns "' + values.dns1 + ' ' + values.dns2 + '"' +
            ' ipv4.method manual' +
            ' ipv4.ignore-auto-dns yes');
        return exec('nmcli con up "' + values.interfaceName + '"');
    }

    const saveStaticIPWindows = async (values: typeof initialValues) => {
        const util = require('util');
        const exec = util.promisify(require('child_process').exec);
        await exec('netsh interface ip set address name="' + values.interfaceName + '" source=static address='
            + values.ipAddress + ' mask=' + values.subnetMask + ' gateway=' + values.gateway);
        await exec('netsh interface ip delete dnsservers name="' + values.interfaceName + '" address=all');
        await exec('netsh interface ip add dnsservers name="' + values.interfaceName + '" address=' + values.dns1);
        return exec('netsh interface ip add dnsservers name="' + values.interfaceName + '" address=' + values.dns2);
    }

    const handleSubmit = async (values: typeof initialValues, { setStatus, setSubmitting }: FormikHelpers<typeof initialValues>) => {
        let promise;
        if (values.ipType === 'dynamicIP') {
            promise = saveDynamicIP(values);
        } else if (values.ipType === 'staticIP') {
            promise = saveStaticIP(values);
        } else {
            promise = Promise.resolve();
        }

        try {
            await promise;
            setStatus({ message: 'Successfully changed IP Address!' });
            onNetworkChange();
            setSetting({ staticIP: values });
        } catch (error) {
            console.error(error);
            setStatus({ message: 'Failed to change IP Address!', messageType: 'error' });
        } finally {
            setSubmitting(false);
        }
    }

    const blankValues = useMemo(() => {
        return {
            ipType: 'dynamicIP',
            ipAddress: '',
            subnetMask: '',
            gateway: '',
            dns1: '',
            dns2: '',
            interfaceName: isWindows ? iface : 'Wired connection 1'
        };
    }, [iface])

    const initialValues = useMemo(() => staticIP ?? blankValues, [blankValues, staticIP])

    return (
        <fieldset className={styles.IPAddressForm}>
            <AdvancedForm initialValues={initialValues} onSubmit={handleSubmit} >
                {({ values, setValues }: FormikProps<typeof initialValues>) => {
                    const handleTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
                        if (event.currentTarget.value === 'dynamicIP') {
                            setValues(blankValues);
                        } else if (event.currentTarget.value === 'staticIP') {
                            setValues({
                                ...initialValues,
                                ipType: event.currentTarget.value
                            });
                        }
                    }

                    return (
                        <>
                            <fieldset>
                                <Select
                                    name='ipType'
                                    onChange={handleTypeChange}
                                    placeholder='IP Type'
                                    showLabel>
                                    <option value='dynamicIP'>Dynamic IP</option>
                                    <option value='staticIP'>Static IP</option>
                                </Select>
                                <AdvancedField
                                    name='ipAddress'
                                    disabled={values.ipType !== 'staticIP'}
                                    placeholder='IP Address'
                                    showLabel />
                                <AdvancedField
                                    name='subnetMask'
                                    disabled={values.ipType !== 'staticIP'}
                                    placeholder='Subnet Mask'
                                    showLabel />
                                <AdvancedField
                                    name='gateway'
                                    disabled={values.ipType !== 'staticIP'}
                                    placeholder='Gateway'
                                    showLabel />
                            </fieldset>
                            <fieldset>
                                <AdvancedField
                                    name='interfaceName'
                                    placeholder='Interface Name (NIC)'
                                    showLabel />
                                <AdvancedField
                                    name='dns1'
                                    disabled={values.ipType !== 'staticIP'}
                                    placeholder='DNS1'
                                    showLabel />
                                <AdvancedField
                                    name='dns2'
                                    disabled={values.ipType !== 'staticIP'}
                                    placeholder='DNS2'
                                    showLabel />
                                <AdvancedButton>Save</AdvancedButton>
                            </fieldset>
                            <Message />
                        </>
                    )
                }}
            </AdvancedForm>
        </fieldset>
    );
}