import { StrictMode } from 'react';
import type { ElementType } from 'react';
import AnalyticsController from 'components/HigherOrder/AnalyticsController/AnalyticsController';
import APIController from 'components/HigherOrder/APIController/APIController';
import AuthController from 'components/HigherOrder/AuthController/AuthController';
import MoneyOrderController from 'components/HigherOrder/MoneyOrderController/MoneyOrderController';
import ParmController from 'components/HigherOrder/ParmController/ParmController';
import PrintController from 'components/HigherOrder/PrintController/PrintController';
import SettingsController from 'components/HigherOrder/SettingsController/SettingsController';
import SystemController from 'components/HigherOrder/SystemConroller/SystemController';
import TrainingOverlay from 'components/HigherOrder/TrainingOverlay/TrainingOverlay';
import SurveyController from 'components/HigherOrder/SurveyController/SurveyController';
import Navigator from './Navigator/Navigator';
import Updater from './Updater';
import { BrowserRouter, HashRouter } from "react-router-dom";
import './App.css';
import { isElectron, isLocal } from 'utilities/Environment';

const Router = (isElectron && !isLocal ? HashRouter : BrowserRouter) as ElementType;

export default function App() {
    return (
        <StrictMode>
            <div className='App'>
                <Router>
                    <SystemController>
                        <AuthController>
                            <ParmController>
                                <SettingsController>
                                    <MoneyOrderController>
                                        <APIController>
                                            <SurveyController>
                                                <PrintController>
                                                    <AnalyticsController>
                                                        <TrainingOverlay>
                                                            <Updater >
                                                                <Navigator />
                                                            </Updater>
                                                        </TrainingOverlay>
                                                    </AnalyticsController>
                                                </PrintController>
                                            </SurveyController>
                                        </APIController>
                                    </MoneyOrderController>
                                </SettingsController>
                            </ParmController>
                        </AuthController>
                    </SystemController>
                </Router>
            </div>
        </StrictMode>
    );
}