import { useContext } from 'react';
import { Field, useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import Message from '../../../../Common/Message/Message';
import AdvancedButton from '../../../../Common/AdvancedButton/AdvancedButton';
import { ParmContext } from '../../../../HigherOrder/ParmController/ParmController';
import styles from './LoginForm.module.css';
import type { FormValues } from '../LoginController';

const capsMessage = "Caps Lock is on";

export default function LoginForm() {

    const { status, setStatus } = useFormikContext<FormValues>();
    const { parms } = useContext(ParmContext);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.getModifierState("CapsLock")) {
            if (status?.message !== capsMessage) {
                setStatus({ message: capsMessage, messageType: 'error' });
            }
        } else if (status?.message === capsMessage) {
            setStatus({ message: "" });
        }
    }

    return (
        <div className={styles.loginForm} >
            <section>
                {!parms &&
                    <Field
                        type='text'
                        name='agent'
                        placeholder='Agent Number'
                        maxLength={11}
                        autoFocus={true}
                        required />
                }
                <Field
                    type='text'
                    name='username'
                    placeholder='Username'
                    maxLength={15}
                    autoFocus={!!parms}
                    required />
                <Field
                    type='password'
                    name='password'
                    placeholder='Password'
                    maxLength={25}
                    onKeyDown={handleKeyDown}
                    required />
            </section>
            <section>
                <AdvancedButton>Submit</AdvancedButton>
            </section>
            <section>
                <Link to='/Login/Challenge'>Forgot Password?</Link>
            </section>
            <Message />
        </div>
    );
}