import Menu from '../Menu/Menu';
import styles from './Default.module.css';
import MTUVideo from 'components/Common/Popups/MTUVideo/MTUVideo';
import Survey from 'components/Common/Popups/Survey/Survey';
import AMLPopup from 'components/Common/Popups/AMLPopup/AMLPopup';
import { useContext, useMemo } from 'react';
import { ParmContext } from 'components/HigherOrder/ParmController/ParmController';
import { addYears, differenceInDays, parse } from 'date-fns';
import { TrainingContext } from 'components/HigherOrder/TrainingOverlay/TrainingOverlay';
import { SettingsContext } from 'components/HigherOrder/SettingsController/SettingsController';
import { SurveyContext } from 'components/HigherOrder/SurveyController/SurveyController';
import { useBoolean } from 'usehooks-ts';
import { serverParse } from 'components/hooks/TimeZone';

export default function Default() {

    const { clerkSettings: { mtuVideoWatched } } = useContext(SettingsContext);
    const { currentSurvey } = useContext(SurveyContext);
    const { isTraining } = useContext(TrainingContext);
    const { parms } = useContext(ParmContext);
    const lastAMLDate = parms?.clerkInfo.lastAMLDate;
    const lastMTUTransaction = parms?.clerkInfo.lastMTUTransaction;

    const shouldAML = useMemo(() => {
        if (lastAMLDate) {
            const parsedDate = parse(lastAMLDate, 'yyyyMMdd', new Date());
            const amlDueDate = addYears(parsedDate, 1);
            const amlDaysLeft = differenceInDays(amlDueDate, new Date());
            return amlDaysLeft <= 21
        }
        return false;
    }, [lastAMLDate]);

    const daysSinceLastMTU = useMemo(() => {
        if (lastMTUTransaction) {
            const parsedLastMTUTransaction = serverParse(lastMTUTransaction, 'yyyy-MM-dd HH:mm:ss.SSSSSS');
            return differenceInDays(new Date(), parsedLastMTUTransaction);
        }
        return 99999;
    }, [lastMTUTransaction]);

    const shouldMTUVideo = !mtuVideoWatched
        && !!(parms?.parameters.mtuVideo)
        && daysSinceLastMTU > 90;

    const shouldSurvey = !!currentSurvey;

    const { value: amlClosed, setTrue: closeAML } = useBoolean(false);
    const { value: mtuVideoClosed, setTrue: closeMTUVideo } = useBoolean(false);

    const amlOpen = !isTraining && shouldAML && !amlClosed;
    const mtuOpen = !isTraining && shouldMTUVideo && !mtuVideoClosed && !amlOpen;
    const surveyOpen = !isTraining && shouldSurvey && !mtuOpen && !amlOpen;

    return (
        <div className={styles.default}>
            <AMLPopup closed={!amlOpen} close={closeAML} />
            <MTUVideo closed={!mtuOpen} close={closeMTUVideo} />
            <Survey closed={!surveyOpen} />
            <Menu noOverlay open={!mtuOpen && !surveyOpen && !amlOpen} />
        </div>
    );
}